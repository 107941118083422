import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useRef, useEffect } from "react";
import addIcon from "../../../../assets/LoginIcons/add.svg";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { CheckCircle, Delete, ErrorRounded } from "@mui/icons-material";

import "../Invoice.css";

const Invoiceids = (props) => {
  const [test, setTest] = useState([]);
  const [message, setMessage] = useState([]);
  const [randomNum, setRandomNumber] = useState();
  const axiosPrivate = useAxiosPrivate();
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const progressInfosRef = useRef(null);

  const fileupload = async (file, onUploadProgress) => {
    let formData = new FormData();

    formData.append("file", file);

    formData.append("uid", `${randomNum}`);

    setTest((value) => [...value, file]);
    await axiosPrivate.post("/mailbox", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  };

  useEffect(() => {
    let x = Math.floor(Math.random() * 100000000000 + 1);
    setRandomNumber(x);
  }, []);

  const upload = async (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    return fileupload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      setProgressInfos((vale) => ({ ...vale, val: _progressInfos }));
    })
      .then(() => {
        setMessage((prevMessage) => [...prevMessage, "success"]);
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        setProgressInfos({ val: _progressInfos });
        setMessage((prevMessage) => [...prevMessage, "error"]);
      });
  };

  const uploadFiles = async (event) => {
    const files = Array.from(event.target.files);
    let merged = files.concat(test);
    merged = merged.filter((item, index) => {
      return merged.indexOf(item) === index;
    });

    let _progressInfos = merged.map((file) => ({
      percentage: 0,
      fileName: file.name,
    }));
    progressInfosRef.current = {
      val: _progressInfos,
    };

    const uploadPromises = merged.map((file, i) => upload(i, file));

    // Promise.all(uploadPromises)
    //   .then(() => getFiles())
    //   .then((files) => {});

    setMessage([]);
  };

  const deleteHandler = async (i, e) => {
    const del = progressInfos.val.filter((User, index) => i !== index);
    const el = progressInfos.val.filter((User, index) => i === index);

    setProgressInfos({ val: del });
    const bar = test.filter((User, index) => i !== index);
    setTest(bar);

    // try {
    //   await axiosPrivate.get(
    //     `mailbox/delete-attachment/${randomNum}/${el[0].fileName}`,
    //     {}
    //   );
    // } catch (err) {}
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          SC106647 - Project 3 (US Gen Pop 18+)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="col-3">
            <label className="formlabelamit">Complete Id’s</label>
            <textarea
              name=""
              id=""
              placeholder=""
              className="invoice_emailcont"
            ></textarea>
          </Col>
        </Row>
        <Col lg={12} md={6} sm={6} xs={12} className="col-3">
          <div className="attachmentContainer">
            {progressInfos &&
              progressInfos.val.length > 0 &&
              progressInfos.val.map((progressInfo, index) => (
                <div key={index} className="Invoice_attachmentItem">
                  <div className="attachmentContent">
                    <span className="invoice_fileInfo">
                      {progressInfo.fileName}
                    </span>
                    {message[index] === "success" ? (
                      <CheckCircle className="success" />
                    ) : (
                      <ErrorRounded className="error" />
                    )}

                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-default"
                        role="progressbar"
                        aria-valuenow={progressInfo.percentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: progressInfo.percentage + "%" }}
                      >
                        {progressInfo.percentage}%
                      </div>
                    </div>
                    <Delete
                      className="deleteAttachment"
                      title="Remove Attachment"
                      onClick={(e) => deleteHandler(index, e)}
                    />
                  </div>
                </div>
              ))}
          </div>
        </Col>
        <Row>
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={6}
            // className="col-3"
            style={{
              borderRight: "1px solid black",
            }}
          >
            <input type="checkbox" id="AppendId" name="vehicle1" value="Bike" />
            <label for="AppendId" className="invoicelabel">
              Append Id’s
            </label>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={6}
            // className="col-3"
            // style={{
            //   paddingLeft: "85px",
            // }}
          >
            <input type="checkbox" id="AppendId" name="vehicle1" value="Bike" />
            <label for="AppendId" className="invoicelabel">
              Upload Approved
            </label>
          </Col>
        </Row>
        <Row>
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className="col-3"
            style={{
              borderRight: "1px solid black",
            }}
          >
            <input type="checkbox" id="AppendId" name="vehicle1" value="Bike" />
            <label for="AppendId" className="invoicelabel">
              Overwrite Id’s
            </label>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className="col-3"
            // style={{
            //   paddingLeft: "85px",
            // }}
          >
            <input type="checkbox" id="AppendId" name="vehicle1" value="Bike" />
            <label for="AppendId" className="invoicelabel">
              Upload Rejected
            </label>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col lg={12} md={12} sm={12} xs={12} className="col-3">
          <button className="cbtn saveBtn" type="submit">
            Send{" "}
          </button>
          <label
            className="cbtn addBtn"
            style={{ cursor: "pointer", float: "left", marginLeft: "8px" }}
          >
            <img src={addIcon} className="headingIcons" alt="" /> Attachment
            <input
              type="file"
              multiple
              onChange={uploadFiles}
              className="attachInput"
            />
          </label>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default Invoiceids;
