import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import "./RequestStatus.css";

function AddManualSupplier(props) {
  const [selected, setSelected] = useState([]);
  const options = [
    { label: "Dyanata", value: "dyanata" },
    { label: "Api", value: "api" },
    { label: "Fulcrome", value: "fulcrome" },
  ];
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Add Manual Supplier
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row>
            <Col lg={4} md={12} sm={12} xs={12} className="csmulti">
              <label className="formlabelamit">Supplier Name </label>
              <div>
                <MultiSelect
                  className=""
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy={"Select"}
                  isCreatable={true}
                  disableSearch
                />
              </div>
            </Col>
          </Row>
          {selected.map((e) => (
            <>
              {" "}
              <div style={{ height: "15px" }}></div>
              <Row
                style={{
                  backgroundColor: "whitesmoke",
                  margin: "2px",
                  paddingTop: "10px",
                  paddingBottom: "20px",
                }}
              >
                <Col lg={4} md={4} sm={4} xs={12}>
                  <label className="formlabelamit">Supplier Name</label>
                  <input
                    placeholder="Cint"
                    className="formtextamit"
                    type="text"
                  />
                </Col>
                <Col lg={4} md={4} sm={4} xs={12}>
                  <label className="formlabelamit">CPI</label>
                  <input
                    placeholder="CPI"
                    className="formtextamit"
                    type="text"
                  />
                </Col>
                <Col lg={4} md={4} sm={4} xs={12}>
                  <label className="formlabelamit">Quota</label>
                  <input
                    placeholder="Quota"
                    className="formtextamit"
                    type="text"
                  />
                </Col>
              </Row>
            </>
          ))}

          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <label className="formlabelamit">Notes</label>
              <textarea
                style={{ height: "100px" }}
                placeholder="Notes"
                className="formtextamit"
                type="text"
              />
            </Col>
          </Row>
          <Col>
            <button
              class="cbtn saveBtn"
              style={{
                marginTop: "20px",
                marginRight: "10px",
              }}
            >
              Submit
            </button>
          </Col>
          <div className="amit" style={{ height: "10px" }}></div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default AddManualSupplier;
