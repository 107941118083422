const InsufficientPermisiion = () => {
  return (
    <>
      {" "}
      <div style={{ textAlign: "center" }}>
        <h5>InsufficientPermisiion</h5>
      </div>
    </>
  );
};

export default InsufficientPermisiion;
