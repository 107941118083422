import Modal from "react-bootstrap/Modal";
import "../Popup/RequestStatus.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

function RequestStatus(props) {
  const [selected, setSelected] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const Status = [
    { label: "Live", value: "live" },
    { label: "Paused", value: "paused" },
    { label: "Pending", value: "pending" },
    { label: "Off set inquiry", value: "off set inquiry" },
  ];
  const User = [
    { label: "Arvind", value: "arvind" },
    { label: "Kapil", value: "kapil" },
    { label: "Shahpar", value: "shahpar" },
    { label: "Sunny", value: "sunny" },
  ];
  return (
    <Modal
      {...props}
      size="lg"
      style={{ width: "50%", marginLeft: "25%" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Request Status/Owner
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="csmulti" lg={6} md={6} sm={12} xs={12}>
            <label className="formlabelamit">Request Status</label>
            <MultiSelect
              className="multiselectoption"
              options={Status}
              value={selected}
              onChange={setSelected}
              labelledBy={"Select"}
              isCreatable={true}
              disableSearch
            />
          </Col>
          <Col className="csmulti" lg={6} md={6} sm={12} xs={12}>
            <label className="formlabelamit">Request Owner</label>
            <MultiSelect
              className="multiselectoption"
              options={User}
              value={selectedUser}
              onChange={setSelectedUser}
              labelledBy={"Select"}
              isCreatable={true}
              disableSearch
            />
          </Col>
        </Row>
        <div style={{ height: "10px" }}></div>
        <Col>
          <button
            class="cbtn saveBtn"
            style={{
              marginTop: "20px",
              marginRight: "10px",
            }}
          >
            Submit
          </button>
        </Col>
      </Modal.Body>
    </Modal>
  );
}
export default RequestStatus;
