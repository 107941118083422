import pageIcon from "../../../../assets/BidsIcons/client.svg";
import addIcon from "../../../../assets/BidsIcons/add.svg";
import BidsSubHeader from "../../../BidsModule/BidsSubHeader/BidsSubHeader";
import Bidstopheader from ".././Bidstopheader";
import Header from "../../../UI/Header/Header";
import { useNavigate, useParams } from "react-router";
import DataTable from "react-data-table-component";
import SpinnerLoader from "../../../UI/Spinner";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import EditIcon from "../../../../assets/LoginIcons/edit.svg";
import toast from "react-hot-toast";
import "../../../AdminModule/Tables/StyleDatatable.css";
import Bidstopheaderselect from "../../BidsTable/BidsTopHeaderSelect";
import useAuth from "../../../../hooks/useAuth";

const RegionListing = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(20);
  const [filterUser, setFilterUser] = useState(null);
  const [clientContactList, setClientContactList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  var localData = localStorage.getItem("clientname");
  const [pending, setPending] = useState(true);

  // ..........................permisssion.........
  const { auth } = useAuth();
  const superUser = auth.superuser;
  const all_permission = auth.permission;

  // ..........................permisssion.........

  const { id } = useParams();
  var buttonName = "region";

  useEffect(() => {
    get_CLientContact_AllList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_CLientContact_AllList = async () => {
    try {
      const response = await axiosPrivate.get(
        `/client/client-region-list/${id}`,
        {}
      );

      let data = response.data.all_client_region;
      setClientContactList(data);
      setPending(false);
    } catch (err) {}
    return () => {};
  };

  const notifyerror = (e) => toast.error(`${e} error occure`);
  const columns = [
    {
      id: "sno",
      name: "Sno.",
      selector: (row, i) => currentRowsPerPage * (currentPage - 1) + (i + 1),
      width: "100px",
    },
    {
      id: "region",
      name: "Region",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <>
          {superUser === true ? (
            <>
              {" "}
              <BootstrapSwitchButton
                className="switch btn on btn-outline-success btn-xs"
                checked={row.status === 1 ? true : false}
                onChange={(e) => {
                  handle_status(row.id, e);
                }}
                size="xs"
                width={80}
                onlabel="Active"
                offlabel="Inactive"
                onstyle="outline-success"
                offstyle="outline-danger"
              />
            </>
          ) : (
            <>
              {all_permission.includes("client.client_change_region_status") ? (
                <>
                  {" "}
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    checked={row.status === 1 ? true : false}
                    onChange={(e) => {
                      handle_status(row.id, e);
                    }}
                    size="xs"
                    width={80}
                    onlabel="Active"
                    offlabel="Inactive"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ),
      width: "120px",
    },
    {
      name: "Action",
      selector: (row) => (
        // <td>

        // </td>
        <td>
          {superUser === true ? (
            <>
              <span
                className="actionButton"
                onClick={() => {
                  navigate(`/update-region/${id}/${row.id}`);
                }}
              >
                <img src={EditIcon} alt="" />
              </span>
              <span className="actionButton">
                <img alt="" />
              </span>
            </>
          ) : (
            <>
              {all_permission.includes("client.client_update_region") ? (
                <>
                  {" "}
                  <span
                    className="actionButton"
                    onClick={() => {
                      navigate(`/update-region/${id}/${row.id}`);
                    }}
                  >
                    <img src={EditIcon} alt="" />
                  </span>
                  <span className="actionButton">
                    <img alt="" />
                  </span>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </td>
      ),
      width: "110px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "40px",
      },
    },
  };

  const handle_status = async (regionid, e) => {
    try {
      await axiosPrivate
        .patch(
          "client/active-inactive-client-region",
          JSON.stringify({
            client_id: id,
            client_region_id: regionid,
            new_status: e,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          if (e.data.new_status === 1) {
            notifySuccess("Region is active");
          } else {
            notifySuccess("Region is inactive");
          }
        });
    } catch (err) {
      notifyerror(err.response.statusText);
    }
  };
  const notifySuccess = (msg) => {
    toast.success((t) => (
      <span style={{ color: "white" }}>
        {msg}
        <button className="closeToaster" onClick={() => toast.dismiss(t.id)}>
          x
        </button>
      </span>
    ));
  };

  const showSearch = (searchText) => {
    const copyOfUsers = [...clientContactList];
    setSearch(searchText);
    const result = copyOfUsers.filter((points) => {
      return points.name
        .toString()
        .toLowerCase()
        .match(searchText.toString().toLowerCase());
    });
    setFilterUser(result);
  };
  return (
    <>
      {/* <Header /> */}
      <BidsSubHeader pname="region" />

      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachbox">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" />{" "}
                {localData}
              </h3>
            </div>
            <input
              type="search"
              className="tableSearch"
              placeholder="Search..."
              value={search}
              onChange={(e) => showSearch(e.target.value)}
            />

            {superUser === true ? (
              <>
                <button //style={{marginTop:'5px'}}
                  className="cbtn addBtn"
                  onClick={() => {
                    navigate(`/add-region/${id}`);
                  }}
                >
                  <img src={addIcon} alt="" /> &nbsp; Add Region
                </button>
              </>
            ) : (
              <>
                {all_permission.includes("client.client_add_region") ? (
                  <>
                    <button
                      className="cbtn addBtn"
                      onClick={() => {
                        navigate(`/add-region/${id}`);
                      }}
                    >
                      <img src={addIcon} alt="" /> &nbsp; Add Region
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <hr className="showingHeader" />
          {/* <Bidstopheader buttonName={buttonName} tid="5" clientid={id} /> */}
          <Bidstopheader clientid={id} tid="5" className="showingHeader" />
          <Bidstopheaderselect clientid={id} namevalue={"region"} />
          <div className="mainContent dTable bids">
            <DataTable
              columns={columns}
              data={filterUser || clientContactList}
              pagination
              fixedHeader
              highlightOnHover
              onChangePage={(page, totalRows) => {
                setCurrentPage(page);
              }}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setCurrentRowsPerPage(currentRowsPerPage);
              }}
              defaultSortFieldId="region"
              defaultSortAsc={true}
              progressPending={pending}
              customStyles={customStyles}
              progressComponent={<SpinnerLoader />}
              noDataComponent="No Data Found"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default RegionListing;
