import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "./APRequest.css";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
function MyVerticallyCenteredModal(props) {
  const axiosPrivate = useAxiosPrivate();
  const [selected, setSelected] = useState([]);
  const [devicetype, setDeviceTYpe] = useState([]);
  const [targetType, setTargetTYpe] = useState([]);
  const [targetdetaioption, setTragetDetail] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const options = [
    { label: "Grapes", value: "grapes" },
    { label: "Mango", value: "mango" },
    { label: "Strawberry", value: "strawberry" },
    { label: "Grapes", value: "grapes" },
    { label: "Mango", value: "mango" },
  ];
  // const devicetypearray = [
  //   { label: "Mobile", value: "Mobile" },
  //   { label: "Tablet", value: "Tablet" },
  //   { label: "Laptop", value: "Laptop" },
  // ];
  const devicetypearray2 = [
    { label: "Mobile", value: "Mobile" },
    { label: "Tablet", value: "Tablet" },
    { label: "Laptop", value: "Laptop" },
  ];
  useEffect(() => {
    get_DeviceType_api();
    get_Supplierlist_api();
    get_survey_project_Listapi();
  }, []);
  const [deviceTypeStorage, setDeviceTypestorage] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const get_DeviceType_api = async () => {
    const response = await axiosPrivate.get("lookup/get-device-target ");
    setDeviceTypestorage([]);
    response.data.all_status.map((e) => {
      setDeviceTypestorage((val) => [...val, { label: e.name, value: e.id }]);
    });
  };

  const get_Supplierlist_api = async () => {
    try {
      const response = await axiosPrivate.get("supplyinfo/get-supplier-list ");
      setSupplierList([]);
      response.data.supplier.map((e) => {
        setSupplierList((val) => [...val, { label: e.name, value: e.id }]);
      });
    } catch (err) {
      // console.error(err);
    }
  };
  const get_survey_project_Listapi = async () => {
    try {
      const response = await axiosPrivate.get(
        "survey/get-survey-project-id?project_id=64abe693cefc90f21feef510"
      );
      setTragetDetail([]);
      response.data.survey.map((e) => {
        setTragetDetail((val) => [
          ...val,
          {
            label: e._id,
            value: e._id,
            quota: e.n,
            ir: e.ir,
            loi: e.loi,
            cpi: e.cpi,
          },
        ]);
      });
    } catch (err) {
      // console.error(err);
    }
  };
  const handleSaveButton = async (e) => {};
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "10px" }}
        >
          +Add Partner Request
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleSaveButton)}>
          <div className="csmulti">
            <Row style={{ paddingBottom: "10px" }}>
              <Col lg={3} md={6} sm={6} xs={12} className="col-3">
                <label className="formlabelamit">Target Details</label>
                <MultiSelect
                  className="multiselectoption "
                  options={deviceTypeStorage}
                  value={targetType}
                  onChange={(e) => {
                    setTargetTYpe(e);
                  }}
                  labelledBy={"Select"}
                  isCreatable={true}
                  disableSearch
                />
              </Col>{" "}
            </Row>

            {targetType.map((e, index) => (
              <div
                className="container"
                style={{ border: "1px solid #f2f0f0", marginBottom: "10px" }}
              >
                <Row>
                  <p className="apr_loctionP">{e.label}</p>
                </Row>
                <Row style={{ paddingBottom: "10px" }}>
                  <Col lg={3} md={6} sm={6} xs={12} className="col-3">
                    <label className="formlabelamit">Quota</label>
                    <input
                      placeholder="Quota"
                      className="formtextamit"
                      type="text"
                      {...register(`quota.${[index]}.age`, {
                        required: "This field is Required",
                      })}
                      defaultValue={e.quota}
                    />
                    {errors?.quota?.[index]?.age && (
                      <small className="errmsg">
                        {errors.quota[index].age.message}
                      </small>
                    )}
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={12} className="col-3">
                    <label className="formlabelamit">IR</label>
                    <input
                      placeholder="IR"
                      className="formtextamit"
                      type="text"
                      {...register(`ir.${[index]}.age`, {
                        required: "This field is Required",
                      })}
                      defaultValue={e.ir}
                    />
                    {errors?.ir?.[index]?.age && (
                      <small className="errmsg">
                        {errors.ir[index].age.message}
                      </small>
                    )}
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={12} className="col-3">
                    <label className="formlabelamit">LOI</label>
                    <input
                      placeholder="LOI"
                      className="formtextamit"
                      type="text"
                      {...register(`loi.${[index]}.age`, {
                        required: "This field is Required",
                      })}
                      defaultValue={e.loi}
                    />
                    {errors?.loi?.[index]?.age && (
                      <small className="errmsg">
                        {errors.loi[index].age.message}
                      </small>
                    )}
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={12} className="col-3">
                    <label className="formlabelamit">CPI</label>
                    <input
                      placeholder="CPI"
                      className="formtextamit"
                      type="text"
                      {...register(`cpi.${[index]}.age`, {
                        required: "This field is Required",
                      })}
                      defaultValue={e.cpi}
                    />
                    {errors?.cpi?.[index]?.age && (
                      <small className="errmsg">
                        {errors.cpi[index].age.message}
                      </small>
                    )}
                  </Col>
                </Row>
              </div>
            ))}

            <div>
              <Row>
                <Col lg={6} md={6} sm={6} xs={12}>
                  <label className="formlabelamit">Supplier</label>
                  <MultiSelect
                    className="multiselectoption"
                    options={supplierList}
                    value={selected}
                    onChange={setSelected}
                    labelledBy={"Select"}
                    isCreatable={true}
                    disableSearch
                  />
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col lg={6} md={6} sm={6} xs={12}>
                  <label className="formlabelamit">PM Comments</label>
                  <textarea
                    style={{ height: "75px" }}
                    placeholder="PM Comments"
                    className="formtextamit"
                    type="text"
                  />
                </Col>
                <Col lg={6} md={6} sm={6} xs={12}>
                  <label className="formlabelamit">Notes</label>
                  <textarea
                    style={{ height: "75px" }}
                    placeholder="Notes"
                    className="formtextamit"
                    type="text"
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="apr_submitbtn">
            <button className="supply_setupbtn">Submit</button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
export default MyVerticallyCenteredModal;
