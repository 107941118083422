import Modal from "react-bootstrap/Modal";
import React from "react";
import Table from "react-bootstrap/Table";
import { FiDownload } from "react-icons/fi";
import SupplierLink from "./SupplierLInk";
import EditSupplier from "./EditSupplier";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./SurveyTracking.css";
import EditIcon from "../../../../assets/LoginIcons/edit.svg";
import LinkIcon from "../../../../assets/CSicons/icons/link.svg";
function SurveyTracking(props) {
  const [editSupplier, setEditSupplier] = useState(false);
  const [supplierLink, setSupplierLink] = useState(false);
  return (
    <>
      <EditSupplier
        show={editSupplier}
        onHide={() => setEditSupplier(false)}
        props={props}
        setEditSupplier={setEditSupplier}
      />
      <SupplierLink
        show={supplierLink}
        onHide={() => setSupplierLink(false)}
        props={props}
        setSupplierLink={setSupplierLink}
      />
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="quota_survey_track_title"
            id="contained-modal-title-vcenter"
            style={{
              fontSize: "14px",
              paddingLeft: "3px",
            }}
          >
            Project 3 - US Gen Pop 18+
            <div className="">
              <button
                className="Resp
              endata"
              >
                Screening Data
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr style={{ backgroundColor: "#f1efef" }} className="tabledata">
                <th
                  style={{
                    borderBottom: "none",
                  }}
                >
                  Survey Parameter
                </th>
                <th>Survey Details</th>
                <th>Disqo API</th>
                <th>Fulcrum API</th>
                <th>Theorem API</th>
                <th>Prodege API</th>
                <th>Purespectrum API</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "11px" }}>
              <tr>
                <td
                  style={{
                    backgroundColor: "#f1efef",
                    borderBottom: "none",
                  }}
                ></td>
                <td>
                  <span className="fifteenspan">0/</span>
                  15
                </td>
                <td>
                  <span className="fifteenspan">0/</span>
                  15
                </td>
                <td>
                  <span className="fifteenspan">0/</span>
                  15
                </td>
                <td>
                  <span className="fifteenspan">0/</span>
                  15
                </td>
                <td>
                  <span className="fifteenspan">0/</span>
                  15
                </td>
                <td>
                  <span className="fifteenspan">0/</span>
                  15
                </td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">PreScreen</td>
                <td>
                  <div className="inactive_qutobtn">Inactive</div>
                </td>
                <td>
                  <div className="off_qutobtn">OFF</div>
                </td>
                <td>
                  <div className="off_qutobtn">OFF</div>
                </td>{" "}
                <td>
                  <div className="off_qutobtn">OFF</div>
                </td>{" "}
                <td>
                  <div className="off_qutobtn">OFF</div>
                </td>{" "}
                <td>
                  <div className="off_qutobtn">OFF</div>
                </td>
              </tr>
              <tr className="tabledata">
                <td
                  style={{
                    backgroundColor: "#f1efef",
                    borderBottom: "none",
                  }}
                >
                  Started
                </td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
              </tr>
              <tr className="tabledata">
                <td
                  style={{
                    backgroundColor: "#f1efef",
                    borderBottom: "none",
                  }}
                >
                  Incomplete SC/CL
                </td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Reached Client Survey</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Return from Client</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Complete</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Terminate SC/CL</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
              </tr>{" "}
              <tr className="tabledata">
                <td className="qutasiderow">Api Term/Quota</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Quotafull</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Quality Terninate</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Reject</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Duplicate</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Speeder Terminate</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Invalid IP(Country Check)</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">WiseCheck(Dup IP)</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Fraudulent</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">SC Close</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">IP Octet</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Abandon % (SC/CL)</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Conversion(%)</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Overall Conversion(%)</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Incidene Rate %</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Quotafull %</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Margin(%)</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr
                style={{
                  fontSize: "18px",
                  backgroundColor: "#f1efef",
                  borderBottom: "none",
                }}
              >
                <td></td>
                <td>
                  <FiDownload className="quota_iconBtn " />
                  &nbsp;
                  <img
                    src={LinkIcon}
                    alt="LinkIcon"
                    srcset=""
                    onClick={() => {
                      setSupplierLink(true);
                      props.setSetting(false);
                    }}
                    className="quota_iconBtn "
                  />
                  &nbsp;
                  <img
                    src={EditIcon}
                    alt="EditIcon"
                    srcset=""
                    onClick={() => {
                      setEditSupplier(true);
                      props.setSetting(false);
                    }}
                    className="quota_iconBtn "
                  />
                </td>
                <td>
                  <FiDownload className="quota_iconBtn " />
                  &nbsp;
                  <img
                    src={LinkIcon}
                    alt="LinkIcon"
                    srcset=""
                    onClick={() => {
                      setSupplierLink(true);
                      props.setSetting(false);
                    }}
                    className="quota_iconBtn "
                  />
                  &nbsp;
                  <img
                    src={EditIcon}
                    alt="EditIcon"
                    srcset=""
                    onClick={() => {
                      setEditSupplier(true);
                      props.setSetting(false);
                    }}
                    className="quota_iconBtn "
                  />
                </td>
                <td>
                  <FiDownload className="quota_iconBtn " />
                  &nbsp;
                  <img
                    src={LinkIcon}
                    alt="LinkIcon"
                    srcset=""
                    onClick={() => {
                      setSupplierLink(true);
                      props.setSetting(false);
                    }}
                    className="quota_iconBtn "
                  />
                  &nbsp;
                  <img
                    src={EditIcon}
                    alt="EditIcon"
                    srcset=""
                    onClick={() => {
                      setEditSupplier(true);
                      props.setSetting(false);
                    }}
                    className="quota_iconBtn "
                  />
                </td>
                <td>
                  <FiDownload className="quota_iconBtn " />
                  &nbsp;
                  <img
                    src={LinkIcon}
                    alt="LinkIcon"
                    srcset=""
                    onClick={() => {
                      setSupplierLink(true);
                      props.setSetting(false);
                    }}
                    className="quota_iconBtn "
                  />
                  &nbsp;
                  <img
                    src={EditIcon}
                    alt="EditIcon"
                    srcset=""
                    onClick={() => {
                      setEditSupplier(true);
                      props.setSetting(false);
                    }}
                    className="quota_iconBtn "
                  />
                </td>
                <td>
                  <FiDownload className="quota_iconBtn " />
                  &nbsp;
                  <img
                    src={LinkIcon}
                    alt="LinkIcon"
                    srcset=""
                    onClick={() => {
                      setSupplierLink(true);
                      props.setSetting(false);
                    }}
                    className="quota_iconBtn "
                  />
                  &nbsp;
                  <img
                    src={EditIcon}
                    alt="EditIcon"
                    srcset=""
                    onClick={() => {
                      setEditSupplier(true);
                      props.setSetting(false);
                    }}
                    className="quota_iconBtn "
                  />
                </td>
                <td>
                  <FiDownload className="quota_iconBtn " />
                  &nbsp;
                  <img
                    src={LinkIcon}
                    alt="LinkIcon"
                    srcset=""
                    onClick={() => {
                      setSupplierLink(true);
                      props.setSetting(false);
                    }}
                    className="quota_iconBtn "
                  />
                  &nbsp;
                  <img
                    src={EditIcon}
                    alt="EditIcon"
                    srcset=""
                    onClick={() => {
                      setEditSupplier(true);
                      props.setSetting(false);
                    }}
                    className="quota_iconBtn "
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default SurveyTracking;
