import React, { useState, useEffect } from "react";
import Header from "../../UI/Header/Header";
import BidsSubHeader from "../../BidsModule/BidsSubHeader/BidsSubHeader";
import { useNavigate } from "react-router";
import pageIcon from "../../../assets/BidsIcons/addClient.svg";
import Bidstopheader from "./Bidstopheader";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useParams } from "react-router";
import toast from "react-hot-toast";
import ButtonSpinner from "../../UI/ButtonSpinner";
import { useForm } from "react-hook-form";
import SpinnerLoader from "../../UI/Spinner";
import Select from "react-select";
import Bidstopheaderselect from "../BidsTable/BidsTopHeaderSelect";
import useAuth from "../../../hooks/useAuth";
import Spinner from "react-bootstrap/Spinner";
const UpdateClient = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [clientName, setClientName] = useState();
  const [billingAddress, setbillingAddress] = useState();
  const [accountOwner, setAccountOwner] = useState([]);
  const [clientType, setClientType] = useState([]);
  const [projectMinFee, setProjectMinFee] = useState();
  const [phone, setPhone] = useState();
  const [country, setCountry] = useState([]);
  const [secretKey, setSecretKey] = useState();
  const [city, setCity] = useState();
  const [zipcode, setZipcode] = useState();
  const [stateId, setStateId] = useState();
  const [selectedstateId, setSelectedstateId] = useState();
  const [selectedcountId, setSelectedcountId] = useState();
  const { id } = useParams();
  const [clientNameType, setClientNameType] = useState();
  const [apidata, setApidata] = useState(0);
  const [accountOwnerss, setAccountOwnerss] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [approvedstate, setApprovedstate] = useState();
  const [allState, setAllState] = useState([]);
  var localData = localStorage.getItem("clientname");
  const [loder, setLoder] = useState(false);

  const clientId = parseInt(id);
  const { auth } = useAuth();

  // ..........................permisssion.........
  const superUser = auth.superuser;
  const all_permission = auth.permission;

  useEffect(() => {
    getCountriesList();
    clientTypes();
    getClientById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  useEffect(() => {
    userSlectedState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const updateClientApi = async (end) => {
    setIsSubmitting(true);
    const clientId = parseInt(id);

    var state = end.state;

    if (state === undefined || state === "") {
      state = null;
    }

    try {
      await axiosPrivate
        .patch(
          "client/update-client",
          JSON.stringify({
            client_id: clientId,
            name: end.Name,
            user_reference_id: accountOwnerss,
            client_type_id: end.clientType,
            project_minimum_fee: end.ProjectFee,
            excluded_vendor_id: [],
            secret_key: end.secretKey,
            phone: end.Phone,
            billing_address: end.BillingAdress,
            country_id: end.country,
            state_id: state,
            city: end.City,
            zipcode: end.Zipcode,
            status: 1,
            is_api: end.isApi,
            is_approved: end.Approved,
            quota_check_type: 0,
          }),
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((e) => {
          if (e.status === 200) {
            notifysucces();
            navigate("/clients");
          }
        });
    } catch (err) {}

    setIsSubmitting(false);
  };

  const selectFun = (id) => {
    country.map((e) => {
      if (e._id == id) {
        setAllState(e.all_states);
      }
    });
  };

  const notifysucces = () => toast.success("Client updated successfully");
  const userSlectedState = () => {
    country.map((e) => {
      if (e._id == selectedcountId) {
        setAllState(e.all_states);
        e.all_states.map((end) => {
          if (end.id == selectedstateId) {
          }
        });
      }
    });
  };

  const getClientById = async () => {
    let isMounted = true;

    try {
      const response = await axiosPrivate.get(
        `client/get-client-list/${id}`,
        {}
      );
      let clientDetails = response.data.client;
      if (clientDetails && Object.keys(clientDetails).length === 0) {
        setLoder(false);
        navigate("/404");
      } else {
        setLoder(true);
        isMounted && setClientName(response.data.client.name);
        setbillingAddress(response.data.client.billing_address);
        setCity(response.data.client.city);
        setSecretKey(response.data.client.secret_key);
        setZipcode(response.data.client.zipcode);
        setPhone(response.data.client.phone);
        setProjectMinFee(response.data.client.project_minimum_fee);
        setSelectedcountId(response.data.client.country_id);
        setSelectedstateId(response.data.client.state_id);
        setStateId(response.data.client.state_id);
        setClientNameType(response.data.client.client_type_id);
        setApidata(response.data.client.is_api);
        setApprovedstate(response.data.client.is_approved);
        getAccountOwners(response.data.client.user_reference_id);
      }
    } catch (err) {
      navigate("/404");
    }
    setLoder(true);
  };

  const clientTypes = async () => {
    let isMounted = true;
    try {
      const response = await axiosPrivate.get("lookup/client-type-list", {});
      isMounted && setClientType(response.data.all_client_type);
    } catch (err) {}
  };

  const getAccountOwners = async (value) => {
    let isMounted = true;
    try {
      const response = await axiosPrivate.get("user/user-department/2", {});
      isMounted && setAccountOwner(response.data.all_users);

      const result = response.data.all_users.filter(
        (word) => word._id === value
      );
      if (result.length > 0) {
        setAccountOwnerss(value);
      } else {
        setAccountOwnerss("");
      }
    } catch (err) {}
  };

  const getCountriesList = async () => {
    let isMounted = true;
    try {
      const response = await axiosPrivate.get("lookup/country-list", {});
      let array = [];
      Object.values(response.data.all_countries).forEach(function (key) {
        array.push(key);
      });
      isMounted && setCountry(array);
    } catch (err) {}
  };

  useEffect(() => {
    setValue("country", selectedcountId);
    setValue("AccoumtOwner", accountOwnerss);
    setValue("clientType", clientNameType);
    setValue("state", stateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedcountId, accountOwnerss, clientNameType, stateId]);

  const countryReg = register("country", {
    required: "This field is Required",
  });

  const stateReg = register("state");

  const accountOwnerReg = register("AccoumtOwner", {
    required: "This field is Required",
  });

  const clientTypeReg = register("clientType", {
    required: "This field is Required",
  });

  return (
    <>
      {/* <Header /> */}
      <BidsSubHeader pname="clientlist" />
      {loder ? (
        <>
          <div className="top_container">
            <div className="main_container ">
              <form onSubmit={handleSubmit(updateClientApi)}>
                <div className="user_serachbox">
                  <div style={{ float: "left" }}>
                    <h3 className="tableHeading" onClick={() => {}}>
                      <img src={pageIcon} className="headingIcons" alt="" />{" "}
                      Update &nbsp; {localData}
                    </h3>
                  </div>
                  <div className="right-pane">
                    <button
                      className="cbtn closeBtn"
                      onClick={() => {
                        navigate("/clients");
                      }}
                    >
                      {" "}
                      Close
                    </button>
                    {superUser === true ? (
                      <>
                        <button className="cbtn saveBtn">
                          {isSubmitting && (
                            <Spinner
                              style={{ color: "white" }}
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          &nbsp;&nbsp; Save
                        </button>

                        {/* <button
                          className="cbtn saveBtn"
                          onClick={() => {
                            // navigate("/clients");
                            updateClientApi();
                          }}>
                          {isSubmitting && <ButtonSpinner />} &nbsp; Save
                        </button> */}
                      </>
                    ) : (
                      <>
                        {all_permission.includes("client.client_update") ? (
                          <>
                            {/* <button
                              className="cbtn saveBtn"
                              onClick={() => {
                                // navigate("/clients");
                                updateClientApi();
                              }}>
                              {isSubmitting && <ButtonSpinner />} &nbsp; Save
                            </button> */}
                            <button className="cbtn saveBtn">
                              {isSubmitting && (
                                <Spinner
                                  style={{ color: "white" }}
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              &nbsp;&nbsp; Save
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <hr className="showingHeader" />

                <>
                  {/* <Bidstopheader clientid={clientId} tid="1" /> */}
                  <Bidstopheader
                    clientid={id}
                    tid="1"
                    className="showingHeader"
                  />
                  <Bidstopheaderselect clientid={id} namevalue={"clients"} />

                  <div className="mainContent">
                    <Row>
                      <Col lg={3} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Name*</label>
                        <input
                          type="text"
                          className="formtext"
                          {...register("Name", {
                            required: "This field is Required",
                            pattern: {
                              value:
                                /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRST " """UVWXYZ&0123456789 ]*$/,
                              message: "Only letters are allowed",
                            },
                          })}
                          defaultValue={clientName}
                        />
                        {errors.Name && (
                          <small className="errmsg">
                            {errors.Name.message}
                          </small>
                        )}
                      </Col>

                      <Col lg={3} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Billing Address</label>
                        <input
                          className="formtext"
                          {...register("BillingAdress", {
                            pattern: {
                              value:
                                /^(([^<>()[\],;:\s@\"]+([^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line
                              message: "Enter Valid Email Id",
                            },
                          })}
                          defaultValue={billingAddress}
                        />
                        {errors.BillingAdress && (
                          <small className="errmsg">
                            {errors.BillingAdress.message}
                          </small>
                        )}
                      </Col>
                      <Col lg={3} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Account Owner*</label>

                        <select
                          className="formtext"
                          {...accountOwnerReg}
                          onChange={(e) => {
                            setAccountOwnerss(e.target.value);
                          }}
                          value={accountOwnerss}
                        >
                          <option value="">Select</option>
                          {accountOwner.map((owner) => (
                            <option value={owner._id} key={owner.name}>
                              {owner.name}
                            </option>
                          ))}
                        </select>
                        {accountOwnerss == "" || accountOwner == undefined ? (
                          <>
                            {errors.AccoumtOwner && (
                              <small className="errmsg">
                                {errors.AccoumtOwner.message}
                              </small>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col lg={3} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Client Type*</label>
                        <select
                          className="formtext"
                          {...clientTypeReg}
                          onChange={(e) => {
                            setClientNameType(e.target.value);
                          }}
                          value={clientNameType}
                        >
                          <option value="">Select Client Type</option>
                          {clientType.map((type) => (
                            <option value={type._id} key={type.name}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                        {errors.ClientType && (
                          <small className="errmsg">
                            {errors.ClientType.message}
                          </small>
                        )}
                      </Col>

                      <Col lg={3} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">
                          Project Minimum Fee*
                        </label>
                        <input
                          type="text"
                          className="formtext"
                          {...register("ProjectFee", {
                            required: "This field is Required",
                            pattern: {
                              value: /^[0123456789 ]*$/,
                              message: "Only Numbers are allowed",
                            },
                          })}
                          defaultValue={projectMinFee}
                        />
                        {errors.ProjectFee && (
                          <small className="errmsg">
                            {errors.ProjectFee.message}
                          </small>
                        )}
                      </Col>

                      <Col lg={3} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Phone</label>
                        <input
                          className="formtext"
                          {...register("Phone", {
                            pattern: {
                              value: /^[0-9-+().]*$/,
                              message: "Only Numbers,(,),+,-,. are allowed",
                            },
                          })}
                          defaultValue={phone}
                        />
                        {errors.Phone && (
                          <small className="errmsg">
                            {errors.Phone.message}
                          </small>
                        )}
                      </Col>
                      <Col lg={3} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Country*</label>
                        <select
                          className="formtext"
                          {...countryReg}
                          onChange={(e) => {
                            setStateId("");
                            setSelectedcountId(e.target.value);
                            selectFun(e.target.value);
                          }}
                          value={selectedcountId}
                        >
                          {country.map((e) => (
                            <option value={e._id}>{e.country_name}</option>
                          ))}
                        </select>
                        {errors.Country && (
                          <small className="errmsg">
                            {errors.Country.message}
                          </small>
                        )}
                      </Col>
                      <Col lg={3} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">State</label>
                        <select
                          className="formtext"
                          {...stateReg}
                          onChange={(e) => {
                            setStateId(e.target.value);
                          }}
                          value={stateId}
                        >
                          <option value="">Select</option>
                          {allState.map((state) => (
                            <option value={state.id}>{state.state_name}</option>
                            // <option value={state.id}>{state.id}</option>
                          ))}
                        </select>
                        {errors.state && (
                          <small className="errmsg">
                            {errors.state.message}
                          </small>
                        )}
                      </Col>

                      <Col lg={3} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">City</label>
                        <input
                          className="formtext"
                          {...register("City", {
                            pattern: {
                              value:
                                /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                              message: "Only letters are allowed",
                            },
                          })}
                          defaultValue={city}
                        />
                        {errors.City && (
                          <small className="errmsg">
                            {errors.City.message}
                          </small>
                        )}
                      </Col>

                      <Col lg={3} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Is API*</label>
                        <select
                          className="formtext"
                          {...register("isApi", {
                            required: "This field is Required",
                          })}
                          defaultValue={apidata}
                        >
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                        {errors.isApi && (
                          <small className="errmsg">
                            {errors.isApi.message}
                          </small>
                        )}
                      </Col>

                      <Col lg={3} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Excluded Vendors</label>
                        <select className="formtext">
                          <option value="">Select Option</option>
                        </select>
                      </Col>
                      <Col lg={3} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Secret Key</label>
                        <input
                          className="formtext"
                          {...register("secretKey")}
                          defaultValue={secretKey}
                        />
                        {errors.secretKey && (
                          <small className="errmsg">
                            {errors.secretKey.message}
                          </small>
                        )}
                      </Col>

                      <Col lg={3} md={4} sm={6} xs={12} className="col-3 col">
                        <label className="formlabel">Zipcode</label>
                        <input
                          className="formtext"
                          {...register("Zipcode", {
                            pattern: {
                              value: /^[0123456789 ]*$/,
                              message: "Only Numbers are allowed",
                            },
                          })}
                          defaultValue={zipcode}
                        />
                        {errors.Zipcode && (
                          <small className="errmsg">
                            {errors.Zipcode.message}
                          </small>
                        )}
                      </Col>

                      <Col lg={3} md={4} sm={6} xs={12} className="col-3 col">
                        <label className="formlabel">Approved*</label>
                        <select
                          className="formtext"
                          {...register("Approved", {
                            required: "This field is Required",
                          })}
                          defaultValue={approvedstate}
                        >
                          <option value="">Select Option</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                        {errors.Approved && (
                          <small className="errmsg">
                            {errors.Approved.message}
                          </small>
                        )}
                      </Col>
                    </Row>
                  </div>
                </>
              </form>
            </div>
          </div>
        </>
      ) : (
        <>
          <SpinnerLoader />
        </>
      )}
    </>
  );
};

export default UpdateClient;
