import Modal from "react-bootstrap/Modal";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { IoAddCircleOutline } from "react-icons/io5";
import { useForm } from "react-hook-form";
import "./ESupplier.css";
import Select from "react-select";
import { AiOutlineMinusCircle } from "react-icons/ai";
import SurveyTracking from "../../Quota/Quotapopup/SurveyTracking";
function EditSupplier(props) {
  const axiosPrivate = useAxiosPrivate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [selected, setSelected] = useState([]);
  const [setting, setSetting] = useState(false);
  const [showlink, setShowlink] = useState(false);
  const [terminationPopup, setTerminationPopup] = useState([1]);

  const [quotaPop, setQuotaPop] = useState([1]);
  const [redirect, setRedirectPop] = useState([1]);
  const [statusn, setStatusn] = useState();
  const [statusOption, setStatusOption] = useState([]);
  const handleSaveButton = (e) => {
    e.preventDefault();
  };

  const options = [
    {
      label: "Live",
      value: "Live",
    },

    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "Oﬀ spec inquiry",
      value: "Inquiry",
    },
    {
      label: "Paused",
      value: "Paused",
    },
  ];

  useEffect(() => {
    getprojectStatus();
  }, []);

  const getprojectStatus = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get-project-status");
      response.data.all_status.map((e) => {
        setStatusOption((val) => [...val, { label: e.name, value: e.id }]);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteClientType = (index) => {
    setTerminationPopup((prevClientType) => {
      const updatedClientType = [...prevClientType];
      updatedClientType.splice(index, 1);
      return updatedClientType;
    });
  };
  const handleDeleteQuota = (index) => {
    setQuotaPop((prevClientType) => {
      const updatedClientType = [...prevClientType];
      updatedClientType.splice(index, 1);
      return updatedClientType;
    });
  };
  const handleDeleteRedirect = (index) => {
    setRedirectPop((prevClientType) => {
      const updatedClientType = [...prevClientType];
      updatedClientType.splice(index, 1);
      return updatedClientType;
    });
  };
  return (
    <>
      <SurveyTracking
        show={setting}
        setSetting={setSetting}
        onHide={() => setSetting(false)}
      />

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "16px", paddingLeft: "10px" }}
          >
            <ModeEditIcon style={{ fontSize: "20px" }} />
            &nbsp; Edit Supplier - Peanut Labs
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handleSaveButton)}>
            <div className="csmulti">
              <div>
                <Row
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  <Col className="inputSize col" md={4} sm={4} xs={12}>
                    <label className="formlabelamit">Status</label>

                    <Select
                      {...register("Status", {
                        required: "This field is Required",
                      })}
                      placeholder="Select..."
                      className="epjt_select formtext"
                      classNamePrefix="select"
                      options={statusOption}
                      onChange={(e) => {
                        setStatusn(e.value);
                      }}
                      noOptionsMessage={() => "No Data Found"}
                    />
                    {statusn ? (
                      <></>
                    ) : (
                      <>
                        {errors.Status && (
                          <small className="errmsg editsuperror">
                            {errors.Status.message}
                          </small>
                        )}
                      </>
                    )}
                  </Col>
                  <Col className="inputSize col" md={4} sm={4} xs={12}>
                    <label className="formlabelamit">Supplier Cost</label>
                    <input
                      {...register("SupplierCost", {
                        required: "This field is Required",
                      })}
                      placeholder="Supplier Cost"
                      className="formtextamit"
                      type="text"
                    />
                    {errors.SupplierCost && (
                      <small className="errmsg editsuperror">
                        {errors.SupplierCost.message}
                      </small>
                    )}
                  </Col>
                  <Col className="inputSize col" md={4} sm={4} xs={12}>
                    <label className="formlabelamit">Quota</label>
                    <input
                      {...register("Quota", {
                        required: "This field is Required",
                      })}
                      placeholder="Quota"
                      className="formtextamit"
                      type="text"
                    />
                    {errors.Quota && (
                      <small className="errmsg editsuperror">
                        {errors.Quota.message}
                      </small>
                    )}
                  </Col>
                  <Col className="inputSize" md={4} sm={4} xs={12}>
                    <label className="formlabelamit">Variable 1</label>
                    <input
                      placeholder="RID"
                      className="formtextamit"
                      type="text"
                    />
                  </Col>
                  <Col className="inputSize" md={4} sm={4} xs={12}>
                    <label className="formlabelamit">Variable 2</label>
                    <input className="formtextamit" type="text" />
                  </Col>
                  <Col className="inputSize" md={4} sm={4} xs={12}>
                    <label className="formlabelamit">Variable 3</label>
                    <input className="formtextamit" type="text" />
                  </Col>
                  <Col className="inputSize" md={4} sm={4} xs={12}>
                    <label className="formlabelamit">Variable 4</label>
                    <input className="formtextamit" type="text" />
                  </Col>
                </Row>
                <div className="amit" style={{ height: "10px" }}></div>
                <Row
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  <Col lg={2} md={4} sm={4} xs={6}>
                    <div className="pswtchbtn s_edit_switcbtn">
                      <label className="formlabelamit">Screen Captcha</label>
                      <BootstrapSwitchButton
                        className="switch btn on btn-outline-success btn-xs"
                        size="xs"
                        width={60}
                        onlabel="ON"
                        offlabel="OFF"
                        onstyle="outline-success"
                        offstyle="outline-danger"
                      />
                    </div>
                  </Col>
                  <Col lg={2} md={4} sm={4} xs={6}>
                    <div className="pswtchbtn s_edit_switcbtn">
                      <label className="formlabelamit">
                        Red Herring Screen
                      </label>
                      <BootstrapSwitchButton
                        className="switch btn on btn-outline-success btn-xs"
                        size="xs"
                        width={60}
                        onlabel="ON"
                        offlabel="OFF"
                        onstyle="outline-success"
                        offstyle="outline-danger"
                      />
                    </div>
                  </Col>
                  <Col lg={2} md={4} sm={4} xs={6}>
                    <div className="pswtchbtn s_edit_switcbtn">
                      <label className="formlabelamit">Research Defender</label>
                      <BootstrapSwitchButton
                        className="switch btn on btn-outline-success btn-xs"
                        size="xs"
                        width={60}
                        onlabel="ON"
                        offlabel="OFF"
                        onstyle="outline-success"
                        offstyle="outline-danger"
                      />
                    </div>
                  </Col>
                  <Col lg={2} md={4} sm={4} xs={6}>
                    <div className="pswtchbtn s_edit_switcbtn">
                      <label className="formlabelamit">Soft Launch</label>
                      <BootstrapSwitchButton
                        className="switch btn on btn-outline-success btn-xs"
                        size="xs"
                        width={60}
                        onlabel="ON"
                        offlabel="OFF"
                        onstyle="outline-success"
                        offstyle="outline-danger"
                      />
                    </div>
                  </Col>
                  <Col lg={2} md={4} sm={4} xs={6}>
                    <div className="pswtchbtn s_edit_switcbtn">
                      <label className="formlabelamit">Exception Link</label>
                      <BootstrapSwitchButton
                        onChange={(e) => {
                          if (e === true) {
                            setShowlink(true);
                          } else {
                            setShowlink(false);
                          }
                        }}
                        className="switch btn on btn-outline-success btn-xs"
                        size="xs"
                        width={60}
                        onlabel="ON"
                        offlabel="OFF"
                        onstyle="outline-success"
                        offstyle="outline-danger"
                      />
                    </div>
                  </Col>
                </Row>
              </div>{" "}
              <div style={{ height: "10px" }}></div>
              {showlink === true ? (
                <>
                  {" "}
                  <Row className="editsupplier_explink">
                    <label style={{ padding: "0px" }} className="formlabelamit">
                      Complete Link{" "}
                    </label>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        backgroundColor: "#f8f7f7",
                      }}
                    >
                      {terminationPopup.map((e, index) => (
                        <Row
                          style={{ paddingTop: "10px", paddingBottom: "10px" }}
                        >
                          <Col className="col-4">
                            <label className="formlabelamit">
                              Redirect Type
                            </label>
                            <MultiSelect
                              className="multiselectoption"
                              options={options}
                              value={selected}
                              onChange={setSelected}
                              labelledBy={"Select"}
                              isCreatable={true}
                              disableSearch
                              disabled={true}
                            />
                          </Col>
                          <Col className="col-4">
                            <label className="formlabelamit"></label>
                            <input
                              className="formtextamit"
                              type="text"
                              placeholder="Variable"
                            />
                          </Col>
                          <Col className="col-3">
                            <label className="formlabelamit"></label>
                            <MultiSelect
                              className="multiselectoption"
                              options={options}
                              value={selected}
                              onChange={setSelected}
                              labelledBy={"Select"}
                              isCreatable={true}
                              disableSearch
                              disabled={true}
                            />
                          </Col>
                          <Col className="col-1">
                            {terminationPopup.length - 1 === index ? (
                              <div
                                className="col-1"
                                id="plusicon"
                                onClick={() => {
                                  setTerminationPopup((e) => [...e, 1]);
                                }}
                              >
                                <div className="">
                                  <IoAddCircleOutline className="plussss IoAddCircleOutline" />
                                </div>
                              </div>
                            ) : (
                              <div style={{ marginTop: "20px" }}>
                                <AiOutlineMinusCircle
                                  className="IoAddCircleOutline"
                                  onClick={() => {
                                    handleDeleteClientType(index);
                                  }}
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                  <Row className="editsupplier_explink">
                    <label style={{ padding: "0px" }} className="formlabelamit">
                      Quotafull Link
                    </label>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        backgroundColor: "#f8f7f7",
                      }}
                    >
                      {redirect.map((e, index) => (
                        <Row
                          style={{ paddingTop: "10px", paddingBottom: "10px" }}
                        >
                          <Col className="col-4">
                            <label className="formlabelamit">
                              Redirect Type
                            </label>
                            <MultiSelect
                              className="multiselectoption"
                              options={options}
                              value={selected}
                              onChange={setSelected}
                              labelledBy={"Select"}
                              isCreatable={true}
                              disableSearch
                              disabled={true}
                            />
                          </Col>
                          <Col className="col-4">
                            <label className="formlabelamit"></label>
                            <input
                              className="formtextamit"
                              type="text"
                              placeholder="Variable"
                            />
                          </Col>
                          <Col className="col-3">
                            <label className="formlabelamit"></label>
                            <MultiSelect
                              className="multiselectoption"
                              options={options}
                              value={selected}
                              onChange={setSelected}
                              labelledBy={"Select"}
                              isCreatable={true}
                              disableSearch
                              disabled={true}
                            />
                          </Col>
                          <Col className="col-1">
                            {redirect.length - 1 === index ? (
                              <div
                                className="col-1"
                                id="plusicon"
                                onClick={() => {
                                  setRedirectPop((e) => [...e, 1]);
                                }}
                              >
                                {" "}
                                <div className="">
                                  <IoAddCircleOutline className="plussss IoAddCircleOutline" />
                                </div>
                              </div>
                            ) : (
                              <div style={{ marginTop: "20px" }}>
                                {" "}
                                <AiOutlineMinusCircle
                                  className="IoAddCircleOutline "
                                  onClick={() => {
                                    handleDeleteRedirect(index);
                                  }}
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                  <Row className="editsupplier_explink">
                    <label style={{ padding: "0px" }} className="formlabelamit">
                      Termination Link
                    </label>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        backgroundColor: "#f8f7f7",
                      }}
                    >
                      {quotaPop.map((e, index) => (
                        <Row
                          style={{ paddingTop: "10px", paddingBottom: "10px" }}
                        >
                          <Col className="col-4">
                            <label className="formlabelamit">
                              Redirect Type
                            </label>
                            <MultiSelect
                              className="multiselectoption"
                              options={options}
                              value={selected}
                              onChange={setSelected}
                              labelledBy={"Select"}
                              isCreatable={true}
                              disableSearch
                              disabled={true}
                            />
                          </Col>
                          <Col className="col-4">
                            <label className="formlabelamit"></label>
                            <input
                              className="formtextamit"
                              type="text"
                              placeholder="Variable"
                            />
                          </Col>
                          <Col className="col-3">
                            <label className="formlabelamit"></label>
                            <MultiSelect
                              className="multiselectoption"
                              options={options}
                              value={selected}
                              onChange={setSelected}
                              labelledBy={"Select"}
                              isCreatable={true}
                              disableSearch
                              disabled={true}
                            />
                          </Col>
                          <Col className="col-1">
                            {quotaPop.length - 1 === index ? (
                              <div
                                className="col-1"
                                id="plusicon"
                                onClick={() => {
                                  setQuotaPop((e) => [...e, 1]);
                                }}
                              >
                                {" "}
                                <div className="">
                                  <IoAddCircleOutline className="plussss IoAddCircleOutline" />
                                </div>
                              </div>
                            ) : (
                              <div style={{ marginTop: "20px" }}>
                                {" "}
                                <AiOutlineMinusCircle
                                  className="IoAddCircleOutline "
                                  onClick={() => {
                                    handleDeleteQuota(index);
                                  }}
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                  <div>
                    <Col
                      lg={3}
                      md={6}
                      xs={12}
                      className="col"
                      style={{
                        float: "right",
                      }}
                    >
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          className="quota_surtrakbtn"
                          style={{
                            marginRight: "10px",
                          }}
                          onClick={(e) => {
                            setSetting(true);
                            e.preventDefault();
                            props.setEditSupplier(false);
                          }}
                        >
                          Survey Tracking
                        </button>
                        <button
                          className="supply_setupbtn"
                          style={{
                            marginTop: "0px",
                            marginRight: "10px",
                          }}
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <Row className="suppliersetup">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <label className="formlabelamit">Completion Link</label>
                      <input
                        placeholder="https://peanutlabs.com/return/respondent?screlid=MTU5OTYtMTAwLTQ0MTM=&transaction..."
                        className="formtextamit"
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row className="suppliersetup">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <label className="formlabelamit">Termination Link</label>
                      <input
                        placeholder="https://peanutlabs.com/return/respondent?screlid=MTU5OTYtMTAwLTQ0MTM=&transaction..."
                        className="formtextamit"
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row className="suppliersetup">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <label className="formlabelamit">Quotafull Link</label>
                      <input
                        placeholder="https://peanutlabs.com/return/respondent?screlid=MTU5OTYtMTAwLTQ0MTM=&transaction..."
                        className="formtextamit"
                        type="text"
                      />
                    </Col>
                    <Col>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button className="supply_setupbtn">Submit</button>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default EditSupplier;
