import React from "react";
import JoditEditor from "jodit-react";
const Editor = () => {
  return (
    <>
      <JoditEditor />
    </>
  );
};
export default Editor;
