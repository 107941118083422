import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";

const SettingHeader = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [headerId, setHeaderId] = useState(1); // Add state to keep track of the selected value

  // Function to handle select change
  const handleSelectChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setHeaderId(selectedValue); // Update the selected value in the state

    switch (selectedValue) {
      case 1:
        navigate(`/exclusion-setting/${id}`);
        break;
      case 2:
        navigate(`/redirect-url/${id}`);
        break;
      case 3:
        navigate(`/variable-mapping/${id}`);
        break;
      case 4:
        navigate(`/hashing/${id}`);
        break;
      default:
        break;
    }
  };

  // Update the selected value when the props.pname changes
  useEffect(() => {
    switch (props.pname) {
      case "Exclusion Setting":
        setHeaderId(1);
        break;
      case "Redirect URL":
        setHeaderId(2);
        break;
      case "Variable Mapping":
        setHeaderId(3);
        break;
      case "Hashing":
        setHeaderId(4);
        break;
      default:
        setHeaderId(1);
        break;
    }
  }, [props.pname]);

  return (
    <>
      <div className="Supply_to_account">
        <div className="showHeaders">
          <select
            value={headerId}
            className="threeLine SelectHeader"
            id="MainMenuCs"
            onChange={handleSelectChange} // Attach the handler function to onChange
          >
            <option value={1}>Exclusion Setting</option>
            <option value={2}>Redirect URL</option>
            <option value={3}>Variable Mapping</option>
            <option value={4}>Hashing</option>
          </select>
        </div>
        <div className="settingHeadersh">
          <span
            className={props.pname === "Exclusion Setting" ? "active" : ""}
            onClick={() => {
              navigate(`/exclusion-setting/${id}`);
            }}
          >
            Exclusion Setting
          </span>

          <span
            className={props.pname === "Redirect URL" ? "active" : ""}
            onClick={() => {
              navigate(`/redirect-url/${id}`);
            }}
          >
            Redirect URL
          </span>

          <span
            className={props.pname === "Variable Mapping" ? "active" : ""}
            onClick={() => {
              navigate(`/variable-mapping/${id}`);
            }}
          >
            Variable Mapping
          </span>

          <span
            className={props.pname === "Hashing" ? "active" : ""}
            onClick={() => {
              navigate(`/hashing/${id}`);
            }}
          >
            Hashing
          </span>
        </div>
      </div>
    </>
  );
};

export default SettingHeader;
