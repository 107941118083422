import React from "react";
import { components } from "react-select";

const { MenuList } = components;

const CustomMenuList = ({ selectProps, ...props }) => {
  const { onInputChange, inputValue, onMenuInputFocus } = selectProps;

  // Copied from source
  const ariaAttributes = {
    "aria-autocomplete": "list",
    "aria-label": selectProps["aria-label"],
    "aria-labelledby": selectProps["aria-labelledby"],
  };

  return (
    <div>
      <input
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding: 10,
          border: "none",
          borderBottom: "1px solid lightgrey",
          backgroundColor: "#eee",
          height: "25px",
          fontSize: "12px",
        }}
        className="custom_multi"
        autoCorrect="off"
        autoComplete="off"
        spellCheck="false"
        type="text"
        value={inputValue}
        onChange={(e) =>
          onInputChange(e.currentTarget.value, {
            action: "input-change",
          })
        }
        onMouseDown={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onFocus={onMenuInputFocus}
        placeholder="Search..."
        {...ariaAttributes}
      />
      <MenuList {...props} selectProps={selectProps} />
    </div>
  );
};

export default CustomMenuList;
