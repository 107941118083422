import Supplyheader from "./Supplyheader";
import "./Supplypage.css";
import axios from "axios";
import { useState, useEffect } from "react";
import Paginations from "../Paginations/Paginations";
import Table from "react-bootstrap/Table";
import pageIcon from "../../assets/BidsIcons/bids.svg";
import { useNavigate } from "react-router";
import EditIcon from "../../assets/LoginIcons/edit.svg";
import DeleteIcon from "../../assets/LoginIcons/delete.svg";
import plusIcon from "../../assets/CSicons/icons/add.svg";
import { FaTelegramPlane } from "react-icons/fa";
import AddManualSupplier from "./Popup/AddManualSupplier";
import Editsupplierquality from "./Editsupplierquality";
import RequestStatus from "./Popup/RequestStatus";
import SupplierInfo from "./Popup/SupplierInfo";
import { AiOutlineSetting } from "react-icons/ai";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SpinnerLoader from "../UI/Spinner";
const Supplierdetail = () => {
  const axiosPrivate = useAxiosPrivate();
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const [add, setAdd] = useState(false);
  const [update, setUpdate] = useState(false);
  const [see, setSee] = useState(false);
  const [setting, setSetting] = useState(false);
  const [show, setShow] = useState(false);
  const [tabledata, setTableData] = useState([]);
  const [dataLength, setDataLength] = useState();
  const [dataLoader, setDataLoader] = useState(true);
  const pname = "SupplierDetails";
  const navigate = useNavigate();
  useEffect(() => {
    fetchDataWithToken();
    // get_ProjectType_api();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesize, nextPage]);

  useEffect(() => {
    fetchDataWithToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPageSize = (size) => {
    setPagesize(size);
    setNextPage(1);
  };

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };
  const get_ProjectType_api = async () => {
    try {
      const response = await axiosPrivate.get(
        `supplyinfo/supplier-list?&page=${nextPage}&size=${pagesize}&sort=_id`
      );
      setDataLoader(false);
      setTableData(response.data.items);
      setDataLength(response.data.total);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchDataWithToken = async () => {
    const token = "YOUR_API_TOKEN"; // Replace 'YOUR_API_TOKEN' with your actual API token
    const url = `http://52.13.201.242:8003/api/v1/supplyinfo/supplier-list?&page=${nextPage}&size=${pagesize}&sort=_id`; // Replace 'YOUR_API_URL' with the URL of the API you want to fetch

    try {
      const response = await axios.get(url, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "access-token":
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIyOTExMzgsIm5iZiI6MTY5MjI5MTEzOCwianRpIjoiZDgxOWRiYjgtYjBkYi00MjAwLTk3YjMtY2M4M2FhYzUzYTkzIiwiZXhwIjoxNjkyNzIzMTM4LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.ec0MG1JkCkJokFGtZ-1sMNrehSE1qCIAhywLxXRC1sg",
        },
      });
      setDataLoader(false);
      setTableData(response.data.items);
      setDataLength(response.data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <RequestStatus show={setting} onHide={() => setSetting(false)} />
      <AddManualSupplier show={add} onHide={() => setAdd(false)} />

      <SupplierInfo show={see} onHide={() => setSee(false)} />
      <Supplyheader pname={pname} />
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachboxSupply">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" /> Suppliers
              </h3>
            </div>
            <div className="supplyInputBox" style={{}}>
              <input
                type="search"
                className="tableSearch"
                placeholder="Search..."
              />
              <img
                src={plusIcon}
                alt="plusIcon"
                srcset=""
                onClick={() => {
                  navigate("/supplier");
                }}
                className="supplyIconColr"
                style={{
                  float: "right",
                  marginRight: "3px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <hr />
          <div className="mainContent mainContent2">
            {dataLoader ? (
              <SpinnerLoader />
            ) : tabledata.length === 0 ? (
              <>
                {" "}
                <div style={{ textAlign: "center" }}>No data Found</div>
              </>
            ) : (
              <div className="table_container ">
                <Table bordered={false} hover size="sm" className="customTable">
                  <thead className="thead">
                    <tr>
                      <td>Sno.</td>
                      <td>Supplier Abbr</td>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Phone</td>

                      <td>Country</td>

                      <td>Status</td>
                      <td>Action</td>
                    </tr>
                  </thead>

                  <tbody>
                    {tabledata.map((items) => (
                      <>
                        <tr>
                          <td>{items._id}</td>
                          <td>{items.supplier_abbr} </td>
                          <td> {items.name}</td>
                          <td>{items.email}</td>
                          <td>{items.phone}</td>

                          {/* {items.address === undefined ? (
                            <>
                              <td>NA</td>
                            </>
                          ) : (
                            <>{<td> {items.address.billing_address} </td>}</>
                          )} */}

                          {items.address === undefined ? (
                            <>
                              <td>NA</td>
                            </>
                          ) : (
                            <>{<td> {items.address.country.name} </td>}</>
                          )}

                          <td>Active</td>
                          <td>
                            <img
                              src={EditIcon}
                              alt="EditIcon"
                              srcset=""
                              className="SupIcon"
                              onClick={() => {
                                navigate(`/edit_supplier/${items._id}`);
                              }}
                            />
                            <FaTelegramPlane className="SupIcon" />
                            <img
                              src={DeleteIcon}
                              alt="DeleteIcon"
                              srcset=""
                              className="SupIcon"
                            />
                            <AiOutlineSetting
                              size={17}
                              onClick={() => {
                                navigate(`/exclusion-setting/${items._id}`);
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
            <Paginations
              userlist={dataLength}
              getPageSize={getPageSize}
              handlePageChnage={handlePageChnage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Supplierdetail;
