import Table from "react-bootstrap/Table";

import Paginations from "../Paginations/Paginations";
import { useState, useEffect } from "react";
import pageIcon from "../../assets/BidsIcons/bids.svg";

import { AiOutlineTag } from "react-icons/ai";

import plusIcon from "../../assets/CSicons/icons/add.svg";

import downloadIcon from "../../assets/CSicons/icons/download.svg";
import EditIcon from "../../assets/LoginIcons/edit.svg";

import searchIcon from "../../assets/CSicons/icons/search.svg";

import DeleteIcon from "../../assets/LoginIcons/delete.svg";
import Reconsilitionupload from "./Reconsilitionupload";
import Reconcilitionlogs from "./Reconcilitionlogs";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "./Reconciliation.css";
const Reconcillation = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [surveyType, setSurveyType] = useState([]);
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [deleteCount, SetDeleteCount] = useState(0);
  const [isCheck, setIsCheck] = useState([]);
  const [projectStatus, setProjectStatus] = useState([]);
  const [uploadImg, setUploadImg] = useState(false);
  const [logs, setLogs] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    get_ProjectSTatus();
  }, []);

  const get_ProjectSTatus = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get-project-status ");
      response.data.all_status.map((e) => {
        setProjectStatus((val) => [...val, { label: e.name, value: e.id }]);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const data = [
    {
      _id: "1",
    },
    {
      _id: "2",
    },
    {
      _id: "3",
    },
    {
      _id: "4",
    },
    {
      _id: "5",
    },
    {
      _id: "6",
    },
    {
      _id: "7",
    },
  ];

  const getPageSize = (size) => {
    setPagesize(size);
  };

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };

  const handleSelectAll = (e) => {
    SetDeleteCount(0);

    data.map((element) => {
      // setMailBoxId(element.mailbox_id);
    });

    setIsCheckAll(!isCheckAll);
    setIsCheck(data.map((li) => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleSingleSelect = (e, msgid, mailid) => {
    setIsCheckAll(false);
    if (e.target.checked === true) {
      SetDeleteCount(deleteCount + 1);
    } else {
      SetDeleteCount(deleteCount - 1);
    }

    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <>
      <Reconsilitionupload
        show={uploadImg}
        onHide={() => setUploadImg(false)}
      />
      <Reconcilitionlogs show={logs} onHide={() => setLogs(false)} />
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachboxSupply">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" />{" "}
                Reconciliation
              </h3>
            </div>

            <span
              style={{
                float: "right",
              }}
            >
              <a className="search-filter-toggle" href="#">
                <img
                  src={searchIcon}
                  alt="searchIcon"
                  srcset=""
                  className="supplyIconColr"
                  onClick={() => setShow(!show)}
                />
              </a>

              <a href="#">
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  srcset=""
                  className="supplyIconColr"
                  onClick={() => {
                    setUploadImg(true);
                  }}
                />
              </a>

              <a href="#">
                <img
                  src={downloadIcon}
                  alt="downloadIcon"
                  srcset=""
                  className="supplyIconColr"
                />
              </a>
            </span>
          </div>
          <hr />
          <div
            className="mainContent mainContent2"
            style={{ marginTop: "10px" }}
          >
            {show ? (
              <div className="Reconciliation_search-filter">
                <div className="R_input_div ">
                  <label for="Project_name" className="R_label">
                    {" "}
                    Project name
                  </label>
                  <input
                    style={{ color: "#999" }}
                    type="text"
                    name="Project_name"
                    className="R_input"
                    placeholder="Project name"
                  />
                </div>

                <div className="R_input_div ">
                  <label for="Project_name" className="R_label">
                    {" "}
                    SC Number
                  </label>
                  <input
                    style={{ color: "#999" }}
                    type="text"
                    name="Project_name"
                    className="R_input"
                    placeholder="SC Number"
                  />
                </div>
                <div className="R_input_div ">
                  <label for="Project_name" className="R_label">
                    {" "}
                    WS Number
                  </label>
                  <input
                    style={{ color: "#999" }}
                    type="text"
                    name="Project_name"
                    className="R_input"
                    placeholder="WS Number"
                  />
                </div>
                <div className="R_input_div ">
                  <label for="Project_name" className="R_label">
                    {" "}
                    Client Number
                  </label>
                  <input
                    style={{ color: "#999" }}
                    type="text"
                    name="Project_name"
                    className="R_input"
                    placeholder="Client Number"
                  />
                </div>
                <div className="R_input_div ">
                  <label for="Project_name" className="R_label">
                    {" "}
                    Project Status
                  </label>

                  <select name="" id="" className="R_input">
                    <option value="" disabled selected hidden>
                      Project Status
                    </option>

                    {projectStatus.map((pstatusItem) => (
                      <option value={pstatusItem.value}>
                        {pstatusItem.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="R_input_div ">
                  <label for="Project_name" className="R_label">
                    {" "}
                    Reconcile Status
                  </label>

                  <select name="" id="" className="R_input">
                    <option value="" disabled selected hidden>
                      Reconcile Status
                    </option>
                    <option value="1">Live</option>
                    <option value="2">Paused</option>
                    <option value="3"></option>
                    <option value="4"></option>
                  </select>
                </div>
                <div className="R_input_div ">
                  <label for="Project_name" className="R_label">
                    {" "}
                    Show Projects
                  </label>

                  <select name="" id="" className="R_input">
                    <option value="">All</option>
                    <option value="">1597944-US_WW</option>
                    <option value=""> CISO's - CLevel</option>
                  </select>
                </div>
              </div>
            ) : null}

            <div
              className="table_container"
              style={{
                marginTop: "20px",
              }}
            >
              <Table bordered={false} hover size="sm" className="customTable">
                <thead className="thead">
                  <tr>
                    <td
                      style={{
                        minWidth: "40px",
                        width: "50px",
                        maxWidth: "70px",
                      }}
                    >
                      <input
                        cla
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={isCheckAll}
                      />

                      {deleteCount >= 1 || isCheck.length > 0 ? (
                        <img
                          src={DeleteIcon}
                          alt=""
                          srcset=""
                          style={{
                            // minWidth: "20px",
                            width: "15px",
                            // maxWidth: "30px",

                            marginLeft: "5px",
                            marginBottom: "5px",
                            paddingRight: "0px",
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>Sno.</td>
                    <td>Project Name</td>
                    <td>SC#</td>

                    <td> WS#</td>
                    <td> Client#</td>
                    <td>Count SC/CL</td>
                    <td>Status</td>
                    <td>RECON Status</td>

                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleSingleSelect(e);
                        }}
                      />
                    </td>
                    <td>1</td>
                    <td>1597944-US_WW</td>
                    <td>20010001</td>
                    <td>WW-1155 IN2</td>

                    <td> 1597944</td>
                    <td>28/2</td>
                    <td>Billed</td>
                    <td>
                      {1 === 1 ? (
                        <button
                          style={{
                            backgroundColor: "#555",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          Yes{" "}
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#aaa",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          No{" "}
                        </button>
                      )}
                    </td>

                    <td>
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          navigate("/reconsilition-update");
                        }}
                      />

                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        srcset=""
                        className="SupIcon"
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setLogs(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleSingleSelect(e);
                        }}
                      />
                    </td>
                    <td>2</td>
                    <td>683615 -Luxury Cars-Wise</td>
                    <td>20010001</td>
                    <td>WW-1155 IN2</td>

                    <td> 1597944</td>
                    <td>28/2</td>
                    <td>Billed</td>
                    <td>
                      {1 === 1 ? (
                        <button
                          style={{
                            backgroundColor: "#555",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          Yes{" "}
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#aaa",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          No{" "}
                        </button>
                      )}
                    </td>

                    <td>
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          navigate("/reconsilition-update");
                        }}
                      />

                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        srcset=""
                        className="SupIcon"
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setLogs(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleSingleSelect(e);
                        }}
                      />
                    </td>
                    <td>3</td>
                    <td>CISO's - CLevel</td>
                    <td>20010001</td>
                    <td>WW-1155 IN2</td>

                    <td> 1597944</td>
                    <td>28/2</td>
                    <td>Billed</td>
                    <td>
                      {1 === 1 ? (
                        <button
                          style={{
                            backgroundColor: "#555",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          Yes{" "}
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#aaa",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          No{" "}
                        </button>
                      )}
                    </td>

                    <td>
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          navigate("/reconsilition-update");
                        }}
                      />

                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        srcset=""
                        className="SupIcon"
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setLogs(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleSingleSelect(e);
                        }}
                      />
                    </td>
                    <td>4</td>
                    <td>CX - 185792 IN - Wise Genpop-(Ahmedabad)</td>
                    <td>20010001</td>
                    <td>WW-1155 IN2</td>

                    <td> 1597944</td>
                    <td>28/2</td>
                    <td>Billed</td>
                    <td>
                      {1 === 1 ? (
                        <button
                          style={{
                            backgroundColor: "#555",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          Yes{" "}
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#aaa",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          No{" "}
                        </button>
                      )}
                    </td>

                    <td>
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          navigate("/reconsilition-update");
                        }}
                      />

                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        srcset=""
                        className="SupIcon"
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setLogs(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleSingleSelect(e);
                        }}
                      />
                    </td>
                    <td>5</td>
                    <td>CX - 185792 IN - Wise Genpop-(Bangalore)</td>
                    <td>20010001</td>
                    <td>WW-1155 IN2</td>

                    <td> 1597944</td>
                    <td>28/2</td>
                    <td>Billed</td>
                    <td>
                      {1 === 1 ? (
                        <button
                          style={{
                            backgroundColor: "#555",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          Yes{" "}
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#aaa",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          No{" "}
                        </button>
                      )}
                    </td>

                    <td>
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          navigate("/reconsilition-update");
                        }}
                      />

                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        srcset=""
                        className="SupIcon"
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setLogs(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleSingleSelect(e);
                        }}
                      />
                    </td>
                    <td>6</td>
                    <td>CX - 185792 IN - Wise Genpop-(Chennai)</td>
                    <td>20010001</td>
                    <td>WW-1155 IN2</td>

                    <td> 1597944</td>
                    <td>28/2</td>
                    <td>Billed</td>
                    <td>
                      {1 === 1 ? (
                        <button
                          style={{
                            backgroundColor: "#ef9a29",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          Partial{" "}
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#aaa",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          No{" "}
                        </button>
                      )}
                    </td>

                    <td>
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          navigate("/reconsilition-update");
                        }}
                      />

                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        srcset=""
                        className="SupIcon"
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setLogs(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleSingleSelect(e);
                        }}
                      />
                    </td>
                    <td>7</td>
                    <td>CX - 185792 IN - Wise Genpop-(Hyderabad)</td>
                    <td>20010001</td>
                    <td>WW-1155 IN2</td>

                    <td> 1597944</td>
                    <td>28/2</td>
                    <td>Billed</td>
                    <td>
                      {1 === 1 ? (
                        <button
                          style={{
                            backgroundColor: "#555",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          No{" "}
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#ef9a29",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          partial{" "}
                        </button>
                      )}
                    </td>

                    <td>
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          navigate("/reconsilition-update");
                        }}
                      />

                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        srcset=""
                        className="SupIcon"
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setLogs(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleSingleSelect(e);
                        }}
                      />
                    </td>
                    <td>8</td>
                    <td>CX - 185792 IN - Wise Genpop-(Pune)</td>
                    <td>20010001</td>
                    <td>WW-1155 IN2</td>

                    <td> 1597944</td>
                    <td>28/2</td>
                    <td>Billed</td>
                    <td>
                      {1 === 1 ? (
                        <button
                          style={{
                            backgroundColor: "#555",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          No{" "}
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#ef9a29",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          partial{" "}
                        </button>
                      )}
                    </td>

                    <td>
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          navigate("/reconsilition-update");
                        }}
                      />

                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        srcset=""
                        className="SupIcon"
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setLogs(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleSingleSelect(e);
                        }}
                      />
                    </td>
                    <td>9</td>
                    <td>CX - 185792 IN - Wise Genpop-(Mumbai)</td>
                    <td>20010001</td>
                    <td>WW-1155 IN2</td>
                    <td> 1597944</td>
                    <td>28/2</td>
                    <td>Billed</td>
                    <td>
                      {1 === 1 ? (
                        <button
                          style={{
                            backgroundColor: "#555",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          No{" "}
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#ef9a29",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          partial{" "}
                        </button>
                      )}
                    </td>
                    <td>
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          navigate("/reconsilition-update");
                        }}
                      />

                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        srcset=""
                        className="SupIcon"
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setLogs(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleSingleSelect(e);
                        }}
                      />
                    </td>
                    <td>10</td>
                    <td>CX - 185792 IN - Wise Genpop-(Kolkata)</td>
                    <td>20010001</td>
                    <td>WW-1155 IN2</td>

                    <td> 1597944</td>
                    <td>28/2</td>
                    <td>Billed</td>
                    <td>
                      {1 === 1 ? (
                        <button
                          style={{
                            backgroundColor: "#555",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          No{" "}
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#ef9a29",
                            width: "50px",
                            border: "none",
                            color: "white",
                          }}
                        >
                          {" "}
                          partial{" "}
                        </button>
                      )}
                    </td>

                    <td>
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          navigate("/reconsilition-update");
                        }}
                      />

                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        srcset=""
                        className="SupIcon"
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setLogs(true);
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <Paginations
            userlist={10}
            getPageSize={getPageSize}
            handlePageChnage={handlePageChnage}
          />
        </div>
      </div>
    </>
  );
};

export default Reconcillation;
