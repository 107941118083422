import { useState, useEffect, useRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { AiFillDelete } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { CloseButton } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import "bootstrap/dist/css/bootstrap.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import Select from "react-select";
import { components } from "react-select";
import Select from "../Multiselect";
import Multiselect from "../../Multiselect/Multiselect";
import "./Customtarget.css";
const Customtarget = () => {
  const [currentElement, setCurrentElement] = useState();
  const [activeOption, setActiveOption] = useState([0]);
  const [isOpen, setIsOpen] = useState(false);
  const [indrow, setIndRow] = useState();
  const [currentOption, setCurrentOption] = useState();
  const [questionChild, setQuestionChild] = useState([]);
  const [isswitchdisabled, setSwitchIsdisabled] = useState(true);
  const [label, setlabel] = useState({
    0: [],
  });

  const [definition, setDefinition] = useState({
    0: ["+Add Definition", "", "", ""],
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    setError,
    trigger,
  } = useForm({
    mode: "onBlur",
  });

  const {
    register: register2,
    setValue,
    handleSubmit: handleSubmit2,
  } = useForm();

  const hideModal = () => {
    setIsOpen(false);
  };

  const [selTargetOption, setSelTargetOption] = useState();
  const [SingleQuestion, setSingleQuestion] = useState([
    { label: "DMA", value: ["DMA1", "DMA2", "DMA3"] },
    { label: "STATE", value: ["STATE1", "STATE4", "STATE3"] },
    {
      label: "DIVISION",
      value: [
        "DIVISION1",
        "DIVISION4",
        "DIVISION3",
        "DIVISION11",
        "DIVISION41",
        "DIVISION31",
      ],
    },
    {
      label: "HOUSEHOLD DECISION MAKER",
      value: ["HOUSEHOLD4", "HOUSEHOLD64", "HOUSEHOLD3"],
    },
    { label: "MOVIES FREQUENCY", value: ["MOVIES1", "MOVIES2", "MOVIES3"] },
    { label: "AGE AND GENDER OF CHILD", value: ["AGe1", "AGe2", "AGe3"] },
  ]);

  useEffect(() => {
    setValue("definationName", label);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);
  useEffect(() => {
    setIsDisabled(true);
  }, []);
  const addDefinition = (e, d, i) => {
    setIndRow({ id: d, name: i });

    e.preventDefault();

    setCurrentElement(e.target);
    setIsOpen(true);
    setActiveOption((prevData) => {
      const objLength = Object.keys(definition).length;
      const newData = [...prevData];

      newData.splice(objLength + 1, 0, 0);
      return newData;
    });
  };

  function handleSelect(data) {
    setSelTargetOption(data);
  }

  const onSubmitTarget = (data) => {
    let activeopt = parseInt(indrow.name) + 1;
    setActiveOption((prevData) => {
      const newData = [...prevData];
      newData[indrow.id] = activeopt;
      return newData;
    });
    setCurrentOption("TARGET " + activeopt);
    currentElement.value = currentOption;
    if (Array.isArray(data.definationName[indrow.id])) {
      data.definationName[indrow.id].map((e, index) => {
        if (index === indrow.name) {
          setDefinition((prevState) => {
            const definition = { ...prevState };
            if (definition[indrow.id].length - 1 === index) {
              definition[indrow.id].push("");
            }
            definition[indrow.id][indrow.name] = e;
            return definition;
          });
        }
      });
    }
    hideModal();
  };
  const handleFunction = (data, label) => {
    setSelTargetOption([]);
    const clientdata = data.map((item) =>
      Object.assign({
        value: item,
        label: item,
      })
    );
    setlabel((prevState) => {
      const updatedLabel = { ...prevState };
      updatedLabel[indrow.id] = updatedLabel[indrow.id] || [];
      updatedLabel[indrow.id][indrow.name] = label;
      return updatedLabel;
    });
    setQuestionChild(clientdata);
    setIsDisabled(false);
  };

  const deleteAdddefinition = (index, indexitem) => {
    setDefinition((prevDefinition) => {
      const newDefinition = { ...prevDefinition };
      const itemArray = [...newDefinition[indexitem]];
      itemArray[index] = "";
      for (let i = index; i < itemArray.length - 1; i++) {
        itemArray[i] = itemArray[i + 1];
      }
      itemArray[itemArray.length - 1] = "";
      newDefinition[indexitem] = itemArray;
      return newDefinition;
    });
  };
  const handleShowSect = (id) => {
    var element = document.getElementById("cusfirstSect" + id);
    let iconShow = document.getElementById("cusiconSect" + id);

    if (element.matches(".open")) {
      element.classList.remove("open");
      iconShow.classList.remove("open");
    } else {
      element.classList.add("open");
      iconShow.classList.add("open");
    }
  };

  const ValueContainer = ({ children, ...props }) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
      const plural = values.length === 1 ? "" : "s";
      values = `${values.length} item${plural} selected`;
    }

    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    );
  };

  const handleSubmitCustom = (e) => {};

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitCustom)}>
        <div
          className="ProjectSetting pswtchbtn pjt_arrow_icon Custom_arrow"
          //   id="prescreening"
          id={"cusiconSect" + 1}
          onClick={() => {
            handleShowSect(1);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <p
            style={{
              textAlign: "left",
              marginBottom: "0px",
            }}
          >
            Custom Targeting
          </p>
          <div className="rankPainA">
            <button
              className="PSaveBtnA"
              type="submit"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Save
            </button>
            <div
              style={{
                display: "inline-block",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <BootstrapSwitchButton
                className="switch btn on btn-outline-success btn-xs"
                size="xs"
                width={60}
                onlabel="ON "
                offlabel="OFF"
                onstyle="outline-success"
                offstyle="outline-danger"
              />
            </div>

            <KeyboardArrowUpIcon className="CusIconUp" />
            <KeyboardArrowDownIcon className="CusDropIcon" />
            {/* <KeyboardArrowUpIcon />
            <KeyboardArrowDownIcon className="arrowup" id="customrow" /> */}
          </div>
        </div>
        <div className="container-fluid cus_box" id={"cusfirstSect" + 1}>
          <Row
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {definition[0] &&
              definition[0].map((item, index) => (
                <Col lg={3} md={3} sm={6} xs={12} className="col" key={index}>
                  <div className="input-container">
                    <input
                      {...register("definition")}
                      className={`addDefinition_custom ${
                        index > activeOption[0] ? "disabled" : ""
                      }`}
                      type="text"
                      placeholder={item ? item : "+ Add Definition +"}
                      //   id={`ad_${denum}_ + ${index}`}
                      onClick={(e) => {
                        addDefinition(e, 0, index);
                      }}
                      autoComplete="Off"
                      readOnly
                      //   disabled={index > activeOption[0]}
                    />
                    {item && item !== "+Add Definition" && (
                      <AiFillDelete
                        className="custom_delete"
                        onClick={() => {
                          deleteAdddefinition(index, 0);
                        }}
                      />
                    )}
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </form>

      <Modal show={isOpen} className="targetMain">
        <ModalHeader>
          <ModalTitle
            style={{
              fontSize: "14px",
            }}
          >
            Question Description
          </ModalTitle>
          <CloseButton
            onClick={(e) => {
              hideModal();
            }}
          />
        </ModalHeader>
        <>
          <form onSubmit={handleSubmit2(onSubmitTarget)}>
            <ModalBody>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <CreatableSelect
                    className="formtext"
                    id="targetmain"
                    styles={{ fontSize: "14px" }}
                    isClearable
                    options={SingleQuestion}
                    {...register2("definationName")}
                    onChange={(e) => handleFunction(e.value, e.label)}
                  />
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    fontSize: "13px",
                    height: "40px",
                    marginTop: "25px",
                    textAlign: "center",
                  }}
                >
                  I'm looking for people <span className="qCategoryText"></span>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Multiselect
                    {...register2("answerName")}
                    isDisabled={isDisabled}
                    closeMenuOnSelect={false}
                    components={{ ValueContainer }}
                    hideSelectedOptions={false}
                    value={selTargetOption}
                    onChange={handleSelect}
                    options={questionChild}
                    isMulti
                    isClearable
                    isSearchable
                    allowSelectAll={true}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <button className="targetSubmit" type="submit">
                Apply Targetting
              </button>
            </ModalFooter>
          </form>
        </>
      </Modal>
    </>
  );
};

export default Customtarget;
