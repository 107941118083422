import Modal from "react-bootstrap/Modal";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useEffect } from "react";

import BootstrapSwitchButton from "bootstrap-switch-button-react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Select from "react-select";
import { useForm } from "react-hook-form";
import "../PopUp/SetupSupplier.css";
function SetupSupplier(props) {
  const axiosPrivate = useAxiosPrivate();
  const [showlink, setShowlink] = useState(false);
  const [statusOption, setStatusOption] = useState([]);
  const [statusn, setStatusn] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleSaveButton = (e) => {};

  useEffect(() => {
    getprojectStatus();
  }, []);

  const getprojectStatus = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get-project-status");
      response.data.all_status.map((e) => {
        setStatusOption((val) => [...val, { label: e.name, value: e.id }]);
      });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Modal
      className="setsupplierModal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "10px" }}
        >
          <PersonAddAltIcon style={{ fontSize: "20px" }} />
          &nbsp; Setup Supplier - Peanut Labs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          margin: "0px",
          padding: "15px",
        }}
      >
        <form onSubmit={handleSubmit(handleSaveButton)}>
          <div className="setupsupplier">
            <div>
              <Row
                style={{
                  paddingLeft: "10px",
                }}
              >
                <Col
                  className="inputSize fixsize col"
                  lg={2}
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <label className="formlabelamit">Status</label>

                  <Select
                    {...register("Status", {
                      required: "This field is Required",
                    })}
                    placeholder="Select..."
                    className="pjt_select formtext"
                    classNamePrefix="select"
                    options={statusOption}
                    onChange={(e) => {
                      setStatusn(e.value);
                    }}
                    noOptionsMessage={() => "No Data Found"}
                  />
                  {statusn ? (
                    <></>
                  ) : (
                    <>
                      {errors.Status && (
                        <small className="errmsg editsuperror">
                          {errors.Status.message}
                        </small>
                      )}
                    </>
                  )}
                </Col>
                <Col
                  className="inputSize fixsize col"
                  lg={2}
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <label className="formlabelamit">Supplier Cost</label>
                  <input
                    {...register("SupplierCost", {
                      required: "This field is Required",
                    })}
                    placeholder="Supplier Cost"
                    className="formtextamit"
                    type="text"
                  />
                  {errors.SupplierCost && (
                    <small className="errmsg editsuperror">
                      {errors.SupplierCost.message}
                    </small>
                  )}
                </Col>
                <Col
                  className="inputSize fixsize col"
                  lg={2}
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <label className="formlabelamit">Quota</label>
                  <input
                    {...register("Quota", {
                      required: "This field is Required",
                    })}
                    placeholder="Quota"
                    className="formtextamit"
                    type="text"
                  />
                  {errors.Quota && (
                    <small className="errmsg editsuperror">
                      {errors.Quota.message}
                    </small>
                  )}
                </Col>
                <Col className="inputSize fixsize" lg={2} md={4} sm={4} xs={12}>
                  <label className="formlabelamit">Variable 1</label>
                  <input
                    placeholder="RID"
                    className="formtextamit"
                    type="text"
                  />
                </Col>
                <Col className="inputSize fixsize" lg={2} md={4} sm={4} xs={12}>
                  <label className="formlabelamit">Variable 2</label>
                  <input className="formtextamit" type="text" />
                </Col>
                <Col className="inputSize fixsize" lg={2} md={4} sm={4} xs={12}>
                  <label className="formlabelamit">Variable 3</label>
                  <input className="formtextamit" type="text" />
                </Col>
                <Col className="inputSize fixsize" lg={2} md={4} sm={4} xs={12}>
                  <label className="formlabelamit">Variable 4</label>
                  <input className="formtextamit" type="text" />
                </Col>
              </Row>
              <div className="amit" style={{ height: "10px" }}></div>
              <Row
                style={{
                  paddingLeft: "10px",
                }}
              >
                <Col lg={2} md={4} sm={4} xs={6}>
                  <div className="pswtchbtn s_edit_switcbtn">
                    <label className="formlabelamit">Screen Captcha</label>
                    <BootstrapSwitchButton
                      className="switch btn on btn-outline-success btn-xs"
                      size="xs"
                      width={60}
                      onlabel="ON"
                      offlabel="OFF"
                      onstyle="outline-success"
                      offstyle="outline-danger"
                    />
                  </div>
                </Col>
                <Col lg={2} md={4} sm={4} xs={6}>
                  <div className="pswtchbtn s_edit_switcbtn">
                    <label className="formlabelamit">Red Herring Screen</label>
                    <BootstrapSwitchButton
                      className="switch btn on btn-outline-success btn-xs"
                      size="xs"
                      width={60}
                      onlabel="ON"
                      offlabel="OFF"
                      onstyle="outline-success"
                      offstyle="outline-danger"
                    />
                  </div>
                </Col>
                <Col lg={2} md={4} sm={4} xs={6}>
                  <div className="pswtchbtn s_edit_switcbtn">
                    <label className="formlabelamit">Research Defender</label>
                    <BootstrapSwitchButton
                      className="switch btn on btn-outline-success btn-xs"
                      size="xs"
                      width={60}
                      onlabel="ON"
                      offlabel="OFF"
                      onstyle="outline-success"
                      offstyle="outline-danger"
                    />
                  </div>
                </Col>
                <Col lg={2} md={4} sm={4} xs={6}>
                  <div className="pswtchbtn s_edit_switcbtn">
                    <label className="formlabelamit">Soft Launch</label>
                    <BootstrapSwitchButton
                      className="switch btn on btn-outline-success btn-xs"
                      size="xs"
                      width={60}
                      onlabel="ON"
                      offlabel="OFF"
                      onstyle="outline-success"
                      offstyle="outline-danger"
                    />
                  </div>
                </Col>
                <Col lg={2} md={4} sm={4} xs={6}>
                  <div className="pswtchbtn s_edit_switcbtn">
                    <label className="formlabelamit">Exception Link</label>
                    <BootstrapSwitchButton
                      onChange={(e) => {
                        if (e === true) {
                          setShowlink(true);
                        } else {
                          setShowlink(false);
                        }
                      }}
                      className="switch btn on btn-outline-success btn-xs"
                      size="xs"
                      width={60}
                      onlabel="ON"
                      offlabel="OFF"
                      onstyle="outline-success"
                      offstyle="outline-danger"
                    />
                  </div>
                </Col>
              </Row>
            </div>{" "}
            <div style={{ height: "10px" }}></div>
            <Row className="suppliersetup">
              <Col lg={12} md={12} sm={12} xs={12}>
                <label className="formlabelamit">Completion Link</label>
                <input
                  placeholder="https://peanutlabs.com/return/respondent?screlid=MTU5OTYtMTAwLTQ0MTM=&transaction..."
                  className="formtextamit"
                  type="text"
                />
              </Col>
            </Row>
            <Row className="suppliersetup">
              <Col lg={12} md={12} sm={12} xs={12}>
                <label className="formlabelamit">Termination Link</label>
                <input
                  placeholder="https://peanutlabs.com/return/respondent?screlid=MTU5OTYtMTAwLTQ0MTM=&transaction..."
                  className="formtextamit"
                  type="text"
                />
              </Col>
            </Row>
            <Row className="suppliersetup">
              <Col lg={12} md={12} sm={12} xs={12}>
                <label className="formlabelamit">Quotafull Link</label>
                <input
                  placeholder="https://peanutlabs.com/return/respondent?screlid=MTU5OTYtMTAwLTQ0MTM=&transaction..."
                  className="formtextamit"
                  type="text"
                />
              </Col>
              <Col>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button className="supply_setupbtn">Submit</button>
                </div>
              </Col>
            </Row>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
export default SetupSupplier;
