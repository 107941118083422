import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import Table from "react-bootstrap/Table";

import { MultiSelect } from "react-multi-select-component";

function SupplierInfo(props) {
  return (
    <Modal
      {...props}
      size="lg"
      style={{ width: "60%", marginLeft: "20%" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "14px", paddingLeft: "3px" }}
        >
          Supplier Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered={false} hover size="sm" className="customTable">
          <thead
            className="thead"
            style={{
              fontSize: "13px",
            }}
          >
            <tr>
              <td>Supplier Name</td>
              <td> CPI $</td>
              <td> Quota</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>clint</td>
              <td>40</td>
              <td>50</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
export default SupplierInfo;
