import "../../ProjectDetail/ProjectDetail.css";
import { TableCell, TableRow, TableBody } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Table from "react-bootstrap/Table";
import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SetupSupplier from "./SetupSupplier";
import { BiUserPlus } from "react-icons/bi";
import { useState } from "react";
import MyVerticallyCenteredModal from "./APRequest";
import SupplierLink from "./SupplierLink ";
import EditSupplier from "./EditSupplier";
import EditIcon from "../../../../assets/LoginIcons/edit.svg";
import linkIcon from "../../../../assets/CSicons/icons/link.svg";
import { FiUserPlus } from "react-icons/fi";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const SupplyInfo = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [supplierLink, setSupplierLink] = useState(false);
  const [editsupplier, setEditSupplier] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const handleShowSect = (id) => {
    var element = document.getElementById("firstSect" + id);
    let iconShow = document.getElementById("iconSect" + id);

    if (element.matches(".open")) {
      element.classList.remove("open");
      iconShow.classList.remove("open");
    } else {
      element.classList.add("open");
      iconShow.classList.add("open");
    }
  };

  useEffect(() => {
    get_SupplierList_api();
  }, []);

  const get_SupplierList_api = async () => {
    try {
      const response = await axiosPrivate.get("supplyinfo/get-supplier-list ");
      response.data.all_status.map((e) => {});
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <SetupSupplier show={showIcon} onHide={() => setShowIcon(false)} />
      <EditSupplier
        show={editsupplier}
        onHide={() => setEditSupplier(false)}
        setEditSupplier={setEditSupplier}
      />
      <SupplierLink show={supplierLink} onHide={() => setSupplierLink(false)} />
      <div className="saveclsebtn supply_btn">
        <button
          className="cbtn saveBtn pbtn"
          type="submit"
          onClick={() => setModalShow(true)}
        >
          <FiUserPlus
            style={{
              marginRight: "5px",
              marginBottom: "5px",
            }}
          />
          Add Partner Request
        </button>
      </div>
      <hr className="csHR" />
      <div className=" wrapperBelow">
        <div className="Sfirstbox" style={{ position: "relative" }}>
          <div
            className="SArrowHeead"
            id={"iconSect" + 1}
            onClick={() => {
              handleShowSect(1);
            }}
          >
            <p className="supply_country">US Gen Pop 18+</p>
            <KeyboardArrowUpIcon className="SIconUp" />
            <KeyboardArrowDownIcon className="SDropIcon" />
          </div>
          <div className="STable" id={"firstSect" + 1}>
            <Table>
              <thead>
                <TableRow>
                  <TableCell className="supply_theadfont">
                    Supplier Name
                  </TableCell>
                  <TableCell className="supply_theadfont">Status</TableCell>
                  <TableCell className="supply_theadfont">Quota</TableCell>
                  <TableCell className="supply_theadfont">
                    Supplier CPI
                  </TableCell>
                  <TableCell className="supply_theadfont">
                    Supplier Type
                  </TableCell>
                  <TableCell className="supply_theadfont">Action</TableCell>
                </TableRow>
              </thead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "0px",
                    }}
                    className="supplyinfo_text"
                  >
                    <FiberManualRecordIcon
                      style={{ color: "green", height: "10px" }}
                    />
                    Fulcrum API
                  </TableCell>
                  <TableCell className="supplyinfo_text">Active</TableCell>
                  <TableCell className="supplyinfo_text">06 | 500</TableCell>
                  <TableCell className="supplyinfo_text">$1.25</TableCell>
                  <TableCell className="supplyinfo_text">Api</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "0px",
                    }}
                    className="supplyinfo_text"
                  >
                    <FiberManualRecordIcon
                      style={{ color: "green", height: "10px" }}
                    />
                    PureSpectrum API
                  </TableCell>
                  <TableCell className="supplyinfo_text">Active</TableCell>
                  <TableCell className="supplyinfo_text">06 | 500</TableCell>
                  <TableCell className="supplyinfo_text">$1.25</TableCell>
                  <TableCell className="supplyinfo_text">Api</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "0px",
                    }}
                    className="supplyinfo_text"
                  >
                    <FiberManualRecordIcon
                      style={{ color: "green", height: "10px" }}
                    />
                    Dynata API
                  </TableCell>
                  <TableCell className="supplyinfo_text">Active</TableCell>
                  <TableCell className="supplyinfo_text">06 | 500</TableCell>
                  <TableCell className="supplyinfo_text">$1.25</TableCell>
                  <TableCell className="supplyinfo_text">Api</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "0px",
                    }}
                    className="supplyinfo_text"
                  >
                    <FiberManualRecordIcon
                      style={{ color: "green", height: "10px" }}
                    />
                    WisePerks API
                  </TableCell>
                  <TableCell className="supplyinfo_text">Active</TableCell>
                  <TableCell className="supplyinfo_text">06 | 500</TableCell>
                  <TableCell className="supplyinfo_text">$1.25</TableCell>
                  <TableCell className="supplyinfo_text">Api</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "0px",
                    }}
                    className="supplyinfo_text"
                  >
                    <FiberManualRecordIcon
                      style={{ color: "blue", height: "10px" }}
                    />
                    Peanut Labs
                  </TableCell>
                  <TableCell className="supplyinfo_text">Setup</TableCell>
                  <TableCell className="supplyinfo_text">06 | 500</TableCell>
                  <TableCell className="supplyinfo_text">$1.25</TableCell>
                  <TableCell>Manual</TableCell>
                  <TableCell className="supplyinfo_text">
                    <BiUserPlus
                      className="SupplyIcon"
                      onClick={() => {
                        setShowIcon(true);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "0px",
                    }}
                    className="supplyinfo_text"
                  >
                    <FiberManualRecordIcon
                      style={{ color: "orange", height: "10px" }}
                    />
                    P2 Sample
                  </TableCell>
                  <TableCell className="supplyinfo_text">Pause</TableCell>
                  <TableCell className="supplyinfo_text">06 | 500</TableCell>
                  <TableCell className="supplyinfo_text">$1.25</TableCell>
                  <TableCell className="supplyinfo_text">Manual</TableCell>
                  <TableCell className="supplyinfo_text">
                    <img
                      src={EditIcon}
                      alt="EditIcon"
                      srcset=""
                      className="SupplyIcon"
                      onClick={() => {
                        setEditSupplier(true);
                      }}
                    />
                    <img
                      src={linkIcon}
                      alt="linkIcon"
                      srcset=""
                      className="SupplyIcon"
                      onClick={() => {
                        setSupplierLink(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "0px",
                    }}
                    className="supplyinfo_text"
                  >
                    <FiberManualRecordIcon
                      style={{ color: "yellow", height: "10px" }}
                    />
                    Market Cube
                  </TableCell>
                  <TableCell className="supplyinfo_text">Pending</TableCell>
                  <TableCell className="supplyinfo_text">06 | 500</TableCell>
                  <TableCell className="supplyinfo_text">$1.25</TableCell>
                  <TableCell className="supplyinfo_text">Manual</TableCell>
                  <TableCell className="supplyinfo_text">
                    <img
                      src={EditIcon}
                      alt="EditIcon"
                      srcset=""
                      className="SupplyIcon"
                      onClick={() => {
                        setEditSupplier(true);
                      }}
                    />

                    <img
                      src={linkIcon}
                      alt="linkIcon"
                      srcset=""
                      className="SupplyIcon"
                      onClick={() => {
                        setSupplierLink(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "0px",
                    }}
                    className="supplyinfo_text"
                  >
                    <FiberManualRecordIcon
                      style={{ color: "red", height: "10px" }}
                    />
                    Research for Good Inc.
                  </TableCell>
                  <TableCell className="supplyinfo_text">Approved</TableCell>
                  <TableCell className="supplyinfo_text">06 | 500</TableCell>
                  <TableCell className="supplyinfo_text">$1.25</TableCell>
                  <TableCell className="supplyinfo_text">Manual</TableCell>
                  <TableCell className="supplyinfo_text">
                    <img
                      src={EditIcon}
                      alt="EditIcon"
                      srcset=""
                      className="SupplyIcon"
                      onClick={() => {
                        setEditSupplier(true);
                      }}
                    />

                    <img
                      src={linkIcon}
                      alt="linkIcon"
                      srcset=""
                      className="SupplyIcon"
                      onClick={() => {
                        setSupplierLink(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplyInfo;
