import Paginations from "../Paginations/Paginations";
import { useState, useEffect } from "react";
import pageIcon from "../../assets/BidsIcons/bids.svg";
import { Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import downloadIcon from "../../assets/CSicons/icons/download.svg";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import "./Reconciliation.css";
const Reconsilitionupdate = () => {
  const navigate = useNavigate();
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const axiosPrivate = useAxiosPrivate();
  const [surveyType, setSurveyType] = useState([]);
  const getPageSize = (size) => {
    setPagesize(size);
  };

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };
  useEffect(() => {
    get_SupplierList_api();
  }, []);

  const get_SupplierList_api = async () => {
    try {
      const response = await axiosPrivate.get("supplyinfo/get-supplier-list ");
      response.data.supplier.map((e) => {
        setSurveyType((val) => [...val, { label: e.name, value: e.id }]);
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachboxSupply">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" />{" "}
                Reconciliation of SC# 20010001
              </h3>
            </div>
            <button
              className="cbtn saveBtn"
              onClick={() => {
                navigate("/reconciliation");
              }}
            >
              Back
            </button>
          </div>
          <hr />
          <div className="mainContent">
            <div
              style={{
                backgroundColor: "#f1efef",
                padding: "5px",
              }}
            >
              <Row
                style={{
                  margin: "10px auto",
                }}
              >
                <Col className="Quality_Col" lg={2} md={4} sm={12} xs={12}>
                  <div
                    className="flex flex-direction redit_select"
                    style={{
                      margin: "5px",
                    }}
                  >
                    <label class="formlabel" for="status">
                      Select Supplier
                    </label>
                    <select
                      name=""
                      id=""
                      style={{
                        height: "25px",
                      }}
                    >
                      {surveyType.map((surveyItem) => (
                        <option value={surveyItem.value}>
                          {surveyItem.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col className="Quality_Col" lg={2} md={4} sm={12} xs={12}>
                  <div
                    className="flex flex-direction"
                    style={{
                      margin: "5px",
                    }}
                  >
                    <label class="formlabel" for="status">
                      RECON
                    </label>
                    <input
                      placeholder="RECON %"
                      className=""
                      type="text"
                      style={{
                        fontSize: "14px",
                      }}
                    />
                  </div>
                </Col>
                <Col
                  className="Quality_Col R_conc_butn"
                  lg={2}
                  md={4}
                  sm={12}
                  xs={12}
                >
                  <div
                    className="flex flex-direction "
                    style={{
                      margin: "5px",
                    }}
                  >
                    <button class="R_submit-btn">Submit</button>
                  </div>
                </Col>
                <Col
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className="col R_all_cost_btn"
                >
                  <div
                    className="flex flex-direction"
                    style={{
                      margin: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    <button class="R_submit-btn R_update">
                      All Cost Update
                    </button>
                  </div>
                </Col>
              </Row>
            </div>

            <Row
              style={{
                marginTop: "20px",
              }}
            >
              <Col lg={6} md={6} xs={12} className="col">
                <label className="formlabel">Enter Respondent ID's</label>
                <input
                  type="text"
                  placeholder="Search Respondent’s"
                  className="formtext"
                />
              </Col>
              <Col lg={6} md={6} xs={12} className="col">
                <label className="formlabel">Remarks</label>
                <input type="text" placeholder="None" className="formtext" />
              </Col>
            </Row>
            <div
              style={{
                backgroundColor: "#f1efef",
                marginTop: "20px",
                padding: "8px",
              }}
            >
              <Table
                bordered={false}
                id="R_consilition"
                // hover
                size="sm"
                className="R_customTable"
                style={{ marginBottom: "0px" }}
              >
                <tbody>
                  <tr>
                    <td>Total assigned vendor</td>
                    <td>Total respondent</td>
                    <td>Total ID</td>

                    <td>Picked ID</td>
                    <td className="R_recon"> RECON %</td>

                    <td rowSpan={2} className="RD_recon">
                      <img
                        src={downloadIcon}
                        alt=""
                        style={{
                          width: "20px",
                          float: "right",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>139</td>
                    <td>139</td>
                    <td>100</td>

                    <td> 100.0</td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <Row
              style={{
                marginTop: "20px",
              }}
            >
              <Col lg={4} md={6} xs={12} className="col">
                <div
                  style={{
                    // width: "max-content",
                    textAlign: "center",
                    fontSize: "14px",

                    border: "1px solid #f2f0f0",
                  }}
                >
                  <h6
                    style={{
                      height: "30px",
                      paddingTop: "5px",
                    }}
                  >
                    Supplier Details
                  </h6>

                  <Table
                    bordered={false}
                    hover
                    size="sm"
                    className="customTable"
                  >
                    <thead className="thead">
                      <tr>
                        <td> –</td>
                        <td>SC</td>
                        <td>CL</td>

                        <td> RC</td>
                        <td> %</td>

                        <td>RECON</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>FSH</td>
                        <td>0</td>
                        <td>2</td>
                        <td>0</td>

                        <td> 100.0</td>
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr>

                      <tr>
                        <td>FSH</td>
                        <td>0</td>
                        <td>2</td>
                        <td>0</td>

                        <td> 100.0</td>
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr>

                      <tr>
                        <td>FSH</td>
                        <td>0</td>
                        <td>2</td>
                        <td>0</td>

                        <td> 100.0</td>
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr>
                      <tr>
                        <td>FSH</td>
                        <td>0</td>
                        <td>2</td>
                        <td>0</td>

                        <td> 100.0</td>
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr>
                      <tr>
                        <td>FSH</td>
                        <td>0</td>
                        <td>2</td>
                        <td>0</td>

                        <td> 100.0</td>
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr>
                      <tr>
                        <td>FSH</td>
                        <td>0</td>
                        <td>2</td>
                        <td>0</td>

                        <td> 100.0</td>
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr>
                      <tr>
                        <td>FSH</td>
                        <td>0</td>
                        <td>2</td>
                        <td>0</td>

                        <td> 100.0</td>
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col lg={8} md={6} xs={12} className="R_col col">
                <div>
                  <Table
                    bordered={false}
                    hover
                    size="sm"
                    className="customTable"
                  >
                    <thead className="thead">
                      <tr>
                        <td></td>
                        <td>Respondent Id</td>
                        <td>Vendor respondent id</td>
                        <td>Vendor name</td>

                        <td> LOI</td>
                        <td> Vendor Cost</td>
                        <td>Status</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr id="Row1" className="mainrow">
                        <td>
                          <input type="checkbox" name="" id="" />
                        </td>
                        <td>d0825cafce352c480b43ce2c16</td>
                        <td>0911-GYXKG5ZEC2-005-1</td>
                        <td>Fulcrum SH1</td>

                        <td> 365 days, 3:37:43</td>
                        <td className="td_Vendor">4</td>

                        <td>
                          {" "}
                          <button
                            style={{
                              backgroundColor: "#dc2a30",
                              width: "65px",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Rejected
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr id="Row1" className="mainrow">
                        <td>
                          <input type="checkbox" name="" id="" />
                        </td>
                        <td>d0825cafce352c480b43ce2c16</td>
                        <td>0911-GYXKG5ZEC2-005-1</td>
                        <td>Fulcrum SH1</td>

                        <td> 365 days, 3:37:43</td>
                        <td className="td_Vendor">4</td>
                        <td>
                          {" "}
                          <button
                            style={{
                              backgroundColor: "#dc2a30",
                              width: "65px",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Rejected
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr id="Row1" className="mainrow">
                        <td>
                          <input type="checkbox" name="" id="" />
                        </td>
                        <td>d0825cafce352c480b43ce2c16</td>
                        <td>0911-GYXKG5ZEC2-005-1</td>
                        <td>Fulcrum SH1</td>

                        <td> 365 days, 3:37:43</td>
                        <td className="td_Vendor">4</td>
                        <td>
                          {" "}
                          <button
                            style={{
                              backgroundColor: "#dc2a30",
                              width: "65px",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Rejected
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr id="Row1" className="mainrow">
                        <td>
                          <input type="checkbox" name="" id="" />
                        </td>
                        <td>d0825cafce352c480b43ce2c16</td>
                        <td>0911-GYXKG5ZEC2-005-1</td>
                        <td>Fulcrum SH1</td>

                        <td> 365 days, 3:37:43</td>
                        <td className="td_Vendor">4</td>
                        <td>
                          {" "}
                          <button
                            style={{
                              backgroundColor: "#dc2a30",
                              width: "65px",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Rejected
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr id="Row1" className="mainrow">
                        <td>
                          <input type="checkbox" name="" id="" />
                        </td>
                        <td>d0825cafce352c480b43ce2c16</td>
                        <td>0911-GYXKG5ZEC2-005-1</td>
                        <td>Fulcrum SH1</td>

                        <td> 365 days, 3:37:43</td>
                        <td className="td_Vendor">4</td>
                        <td>
                          {" "}
                          <button
                            style={{
                              backgroundColor: "#dc2a30",
                              width: "65px",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Rejected
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr id="Row1" className="mainrow">
                        <td>
                          <input type="checkbox" name="" id="" />
                        </td>
                        <td>d0825cafce352c480b43ce2c16</td>
                        <td>0911-GYXKG5ZEC2-005-1</td>
                        <td>Fulcrum SH1</td>

                        <td> 365 days, 3:37:43</td>
                        <td className="td_Vendor">4</td>
                        <td>
                          {" "}
                          <button
                            style={{
                              backgroundColor: "#dc2a30",
                              width: "65px",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Rejected
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr id="Row1" className="mainrow">
                        <td>
                          <input type="checkbox" name="" id="" />
                        </td>
                        <td>d0825cafce352c480b43ce2c16</td>
                        <td>0911-GYXKG5ZEC2-005-1</td>
                        <td>Fulcrum SH1</td>

                        <td> 365 days, 3:37:43</td>
                        <td className="td_Vendor">4</td>
                        <td>
                          {" "}
                          <button
                            style={{
                              backgroundColor: "#dc2a30",
                              width: "65px",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Rejected
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr id="Row1" className="mainrow">
                        <td>
                          <input type="checkbox" name="" id="" />
                        </td>
                        <td>d0825cafce352c480b43ce2c16</td>
                        <td>0911-GYXKG5ZEC2-005-1</td>
                        <td>Fulcrum SH1</td>

                        <td> 365 days, 3:37:43</td>
                        <td className="td_Vendor">4</td>
                        <td>
                          {" "}
                          <button
                            style={{
                              backgroundColor: "#dc2a30",
                              width: "65px",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Rejected
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr id="Row1" className="mainrow">
                        <td>
                          <input type="checkbox" name="" id="" />
                        </td>
                        <td>d0825cafce352c480b43ce2c16</td>
                        <td>0911-GYXKG5ZEC2-005-1</td>
                        <td>Fulcrum SH1</td>

                        <td> 365 days, 3:37:43</td>
                        <td className="td_Vendor">4</td>
                        <td>
                          {" "}
                          <button
                            style={{
                              backgroundColor: "#dc2a30",
                              width: "65px",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Rejected
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr id="Row1" className="mainrow">
                        <td>
                          <input type="checkbox" name="" id="" />
                        </td>
                        <td>d0825cafce352c480b43ce2c16</td>
                        <td>0911-GYXKG5ZEC2-005-1</td>
                        <td>Fulcrum SH1</td>

                        <td> 365 days, 3:37:43</td>
                        <td className="td_Vendor">4</td>
                        <td>
                          {" "}
                          <button
                            style={{
                              backgroundColor: "#dc2a30",
                              width: "65px",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Rejected
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
          <Paginations
            userlist={10}
            getPageSize={getPageSize}
            handlePageChnage={handlePageChnage}
          />
        </div>
      </div>
    </>
  );
};

export default Reconsilitionupdate;
