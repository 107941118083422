import Qualityheader from "./Qualityheader";
import "./Projectquality.css";
import { CiWarning } from "react-icons/ci";
import { FaTelegramPlane } from "react-icons/fa";
import { useState } from "react";
import Paginations from "../Paginations/Paginations";

import Table from "react-bootstrap/Table";
import { Col, Row } from "react-bootstrap";
import pageIcon from "../../assets/BidsIcons/bids.svg";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DisputedError from "./PopUpPage/DisputedError";
import ErrorCreated from "./PopUpPage/ErrorCreated";
import Project3 from "./PopUpPage/Project3";
import SurveyLogs from "./PopUpPage/SurveyLogs";
import QualityAuditLog from "./PopUpPage/QualityAuditLOgs";
import Projectdetailinline from "./ProjectqualityinlineData";

import downloadIcon from "../../assets/CSicons/icons/download.svg";

import fileIcon from "../../assets/CSicons/icons/file.svg";

import { FiEdit, FiFilter } from "react-icons/fi";
import { AiOutlineTag } from "react-icons/ai";
const Projectquality = () => {
  const [DisError, setDisError] = useState(false);
  const [ErrCreated, setErrCreated] = useState(false);
  const [Project, setProject] = useState(false);
  const [SurLogs, setSurLogs] = useState(false);
  const [Audit, setAudit] = useState(false);

  const [show, setShow] = useState(false);
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);

  const getPageSize = (size) => {
    setPagesize(size);
  };

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };

  const handleRow = (eid) => {
    let activeRow = document.getElementById(eid);

    document
      .querySelectorAll(".Proj_inner-border")
      .forEach((el) => el.classList.remove("open"));
    if (activeRow.classList.contains("active")) {
      activeRow.classList.remove("active");
    } else {
      document
        .querySelectorAll(".mainrow")
        .forEach((el) => el.classList.remove("active"));
      activeRow.classList.add("active");

      document.querySelector("." + eid).classList.add("open");
    }
  };
  return (
    <>
      <DisputedError show={DisError} onHide={() => setDisError(false)} />
      <ErrorCreated show={ErrCreated} onHide={() => setErrCreated(false)} />
      <Project3
        show={Project}
        onHide={() => setProject(false)}
        setProject={setProject}
      />
      <SurveyLogs show={SurLogs} onHide={() => setSurLogs(false)} />
      <QualityAuditLog show={Audit} onHide={() => setAudit(false)} />
      <Qualityheader pname={"ProjectQuality"} />
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachboxSupply">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" /> Project
                Quality
              </h3>
            </div>

            <img
              src={downloadIcon}
              className="project_qualityIconColr"
              alt=""
              srcset=""
              style={{
                float: "right",

                cursor: "pointer",
                fill: "black",
              }}
            />

            <img
              src={fileIcon}
              className="project_qualityIconColr"
              alt=""
              srcset=""
              style={{
                float: "right",

                cursor: "pointer",
                fill: "black",
              }}
            />

            <FiFilter
              className="project_qualityIconColr"
              style={{
                float: "right",

                cursor: "pointer",
                fill: "black",
              }}
              onClick={() => setShow(!show)}
            />
          </div>
          <hr />

          {show ? (
            <div className="Quality_search-filter">
              <Row className="Quality_sf">
                <Col lg={11}>
                  <Row>
                    <Col className="Quality_Col" lg={2} md={4} sm={4} xs={12}>
                      <div class="input-row-child flex flex-direction">
                        <label class="formlabel">Date Range</label>
                        <DateRangePicker
                          initialSettings={{
                            locale: {
                              format: "YYYY-MM-DD",
                            },
                            startDate: "2022-01-11",
                            endDate: "2022-01-12",
                          }}
                        >
                          <input
                            type="text"
                            className="dataPicker"
                            style={{
                              width: "100%",
                            }}
                          />
                        </DateRangePicker>
                      </div>
                    </Col>
                    <Col className="Quality_Col" lg={2} md={4} sm={4} xs={12}>
                      <div className="flex flex-direction">
                        <label class="formlabel" for="status">
                          Prescreening
                        </label>
                        <input placeholder="Prescreening" type="text" />
                      </div>
                    </Col>
                    <Col className="Quality_Col" lg={2} md={4} sm={4} xs={12}>
                      <div className="flex flex-direction">
                        <label class="formlabel" for="status">
                          Quota-full
                        </label>
                        <input
                          placeholder="Quota-full"
                          className=""
                          type="text"
                        />
                      </div>
                    </Col>
                    <Col className="Quality_Col" lg={2} md={4} sm={4} xs={12}>
                      <div className="flex flex-direction">
                        <label class="formlabel" for="status">
                          Low Hits Projects
                        </label>
                        <input placeholder="Low Hits Projects" type="text" />
                      </div>
                    </Col>
                    <Col className="Quality_Col" lg={2} md={4} sm={4} xs={12}>
                      <div className="flex flex-direction">
                        <label class="formlabel" for="status">
                          Disputed Audit
                        </label>
                        <input placeholder="Disputed Audit" type="text" />
                      </div>
                    </Col>
                    <Col className="Quality_Col" lg={2} md={4} sm={4} xs={12}>
                      <div className="flex flex-direction">
                        <label class="formlabel" for="status">
                          Search
                        </label>
                        <input
                          type="search"
                          name="search"
                          placeholder="Search"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={1}>
                  <div className="Quality_subbtn flex flex-direction">
                    <label for="status"></label>
                    <button className="cbtn saveBtn">Search</button>
                  </div>
                </Col>
              </Row>
            </div>
          ) : null}
          <div className="mainContent mainContent2">
            <div className="table_container">
              <Table bordered={false} hover size="sm" className="customTable">
                <thead className="thead">
                  <tr>
                    <td>Project Name</td>
                    <td>Country</td>
                    <td>Project Status</td>

                    <td> LIN Status</td>
                    <td> CPI</td>
                    <td>LOI</td>
                    <td> IR(%)</td>

                    <td>Quota</td>
                    <td> No. of Audits</td>
                    <td>Terminates</td>
                    <td>LU Date</td>
                    <td>PM initials</td>

                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    onClick={(e) => handleRow("Row1")}
                    id="Row1"
                    className="mainrow"
                  >
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>US</td>
                    <td>Live</td>
                    <td>Live</td>

                    <td> 1.47</td>
                    <td>12</td>
                    <td>37.5</td>

                    <td>100</td>
                    <td>
                      <div class="circle-singleline">2</div>
                    </td>
                    <td>07</td>
                    <td>11/20/22</td>
                    <td> Parth</td>

                    <td>
                      <CiWarning
                        className="SupIcon"
                        onClick={() => {
                          setErrCreated(true);
                        }}
                      />

                      <FaTelegramPlane
                        className="SupIcon"
                        onClick={() => {
                          setDisError(true);
                        }}
                      />
                      <FiEdit
                        className="SupIcon"
                        onClick={() => {
                          setProject(true);
                        }}
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setAudit(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr className="Proj_inner-border Row1">
                    <td colspan="16" className="indent">
                      <Projectdetailinline />
                    </td>
                  </tr>
                  <tr
                    onClick={(e) => handleRow("Row2")}
                    id="Row2"
                    className="mainrow"
                  >
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>US</td>
                    <td>Live</td>
                    <td>Live</td>

                    <td> 1.47</td>
                    <td>12</td>
                    <td>37.5</td>

                    <td>100</td>
                    <td>
                      <div class="circle-singleline">2</div>
                    </td>
                    <td>07</td>
                    <td>11/20/22</td>
                    <td> Parth</td>
                    <td>
                      <CiWarning
                        className="SupIcon"
                        onClick={() => {
                          setErrCreated(true);
                        }}
                      />

                      <FaTelegramPlane
                        className="SupIcon"
                        onClick={() => {
                          setDisError(true);
                        }}
                      />
                      <FiEdit
                        className="SupIcon"
                        onClick={() => {
                          setProject(true);
                        }}
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setAudit(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr className="Proj_inner-border Row2">
                    <td colspan="16" className="indent">
                      <Projectdetailinline />
                    </td>
                  </tr>
                  <tr
                    onClick={(e) => handleRow("Row3")}
                    id="Row3"
                    className="mainrow"
                  >
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>US</td>
                    <td>Live</td>
                    <td>Live</td>

                    <td> 1.47</td>
                    <td>12</td>
                    <td>37.5</td>

                    <td>100</td>
                    <td>
                      <div class="circle-singleline">2</div>
                    </td>
                    <td>07</td>
                    <td>11/20/22</td>
                    <td> Parth</td>
                    <td>
                      <CiWarning
                        className="SupIcon"
                        onClick={() => {
                          setErrCreated(true);
                        }}
                      />

                      <FaTelegramPlane
                        className="SupIcon"
                        onClick={() => {
                          setDisError(true);
                        }}
                      />
                      <FiEdit
                        className="SupIcon"
                        onClick={() => {
                          setProject(true);
                        }}
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setAudit(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr className="Proj_inner-border Row3">
                    <td colspan="16" className="indent">
                      <Projectdetailinline />
                    </td>
                  </tr>
                  <tr>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>US</td>
                    <td>Live</td>
                    <td>Live</td>

                    <td> 1.47</td>
                    <td>12</td>
                    <td>37.5</td>

                    <td>100</td>
                    <td>-</td>
                    <td>07</td>
                    <td>11/20/22</td>
                    <td> Parth</td>
                    <td>
                      <CiWarning
                        className="SupIcon"
                        onClick={() => {
                          setErrCreated(true);
                        }}
                      />

                      <FaTelegramPlane
                        className="SupIcon"
                        onClick={() => {
                          setDisError(true);
                        }}
                      />
                      <FiEdit
                        className="SupIcon"
                        onClick={() => {
                          setProject(true);
                        }}
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setAudit(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>US</td>
                    <td>Live</td>
                    <td>Live</td>

                    <td> 1.47</td>
                    <td>12</td>
                    <td>37.5</td>

                    <td>100</td>
                    <td>-</td>
                    <td>07</td>
                    <td>11/20/22</td>
                    <td> Parth</td>
                    <td>
                      <CiWarning
                        className="SupIcon"
                        onClick={() => {
                          setErrCreated(true);
                        }}
                      />

                      <FaTelegramPlane
                        className="SupIcon"
                        onClick={() => {
                          setDisError(true);
                        }}
                      />
                      <FiEdit
                        className="SupIcon"
                        onClick={() => {
                          setProject(true);
                        }}
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setAudit(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>US</td>
                    <td>Live</td>
                    <td>Live</td>

                    <td> 1.47</td>
                    <td>12</td>
                    <td>37.5</td>

                    <td>100</td>
                    <td>-</td>
                    <td>07</td>
                    <td>11/20/22</td>
                    <td> Parth</td>
                    <td>
                      <CiWarning
                        className="SupIcon"
                        onClick={() => {
                          setErrCreated(true);
                        }}
                      />

                      <FaTelegramPlane
                        className="SupIcon"
                        onClick={() => {
                          setDisError(true);
                        }}
                      />
                      <FiEdit
                        className="SupIcon"
                        onClick={() => {
                          setProject(true);
                        }}
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setAudit(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>US</td>
                    <td>Live</td>
                    <td>Live</td>

                    <td> 1.47</td>
                    <td>12</td>
                    <td>37.5</td>

                    <td>100</td>
                    <td>-</td>
                    <td>07</td>
                    <td>11/20/22</td>
                    <td> Parth</td>
                    <td>
                      <CiWarning
                        className="SupIcon"
                        onClick={() => {
                          setErrCreated(true);
                        }}
                      />

                      <FaTelegramPlane
                        className="SupIcon"
                        onClick={() => {
                          setDisError(true);
                        }}
                      />
                      <FiEdit
                        className="SupIcon"
                        onClick={() => {
                          setProject(true);
                        }}
                      />
                      <AiOutlineTag
                        className="SupIcon"
                        onClick={() => {
                          setAudit(true);
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <Paginations
            userlist={10}
            getPageSize={getPageSize}
            handlePageChnage={handlePageChnage}
          />
        </div>
      </div>
    </>
  );
};

export default Projectquality;
