/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./SurveyListing.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "../../UI/DateRangePicker.css";
import SLicon from "./Assets/survey-listing-icon.svg";
import { Col, Row } from "react-bootstrap";
import SurveyInnerTable from "./SurveyInnerTable";
import Select from "react-select";
import Paginations from "../../Paginations/Paginations";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

import {
  BiPlus,
  BiSearch,
  BiGitRepoForked,
  BiFileBlank,
  BiDownload,
  BiChevronLeft,
  BiChevronRight,
  BiSortAlt2,
} from "react-icons/bi";
import SurveyListingPopUp from "./SurveyListingPopUp";

function SurveyListing() {
  const axiosPrivate = useAxiosPrivate();
  const [tableData, setTableData] = useState([]);
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const [show, setShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const handleRow = (eid) => {
    let activeRow = document.getElementById(eid);
    document
      .querySelectorAll(".inner-border")
      .forEach((el) => el.classList.remove("open"));
    if (activeRow.classList.contains("active")) {
      activeRow.classList.remove("active");
    } else {
      document
        .querySelectorAll(".mainrow")
        .forEach((el) => el.classList.remove("active"));
      activeRow.classList.add("active");
      document.querySelector("." + eid).classList.add("open");
    }
  };

  const Client = [
    { value: "Dynata", label: "Dynata" },
    { value: "Cint", label: "Cint" },
  ];
  const ProjType = [
    { value: "B2B", label: "B2B" },
    { value: "Tracker", label: "Tracker" },
  ];
  const ProjManager = [
    { value: "Parth", label: "Parth" },
    { value: "Ankit", label: "Ankit" },
  ];
  const Status = [
    { value: "Live", label: "Live" },
    { value: "Paused", label: "Paused" },
  ];

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };

  // const fetchDataWithToken = async () => {
  //   const token = "YOUR_API_TOKEN"; // Replace 'YOUR_API_TOKEN' with your actual API token
  //   const url =
  //     "http://52.13.201.242:8003/api/v1/project/project-list?page=1&size=50&sort=-_id"; // Replace 'YOUR_API_URL' with the URL of the API you want to fetch

  //   try {
  //     const response = await axios.get(url, {
  //       headers: {
  //         accept: "application/json",
  //         "Content-Type": "application/json",
  //         "access-token":
  //           "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIwMTk2MjUsIm5iZiI6MTY5MjAxOTYyNSwianRpIjoiOGFmYWRmMzAtYTVhNC00OGFlLWI1NzEtZWY4ZTA3ZWY1NTFlIiwiZXhwIjoxNjkyNDUxNjI1LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.bWsqVzKK8ffZA_Nzu51yBnjmWTxGhy_dicw-hbipmvo",
  //       },
  //     });
  //     setTableData(response.data.items);

  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const get_ProjectList_api = async () => {
    try {
      const response = await axiosPrivate.get(
        "project/project-list?page=1&size=50&sort=-_id"
      );
      setTableData(response.data.items);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    get_ProjectList_api();
    // fetchDataWithToken();
  }, []);

  return (
    <>
      <SurveyListingPopUp
        show={lgShow}
        onHide={() => setLgShow(false)}
        setLgShow={setLgShow}
      />
      <section className="content_wrapper">
        <div className="containerbox">
          <div className="data_wrapper">
            {/*-------- Inner Header--------- */}
            <div className="inner-header flex items-center justify-between">
              <div className="inner-left flex items-center">
                <img src={SLicon} />
                <h3>Survey Listing</h3>
              </div>
              <div className="inner-right flex items-center">
                <button className="add-survey-btn">
                  <a className="flex items-center" href="#">
                    <BiPlus />
                    Add Survey
                  </a>
                </button>
                <span className="action-icons flex items-center">
                  <a className="search-filter-toggle" href="#">
                    <BiSearch onClick={() => setShow(!show)} />
                  </a>
                  <a href="#">
                    <BiGitRepoForked />
                  </a>
                  <a href="#">
                    <BiPlus />
                  </a>
                  <a href="#">
                    <BiFileBlank />
                  </a>
                  <a
                    href="#"
                    onClick={() => {
                      setLgShow(true);
                    }}
                  >
                    <BiDownload />
                  </a>
                </span>
              </div>
            </div>

            {/*-------- Search Filter--------- */}
            {show ? (
              <div className="search-filter">
                <Row className="sf">
                  <Col lg={2} md={4} sm={6} xs={12}>
                    <div class="input-row-child flex flex-direction">
                      <label>Date Range</label>
                      <DateRangePicker
                        initialSettings={{
                          locale: {
                            format: "YYYY-MM-DD",
                          },
                          startDate: "2023-04-13",
                          endDate: "Y2023-04-01",
                        }}
                        // onCallback={handleCallback}
                      >
                        <input type="text" className="form-control" />
                      </DateRangePicker>
                    </div>
                  </Col>
                  <Col lg={2} md={4} sm={6} xs={12}>
                    <div className="flex flex-direction">
                      <label for="status">Client</label>
                      <Select
                        placeholder="Select..."
                        className="pjt_select"
                        classNamePrefix="select"
                        // {...accountdataReg}
                        options={Client}
                        noOptionsMessage={() => "No Data Found"}
                      />
                    </div>
                  </Col>
                  <Col lg={2} md={4} sm={6} xs={12}>
                    <div className="flex flex-direction">
                      <label for="status">Project Type</label>
                      <Select
                        placeholder="Select..."
                        className="pjt_select"
                        classNamePrefix="select"
                        // {...accountdataReg}
                        options={ProjType}
                        noOptionsMessage={() => "No Data Found"}
                      />
                    </div>
                  </Col>
                  <Col lg={2} md={4} sm={6} xs={12}>
                    <div className="flex flex-direction">
                      <label for="status">Project Manager</label>
                      <Select
                        placeholder="Select..."
                        className="pjt_select"
                        classNamePrefix="select"
                        // {...accountdataReg}
                        options={ProjManager}
                        noOptionsMessage={() => "No Data Found"}
                      />
                    </div>
                  </Col>
                  <Col lg={2} md={4} sm={6} xs={12}>
                    <div className="flex flex-direction">
                      <label for="status">Status</label>
                      <Select
                        placeholder="Select..."
                        className="pjt_select"
                        classNamePrefix="select"
                        // {...accountdataReg}
                        options={Status}
                        noOptionsMessage={() => "No Data Found"}
                      />
                    </div>
                  </Col>
                  <Col lg={2} md={4} sm={6} xs={12}>
                    <div className="flex flex-direction">
                      <label for="status">Search</label>
                      <input
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            ) : null}

            {/*-------- Table--------- */}
            <div style={{ overflowX: "auto" }}>
              <table>
                <tbody>
                  <tr className="table-heading">
                    <th>
                      <span>
                        Status
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span>
                        SC#
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span title="Project Name">
                        PN
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span>
                        Client
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span>
                        Sales
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span title="Project Manager">
                        PM
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span title="Country">
                        Ctry
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span>
                        Quota
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span>
                        LOI
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th className="w-set">
                      <span title="Conversion">
                        Conv.
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span>
                        L24S
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span>
                        L24T
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span>
                        L24QF
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span>
                        BR
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span>
                        AR
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                    <th>
                      <span>
                        Margin
                        <a className="iconColor" href="#">
                          <BiSortAlt2 />
                        </a>
                      </span>
                    </th>
                  </tr>

                  {tableData.map((items, index) => (
                    <>
                      <tr
                        onClick={(e) => handleRow(`Row${index}`)}
                        id={`Row${index}`}
                        className="mainrow"
                      >
                        <td>{items.project_status.name}</td>
                        <td>SC118853US</td>
                        <td>Survey-US</td>
                        <td
                          className="content_ellipsis"
                          title={items.client.name}
                        >
                          {items.client.name}
                        </td>
                        <td
                          className="content_ellipsis"
                          title={items.sale_representive.name}
                        >
                          {items.sale_representive.name}
                        </td>
                        <td
                          className="content_ellipsis"
                          title={
                            items.project_manager.primary_project_manager.name
                          }
                        >
                          {items.project_manager.primary_project_manager.name}
                        </td>
                        <td>US</td>
                        <td>127/500</td>
                        <td>12/50</td>
                        <td>0.00</td>
                        <td>0</td>
                        <td>25</td>
                        <td>63</td>
                        <td>50</td>
                        <td>45</td>
                        <td>30%</td>
                      </tr>
                      <tr className={`inner-border Row${index}`}>
                        <td colspan="16" className="indent">
                          <SurveyInnerTable />
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>

            <Paginations
              userlist={20}
              getPageSize={10}
              handlePageChnage={handlePageChnage}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default SurveyListing;
