import Table from "react-bootstrap/Table";

const Projectdetailinline = () => {
  return (
    <>
      <Table
        bordered={false}
        hover
        size="sm"
        className="customTable customTable2"
      >
        <thead className="thead">
          <tr>
            <th>SC Close </th>
            <th>Variable</th>
            <th>Invalid IP</th>
            <th>Completes</th>
            <th>SC Abandons</th>
            <th>SC Quotafull</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{}}>
            <td>35</td>
            <td>44</td>
            <td>04</td>
            <td>66</td>
            <td>47</td>
            <td>78</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
export default Projectdetailinline;
