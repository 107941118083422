import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router";
import SettingHeader from "./SettingHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useState, useEffect } from "react";
import ButtonSpinner from "../../UI/ButtonSpinner";
import userIco from "../../../assets/LoginIcons/users.svg";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import "./ExclusionSetting.css";
import SpinnerLoader from "../../UI/Spinner";
const RedirectURL = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const pname = "Redirect URL";
  const [loder, setLoder] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [formData, setFormData] = useState({
    complete: "",
    Terms: "",
    Over_Quotas: "",
    security_terms: "",
  });
  useEffect(() => {
    get_ProjectType_api();
  }, []);

  const fetchDataWithToken = async () => {
    setLoder(false);

    const token = "YOUR_API_TOKEN"; // Replace 'YOUR_API_TOKEN' with your actual API token
    const url = `http://52.13.201.242:8003/api/v1/supplyinfo/get-supplier/${id}`; // Replace 'YOUR_API_URL' with the URL of the API you want to fetch

    try {
      const response = await axios.get(url, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "access-token":
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIyOTExMzgsIm5iZiI6MTY5MjI5MTEzOCwianRpIjoiZDgxOWRiYjgtYjBkYi00MjAwLTk3YjMtY2M4M2FhYzUzYTkzIiwiZXhwIjoxNjkyNzIzMTM4LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.ec0MG1JkCkJokFGtZ-1sMNrehSE1qCIAhywLxXRC1sg",
        },
      });
      setFormData((prevData) => ({
        ...prevData,
        complete: response.data.supplier.redirect_url.complete,
        Terms: response.data.supplier.redirect_url.terms,
        Over_Quotas: response.data.supplier.redirect_url.overquotas,
        security_terms: response.data.supplier.redirect_url.security_terms,
      }));

      // setCountrySelect(response.data.supplier.address.country.id);
      // setprescreening(response.data.supplier.screening.has_screening);
      // setQualiﬁcation(response.data.supplier.screening.has_qualification);
      // setScreen(response.data.supplier.screening.screen_captcha);
      // setHerring(response.data.supplier.screening.has_job_type);
      setLoder(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const get_ProjectType_api = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(`supplyinfo/get-supplier/${id}`);
      // setData(response.data.supplier);
      if (response.data.supplier.redirect_url !== null) {
        setFormData((prevData) => ({
          ...prevData,
          complete: response.data.supplier.redirect_url.complete,
          Terms: response.data.supplier.redirect_url.terms,
          Over_Quotas: response.data.supplier.redirect_url.overquotas,
          security_terms: response.data.supplier.redirect_url.security_terms,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          complete: "",
          Terms: "",
          Over_Quotas: "",
          security_terms: "",
        }));
      }

      // setCountrySelect(response.data.supplier.address.country.id);
      // setprescreening(response.data.supplier.screening.has_screening);
      // setQualiﬁcation(response.data.supplier.screening.has_qualification);
      // setScreen(response.data.supplier.screening.screen_captcha);
      // setHerring(response.data.supplier.screening.has_job_type);
      setLoder(true);
    } catch (err) {
      console.error(err);
    }
  };
  const handleSubmitForm = async (e) => {
    var postData = {
      complete: e.Complete,
      terms: e.Terms,
      overquotas: e.OverQuotas,
      security_terms: e.SecurityTerms,
    };
    const response = await fetch(
      `http://52.13.201.242:8003/api/v1/supplyinfo/supplier-redirect-url/${id}`,
      {
        method: "POST",
        body: JSON.stringify({
          complete: e.Complete,
          terms: e.Terms,
          overquotas: e.OverQuotas,
          security_terms: e.SecurityTerms,
        }),
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "access-token":
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIyOTExMzgsIm5iZiI6MTY5MjI5MTEzOCwianRpIjoiZDgxOWRiYjgtYjBkYi00MjAwLTk3YjMtY2M4M2FhYzUzYTkzIiwiZXhwIjoxNjkyNzIzMTM4LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.ec0MG1JkCkJokFGtZ-1sMNrehSE1qCIAhywLxXRC1sg",
        },
      }
    );
    const result = await response.json();
    setIsSubmitting(false);
  };

  const postData = async (e) => {
    setIsSubmitting(true);

    try {
      await axiosPrivate.post(
        `/supplyinfo/supplier-redirect-url/${id}`,
        JSON.stringify({
          complete: e.Complete,
          terms: e.Terms,
          overquotas: e.OverQuotas,
          security_terms: e.SecurityTerms,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (err) {}
    setIsSubmitting(false);
  };
  return (
    <>
      <SettingHeader pname={pname} />
      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(postData)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img
                    src={userIco}
                    className="headingIcons"
                    alt="Add Department"
                  />{" "}
                  Redirect Url
                </h3>
              </div>

              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={() => {
                    navigate("/supplier-details");
                  }}
                >
                  {" "}
                  Close
                </button>

                <button disabled={isSubmitting} className="cbtn saveBtn">
                  {isSubmitting && <ButtonSpinner />} &nbsp; Save
                </button>
              </div>
            </div>
            <hr />
            {loder ? (
              <>
                <div
                  id="dividerow"
                  style={{ marginTop: "10px" }}
                  className="mainContent"
                >
                  <Row>
                    <Col lg={2} md={2} xs={12} className="col">
                      <label className="formlabel">Complete*</label>
                    </Col>
                    <Col lg={10} md={10} xs={12} className="col">
                      <input
                        {...register("Complete", {
                          required: "This field is Required",
                        })}
                        type="text"
                        placeholder="Complete*"
                        className="CsformText"
                        defaultValue={formData.complete}
                      />
                      {errors.Complete && (
                        <small className="errmsg perrmsg">
                          {errors.Complete.message}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2} md={2} xs={12} className="col">
                      <label className="formlabel">Terms*</label>
                    </Col>
                    <Col lg={10} md={10} xs={12} className="col">
                      <input
                        {...register("Terms", {
                          required: "This field is Required",
                        })}
                        type="text"
                        placeholder="Terms*"
                        className="CsformText"
                        defaultValue={formData.Terms}
                      />
                      {errors.Terms && (
                        <small className="errmsg perrmsg">
                          {errors.Terms.message}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2} md={2} xs={12} className="col">
                      <label className="formlabel">Over Quotas*</label>
                    </Col>
                    <Col lg={10} md={10} xs={12} className="col">
                      <input
                        {...register("OverQuotas", {
                          required: "This field is Required",
                        })}
                        type="text"
                        placeholder="Over Quotas*"
                        className="CsformText"
                        defaultValue={formData.Over_Quotas}
                      />
                      {errors.OverQuotas && (
                        <small className="errmsg perrmsg">
                          {errors.OverQuotas.message}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2} md={2} xs={12} className="col">
                      <label className="formlabel">Security Terms*</label>
                    </Col>
                    <Col lg={10} md={10} xs={12} className="col">
                      <input
                        {...register("SecurityTerms", {
                          required: "This field is Required",
                        })}
                        type="text"
                        placeholder="Security Terms*"
                        className="CsformText"
                        defaultValue={formData.security_terms}
                      />
                      {errors.SecurityTerms && (
                        <small className="errmsg perrmsg">
                          {errors.SecurityTerms.message}
                        </small>
                      )}
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default RedirectURL;
