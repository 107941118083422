import "../ProjectDetail/ProjectDetail.css";
import Table from "react-bootstrap/Table";
import { TableCell, TableRow, TableBody } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import SurveyTracking from "./Quotapopup/SurveyTracking";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Droptable from "./QuotaSub";
import { useState, useEffect } from "react";
import surveyTrackingIcon from "../../../assets/CSicons/icons/survey tracking.svg";
import axios from "axios";

const Quota = (props) => {
  const [setting, setSetting] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [quotaData, setQuotaData] = useState([]);
  const dropDownQuotaTable = (id) => {
    var element = document.getElementById("firstSect" + id);
    let iconShow = document.getElementById("iconSect" + id);
    if (element.matches(".open")) {
      element.classList.remove("open");
      iconShow.classList.remove("open");
    } else {
      element.classList.add("open");
      iconShow.classList.add("open");
    }
  };

  useEffect(() => {
    get_SurveyQuota_api();
  }, []);

  const get_SurveyQuota_api = async () => {
    const url =
      "http://52.13.201.242:8003/api/v1/survey/get-survey-quota/64933e2ff7a0edd51024933e"; // Replace 'YOUR_API_URL' with the URL of the API you want to fetch
    try {
      const response = await axios.get(url, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "access-token":
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTI3OTA1ODAsIm5iZiI6MTY5Mjc5MDU4MCwianRpIjoiZDI1NTlmZmUtMDQ5Ny00MGE3LTk1OTEtMGUxZDkwMjQ3MzM5IiwiZXhwIjoxNjkzMjIyNTgwLCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.LecajTZrJlQCcKUSpJZvLZuEtpmKCWVrzVGjQ-Q2Q04",
        },
      });
      setQuotaData(response.data.survey);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <SurveyTracking
        show={setting}
        setSetting={setSetting}
        onHide={() => setSetting(false)}
      />
      <hr className="csHR" />
      <div className="Sfirstbox">
        <Table className="Quotatable">
          <thead className="quotaHead">
            <TableRow>
              <TableCell>Quota Deﬁnition</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Achieved</TableCell>
              <TableCell>Quota</TableCell>
              <TableCell>Margin</TableCell>
              <TableCell>Survey Tracking</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </thead>
          <tbody>
            {quotaData.map((e, index) => (
              <>
                {" "}
                <tr
                  className="QArrowHeead"
                  id={`iconSect${index}`}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dropDownQuotaTable(index);
                  }}
                >
                  <td>US Gen Pop 18+</td>
                  <td>
                    <button className="quotaBtn">Live</button>
                  </td>
                  <td>{e.achieved}</td>
                  <td>{e.n}</td>
                  <td>{e.margin_threshhold}%</td>
                  <td>
                    <img
                      src={surveyTrackingIcon}
                      alt="surveyTrackingIcon"
                      srcset=""
                      onClick={(e) => {
                        setSetting(true);
                        e.stopPropagation();
                      }}
                      style={{
                        width: "15px",
                      }}
                    />
                  </td>
                  <td>
                    <KeyboardArrowUpIcon className="IconUp" />
                    <KeyboardArrowDownIcon className="DropIcon" />
                  </td>
                </tr>
                <tr className="dropeTable" id={`firstSect${index}`}>
                  <td colSpan={6}>
                    <Droptable />
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Quota;
