import axios from "axios";
const BASE_URL = "https://api-sc3dev.torfac.com/api/v1";
const token = "Bearer " + localStorage.getItem("token");

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

// export const axiosRefresh = axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     accept: "application/json",
//     "Content-Type": "application/json",
//     "access-token": token,
//   },
//   withCredentials: true,
// });

export { BASE_URL };
