import Header from "../../UI/Header/Header";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import Col from "react-bootstrap/Col";
import pageIcon from "../../../assets/BidsIcons/financeDetails.svg";
import addIcon from "../../../assets/BidsIcons/add.svg";
import BidsSubHeader from "../../BidsModule/BidsSubHeader/BidsSubHeader";
import Bidstopheader from "./Bidstopheader";
import SpinnerLoader from "../../UI/Spinner";
import EditIcon from "../../../assets/LoginIcons/edit.svg";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../AdminModule/Tables/StyleDatatable.css";
import DeleteIcon from "../../../assets/LoginIcons/delete.svg";
import toast from "react-hot-toast";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Bidstopheaderselect from "../BidsTable/BidsTopHeaderSelect";
import useAuth from "../../../hooks/useAuth";
const FinanceTable = () => {
  const { id } = useParams();
  const [financeData, setFinanceData] = useState([]);
  const [pending, setPending] = useState(true);

  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [filterUser, setFilterUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const buttonName = "Add Finance Detail";
  const name = "D";
  var localData = localStorage.getItem("clientname");
  const { auth } = useAuth();

  // ..........................permisssion.........
  const superUser = auth.superuser;
  const all_permission = auth.permission;

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosPrivate.get(
          `/client/client-finance-list/${id}`,
          {}
        );

        let data = response.data.all_client_finance;

        setFinanceData(data);
        setPending(false);
      } catch (err) {}
    };
    getData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async (cid) => {
    const payload = {
      client_id: id,
      client_finance_id: cid,
    };
    try {
      const response = await axiosPrivate
        .delete(
          "client/delete-client-finance",
          { data: payload },
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {});
    } catch (err) {}
    const del = financeData.filter((User) => cid !== User.id);

    setFinanceData(del);
    userDeleted();
  };
  const userDeleted = () =>
    toast.success("Finance detail deleted successfully");

  const columns = [
    {
      name: "Sno.",
      selector: (row, i) => currentRowsPerPage * (currentPage - 1) + (i + 1),
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => row.company_name,
      sortable: true,
    },
    {
      name: "Billing Phone No.",
      selector: (row) => row.billing_phone_number,
      sortable: true,
    },
    {
      name: "Billing Email",
      selector: (row) => row.billing_email,
      sortable: true,
    },
    {
      name: "Client Code",
      selector: (row) => row.client_code,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <td>
          {superUser === true ? (
            <>
              <span className="actionButton">
                <img
                  src={EditIcon}
                  onClick={() => {
                    navigate(`/update-finace/${id}/${row.id}`);
                  }}
                  alt=""
                />
              </span>
            </>
          ) : (
            <>
              {all_permission.includes("client.client_update_finance") ||
              all_permission.includes("client.client_get_finance_list") ? (
                <>
                  {" "}
                  <span className="actionButton">
                    <img
                      src={EditIcon}
                      onClick={() => {
                        navigate(`/update-finace/${id}/${row.id}`);
                      }}
                      alt=""
                    />
                  </span>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {superUser === true ? (
            <>
              <span className="actionButton">
                <img
                  src={DeleteIcon}
                  onClick={() => {
                    confirmAlert({
                      message: "Do you want to delete " + row.company_name,
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => handleDelete(row.id),
                        },
                        {
                          label: "No",
                        },
                      ],
                    });
                  }}
                  alt=""
                />
              </span>
            </>
          ) : (
            <>
              {all_permission.includes("client.client_delete_finance") ? (
                <>
                  {" "}
                  <span className="actionButton">
                    <img
                      src={DeleteIcon}
                      onClick={() => {
                        confirmAlert({
                          message: "Do you want to delete " + row.company_name,
                          buttons: [
                            {
                              label: "Yes",
                              onClick: () => handleDelete(row.id),
                            },
                            {
                              label: "No",
                            },
                          ],
                        });
                      }}
                      alt=""
                    />
                  </span>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </td>
      ),
    },
  ];

  const showSearch = (searchText) => {
    const copyOfUsers = [...financeData];

    setSearch(searchText);
    const result = copyOfUsers.filter((points) => {
      return (
        points.company_name
          .toString()
          .toLowerCase()

          .match(searchText.toString().toLowerCase()) ||
        points.billing_phone_number
          .toString()
          .toLowerCase()

          .match(searchText.toString().toLowerCase()) ||
        points.client_code.toString().match(searchText.toString()) ||
        points.billing_email
          .toString()
          .toLowerCase()
          .match(searchText.toString().toLowerCase())
      );
    });
    setFilterUser(result);
  };

  return (
    <>
      {/* <Header /> */}
      <BidsSubHeader pname="clientlist" />
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachbox">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" />{" "}
                {localData}
              </h3>
            </div>
            <input
              type="search"
              className="tableSearch"
              placeholder="Search..."
              value={search}
              onChange={(e) => {
                showSearch(e.target.value);
              }}
            />
            {superUser === true ? (
              <>
                <button //style={{marginTop:'5px'}}
                  className="cbtn addBtn"
                  onClick={() => {
                    navigate(`/add-finance-details/${id}`);
                  }}
                >
                  <img src={addIcon} alt="" /> &nbsp; Add Finance Details
                </button>
              </>
            ) : (
              <>
                {all_permission.includes("client.client_add_finance") ? (
                  <>
                    <button
                      className="cbtn addBtn"
                      onClick={() => {
                        navigate(`/add-finance-details/${id}`);
                      }}
                    >
                      <img src={addIcon} alt="" /> &nbsp; Add Finance Details
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <hr />
          {/* <Bidstopheader
            name={name}
            buttonName={buttonName}
            clientid={id}
            tid="2"
          /> */}
          <Bidstopheader clientid={id} tid="2" className="showingHeader" />
          <Col>
            <Bidstopheaderselect
              clientid={id}
              namevalue={"finance"}
              className="col"
            />
          </Col>

          <div className="mainContent dTable bids">
            <DataTable
              columns={columns}
              data={filterUser || financeData}
              progressPending={pending}
              pagination
              onChangePage={(page, totalRows) => {
                setCurrentPage(page);
              }}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setCurrentRowsPerPage(currentRowsPerPage);
              }}
              fixedHeader
              highlightOnHover
              defaultSortFieldId="Profile"
              defaultSortAsc={true}
              progressComponent={<SpinnerLoader />}
              noDataComponent="No Data Found"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceTable;
