import "./ProjectDetail/ProjectDetail.css";
import "bootstrap/dist/css/bootstrap.css";
import Filter from "./CommonFiles/Filter/Filter";
import ProjectDetail from "./ProjectDetail/ProjectDetail";
import Screening from "./Screening/Screening";
import Quota from "./Quota/Quota";
import SupplyInfo from "./SupplyInfo/PopUp/Supplyinfo";
import Invoicing from "./Invoice/Invoicing";
import Qualityproject from "./Quality_project/Qualityproject";
import { useState } from "react";
import searchIcon from "../../assets/images/icons8-search-60.png";
import Supliercostinfo from "./Invoice/Suppliercostinfo";

const CommonLayout = () => {
  const [selectedHeader, setSelectedHeader] = useState();
  const [headerId, setHeaderId] = useState();
  const [showScreening, setShowScreening] = useState(false);

  const setShowSubHeader = (idNo) => {
    setHeaderId(idNo);
  };

  const hideFilter = () => {
    let element = document.getElementById("hideFilter");
    if (element.matches(".open")) {
      element.classList.remove("open");
    } else {
      element.classList.add("open");
    }
  };

  return (
    <>
      <div className="Ptop_container">
        <div className="PtopHeaderHide">
          <img
            src={searchIcon}
            className="csSearchIcon"
            onClick={() => {
              hideFilter();
            }}
          />
          <p>Project 3</p>
          <button className="">Live</button>
        </div>
        <div className="PleftContainer" id="hideFilter">
          <Filter />
        </div>
        <div className="PrightContainer">
          <div className="PtopHeader">
            <p>Project 3</p>
            <button className="">Live</button>
          </div>

          <div className="showHeader">
            <select
              value={headerId}
              className="threeLine SelectHeader"
              id="MainMenuCs"
              onChange={(e) => {
                setHeaderId(parseInt(e.target.value));
              }}
            >
              <option value={1}>Project Details</option>
              {headerId !== 1 ? <option value={2}>Screening</option> : <></>}
              <option value={3}>Quota</option>
              <option value={4}>Supply Info</option>
              <option value={5}>Invoicing</option>
              <option value={6}> Quality</option>
              <option value={7}> Supplier Cost Info</option>
            </select>
            <div className="PrightHeader">
              <p
                className={headerId === 1 ? "activeBorder" : ""}
                onClick={() => {
                  setHeaderId(1);
                }}
              >
                {" "}
                Project Details
              </p>
              {headerId !== 1 && showScreening === true ? (
                <p
                  className={headerId === 2 ? "activeBorder" : ""}
                  onClick={() => {
                    setHeaderId(2);
                  }}
                >
                  {" "}
                  Screening
                </p>
              ) : (
                <></>
              )}

              <p
                className={headerId === 3 ? "activeBorder" : ""}
                onClick={() => {
                  setHeaderId(3);
                  setShowScreening(false);
                }}
              >
                {" "}
                Quota
              </p>
              <p
                className={headerId === 4 ? "activeBorder" : ""}
                onClick={() => {
                  setHeaderId(4);
                  setShowScreening(false);
                }}
              >
                {" "}
                Supply Info
              </p>
              <p
                className={headerId === 5 ? "activeBorder" : ""}
                onClick={() => {
                  setHeaderId(5);
                  setShowScreening(false);
                }}
              >
                {" "}
                Invoicing
              </p>
              <p
                className={headerId === 6 ? "activeBorder" : ""}
                onClick={() => {
                  setHeaderId(6);
                  setShowScreening(false);
                }}
              >
                {" "}
                Quality
              </p>
              <p
                className={headerId === 7 ? "activeBorder" : ""}
                onClick={() => {
                  setHeaderId(7);
                  setShowScreening(false);
                }}
              >
                {" "}
                Supplier Cost Info
              </p>
            </div>
          </div>

          <div>
            {headerId === 5 ? (
              <Invoicing />
            ) : showScreening === true && headerId === 2 ? (
              <Screening />
            ) : headerId === 3 ? (
              <Quota />
            ) : headerId === 4 ? (
              <SupplyInfo />
            ) : headerId === 6 ? (
              <Qualityproject />
            ) : headerId === 7 ? (
              <Supliercostinfo />
            ) : (
              <ProjectDetail
                setShowSubHeader={setShowSubHeader}
                setSelectedHeader={setSelectedHeader}
                setShowScreening={setShowScreening}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CommonLayout;
