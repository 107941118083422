import Header from "../../../UI/Header/Header";
import { useNavigate, useParams } from "react-router";
import userIco from "../../../../assets/BidsIcons/client.svg";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BidsSubHeader from "../../../BidsModule/BidsSubHeader/BidsSubHeader";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import toast from "react-hot-toast";
import Bidstopheader from ".././Bidstopheader";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SpinnerLoader from "../../../UI/Spinner";
import Bidstopheaderselect from "../../BidsTable/BidsTopHeaderSelect";
const UpdateRegion = () => {
  let { id } = useParams();
  let { regionid } = useParams();
  const navigate = useNavigate();
  var localData = localStorage.getItem("clientname");
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [regionName, setRegionName] = useState("");
  const [regionStatus, setRegionStatus] = useState("");
  const [loder, setLoder] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getRegion = async () => {
    try {
      const response = await axiosPrivate.get(
        `client/get-client-region/${id}/${regionid}`,
        {}
      );
      setRegionName(response.data.client_region.name);
      setRegionStatus(response.data.client_region.status);

      setLoder(true);
    } catch (err) {
      console.error(err);
    }
    return () => {};
  };
  const handleSaveButton = async (e) => {
    setIsSubmitting(true);
    try {
      await axiosPrivate
        .patch(
          "client/update-client-region",
          JSON.stringify({
            client_id: id,
            name: e.Name,
            status: regionStatus,
            client_region_id: regionid,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          if (e.statusText === "OK") {
            clientadded();
            navigate(-1);
          } else {
          }
        });
    } catch (err) {
      notifyerror(err.response.statusText);
    }
    setIsSubmitting(false);
  };

  const notifyerror = (e) => toast.error(`${e} Error Occure`);
  const clientadded = () => toast.success("Region updated successfully");

  return (
    <>
      {/* <Header /> */}

      <BidsSubHeader pname="region" />
      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(handleSaveButton)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img src={userIco} className="headingIcons" alt="" />{" "}
                  {localData}
                </h3>
              </div>
              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={() => {
                    navigate(`/region/${id}`);
                  }}
                >
                  {" "}
                  Close{" "}
                </button>

                <button className="cbtn saveBtn">
                  {" "}
                  {isSubmitting && (
                    <Spinner
                      as="span"
                      style={{ color: "white" }}
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp;Save
                </button>
              </div>
            </div>
            <hr className="showingHeader" />

            {/* <Bidstopheader tid="5" clientid={id} /> */}
            <Bidstopheader clientid={id} tid="5" className="showingHeader" />
            <Bidstopheaderselect clientid={id} namevalue={"region"} />
            <div className="mainContent">
              {loder ? (
                <>
                  {" "}
                  <Row>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Region*</label>
                      <input
                        type="text"
                        placeholder="Region Name"
                        className="formtext"
                        {...register("Name", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ " " ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                        defaultValue={regionName}
                      />
                      {errors.Name && (
                        <small className="errmsg">{errors.Name.message}</small>
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                <SpinnerLoader />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateRegion;
