import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import userIco from "../../assets/LoginIcons/users.svg";
import { useNavigate } from "react-router";
import "bootstrap/dist/css/bootstrap.css";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useState, useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import ResetSubHeader from "./ResetSubHeader/ResetSubHeader";
import JoditEditor from "jodit-react";
import SpinnerLoader from "../UI/Spinner";
const ChangeSignature = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [signatureData, setSignatureData] = useState();
  const [editorData, setEditorData] = useState();
  const [loder, setLoder] = useState(false);
  const editor = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    getSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeSignature = () => toast.success("Signature changed succesfully");
  const notifyerror = (e) => toast.error(`${e} Error Occure`);

  const getSignature = async () => {
    try {
      const response = await axiosPrivate.get(
        "/mailbox/get-email-signature",
        {}
      );

      setSignatureData(response.data.email_signature);
      setLoder(true);
    } catch (err) {
      console.error(err);
    }
    return () => {};
  };

  const handleSaveButton = async (e) => {
    if (signatureData == "<p><br></p>" || signatureData == "") {
    } else {
      setIsSubmitting(true);
      try {
        setIsSubmitting(true);
        await axiosPrivate
          .patch(
            "mailbox/update-email-signature",
            JSON.stringify({
              email_signature: signatureData,
            }),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((e) => {
            if (e.statusText === "OK") {
              changeSignature();
            } else {
            }
          });
      } catch (err) {
        notifyerror(err);
      }
      setIsSubmitting(false);
    }
  };

  const config = {
    autofocus: true,
    cursorAfterAutofocus: "start", // 'end';
    saveSelectionOnBlur: true,
  };
  const BodyT = register("bodyT", {
    required: "This field is Required",
  });

  return (
    <>
      <ResetSubHeader pname="changesignature" />
      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(handleSaveButton)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img src={userIco} className="headingIcons" alt="Add" />{" "}
                  Change Signature
                </h3>
              </div>
              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                >
                  {" "}
                  Close
                </button>

                <button disabled={isSubmitting} className="cbtn saveBtn">
                  {isSubmitting && (
                    <Spinner
                      as="span"
                      style={{ color: "white" }}
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp; Save
                </button>
              </div>
            </div>
            <hr />
            <div className="mainContent">
              <p className="small note">* denotes required fields</p>
              {loder ? (
                <>
                  <Row>
                    <label className="formlabel">Signature*</label>
                    <JoditEditor
                      // ref={editor}
                      // config={config}
                      // value={signatureData}
                      // onChange={(newContent) => {
                      //   setEditorData(newContent);
                      // }}

                      ref={editor}
                      config={config}
                      value={signatureData}
                      onChange={(newContent) => {
                        setValue("bodyT", newContent);
                        setSignatureData(newContent);
                      }}
                      autoFocus={true}
                    />
                    {signatureData == "<p><br></p>" || signatureData == "" ? (
                      <small
                        className="errmsg"
                        style={{ width: "98%", margin: "10px" }}
                      >
                        This field is Required
                      </small>
                    ) : (
                      <></>
                    )}
                  </Row>
                </>
              ) : (
                <>
                  <SpinnerLoader />
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default ChangeSignature;
