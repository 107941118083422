import "./Screening.css";
import { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MultiSelect } from "react-multi-select-component";
import QuestionPopup from "./QuestionPopup";
import TargetQuestionPopup from "./QuestionModule/TargetQuestionPopup";
import Select from "react-select";
import Customtarget from "./Customtarget";
import QuotaTarget from "./QuotaTarget";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Buildurl from "./Buildidsurl";
import addIcon from "../../../assets/LoginIcons/add.svg";
import { parse } from "papaparse";
import toast from "react-hot-toast";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";

const Screening = () => {
  const [devicetype, setDeviceTYpe] = useState([]);
  const [targetQuestionPopup, setTargetQuestionPopup] = useState(false);
  const [questionPopup, setQuestionPopup] = useState(false);
  const [clientType, setClientType] = useState("https://drive.google.com");
  const [clientLIve, setClientLive] = useState("https://drive.google.com");
  const [buildurl, setBuildUrl] = useState();
  const axiosPrivate = useAxiosPrivate();
  const [show, setShow] = useState(false);
  const [modelData, setModelData] = useState([]);
  const [totalDataCsv, setTotalDataCsv] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [container, setContainer] = useState({
    project_C: "show",
    project_I: "up",
    usGen_C: "show",
    usGen_I: "up",
    target_C: "show",
    target_I: "up",
    custom_C: "show",
    custom_I: "up",
    pre_C: "show",
    pre_I: "up",
  });
  const [fileName, setFileNmae] = useState("");

  const handleCustomBoxFeature = (Boxid) => {
    setQuestionPopup(true);
  };

  const RecallContainer = (e) => {
    if (e === "Project Setting") {
      setContainer((event) => ({
        ...event,
        project_C: "hide",
        project_I: "down",
      }));
    }
    if (e === "US Gen Pop 18+") {
      setContainer((event) => ({ ...event, usGen_C: "hide", usGen_I: "down" }));
    }
    if (e === "Target Details") {
      setContainer((event) => ({
        ...event,
        target_C: "hide",
        target_I: "down",
      }));
    }
    if (e === "Custom Targeting") {
      setContainer((event) => ({
        ...event,
        custom_C: "hide",
        custom_I: "down",
      }));
    }
    if (e === "Pre Screening") {
      setContainer((event) => ({ ...event, pre_C: "hide", pre_I: "down" }));
    }
  };

  const [prescreenBox, setprescreenBox] = useState([
    { id: 201, statusid: "active" },
    { id: 202, statusid: "inactive" },
    { id: 203, statusid: "inactive" },
    { id: 204, statusid: "inactive" },
  ]);

  const [questionSet, setQuestionSet] = useState([]);

  const [extraBox, setExtraBox] = useState("true");

  useEffect(() => {
    var a = Math.random();
    if (prescreenBox.length < questionSet.length) {
      setprescreenBox((val) => [...val, { id: a, statusid: a }]);
    }

    if (questionSet.length >= 4 && extraBox === "true") {
      setprescreenBox((val) => [...val, { id: a, statusid: "activebox" }]);
    }
  }, [questionSet]);

  const [OptionTargetSet, setOptionTargetSet] = useState([]);

  const [TargetBox, setTargetBox] = useState([
    { id: 1001, status: "active" },
    { id: 1002, status: "inactive" },
    { id: 1003, status: "inactive" },
    { id: 1004, status: "inactive" },
  ]);

  const [allOptions, setAllOptions] = useState([]);
  const [extraBox2, setExtraBox2] = useState("true");
  const RecevingOptions = (e) => {
    e.map((data) => {
      var a = Math.random();
      setOptionTargetSet((val, index) => [...val, { id: a, name: data.value }]);
    });
    setAllOptions(e);
  };

  useEffect(() => {
    var a = Math.random();
    if (TargetBox.length < OptionTargetSet.length) {
      setTargetBox((val, index) => [...val, { id: a, name: "data" }]);
    }

    if (OptionTargetSet.length >= 4 && extraBox2 === "true") {
      setTargetBox((val) => [...val, { id: a, name: "data" }]);
    }
  }, [allOptions]);

  // ..............................................api...................

  useEffect(() => {
    get_DeviceType_api();
    get_SurveyType_api();
  }, []);

  const [deviceTypeStorage, setDeviceTypestorage] = useState([]);
  const [surveyTypeStorage, setsurveyTypestorage] = useState([]);
  const [surveyStorage, setSurveyStorage] = useState("");

  const get_DeviceType_api = async () => {
    const response = await axiosPrivate.get("lookup/get-device-target ");
    response.data.all_status.map((e) => {
      setDeviceTypestorage((val) => [...val, { label: e.name, value: e.id }]);
    });
  };

  const get_SurveyType_api = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get-survey-type ");
      response.data.all_status.map((e) => {
        setsurveyTypestorage((val) => [...val, { label: e.name, value: e.id }]);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleSizeTest = () => {
    var element = document.getElementById("ClientTestLink");
    var eyeclose = document.getElementById("eyeclose");
    var eyeopen = document.getElementById("eyeopen");

    if (element.matches(".open")) {
      element.classList.remove("open");
      eyeclose.classList.add("open");
      eyeopen.classList.remove("open");
    } else {
      element.classList.add("open");
      eyeclose.classList.remove("open");
      eyeopen.classList.add("open");
    }
  };
  const handleSizelive = () => {
    var element = document.getElementById("ClientLiveLink");
    var eyeclose = document.getElementById("eyeclosel");
    var eyeopen = document.getElementById("eyeopenl");

    if (element.matches(".open")) {
      element.classList.remove("open");
      eyeclose.classList.add("open");
      eyeopen.classList.remove("open");
    } else {
      element.classList.add("open");
      eyeclose.classList.remove("open");
      eyeopen.classList.add("open");
    }
  };
  const [test, setTest] = useState([]);
  const [message, setMessage] = useState([]);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const progressInfosRef = useRef(null);
  const [showpage, setShowpage] = useState("Mark error");
  const [randomNum, setRandomNumber] = useState();
  const fileupload = async (file, onUploadProgress) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("uid", `${randomNum}`);
    setTest((value) => [...value, file]);
    await axiosPrivate.post("/mailbox", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  };
  useEffect(() => {
    let x = Math.floor(Math.random() * 100000000000 + 1);
    setRandomNumber(x);
  }, []);

  const upload = async (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    return fileupload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
    })
      .then(() => {
        setMessage((prevMessage) => [...prevMessage, "success"]);
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        setProgressInfos({ val: _progressInfos });
        setMessage((prevMessage) => [...prevMessage, "error"]);
      });
  };

  const uploadFiles = async (event) => {
    const file = event.target.files[0];
    parse(file, {
      complete: (results) => {
        csvfileDataLoading(results);
      },
      header: true,
      dynamicTyping: true,
    });
    const files = Array.from(event.target.files);

    let merged = files.concat(test);
    merged = merged.filter((item, index) => {
      return merged.indexOf(item) === index;
    });

    let _progressInfos = merged.map((file) => ({
      percentage: 100,
      fileName: file.name,
    }));

    progressInfosRef.current = {
      val: _progressInfos,
    };

    const uploadPromises = merged.map((file, i) => upload(i, file));
    setMessage([]);
  };
  const deleteHandler = async (i, e) => {
    const del = progressInfos.val.filter((User, index) => i !== index);
    const el = progressInfos.val.filter((User, index) => i === index);

    setProgressInfos({ val: del });
    const bar = test.filter((User, index) => i !== index);
    setTest(bar);
  };
  const csvfileDataLoading = (e) => {
    const fileName = e.target.files[0].name;
    setFileNmae(fileName);

    const file = e.target.files[0];
    parse(file, {
      complete: (results) => {
        csvErrorHnadling(results);
      },
      header: true,
      dynamicTyping: true,
    });
  };
  const csvErrorHnadling = (data) => {
    var keys = Object.keys(data.data[0]);
    var dataSHow = data.data.slice(0, 5);
    setTotalDataCsv(data.data.length);
    setModelData(dataSHow);

    if (surveyStorage == 2) {
      if (keys[0] != "unique_links") {
        notifyerror1("unique_links");
        setFileNmae();
      } else {
        handleShow();
      }
    }
    if (surveyStorage == 3 || surveyStorage == 4) {
      var arrayMap = [
        { samplicity_Id: "false", vendor_id: "false", links: "false" },
      ];

      var csvDataShow = 0;

      keys.map((e) => {
        if (e == "samplicity_Id") {
          arrayMap[0].samplicity_Id = "true";
        }
        if (e == "vendor_id") {
          arrayMap[0].vendor_id = "true";
        }
        if (e == "links") {
          arrayMap[0].links = "true";
        }
      });

      for (keys in arrayMap[0]) {
        if (arrayMap[0][keys] == "true") {
          csvDataShow = csvDataShow + 1;
        } else {
          notifyerror1(keys);
        }
      }
      if (csvDataShow == 3) {
        handleShow();
      }
    }
  };

  const row = [{ lastN: "Snow", firstName: "Jon", age: 35 }];
  const columns = [
    {
      id: "unique_links",
      name: "unique_links",
      selector: (row) => row.unique_links,
      sortable: true,
    },
  ];
  const Recontactcolumns = [
    {
      id: "samplicity_Id",
      name: "samplicity_Id",
      selector: (row) => row.samplicity_Id,
      sortable: true,
    },
    {
      id: "vendor_id",
      name: "vendor_id",
      selector: (row) => row.vendor_id,
      sortable: true,
    },
    {
      id: "links",
      name: "links",
      selector: (row) => row.links,
      sortable: true,
    },
  ];
  const notifyerror1 = (e) => toast.error(`${e} Keys not same`);

  return (
    <>
      <Buildurl
        show={buildurl}
        onHide={() => setBuildUrl(false)}
        setClientType={setClientType}
        setClientLive={setClientLive}
        clientLIve={clientLIve}
        setBuildUrl={setBuildUrl}
      />
      <TargetQuestionPopup
        popup={setTargetQuestionPopup}
        show={targetQuestionPopup}
        onHide={() => setTargetQuestionPopup(false)}
      />
      <QuestionPopup
        RecevingOptions={RecevingOptions}
        questionCount={questionSet}
        show={questionPopup}
        onHide={() => setQuestionPopup(false)}
      />
      <div className="saveclsebtn supply_btn">
        <button className="cbtn closeBtn pbtn">Close</button>
      </div>
      <>
        {/* <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button> */}

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="mainContent dTable">
              {surveyStorage == 2 ? (
                <>
                  <DataTable columns={columns} data={modelData} fixedHeader />
                </>
              ) : (
                <>
                  {" "}
                  <DataTable
                    columns={Recontactcolumns}
                    data={modelData}
                    fixedHeader
                  />
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <>
              <h7>Total Data</h7> = {totalDataCsv}
            </>
          </Modal.Footer>
        </Modal>
      </>

      <hr className="csHR" />
      <div className=" wrapperBelow">
        <div className="RightContainer csmulti">
          <div className="RightChild">
            <div className="ProjectInput"></div>

            <div className="ProjectSetting pjt_arrow_icon">
              <p>Project Setting</p>
              <span className="projectarrow">
                {container.project_I === "up" ? (
                  <KeyboardArrowUpIcon
                    onClick={() => {
                      RecallContainer("Project Setting");
                    }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    onClick={() => {
                      setContainer((e) => ({
                        ...e,
                        project_I: "up",
                        project_C: "show",
                      }));
                    }}
                  />
                )}
              </span>
            </div>

            {container.project_C === "show" ? (
              <>
                <div className="ptoogle">
                  <Row>
                    <Col lg={3} md={3} sm={6} xs={6} className="col-3">
                      <div className="redhearing">
                        <lable className="redcss">Red Herring Screen</lable>
                        <div className="tooglecenter pswtchbtn">
                          <BootstrapSwitchButton
                            className="switch btn on btn-outline-success btn-xs"
                            size="xs"
                            width={60}
                            onlabel="ON "
                            offlabel="OFF"
                            onstyle="outline-success"
                            offstyle="outline-danger"
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg={3} md={3} sm={6} xs={6} className="col-3">
                      <div className="screen">
                        <lable className="redcss"> Screen Captcha</lable>
                        <div className="tooglecenter pswtchbtn">
                          <BootstrapSwitchButton
                            className="switch btn on btn-outline-success btn-xs"
                            width={60}
                            size="xs"
                            onlabel="ON "
                            offlabel="OFF"
                            onstyle="outline-success"
                            offstyle="outline-danger"
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg={3} md={3} sm={6} xs={6} className="col-3">
                      <div className="reserch">
                        <lable className="redcss">Research Defender</lable>
                        <div className="tooglecenter pswtchbtn">
                          <BootstrapSwitchButton
                            className="switch btn on btn-outline-success btn-xs"
                            width={60}
                            size="xs"
                            onlabel="ON "
                            offlabel="OFF"
                            onstyle="outline-success"
                            offstyle="outline-danger"
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg={3} md={3} sm={6} xs={6} className="col-3">
                      <div className="soft">
                        <lable className="redcss">Soft Launch</lable>
                        <div className="tooglecenter pswtchbtn">
                          {" "}
                          <BootstrapSwitchButton
                            className="switch btn on btn-outline-success btn-xs "
                            width={60}
                            size="xs"
                            onlabel="ON "
                            offlabel="OFF"
                            onstyle="outline-success"
                            offstyle="outline-danger"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="">
              <div className="ProjectSetting pjt_arrow_icon">
                <p>US Gen Pop 18+</p>
                <div className="rankPainA">
                  <button style={{ marginRight: "-3px" }} className="PSaveBtnA">
                    Save
                  </button>
                  &nbsp; &nbsp;
                  <button className="liveBtnA">Live</button>
                </div>

                {container.usGen_I === "up" ? (
                  <>
                    <KeyboardArrowUpIcon
                      className="arrowup"
                      onClick={() => {
                        RecallContainer("US Gen Pop 18+");
                      }}
                    />
                  </>
                ) : (
                  <>
                    <KeyboardArrowDownIcon
                      className="arrowup"
                      onClick={() => {
                        setContainer((e) => ({
                          ...e,
                          usGen_C: "show",
                          usGen_I: "up",
                        }));
                      }}
                    />
                  </>
                )}
              </div>
              {container.usGen_C === "show" ? (
                <>
                  <div class="container-fluid">
                    <Row>
                      <Col lg={3} md={6} sm={6} xs={12} className="col-3">
                        <label className="formlabelamit">Survey Type*</label>
                        <Select
                          placeholder="Select..."
                          className="pjt_select formtext"
                          classNamePrefix="select"
                          options={surveyTypeStorage}
                          onChange={(e) => {
                            setSurveyStorage(e.value);
                          }}
                          noOptionsMessage={() => "No Data Found"}
                        />
                      </Col>
                      <Col lg={3} md={6} sm={6} xs={12} className="col-3">
                        {" "}
                        <label className="formlabelamit">Device Target*</label>
                        <MultiSelect
                          className="multiselectoption "
                          options={deviceTypeStorage}
                          value={devicetype}
                          onChange={(e) => {
                            setDeviceTYpe(e);
                          }}
                          labelledBy={"Select"}
                          isCreatable={true}
                          disableSearch
                        />
                      </Col>
                      <Col lg={3} md={6} sm={6} xs={12} className="col-3">
                        <label className="formlabelamit">Soft Launch</label>

                        <input
                          className="formtextamit"
                          type="text"
                          placeholder="Soft Launch"
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Col lg={6} md={12} sm={12} xs={12} className="col">
                        <label className="formlabelamit clientlabel">
                          Client Test Link
                        </label>

                        <textarea
                          style={{
                            position: "relative",
                            overflow: "hidden",
                          }}
                          id="ClientTestLink"
                          className="formtextamit"
                          placeholder="Client Test Link"
                          type="text"
                          value={clientType}
                          onChange={(e) => {
                            setClientType(e.target.value);
                          }}
                        />
                        <AiOutlineEye
                          id="eyeclose"
                          onClick={handleSizeTest}
                          className="eyeclose eye-icon"
                        />
                        <AiOutlineEyeInvisible
                          id="eyeopen"
                          onClick={handleSizeTest}
                          className="eyeopen eye-icon"
                        />
                      </Col>
                      <Col lg={6} md={12} sm={12} xs={12} className="col">
                        <label className="formlabelamit clientlabel" id="ctl">
                          Client Live Link
                        </label>

                        <textarea
                          style={{
                            position: "relative",
                            overflow: "hidden",
                          }}
                          id="ClientLiveLink"
                          className="formtextamit"
                          placeholder="Client Live Link"
                          type="text"
                          value={clientLIve}
                          onChange={(e) => {
                            setClientLive(e.target.value);
                          }}
                        />
                        <AiOutlineEye
                          id="eyeclosel"
                          onClick={handleSizelive}
                          className="eyeclosel eye-icon"
                        />
                        <AiOutlineEyeInvisible
                          id="eyeopenl"
                          onClick={handleSizelive}
                          className="eyeopenl eye-icon"
                        />
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Col
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                        lg={3}
                        md={12}
                        sm={12}
                        xs={12}
                        className="col"
                      >
                        <button
                          className="cbtn saveBtn "
                          style={{}}
                          onClick={() => {
                            setBuildUrl(true);
                          }}
                        >
                          Build Your URL
                        </button>
                      </Col>

                      <Col lg={9} md={12} sm={12} xs={12} className="col">
                        <Row>
                          <Col
                            style={{ marginTop: "10px" }}
                            lg={8}
                            md={8}
                            sm={8}
                            xs={12}
                            className="col"
                          >
                            <span>{fileName}</span>
                          </Col>
                          <Col
                            style={{
                              display: "flex",
                              marginTop: "10px",
                              justifyContent: "flex-end",
                            }}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={12}
                            className="col"
                          >
                            {surveyStorage == 1 || surveyStorage == "" ? (
                              <></>
                            ) : (
                              <>
                                {" "}
                                <div>
                                  <label
                                    className="cbtn addBtn"
                                    id="uploadMargin"
                                  >
                                    <img
                                      src={addIcon}
                                      className="headingIcons"
                                      alt=""
                                    />{" "}
                                    Upload File
                                    <input
                                      type="file"
                                      onChange={(e) => {
                                        csvfileDataLoading(e);
                                      }}
                                      className="attachInput"
                                      accept=".csv"
                                    />
                                  </label>
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            <div
              style={{
                border: "1px solid white",
              }}
            >
              <QuotaTarget />
            </div>
            <div>
              <Customtarget />
            </div>
            <div>
              <div className="ProjectSetting pjt_arrow_icon" id="prescreening">
                <p>Pre Screening</p>

                <div className="rankPainA pswtchbtn ">
                  <button
                    className="new"
                    onClick={() => {
                      handleCustomBoxFeature();
                    }}
                  >
                    Add
                  </button>
                  &nbsp; &nbsp;
                  <button style={{ marginRight: "6px" }} className="PSaveBtnA">
                    Save
                  </button>
                  &nbsp;
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    width={60}
                    onlabel="ON"
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                  &nbsp; &nbsp;
                </div>
                {container.pre_I === "up" ? (
                  <>
                    <KeyboardArrowUpIcon
                      className="arrowup"
                      id="prearrow"
                      onClick={() => {
                        RecallContainer("Pre Screening");
                      }}
                    />
                  </>
                ) : (
                  <>
                    <KeyboardArrowDownIcon
                      className="arrowup"
                      id="prearrow"
                      onClick={() => {
                        setContainer((event) => ({
                          ...event,
                          pre_C: "show",
                          pre_I: "up",
                        }));
                      }}
                    />
                  </>
                )}
              </div>
              {container.pre_C === "show" ? (
                <>
                  <div
                    style={{
                      paddingBottom: "10px",
                    }}
                    className="container-fluid"
                  >
                    <Row
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {prescreenBox.map((end, index) => (
                        <>
                          {questionSet.length > index ? (
                            <>
                              <Col
                                lg={3}
                                md={6}
                                sm={6}
                                xs={12}
                                iv
                                className="col-3"
                              >
                                <div className="adddef_empty">
                                  {questionSet[index].name}
                                </div>
                              </Col>
                            </>
                          ) : (
                            <>
                              {index === questionSet.length ? (
                                <>
                                  {" "}
                                  <Col
                                    lg={3}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    iv
                                    className="col-3"
                                  >
                                    <div
                                      className="adddef_sected"
                                      onClick={() => {}}
                                    >
                                      +Add Definition
                                    </div>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <Col
                                    lg={3}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    iv
                                    className="col-3"
                                  >
                                    <div className="adddef_empty">
                                      +Add Definition
                                    </div>
                                  </Col>
                                </>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default Screening;
