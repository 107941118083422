import React, { Component } from "react";

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: [],
      selectAll: false,
      searchTerm: "",
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  componentDidUpdate(_, prevState) {
    // When selectAll is unchecked, uncheck all options
    if (prevState.selectAll !== this.state.selectAll && !this.state.selectAll) {
      this.setState({ selectedOptions: [] });
    }
  }

  handleClickOutside = (event) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ searchTerm: "" });
    }
  };

  handleSelectAll = () => {
    this.setState((prevState) => ({
      selectAll: !prevState.selectAll,
      selectedOptions: prevState.selectAll ? [] : this.props.options,
    }));
  };

  handleOptionToggle = (option) => {
    this.setState((prevState) => ({
      selectedOptions: prevState.selectedOptions.includes(option)
        ? prevState.selectedOptions.filter((item) => item !== option)
        : [...prevState.selectedOptions, option],
      selectAll: false,
    }));
  };

  handleClearSelection = () => {
    this.setState({ selectedOptions: [], selectAll: false });
  };

  render() {
    const { selectedOptions, selectAll, searchTerm } = this.state;
    const { options } = this.props;

    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
      <>
        <div
          ref={this.dropdownRef}
          style={{
            border: "1px solid red",
          }}
        >
          {/* Value container */}
          {selectedOptions.length > 0 ? (
            <div>
              {selectedOptions.length} item
              {selectedOptions.length > 1 ? "s" : ""} selected{" "}
              <span onClick={this.handleClearSelection}>X</span>
            </div>
          ) : (
            <>Select...</>
          )}
        </div>
        <div>
          {/* Search Bar */}
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => this.setState({ searchTerm: e.target.value })}
          />

          {/* Dropdown with options */}
          <div
            style={{
              border: "1px solid red",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={this.handleSelectAll}
              />
              Select All
            </label>
            {filteredOptions.map((option) => (
              <label key={option.value}>
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => this.handleOptionToggle(option)}
                />
                {option.label}
              </label>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default Dropdown;
