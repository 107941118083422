import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import "../PopUpPage/ErrorCreated.css";
const QualityAuditLog = (props) => {
  return (
    <>
      {" "}
      <Modal
        className="popupmodehere"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "16px", paddingLeft: "10px" }}
          >
            &nbsp;Quality Audit Logs: 23020321
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr>
                <th style={{ width: "400px", fontSize: "12px" }}>Date</th>
                <th style={{ width: "200px", fontSize: "12px" }}>Updated By</th>
                <th style={{ fontSize: "12px", width: "400px" }}>Details</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{ width: "400px", fontSize: "12px", border: "none" }}
                >
                  09 Feb 2023 00:58:53
                </td>
                <td
                  style={{ width: "200px", fontSize: "12px", border: "none" }}
                >
                  Type Of:
                </td>
                <td style={{ fontSize: "12px", border: "none" }}>
                  Quality Audit, Mark Error
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "200px",
                    fontSize: "12px",
                    border: "none",
                  }}
                ></td>

                <td
                  style={{ width: "200px", fontSize: "12px", border: "none" }}
                >
                  Subject Line:
                </td>
                <td style={{ fontSize: "12px", border: "none" }}>
                  What is Lorem Ipsum
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "200px",
                    fontSize: "12px",
                    border: "none",
                  }}
                ></td>

                <td
                  style={{ width: "200px", fontSize: "12px", border: "none" }}
                >
                  Receiver(TC):
                </td>
                <td style={{ fontSize: "12px", border: "none" }}>
                  sunny@torfac.com
                </td>
              </tr>
              <tr>
                <td style={{ width: "400px", fontSize: "12px" }}></td>
                <td style={{ width: "200px", fontSize: "12px" }}>
                  Receiver(TC):
                </td>
                <td style={{ fontSize: "12px" }}>sunny@torfac.com</td>
              </tr>
              <tr>
                <td
                  style={{ width: "400px", fontSize: "12px", border: "none" }}
                >
                  09 Feb 2023 00:58:53{" "}
                </td>
                <td
                  style={{ width: "200px", fontSize: "12px", border: "none" }}
                >
                  Receiver(TC):
                </td>
                <td style={{ fontSize: "12px", border: "none" }}>
                  Quality Audit, No Error
                </td>
              </tr>
              <tr>
                <td style={{ width: "400px", fontSize: "12px" }}></td>
                <td style={{ width: "200px", fontSize: "12px" }}>Comment:</td>
                <td style={{ fontSize: "12px" }}>
                  where the real content should be. If you have business
                </td>
              </tr>
              <tr>
                <td
                  style={{ width: "400px", fontSize: "12px", border: "none" }}
                >
                  09 Feb 2023 00:58:53{" "}
                </td>
                <td
                  style={{ width: "200px", fontSize: "12px", border: "none" }}
                >
                  Type of:
                </td>
                <td style={{ fontSize: "12px", border: "none" }}>
                  Quality Audit, No Error
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "200px",
                    fontSize: "12px",
                    border: "none",
                  }}
                ></td>

                <td
                  style={{ width: "200px", fontSize: "12px", border: "none" }}
                >
                  Comment:
                </td>
                <td style={{ fontSize: "12px", border: "none" }}>
                  where the real content should be. If you have business
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "200px",
                    fontSize: "12px",
                    border: "none",
                  }}
                ></td>

                <td
                  style={{ width: "200px", fontSize: "12px", border: "none" }}
                >
                  Subject Line:
                </td>
                <td style={{ fontSize: "12px", border: "none" }}>
                  What is Lorem Ipsum
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "200px",
                    fontSize: "12px",
                    border: "none",
                  }}
                ></td>

                <td
                  style={{ width: "200px", fontSize: "12px", border: "none" }}
                >
                  Subject Line:
                </td>
                <td style={{ fontSize: "12px", border: "none" }}>
                  What is Lorem Ipsum
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default QualityAuditLog;
