import Supplyheader from "../Supplyheader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./SupplierDetails.css";
import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useNavigate } from "react-router";
import userIco from "../../../assets/LoginIcons/users.svg";
import Select from "react-select";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
function Addnewsupplier() {
  const [selected, setSelected] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prescreening, setprescreening] = useState();
  const [qualiﬁcation, setQualiﬁcation] = useState();
  const [screen, setScreen] = useState();
  const [herring, setHerring] = useState();

  const axiosPrivate = useAxiosPrivate();
  const [countryselect, setCountrySelect] = useState();
  const [countryListStorage, setCountryListStorage] = useState([{}]);
  useEffect(() => {
    setValue("Country", countryselect);
  }, [countryselect]);
  const pname = "SupplierDetails";
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  useEffect(() => {
    get_Country_api();
  }, []);
  const get_Country_api = async () => {
    try {
      const response = await axiosPrivate.get("lookup/country-list");
      response.data.all_countries.map((e) => {
        setCountryListStorage((val) => [
          ...val,
          { label: e.country_name, value: e._id },
        ]);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const handleSubmitForm = async (e) => {
    const response = await fetch(
      `http://52.13.201.242:8003/api/v1/supplyinfo/create-supplier`,
      {
        method: "POST",
        body: JSON.stringify({
          name: e.firstName,
          supplier_abbr: e.VendorAbbr,
          email: e.email,
          phone: e.contactNumber,
          address: {
            billing_address: e.BillingAddress,
            country_id: countryselect,
            state: e.state,
            city: e.city,
            zipcode: e.zipcode,
          },
          screening: {
            has_screening: prescreening,
            has_qualification: qualiﬁcation,
            screen_captcha: screen,
            has_job_type: herring,
          },
          // is_api: true,
          // is_publisher: true,
          is_publisher: e.Publisher,
          is_api: e.isApi,
          status: e.status,
        }),
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "access-token":
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIyOTExMzgsIm5iZiI6MTY5MjI5MTEzOCwianRpIjoiZDgxOWRiYjgtYjBkYi00MjAwLTk3YjMtY2M4M2FhYzUzYTkzIiwiZXhwIjoxNjkyNzIzMTM4LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.ec0MG1JkCkJokFGtZ-1sMNrehSE1qCIAhywLxXRC1sg",
        },
      }
    );
    const result = await response.json();
    setIsSubmitting(false);
  };
  const handleSaveButton = async (e, data) => {
    // setIsSubmitting(true);
    try {
      await axiosPrivate
        .post(
          "supplyinfo/create-supplier",
          JSON.stringify({
            name: e.firstName,
            supplier_abbr: e.VendorAbbr,
            email: e.email,
            phone: e.contactNumber,
            address: {
              billing_address: e.BillingAddress,
              country_id: countryselect,
              state: e.state,
              city: e.city,
              zipcode: e.zipcode,
            },
            screening: {
              has_screening: prescreening,
              has_qualification: qualiﬁcation,
              screen_captcha: screen,
              has_job_type: herring,
            },

            recon_resp: e.ReconId,
            resp_id: e.RespId,
            recon_old: e.Recontact,
            is_publisher: e.Publisher,
            is_api: e.isApi,
            status: e.status,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            // notifysuccess("Check your mail for further verification");
            // navigate("/users");
          }
        });
    } catch (err) {}
    setIsSubmitting(false);
  };

  return (
    <>
      <Supplyheader pname={pname} />
      <Row className="supplierParents csmulti">
        <form onSubmit={handleSubmit(handleSaveButton)}>
          <Row className="suppliyChild">
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img src={userIco} className="headingIcons" alt="" /> New
                  Supplier
                </h3>
              </div>
              <div className="right-pane">
                <button className="cbtn closeBtn" onClick={() => {}}>
                  {" "}
                  Close
                </button>

                <button
                  // disabled={isSubmitting}
                  className="cbtn saveBtn"
                  type="submit"
                >
                  &nbsp;&nbsp; Save
                </button>
              </div>
            </div>
            <hr
              style={{
                marginTop: "20px",
              }}
            />
            <Row style={{ margin: "0px" }}>
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">Name*</label>
                <input
                  placeholder="Name"
                  className="formtextamit"
                  type="text"
                  {...register("firstName", {
                    required: "This field is Required",
                    pattern: {
                      value:
                        /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                      message: "Only letters are allowed",
                    },
                  })}
                />
                {errors.firstName && (
                  <small className="errmsg">{errors.firstName.message}</small>
                )}
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">Email*</label>
                <input
                  placeholder="Email"
                  className="formtextamit"
                  type="text"
                  {...register("email", {
                    required: "This field is Required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line
                      message: "Enter Valid Email Id",
                    },
                  })}
                />
                {errors.email && (
                  <small className="errmsg">{errors.email.message}</small>
                )}
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">Phone*</label>
                <input
                  placeholder="Phone"
                  className="formtextamit"
                  type="text"
                  {...register("contactNumber", {
                    required: "This field is Required",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                />
                {errors.contactNumber && (
                  <small className="errmsg">
                    {errors.contactNumber.message}
                  </small>
                )}
              </Col>{" "}
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">Billing Address*</label>
                <input
                  placeholder="Billing Address"
                  className="formtextamit"
                  type="text"
                  {...register("BillingAddress", {
                    required: "This field is Required",
                    pattern: {
                      value:
                        /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                      message: "Only letters are allowed",
                    },
                  })}
                />
                {errors.BillingAddress && (
                  <small className="errmsg">
                    {errors.BillingAddress.message}
                  </small>
                )}
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">Country*</label>

                <Select
                  placeholder="Select..."
                  className="pjt_select formtext"
                  classNamePrefix="select"
                  {...register("Country", {
                    required: "This field is Required",
                  })}
                  options={countryListStorage}
                  onChange={(e) => {
                    setCountrySelect(e.value);
                  }}
                  noOptionsMessage={() => "No Data Found"}
                />
                {countryselect ? (
                  <></>
                ) : (
                  <>
                    {errors.Country && (
                      <small className="errmsg perrmsg">
                        {errors.Country.message}
                      </small>
                    )}
                  </>
                )}
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">State</label>
                <input
                  placeholder="State"
                  className="formtextamit"
                  type="text"
                  {...register("state", {
                    pattern: {
                      value:
                        /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                      message: "Only letters are allowed",
                    },
                  })}
                />
                {errors.state && (
                  <small className="errmsg">{errors.state.message}</small>
                )}
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">City</label>
                <input
                  placeholder="City"
                  className="formtextamit"
                  type="text"
                  {...register("city", {
                    pattern: {
                      value:
                        /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                      message: "Only letters are allowed",
                    },
                  })}
                />
                {errors.city && (
                  <small className="errmsg">{errors.city.message}</small>
                )}
              </Col>{" "}
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">Zipcode</label>
                <input
                  placeholder="Zipcode"
                  className="formtextamit"
                  type="text"
                  {...register("Zipcode", {
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                />
                {errors.Zipcode && (
                  <small className="errmsg">{errors.Zipcode.message}</small>
                )}
              </Col>
            </Row>{" "}
            <Row style={{ margin: "0px" }}>
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">Resp id var*</label>
                <input
                  placeholder="None"
                  className="formtextamit"
                  type="text"
                  {...register("RespId", {
                    required: "This field is Required",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                />
                {errors.RespId && (
                  <small className="errmsg">{errors.RespId.message}</small>
                )}
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">Recon resp var*</label>
                <input
                  placeholder="None"
                  className="formtextamit"
                  type="text"
                  {...register("ReconId", {
                    required: "This field is Required",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                />
                {errors.ReconId && (
                  <small className="errmsg">{errors.ReconId.message}</small>
                )}
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">CPI Logic (in %)*</label>
                <input
                  placeholder="CPI Logic"
                  className="formtextamit"
                  type="text"
                  {...register("CPI", {
                    required: "This field is Required",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                />
                {errors.CPI && (
                  <small className="errmsg">{errors.CPI.message}</small>
                )}
              </Col>{" "}
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">Is API*</label>
                {/* <input
                  placeholder="Select Choice"
                  className="formtextamit"
                  type="text"
                  {...register("isApi", {
                    required: "This field is Required",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                />
                {errors.isApi && (
                  <small className="errmsg">{errors.isApi.message}</small>
                )} */}
                <select
                  className="formtext"
                  {...register("isApi", {
                    required: "This field is Required",
                  })}
                  // defaultValue={formData.isAPI === true ? 1 : 0}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                {errors.isApi && (
                  <small className="errmsg">{errors.isApi.message}</small>
                )}
              </Col>
            </Row>{" "}
            <Row style={{ margin: "0px" }}>
              <Col lg={6} md={12} sm={12} xs={12}>
                <Row>
                  {" "}
                  <Col lg={6} md={6} sm={6} xs={12}>
                    <label className="formlabelamit">Is Publisher*</label>
                    {/* <input
                      placeholder="No"
                      className="formtextamit"
                      type="text"
                      {...register("Publisher", {
                        required: "This field is Required",
                        pattern: {
                          value:
                            /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                          message: "Only letters are allowed",
                        },
                      })}
                    />
                    {errors.Publisher && (
                      <small className="errmsg">
                        {errors.Publisher.message}
                      </small>
                    )} */}
                    <select
                      className="formtext"
                      {...register("Publisher", {
                        required: "This field is Required",
                      })}
                      // defaultValue={formData.isPublisher === true ? 1 : 0}
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    {errors.Publisher && (
                      <small className="errmsg">
                        {errors.Publisher.message}
                      </small>
                    )}
                  </Col>{" "}
                  <Col lg={6} md={6} sm={6} xs={12}>
                    <label className="formlabelamit">Vendor Abbr*</label>
                    <input
                      placeholder="Vendor Abbr"
                      className="formtextamit"
                      type="text"
                      {...register("VendorAbbr", {
                        required: "This field is Required",
                        pattern: {
                          value:
                            /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                          message: "Only letters are allowed",
                        },
                      })}
                    />
                    {errors.VendorAbbr && (
                      <small className="errmsg">
                        {errors.VendorAbbr.message}
                      </small>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={12} sm={12} xs={12}>
                <Row>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <label className="formlabelamit">Pre-Screening</label>
                    <div className="tooglecenter pswtchbtn">
                      <BootstrapSwitchButton
                        className="switch btn on btn-outline-success btn-xs"
                        size="xs"
                        width={60}
                        onlabel="On "
                        offlabel="Off"
                        onstyle="outline-success"
                        offstyle="outline-danger"
                        onChange={(e) => {
                          setprescreening(e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <label className="formlabelamit">Qualiﬁcation screen</label>
                    <div className="tooglecenter pswtchbtn">
                      <BootstrapSwitchButton
                        {...register("Qualiﬁcation")}
                        className="switch btn on btn-outline-success btn-xs"
                        size="xs"
                        width={60}
                        onlabel="On "
                        offlabel="Off"
                        onstyle="outline-success"
                        offstyle="outline-danger"
                        onChange={(e) => {
                          setQualiﬁcation(e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <label className="formlabelamit">Screen Captcha</label>
                    <div className="tooglecenter pswtchbtn">
                      <BootstrapSwitchButton
                        className="switch btn on btn-outline-success btn-xs"
                        size="xs"
                        width={60}
                        onlabel="On "
                        offlabel="Off"
                        onstyle="outline-success"
                        offstyle="outline-danger"
                        onChange={(e) => {
                          setScreen(e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <label className="formlabelamit">Red Herring Screen</label>
                    <div className="tooglecenter pswtchbtn">
                      <BootstrapSwitchButton
                        className="switch btn on btn-outline-success btn-xs"
                        size="xs"
                        width={60}
                        onlabel="On "
                        offlabel="Off"
                        onstyle="outline-success"
                        offstyle="outline-danger"
                        onChange={(e) => {
                          setHerring(e);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>{" "}
            <Row style={{ margin: "0px" }}>
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">Recontact Old Id Var</label>
                <input
                  placeholder="Quota"
                  className="formtextamit"
                  type="text"
                  {...register("Recontact", {
                    pattern: {
                      value:
                        /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                      message: "Only letters are allowed",
                    },
                  })}
                />
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <label className="formlabelamit">Status</label>
                <input
                  placeholder="Status"
                  className="formtextamit"
                  type="text"
                  {...register("Status", {
                    pattern: {
                      value:
                        /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                      message: "Only letters are allowed",
                    },
                  })}
                />
              </Col>
            </Row>
          </Row>
        </form>
      </Row>
    </>
  );
}
export default Addnewsupplier;
