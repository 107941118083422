import Table from "react-bootstrap/Table";
import { TableCell, TableRow, TableBody } from "@mui/material";
import "../../CSModule/ProjectDetail/ProjectDetail.css";

const Droptable = () => {
  return (
    <>
      <Table
        style={{
          backgroundColor: "#fcfbfb",
        }}
        className="quotasub"
      >
        <thead style={{}}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell className="tabledata quotasub">Fielded | Goal</TableCell>

            <TableCell className="tabledata quotasub">CPI ($) </TableCell>
            <TableCell className="tabledata quotasub">
              Last Start Date
            </TableCell>
            <TableCell className="tabledata quotasub">
              Last Complete Date
            </TableCell>
            <TableCell className="tabledata quotasub">In Progress</TableCell>
          </TableRow>
        </thead>
        <tbody>
          <tr>
            <td>Azure</td>
            <td>200 | 1000</td>
            <td>5.4</td>
            <td>01/09/ 2023</td>
            <td>01/09/2023</td>
            <td>20%</td>
          </tr>
          <tr>
            <td>Branded Research</td>
            <td>0 | 1000</td>
            <td>5.4</td>
            <td>-</td>
            <td>-</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Dynata</td>
            <td>0 | 1000</td>
            <td>5.4</td>
            <td>-</td>
            <td>-</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Light Speed</td>
            <td>0 | 1000</td>
            <td>5.4</td>
            <td>-</td>
            <td>-</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Redon Research</td>
            <td>0 | 1000</td>
            <td>5.4</td>
            <td>-</td>
            <td>-</td>
            <td>0</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
export default Droptable;
