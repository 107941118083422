import React, { useEffect, useRef, useState } from "react";
import ReactSelect, { components } from "react-select";
import CustomMenuList from "./CustomMenuList";
import CustomValueContainer from "./CustomValueContainer";
import { MdClear } from "react-icons/md";

const Multiselect = (props) => {
  const { options, value, onChange } = props;
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const onDomClick = (e) => {
    if (!containerRef.current) return;

    let menu = containerRef.current.querySelector(".select__menu");

    if (
      !containerRef.current.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false);
      setInputValue("");
    }
  };

  const CheckboxOptionComponent = ({ children, ...props }) => (
    <components.Option {...props}>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => handleSelectOption(props.data)}
      >
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => handleSelectOption(props.data)}
          style={{
            margin: "5px",
          }}
        />
        {children}
      </div>
    </components.Option>
  );

  const CustomClearIndicator = (props) => {
    const handleClear = () => {
      onChange([]); // Clear selected options
    };

    return (
      <components.ClearIndicator {...props}>
        <span onClick={handleClear}>
          <MdClear
            size={18}
            style={{
              color: "#999",
            }}
          />
        </span>
      </components.ClearIndicator>
    );
  };

  const handleSelectOption = (option) => {
    const selectedValue = Array.isArray(value) ? value : [];

    if (option.value === "select-all") {
      const allSelected = selectedValue.length === options.length;
      const updatedSelectedOptions = allSelected ? [] : options;
      onChange(updatedSelectedOptions);
    } else {
      const optionIndex = selectedValue.findIndex(
        (item) => item.value === option.value
      );
      if (optionIndex === -1) {
        onChange([...selectedValue, option]);
      } else {
        const updatedSelectedOptions = selectedValue.filter(
          (item) => item.value !== option.value
        );
        onChange(updatedSelectedOptions);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  if (props.allowSelectAll) {
    const handleSelectAll = () => {
      const allSelected = value.length === options.length && value.length > 0;
      const updatedSelectedOptions = allSelected ? [] : options;
      onChange(updatedSelectedOptions);
    };

    return (
      <div ref={containerRef}>
        <ReactSelect
          innerRef={containerRef}
          {...props}
          isDisabled={props.isDisabled}
          className="basic-single"
          classNamePrefix="select"
          name="color"
          options={[{ value: "select-all", label: "Select All" }, ...options]}
          components={{
            Option: (optionProps) => (
              <div className="multiselect_all">
                {optionProps.data.label === "Select All" ? (
                  <div
                    className="select_all"
                    id="all_select"
                    onClick={() => handleSelectAll()}
                    style={{
                      cursor: "pointer",
                      padding: "6px",
                      paddingLeft: "12px",
                      fontSize: "12px",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={value.length === options.length}
                      onChange={() => handleSelectAll()}
                      style={{
                        margin: "5px",
                      }}
                    />
                    {optionProps.data.label}
                  </div>
                ) : (
                  <CheckboxOptionComponent {...optionProps} />
                )}
              </div>
            ),
            MenuList: CustomMenuList,
            ValueContainer: CustomValueContainer,
            ClearIndicator: CustomClearIndicator,
          }}
          isMulti={true}
          isClearable={true}
          inputValue={inputValue}
          isSearchable={false}
          hideSelectedOptions={false}
          onMenuInputFocus={() => setIsFocused(true)}
          onChange={() => setIsFocused(false)}
          onInputChange={(val) => setInputValue(val)}
          value={value}
          {...{
            menuIsOpen: isFocused || undefined,
            isFocused: isFocused || undefined,
          }}
        />
      </div>
    );
  }

  return <ReactSelect {...props} />;
};

export default Multiselect;
