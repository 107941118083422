const defaultStore = {
  singleIds: 0,
  doubleIds: 0,
};

const taskReducer = (defaultStore, action) => {
  switch (action.type) {
    case "DetailClient":
      const id = action.payload;
      return { ...defaultStore, clientId: id };
    case "TWOCLIENT":
      const id1 = action.payload;
      const ckey = action.ckey;
      return { ...defaultStore, clientId: id1, ckey: ckey };
    default:
      return defaultStore;
  }
};

export default taskReducer;
