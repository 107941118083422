import "../SupplyModule/Supplyheader.css";
import { useNavigate } from "react-router";

const Qualityheader = (props) => {
  const navigate = useNavigate();
  const cptype = props.pname;

  return (
    <>
      <div className="Supply_to_account">
        <span
          className={cptype === "ProjectQuality" ? "active" : ""}
          onClick={() => {
            navigate("/project-quality");
          }}
        >
          Project Quality
        </span>
        <span
          className={cptype === "ProjectQualityReport" ? "active" : ""}
          onClick={() => {
            navigate("/project-quality-report");
          }}
        >
          Project Quality Report
        </span>
        <span
          className={cptype === "Samplewastage" ? "active" : ""}
          onClick={() => {
            navigate("/sample-wastage");
          }}
        >
          Sample Wastage
        </span>
      </div>
    </>
  );
};

export default Qualityheader;
