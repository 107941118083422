import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

const LineChart = (props) => {
  let newCurrentDate = moment().subtract(7, "days").format("YYYY-MM-DD");
  let newEndDate = moment().format("YYYY-MM-DD");
  const [dataa, setData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  let startD = useRef();
  let endD = useRef();
  useEffect(() => {
    if (props.bidBooked === undefined) {
      startD.current = newCurrentDate;
      endD.current = newEndDate;
    } else {
      startD.current = props.bidBooked.CurrentDate;
      endD.current = props.bidBooked.EndDate;
    }
    if (props.data) {
      props.setFlag(false);
      let user = "";
      props.data.map((e) => {
        if (props.data[props.data.length - 1]._id !== e._id) {
          user = user + `user${e._id}&`;
        } else {
          user = user + `user${e._id}`;
        }
      });
      getData(startD, endD, user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.bidBooked]);

  useEffect(() => {
    if (props.bidBooked === undefined) {
      startD.current = newCurrentDate;
      endD.current = newEndDate;
    } else {
      startD.current = props.bidBooked.CurrentDate;
      endD.current = props.bidBooked.EndDate;
    }
    getDataUser(startD, endD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bidBooked]);

  const getData = async (startD, endD, user) => {
    try {
      const response = await axiosPrivate.get(
        `bid-dashboard/bid-booked?start_time=${startD.current}&end_time=${endD.current}&${user}`,
        {}
      );
      let data = response.data.bid_booked;
      const newData = Object.keys(data).reduce((result, currentKey) => {
        let fdata = data[currentKey];
        fdata.ckey = currentKey;
        result.push(fdata);
        return result;
      }, []);
      setData(newData);
    } catch (err) {}
  };

  const getDataUser = async (startD, endD) => {
    try {
      const response = await axiosPrivate.get(
        `bid-dashboard/bid-booked?start_time=${startD.current}&end_time=${endD.current}`,
        {}
      );

      let data = response.data.bid_booked;

      const newData = Object.keys(data).reduce((result, currentKey) => {
        let fdata = data[currentKey];
        fdata.ckey = currentKey;
        result.push(fdata);
        return result;
      }, []);

      setData(newData);
    } catch (err) {
      // navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: "white",
          borderColor: "grey",
          tickColor: "grey",
        },
      },
    },
  };

  const labels = dataa.map((e) => e.name);

  const data = {
    labels,
    datasets: [
      {
        label: "Bid Revenue",
        data: dataa.map((e) => e.bid_amount),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        tension: 0.1,
      },
      {
        label: "Booked Revenue",
        data: dataa.map((e) => e.booked_amount),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        tension: 0.5,
      },
    ],
  };

  return (
    <>
      <Line options={options} data={data} id="chart" />
    </>
  );
};

export default LineChart;
