import React, { useState, useEffect } from "react";
import "./Permissions.css";
import Header from "../UI/Header/Header";
import pageIcon from "../../assets/BidsIcons/addClient.svg";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import BidsSubHeader from "../BidsModule/BidsSubHeader/BidsSubHeader";
import ButtonSpinner from "../UI/ButtonSpinner";
import Permissions from "./Permissions";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router";
export default function Operation(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [all_data, setAll_data] = useState([]);
  const id = props.id;
  const navigate = useNavigate();
  useEffect(() => {
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findData = async () => {
    try {
      const response = await axiosPrivate.get(
        `/permission/get-permission/?module_id=2&user_id=${id}`,
        {}
      );
    } catch (err) {
      console.error(err);
    }
    return () => {};
  };
  return (
    <>
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachbox">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading" onClick={() => {}}>
                <img src={pageIcon} className="headingIcons" alt="" />{" "}
                Operations
              </h3>
            </div>
            <div className="right-pane">
              <button
                className="cbtn closeBtn"
                onClick={() => {
                  navigate("/users");
                }}>
                {" "}
                Close
              </button>

              <button className="cbtn saveBtn">
                {isSubmitting && <ButtonSpinner />} &nbsp; Save
              </button>
            </div>
          </div>

          <div className="permissions-columns">
            <div className="mainContent">
              <div className="container-checkbox-fields">
                <Row>
                  {all_data.map((e) => (
                    <>
                      <div className="permissions-user-box">
                        <span
                          style={{ marginTop: "10px", marginleft: "20px;" }}>
                          <input
                            checked={e.is_selected}
                            type="checkbox"
                            style={{
                              marginTop: "10px",
                              marginLeft: "-40px",
                            }}></input>{" "}
                          {e.name}
                        </span>
                      </div>

                      {e.childmenu.length < 0 ? (
                        <></>
                      ) : (
                        <>
                          <fieldset>
                            <legend>Choose your interests</legend>
                            <div>
                              <input
                                type="checkbox"
                                id="coding"
                                name="interest"
                                value="coding"
                                checked
                              />
                              <label for="coding">Coding</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                id="music"
                                name="interest"
                                value="music"
                              />
                              <label for="music">Music</label>
                            </div>
                          </fieldset>
                        </>
                      )}
                    </>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
