import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router";
import SettingHeader from "./SettingHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useState, useEffect } from "react";
import ButtonSpinner from "../../UI/ButtonSpinner";
import userIco from "../../../assets/LoginIcons/users.svg";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { AiFillDelete } from "react-icons/ai";
import SpinnerLoader from "../../UI/Spinner";
import axios from "axios";
import "./ExclusionSetting.css";
const VariableMapping = () => {
  const navigate = useNavigate();
  const pname = "Variable Mapping";
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showVariableIncome, setShowVariableIncome] = useState([1]);
  const [showVariableOutgoing, setShowVariableOutgoing] = useState([1]);
  const [loder, setLoder] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [formData, setFormData] = useState({
    usT: "",
    usVF: "",
    upT: "",
    upVF: "",
    upcv1: "",
    upcf1: "",
    upcv2: "",
    upcf2: "",
    upcv3: "",
    upcf3: "",
    upcv4: "",
    upcf4: "",
    upcv5: "",
    upcf5: "",
    upcv6: "",
    upcf6: "",
    outgoingUS: "",
    outgoingUP: "",
    outgoingCV1: "",
    outgoingCV2: "",
    outgoingCV3: "",
    outgoingCV4: "",
    outgoingCV5: "",
    outgoingCV6: "",
  });
  useEffect(() => {
    get_ProjectType_api();
  }, []);
  const handleVariable = (e) => {
    e.stopPropagation();
    if (showVariableIncome.length >= 6) {
    } else {
      setShowVariableIncome((e) => [...e, 1]);
    }
  };
  const handleVariableOutGOing = () => {
    if (showVariableOutgoing.length >= 6) {
    } else {
      setShowVariableOutgoing((e) => [...e, 1]);
    }
  };

  const get_ProjectType_api = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(`supplyinfo/get-supplier/${id}`);
      if (response.data.supplier.variable_mapping !== null) {
        setFormData((prevData) => ({
          ...prevData,
          usT: response.data.supplier.variable_mapping.incoming
            .in_unique_session.transaction_id,
          usVF: response.data.supplier.variable_mapping.incoming
            .in_unique_session.var_format,
          upT: response.data.supplier.variable_mapping.incoming
            .in_unique_panelist.panelist_id,
          upVF: response.data.supplier.variable_mapping.incoming
            .in_unique_panelist.var_format,
          upcv1:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_1.custom_variable,
          upcf1:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_1.custom_format,
          upcv2:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_2.custom_variable,
          upcf2:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_2.custom_format,
          upcv3:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_3.custom_variable,
          upcf3:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_3.custom_format,
          upcv4:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_4.custom_variable,
          upcf4:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_4.custom_format,
          upcv5:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_5.custom_variable,
          upcf5:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_5.custom_format,
          upcv6:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_6.custom_variable,
          upcf6:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_6.custom_format,
          outgoingUS:
            response.data.supplier.variable_mapping.outgoing.out_unique_session,
          outgoingUP:
            response.data.supplier.variable_mapping.outgoing
              .out_unique_panelist,
          outgoingCV1:
            response.data.supplier.variable_mapping.outgoing.out_custom_variable
              .custom_variable_1,
          outgoingCV2:
            response.data.supplier.variable_mapping.outgoing.out_custom_variable
              .custom_variable_2,

          outgoingCV3:
            response.data.supplier.variable_mapping.outgoing.out_custom_variable
              .custom_variable_3,

          outgoingCV4:
            response.data.supplier.variable_mapping.outgoing.out_custom_variable
              .custom_variable_4,
          outgoingCV5:
            response.data.supplier.variable_mapping.outgoing.out_custom_variable
              .custom_variable_5,
          outgoingCV6:
            response.data.supplier.variable_mapping.outgoing.out_custom_variable
              .custom_variable_6,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,

          usT: "",
          usVF: "",
          upT: "",
          upVF: "",
          upcv1: "",
          upcf1: "",
          upcv2: "",
          upcf2: "",
          upcv3: "",
          upcf3: "",
          upcv4: "",
          upcf4: "",
          upcv5: "",
          upcf5: "",
          upcv6: "",
          upcf6: "",
          outgoingUS: "",
          outgoingUP: "",
          outgoingCV1: "",
          outgoingCV2: "",
          outgoingCV3: "",
          outgoingCV4: "",
          outgoingCV5: "",
          outgoingCV6: "",
        }));
      }
      // setData(response.data.supplier);

      // setShowFormone(
      //   response.data.supplier.hashing.hash_variable.in_hash_var.status
      // );
      // setShowFormtwo(
      //   response.data.supplier.hashing.hash_variable.out_hash_var.status
      // );
      setLoder(true);
      // setCountrySelect(response.data.supplier.address.country.id);
      // setprescreening(response.data.supplier.screening.has_screening);
      // setQualiﬁcation(response.data.supplier.screening.has_qualification);
      // setScreen(response.data.supplier.screening.screen_captcha);
      // setHerring(response.data.supplier.screening.has_job_type);
    } catch (err) {
      console.error(err);
    }
  };

  const postData = async (e) => {
    setIsSubmitting(true);

    try {
      await axiosPrivate.post(
        `/supplyinfo/supplier-variable-mapping/${id}`,
        JSON.stringify({
          incoming: {
            in_unique_session: {
              transaction_id: e.usT,
              var_format: e.usVF,
            },
            in_unique_panelist: {
              panelist_id: e.upT,
              var_format: e.upVF,
            },
            in_custom_variable: {
              custom_variable_1: {
                custom_variable: e.upcv1,
                custom_format: e.upcf1,
              },
              custom_variable_2: {
                custom_variable: e.upcv2,
                custom_format: e.upcf2,
              },
              custom_variable_3: {
                custom_variable: e.upcv3,
                custom_format: e.upcf3,
              },
              custom_variable_4: {
                custom_variable: e.upcv4,
                custom_format: e.upcf4,
              },
              custom_variable_5: {
                custom_variable: e.upcv5,
                custom_format: e.upcf5,
              },
              custom_variable_6: {
                custom_variable: e.upcv6,
                custom_format: e.upcf6,
              },
            },
          },
          outgoing: {
            out_unique_session: e.outgoingUS,
            out_unique_panelist: e.outgoingUP,
            out_custom_variable: {
              custom_variable_1: e.outgoingCV1,
              custom_variable_2: e.outgoingCV2,
              custom_variable_3: e.outgoingCV3,
              custom_variable_4: e.outgoingCV4,
              custom_variable_5: e.outgoingCV5,
              custom_variable_6: e.outgoingCV6,
            },
          },
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (err) {}
    setIsSubmitting(false);
  };
  const fetchDataWithToken = async () => {
    setLoder(false);

    const token = "YOUR_API_TOKEN"; // Replace 'YOUR_API_TOKEN' with your actual API token
    const url = `http://52.13.201.242:8003/api/v1/supplyinfo/get-supplier/${id}`; // Replace 'YOUR_API_URL' with the URL of the API you want to fetch

    try {
      const response = await axios.get(url, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "access-token":
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIyOTExMzgsIm5iZiI6MTY5MjI5MTEzOCwianRpIjoiZDgxOWRiYjgtYjBkYi00MjAwLTk3YjMtY2M4M2FhYzUzYTkzIiwiZXhwIjoxNjkyNzIzMTM4LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.ec0MG1JkCkJokFGtZ-1sMNrehSE1qCIAhywLxXRC1sg",
        },
      });
      setFormData((prevData) => ({
        ...prevData,
        usT: response.data.supplier.variable_mapping.incoming.in_unique_session
          .transaction_id,
        usVF: response.data.supplier.variable_mapping.incoming.in_unique_session
          .var_format,
        upT: response.data.supplier.variable_mapping.incoming.in_unique_panelist
          .panelist_id,
        upVF: response.data.supplier.variable_mapping.incoming
          .in_unique_panelist.var_format,
        upcv1:
          response.data.supplier.variable_mapping.incoming.in_custom_variable
            .custom_variable_1.custom_variable,
        upcf1:
          response.data.supplier.variable_mapping.incoming.in_custom_variable
            .custom_variable_1.custom_format,
        upcv2:
          response.data.supplier.variable_mapping.incoming.in_custom_variable
            .custom_variable_2.custom_variable,
        upcf2:
          response.data.supplier.variable_mapping.incoming.in_custom_variable
            .custom_variable_2.custom_format,
        upcv3:
          response.data.supplier.variable_mapping.incoming.in_custom_variable
            .custom_variable_3.custom_variable,
        upcf3:
          response.data.supplier.variable_mapping.incoming.in_custom_variable
            .custom_variable_3.custom_format,
        upcv4:
          response.data.supplier.variable_mapping.incoming.in_custom_variable
            .custom_variable_4.custom_variable,
        upcf4:
          response.data.supplier.variable_mapping.incoming.in_custom_variable
            .custom_variable_4.custom_format,
        upcv5:
          response.data.supplier.variable_mapping.incoming.in_custom_variable
            .custom_variable_5.custom_variable,
        upcf5:
          response.data.supplier.variable_mapping.incoming.in_custom_variable
            .custom_variable_5.custom_format,
        upcv6:
          response.data.supplier.variable_mapping.incoming.in_custom_variable
            .custom_variable_6.custom_variable,
        upcf6:
          response.data.supplier.variable_mapping.incoming.in_custom_variable
            .custom_variable_6.custom_format,
        outgoingUS:
          response.data.supplier.variable_mapping.outgoing.out_unique_session,
        outgoingUP:
          response.data.supplier.variable_mapping.outgoing.out_unique_panelist,
        outgoingCV1:
          response.data.supplier.variable_mapping.outgoing.out_custom_variable
            .custom_variable_1,
        outgoingCV2:
          response.data.supplier.variable_mapping.outgoing.out_custom_variable
            .custom_variable_2,

        outgoingCV3:
          response.data.supplier.variable_mapping.outgoing.out_custom_variable
            .custom_variable_3,

        outgoingCV4:
          response.data.supplier.variable_mapping.outgoing.out_custom_variable
            .custom_variable_4,
        outgoingCV5:
          response.data.supplier.variable_mapping.outgoing.out_custom_variable
            .custom_variable_5,
        outgoingCV6:
          response.data.supplier.variable_mapping.outgoing.out_custom_variable
            .custom_variable_6,
      }));

      setLoder(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmitForm = async (e) => {
    const response = await fetch(
      `http://52.13.201.242:8003/api/v1/supplyinfo/supplier-variable-mapping/${id}`,
      {
        method: "POST",
        body: JSON.stringify({
          incoming: {
            in_unique_session: {
              transaction_id: e.usT,
              var_format: e.usVF,
            },
            in_unique_panelist: {
              panelist_id: e.upT,
              var_format: e.upVF,
            },
            in_custom_variable: {
              custom_variable_1: {
                custom_variable: e.upcv1,
                custom_format: e.upcf1,
              },
              custom_variable_2: {
                custom_variable: e.upcv2,
                custom_format: e.upcf2,
              },
              custom_variable_3: {
                custom_variable: e.upcv3,
                custom_format: e.upcf3,
              },
              custom_variable_4: {
                custom_variable: e.upcv4,
                custom_format: e.upcf4,
              },
              custom_variable_5: {
                custom_variable: e.upcv5,
                custom_format: e.upcf5,
              },
              custom_variable_6: {
                custom_variable: e.upcv6,
                custom_format: e.upcf6,
              },
            },
          },
          outgoing: {
            out_unique_session: e.outgoingUS,
            out_unique_panelist: e.outgoingUP,
            out_custom_variable: {
              custom_variable_1: e.outgoingCV1,
              custom_variable_2: e.outgoingCV2,
              custom_variable_3: e.outgoingCV3,
              custom_variable_4: e.outgoingCV4,
              custom_variable_5: e.outgoingCV5,
              custom_variable_6: e.outgoingCV6,
            },
          },
        }),
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "access-token":
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIyOTExMzgsIm5iZiI6MTY5MjI5MTEzOCwianRpIjoiZDgxOWRiYjgtYjBkYi00MjAwLTk3YjMtY2M4M2FhYzUzYTkzIiwiZXhwIjoxNjkyNzIzMTM4LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.ec0MG1JkCkJokFGtZ-1sMNrehSE1qCIAhywLxXRC1sg",
        },
      }
    );
    const result = await response.json();
    setIsSubmitting(false);
  };
  const handleDeleteClientLive = (index) => {
    setShowVariableIncome((prevClientlive) => {
      const updatedClientlive = [...prevClientlive];
      updatedClientlive.splice(index, 1);
      return updatedClientlive;
    });
  };
  const handleDelete = (index) => {
    setShowVariableOutgoing((prevClientlive) => {
      const updatedClientlive = [...prevClientlive];
      updatedClientlive.splice(index, 1);
      return updatedClientlive;
    });
  };

  return (
    <>
      <SettingHeader pname={pname} />
      {loder ? (
        <>
          <div className="top_container">
            <div className="main_container ">
              <form onSubmit={handleSubmit(postData)}>
                {/* <form onSubmit={handleSubmit(createDepartment)}> */}
                <div className="user_serachbox">
                  <div style={{ float: "left" }}>
                    <h3 className="tableHeading">
                      <img
                        src={userIco}
                        className="headingIcons"
                        alt="Add Department"
                      />{" "}
                      Variable Mapping
                    </h3>
                  </div>

                  <div className="right-pane">
                    <button
                      className="cbtn closeBtn"
                      onClick={() => {
                        navigate("/supplier-details");
                      }}
                    >
                      {" "}
                      Close
                    </button>

                    <button disabled={isSubmitting} className="cbtn saveBtn">
                      {isSubmitting && <ButtonSpinner />} &nbsp; Save
                    </button>
                  </div>

                  <hr />
                </div>
                <div
                  id="dividerow"
                  style={{ marginTop: "10px" }}
                  className="mainContent"
                >
                  <div className="settingHeading">
                    <p className="pBlockHead">Incoming</p>
                  </div>
                  <div style={{ border: "1px solid whiteSmoke" }}>
                    <div className="rowContant">
                      <Row>
                        <Col lg={6} md={6} xs={12} className="col">
                          <label
                            className="formlabel"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Unique Session*
                          </label>
                          <Row>
                            <Col lg={6} md={6} xs={12} className="col">
                              <label className="formlabel">
                                Transaction Id*
                              </label>
                              <input
                                {...register("usT", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Unique Session"
                                className="CsformText"
                                defaultValue={formData.usT}
                              />
                              {errors.usT && (
                                <small className="errmsg perrmsg">
                                  {errors.usT.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={6} md={6} xs={12} className="col">
                              <label className="formlabel">
                                Variable Format*
                              </label>
                              <input
                                {...register("usVF", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Unique Panelist*"
                                className="CsformText"
                                defaultValue={formData.usVF}
                              />
                              {errors.usVF && (
                                <small className="errmsg perrmsg">
                                  {errors.usVF.message}
                                </small>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={6} md={6} xs={12} className="col">
                          <label
                            className="formlabel"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Unique Panelist*
                          </label>
                          <Row>
                            <Col lg={6} md={6} xs={12} className="col">
                              <label className="formlabel">
                                Transaction Id*
                              </label>
                              <input
                                {...register("upT", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Unique Session"
                                className="CsformText"
                                defaultValue={formData.upT}
                              />
                              {errors.upT && (
                                <small className="errmsg perrmsg">
                                  {errors.upT.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={6} md={6} xs={12} className="col">
                              <label className="formlabel">
                                Variable Format*
                              </label>
                              <input
                                {...register("upVF", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Unique Panelist*"
                                className="CsformText"
                                defaultValue={formData.upVF}
                              />
                              {errors.upVF && (
                                <small className="errmsg perrmsg">
                                  {errors.upVF.message}
                                </small>
                              )}
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={3} md={6} xs={12} className="col">
                          <label className="formlabel">
                            Custom Variable 1*
                          </label>
                          <input
                            {...register("upcv1", {
                              required: "This field is Required",
                            })}
                            type="text"
                            placeholder="Custom Variable"
                            className="CsformText"
                            defaultValue={formData.upcv1}
                          />
                          {errors.upcv1 && (
                            <small className="errmsg perrmsg">
                              {errors.upcv1.message}
                            </small>
                          )}
                          <input
                            {...register("upcf1", {
                              required: "This field is Required",
                            })}
                            type="text"
                            placeholder="Custom Variable Format"
                            className="CsformText"
                            defaultValue={formData.upcf1}
                          />
                          {errors.upcf1 && (
                            <small className="errmsg perrmsg">
                              {errors.upcf1.message}
                            </small>
                          )}
                        </Col>
                        <Col lg={3} md={6} xs={12} className="col">
                          <label className="formlabel">
                            Custom Variable 2*
                          </label>
                          <input
                            {...register("upcv2", {
                              required: "This field is Required",
                            })}
                            type="text"
                            placeholder="Custom Variable"
                            className="CsformText"
                            defaultValue={formData.upcv2}
                          />
                          {errors.upcv2 && (
                            <small className="errmsg perrmsg">
                              {errors.upcv2.message}
                            </small>
                          )}
                          <input
                            {...register("upcf2", {
                              required: "This field is Required",
                            })}
                            type="text"
                            placeholder="Custom Variable Format"
                            className="CsformText"
                            defaultValue={formData.upcf2}
                          />
                          {errors.upcf2 && (
                            <small className="errmsg perrmsg">
                              {errors.upcf2.message}
                            </small>
                          )}
                        </Col>
                        {showVariableIncome.length >= 2 ||
                        formData.upcv3 !== null ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 3*
                            </label>
                            <input
                              {...register("upcv3", {
                                required: "This field is Required",
                              })}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.upcv3}
                            />

                            {errors.upcv3 && (
                              <small className="errmsg perrmsg">
                                {errors.upcv3.message}
                              </small>
                            )}
                            <input
                              {...register("upcf3", {
                                required: "This field is Required",
                              })}
                              type="text"
                              placeholder="Custom Variable Format"
                              className="CsformText"
                              defaultValue={formData.upcf3}
                            />

                            {errors.upcf3 && (
                              <small className="errmsg perrmsg">
                                {errors.upcf3.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        {showVariableIncome.length >= 3 ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 4*
                            </label>
                            <input
                              {...register("upcv4", {
                                required: "This field is Required",
                              })}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.upcv4}
                            />

                            {errors.upcv4 && (
                              <small className="errmsg perrmsg">
                                {errors.upcv4.message}
                              </small>
                            )}
                            <input
                              {...register("upcf4", {
                                required: "This field is Required",
                              })}
                              type="text"
                              placeholder="Custom Variable Format"
                              className="CsformText"
                              defaultValue={formData.upcf4}
                            />

                            {errors.upcf4 && (
                              <small className="errmsg perrmsg">
                                {errors.upcf4.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        {showVariableIncome.length >= 4 ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 5*
                            </label>
                            <input
                              {...register("upcv5", {
                                required: "This field is Required",
                              })}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.upcv5}
                            />

                            {errors.upcv5 && (
                              <small className="errmsg perrmsg">
                                {errors.upcv5.message}
                              </small>
                            )}
                            <input
                              {...register("upcf5", {
                                required: "This field is Required",
                              })}
                              type="text"
                              placeholder="Custom Variable Format"
                              className="CsformText"
                              defaultValue={formData.upcf5}
                            />

                            {errors.upcf5 && (
                              <small className="errmsg perrmsg">
                                {errors.upcf5.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        {showVariableIncome.length >= 5 ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 6*
                            </label>
                            <input
                              {...register("upcv6", {
                                required: "This field is Required",
                              })}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.upcv6}
                            />

                            {errors.upcv6 && (
                              <small className="errmsg perrmsg">
                                {errors.upcv6.message}
                              </small>
                            )}
                            <input
                              {...register("upcf6", {
                                required: "This field is Required",
                              })}
                              type="text"
                              placeholder="Custom Variable Format"
                              className="CsformText"
                              defaultValue={formData.upcf6}
                            />

                            {errors.upcf6 && (
                              <small className="errmsg perrmsg">
                                {errors.upcf6.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        <Col>
                          {showVariableIncome.length !== 5 ? (
                            <>
                              <button
                                className="cbtn saveBtn addbutton"
                                onClick={(e) => {
                                  handleVariable(e);
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                              >
                                Add
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                          {showVariableIncome.length >= 3 ? (
                            <>
                              <button
                                className="cbtn closeBtn addbutton"
                                style={{
                                  marginRight: "10px",
                                }}
                                onClick={(e) => {
                                  handleDeleteClientLive();
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                              >
                                Remove
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div id="dividerow" className="mainContent">
                  <div className="settingHeading">
                    <p className="pBlockHead">Outgoing</p>
                  </div>
                  <div style={{ border: "1px solid whiteSmoke" }}>
                    <div className="rowContant">
                      <Row>
                        <Col lg={3} md={6} xs={12} className="col">
                          <label className="formlabel">Unique Sessions*</label>
                          <input
                            {...register("outgoingUS", {
                              required: "This field is Required",
                            })}
                            type="text"
                            placeholder="Unique Sessions"
                            className="CsformText"
                            defaultValue={formData.outgoingUS}
                          />
                          {errors.outgoingUS && (
                            <small className="errmsg perrmsg">
                              {errors.outgoingUS.message}
                            </small>
                          )}
                        </Col>
                        <Col lg={3} md={6} xs={12} className="col">
                          <label className="formlabel">Unique Panelist*</label>
                          <input
                            {...register("outgoingUP", {
                              required: "This field is Required",
                            })}
                            type="text"
                            placeholder="Unique Panelist*"
                            className="CsformText"
                            defaultValue={formData.outgoingUP}
                          />
                          {errors.outgoingUP && (
                            <small className="errmsg perrmsg">
                              {errors.outgoingUP.message}
                            </small>
                          )}
                        </Col>
                        <Col lg={3} md={6} xs={12} className="col">
                          <label className="formlabel">
                            Custom Variable 1*
                          </label>
                          <input
                            {...register("outgoingCV1", {
                              required: "This field is Required",
                            })}
                            type="text"
                            placeholder="Custom Variable"
                            className="CsformText"
                            defaultValue={formData.outgoingCV1}
                          />
                          {errors.outgoingCV1 && (
                            <small className="errmsg perrmsg">
                              {errors.outgoingCV1.message}
                            </small>
                          )}
                        </Col>
                        <Col lg={3} md={6} xs={12} className="col">
                          <label className="formlabel">
                            Custom Variable 2*
                          </label>
                          <input
                            {...register("outgoingCV2", {
                              required: "This field is Required",
                            })}
                            type="text"
                            placeholder="Custom Variable"
                            className="CsformText"
                            defaultValue={formData.outgoingCV2}
                          />
                          {errors.outgoingCV2 && (
                            <small className="errmsg perrmsg">
                              {errors.outgoingCV2.message}
                            </small>
                          )}
                        </Col>
                        {showVariableOutgoing.length >= 2 ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 3*
                            </label>
                            <input
                              {...register("outgoingCV3", {
                                required: "This field is Required",
                              })}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.outgoingCV3}
                            />
                            {errors.outgoingCV3 && (
                              <small className="errmsg perrmsg">
                                {errors.outgoingCV3.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        {showVariableOutgoing.length >= 3 ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 4*
                            </label>
                            <input
                              {...register("outgoingCV4", {
                                required: "This field is Required",
                              })}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.outgoingCV4}
                            />
                            {errors.outgoingCV4 && (
                              <small className="errmsg perrmsg">
                                {errors.outgoingCV4.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        {showVariableOutgoing.length >= 4 ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 5*
                            </label>
                            <input
                              {...register("outgoingCV5", {
                                required: "This field is Required",
                              })}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.outgoingCV5}
                            />
                            {errors.outgoingCV5 && (
                              <small className="errmsg perrmsg">
                                {errors.outgoingCV5.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        {showVariableOutgoing.length >= 5 ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 6*
                            </label>
                            <input
                              {...register("outgoingCV6", {
                                required: "This field is Required",
                              })}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.outgoingCV6}
                            />
                            {errors.outgoingCV6 && (
                              <small className="errmsg perrmsg">
                                {errors.outgoingCV6.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}

                        <Col>
                          {showVariableOutgoing.length !== 5 ? (
                            <>
                              <button
                                className="cbtn saveBtn addbutton"
                                style={{}}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleVariableOutGOing();

                                  e.preventDefault();
                                }}
                              >
                                Add
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                          {showVariableOutgoing.length >= 2 ? (
                            <>
                              <button
                                className="cbtn closeBtn addbutton"
                                style={{
                                  marginRight: "10px",
                                }}
                                onClick={(e) => {
                                  handleDelete();
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                              >
                                Remove
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default VariableMapping;
