import React, { useState, useEffect } from "react";
import "./Permissions.css";

import pageIcon from "../../assets/BidsIcons/addClient.svg";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import ButtonSpinner from "../UI/ButtonSpinner";
import Permissions from "./Permissions";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router";
export default function Supply(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [all_data, setAll_data] = useState([]);
  const id = props.id;
  const navigate = useNavigate();
  useEffect(() => {
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findData = async () => {
    try {
      const response = await axiosPrivate.get(
        `/permission/get-permission/?module_id=3&user_id=${id}`,
        {}
      );
      setAll_data(response.data.permission);
    } catch (err) {
      console.error(err);
      // navigate("/login", { state: { from: location }, replace: true });
    }
    return () => {};
  };
  return (
    <>
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachbox">
            <div style={{ float: "left" }}>
              <h3
                className="tableHeading"
                onClick={() => {
                  // navigate("/user");
                }}
              >
                <img src={pageIcon} className="headingIcons" alt="" /> Supply
              </h3>
            </div>
            <div className="right-pane">
              <button
                className="cbtn closeBtn"
                onClick={() => {
                  // navigate("/clients");
                  // demofirst();
                  navigate("/users");
                }}
              >
                {" "}
                Close
              </button>

              <button className="cbtn saveBtn">
                {isSubmitting && <ButtonSpinner />} &nbsp; Save
              </button>
            </div>
          </div>

          <div className="permissions-columns">
            <div className="mainContent">
              <div className="container-checkbox-fields">
                <Row>
                  {all_data.map((e) => (
                    <>
                      <div className="permissions-user-box">
                        <span
                          style={{ marginTop: "10px", marginleft: "20px;" }}
                        >
                          <input
                            checked={e.is_selected}
                            type="checkbox"
                            style={{ marginTop: "10px", marginLeft: "-40px" }}
                          ></input>{" "}
                          {e.name}
                        </span>
                      </div>

                      {e.childmenu.length < 0 ? (
                        <></>
                      ) : (
                        e.childmenu.map((data) => (
                          <>
                            <Col sm={4} md={3} lg={2} xs={12}>
                              <span>
                                <input
                                  checked={data.is_selected}
                                  style={{ marginLeft: "20px" }}
                                  type="checkbox"
                                  className="user-inputs"
                                ></input>{" "}
                                {data.name.slice(0, 25)}
                              </span>
                            </Col>
                          </>
                        ))
                      )}
                    </>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
