import { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import "./Login.css";
import Logo from "../../assets/images/logo.png";
import samplicitytagline from "../../assets/SamplicityBlackLogo.svg";
import passwordIcon from "../../assets/images/icons8-forgot-password-64.png";
import userIco from "../../assets/images/icons8-mail-48.png";
import toast from "react-hot-toast";
import Toast from "../UI/Toaster/Toaster";
import { useForm } from "react-hook-form";
import axios from "../../api/axios";
import ButtonSpinner from "../UI/ButtonSpinner";
const LOGIN_URL = "/auth/login";

const Login = () => {
  const { setAuth, setPersist } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";
  const passRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const cat = localStorage.getItem("reset");
    if (cat === "yes") {
      changepassword();
    }
    localStorage.removeItem("reset");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changepassword = () => toast.success("Password changed succesfully");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const notifyError = (errMsg) => {
    toast.error((t) => (
      <span style={{ color: "white" }}>
        {errMsg}
        <button className="closeToaster" onClick={() => toast.dismiss(t.id)}>
          x
        </button>
      </span>
    ));
  };

  const handleSubmitButton = async (e) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email: e.email.toLowerCase(), password: e.password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      const accessToken = response?.data?.access_token;
      const refreshToken = response?.data?.refresh_token;
      const permission = response.data.permissions.perm_list;
      const superuser = response.data.permissions.is_superuser;

      setPersist(true);
      localStorage.setItem("persist", true);
      localStorage.setItem("token", refreshToken);

      setAuth({ user, pwd, accessToken, refreshToken, permission, superuser });

      setUser("");
      setPwd("");

      setIsSubmitting(false);
      navigate(from, { replace: true });
    } catch (err) {
      setIsSubmitting(false);
      if (!err?.response) {
        notifyError("No Server Response");
      } else if (err.response?.status === 400) {
        notifyError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        notifyError("Enter valid Email/Password");
        passRef.current.focus();
      } else {
        notifyError("Login Failed");
      }
    }
  };

  return (
    <>
      <Toast />
      <div className="login_container">
        <div>
          <img className="login_torfacimg" src={Logo} alt="" />
        </div>
        <div>
          <img
            className="login_samplicity_logo"
            src={samplicitytagline}
            alt="samplicity"
          />
        </div>
        <div className="login_form">
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <form onSubmit={handleSubmit(handleSubmitButton)}>
            <div className="col">
              <input
                className="login_user_input input-text"
                placeholder="Enter Email"
                id="username"
                style={{ backgroundImage: `url(${userIco})` }}
                {...register("email", {
                  required: "This field is Required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\],;:\s@\"]+)*)|(.+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@]{2,})$/i, //eslint-disable-line
                    message: "Enter Valid Email Id",
                  },
                })}
              />
              {errors.email && (
                <small className="errmsg">{errors.email.message}</small>
              )}
            </div>

            <div className="col">
              <input
                className="login_user_input input-text"
                placeholder="Enter Password"
                type="password"
                id="password"
                style={{ backgroundImage: `url(${passwordIcon})` }}
                {...register("password", {
                  required: "This field is Required",
                  pattern: {
                    value:
                      /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0987654321@#$%]*$/,
                    message: "Enter valid password",
                  },
                })}
              />
              {errors.password && (
                <small className="errmsg">{errors.password.message}</small>
              )}
            </div>
            <p className="errorMessage"></p>

            <button id="loginbutton" disabled={isSubmitting}>
              {" "}
              {isSubmitting && <ButtonSpinner />}&nbsp; LOGIN
            </button>
          </form>
          <p
            className="login_forget_text"
            onClick={() => {
              navigate("/forgot-password");
            }}
          >
            Forgot Password?
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
