import Spinner from 'react-bootstrap/Spinner';
import sc from "../../assets/images/SC.gif"
const SpinnerLoader = () => {
  return (
    <>
        <div className="LoaderContainer">
        <img src={sc} height={50} alt="loader" /><h5>
        &nbsp;<Spinner as="span" animation="grow"  size="sm" role="status" aria-hidden="true" />
        &nbsp;<Spinner as="span" animation="grow"  size="sm" role="status" aria-hidden="true" />
        &nbsp;<Spinner as="span" animation="grow"  size="sm" role="status" aria-hidden="true" />
        </h5>
        </div>
    </>
   
  )
}

export default SpinnerLoader