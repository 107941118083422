import { useEffect, useState } from "react";
import "./ProjectDetail.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ProjectDetailMulipalForm from "./ProjectDetailMulipalForm";
import Select from "react-select";
import SpinnerLoader from "../../UI/Spinner";

const ProjectDetail = (props) => {
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    props.setShowSubHeader(1);
    props.setSelectedHeader("project");
  }, []);

  const ClientName = [
    { value: "Affordable Samples", label: "Affordable Sample " },
    { value: "Ags Global ", label: "Ags Global" },
  ];

  const statusOption = [
    {
      label: "Live",
      value: "Live",
    },

    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "Oﬀ spec inquiry",
      value: "Inquiry",
    },
    {
      label: "Paused",
      value: "Paused",
    },
  ];

  // .................................................................
  //           *****************    Api    ************************
  // ...............................................................

  const [projectTypeStorage, setProjectTypeStorage] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [projectStatusStorage, setProjetStatusStorage] = useState([{}]);
  const [clientListStorage, setClientListStorage] = useState([{}]);
  const [salePersionStorage, setCsalePersionStorage] = useState([{}]);
  const [pPManagerStorage, setPpManagerStorage] = useState([{}]);
  const [sPManagerStorage, setSpManagerStorage] = useState([{}]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [genPopModel, setGenPopModel] = useState([
    { name: "IR", email: "LOI" },
    { name: "IR", email: "LOI" },
  ]);
  const [projectData, setProjectData] = useState([
    {
      ProjectName: "",
      ProjectType: {},
      ClientName: {},
      ClientNumber: "",
      SalePerson: {},
      PPManager: {},
      SPManager: {},
      Status: {},
      StartDate: "",
      EndDate: "",
      EstRevenue: "",
      RevistRevenue: "",
    },
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    get_ProjectType_api();
    get_SurveyMarge_api();
    get_ProjectStatus_api();
    get_ClintList_api();
    get_SalePersion_api();
    get_pPManager_api();
    get_sPManager_api();
    get_SurveyProject();
    get_ProjectData_Params();
  }, []);
  const get_ProjectType_api = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get-project-type");
      let data = response.data.all_status;

      var clientlist = [...data];
      const clientdata = clientlist.map((item, i) =>
        Object.assign(item, {
          value: parseInt(item.id),
          label: item.name,
        })
      );
      setProjectTypeStorage(clientdata);
    } catch (err) {
      console.error(err);
    }
  };
  const get_SurveyMarge_api = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get-survey-margin");
    } catch (err) {
      console.error(err);
    }
  };
  const get_ProjectStatus_api = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get-project-status");
      response.data.all_status.map((e) => {
        setProjetStatusStorage((val) => [
          ...val,
          { label: e.name, value: e.id },
        ]);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const get_ClintList_api = async () => {
    try {
      const response = await axiosPrivate.get("client/client-list");
      response.data.all_client.map((e) => {
        setClientListStorage((val) => [
          ...val,
          { label: e.name, value: e._id },
        ]);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const get_SalePersion_api = async () => {
    try {
      const response = await axiosPrivate.get("user/all-users");
      response.data.all_users.map((e) => {
        setCsalePersionStorage((val) => [
          ...val,
          { label: e.first_name, value: e._id },
        ]);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const get_pPManager_api = async () => {
    try {
      const response = await axiosPrivate.get("user/all-users");
      response.data.all_users.map((e) => {
        setPpManagerStorage((val) => [
          ...val,
          { label: e.first_name, value: e._id },
        ]);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const get_sPManager_api = async () => {
    try {
      const response = await axiosPrivate.get("user/all-users");
      response.data.all_users.map((e) => {
        setSpManagerStorage((val) => [
          ...val,
          { label: e.first_name, value: e._id },
        ]);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleProjectClose = (e) => {
    e.preventDefault();
  };

  const handleProjectForm = (event) => {
    event.preventDefault();
  };

  const handleProjectType = (e) => {};
  const handleClintname = (e) => {};

  useEffect(() => {
    setValue("ClientName", projectData);
  }, [projectData]);

  const get_ProjectData_Params = async () => {
    try {
      const response = await axiosPrivate.get(
        "project/get-project/64933e2ff7a0edd51024933e"
      );
      setProjectData((prevProjectData) => [
        {
          ...prevProjectData[0],
          ProjectName: response.data.project.project_name,
          ProjectType: {
            label: response.data.project.project_type.name,
            value: parseInt(response.data.project.project_type.id),
          },
          ClientName: {
            label: response.data.project.client.name,
            value: response.data.project.client.id,
          },
          ClientNumber: response.data.project.client.client_number,
          SalePerson: {
            label: response.data.project.sale_representive.name,
            value: response.data.project.sale_representive.id,
          },
          PPManager: {
            label:
              response.data.project.project_manager.primary_project_manager
                .name,
            value:
              response.data.project.project_manager.primary_project_manager.id,
          },
          SPManager: {
            label:
              response.data.project.project_manager.secondary_project_manager
                .name,
            value:
              response.data.project.project_manager.secondary_project_manager
                .id,
          },
          Status: {
            label: response.data.project.project_status.name,
            value: response.data.project.project_status.id,
          },
          EstRevenue: response.data.project.revenue.est_revenue,
          RevistRevenue: response.data.project.revenue.revised_est_revenue,
        },
      ]);

      setStartDate(new Date(response.data.project.start_date));
      setEndDate(new Date(response.data.project.end_date));
    } catch (err) {
      console.error(err);
    }
    setSpinner(false);
  };

  const get_SurveyProject = async () => {
    try {
      const response = await axiosPrivate.get(
        "survey/get-survey-project-id?project_id=64933e2ff7a0edd51024933e"
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {spinner ? (
        <>
          <SpinnerLoader />
        </>
      ) : (
        <>
          {" "}
          <form onSubmit={handleSubmit(handleProjectForm)}>
            <div className="saveclsebtn ">
              <button
                className="cbtn closeBtn pbtn"
                onClick={(e) => {
                  handleProjectClose(e);
                }}
              >
                Close
              </button>
              <button className="cbtn saveBtn pbtn" type="submit">
                Save
              </button>
            </div>

            <hr className="csHR" />
            {/* <div className=" wrapperBelow"> */}
            <div className="pswtchbtn pcolorbtn pswtchleft">
              <BootstrapSwitchButton
                className="switch btn on btn-outline-success btn-xs projectswitchbtn"
                id="bbts"
                size="xs"
                width={80}
                onlabel="Live"
                offlabel="Inactive"
                onstyle="outline-success"
                offstyle="outline-danger"
              />
            </div>

            <Row className="prj_rowone">
              <Col lg={3} md={6} xs={12} className="col">
                <label className="formlabel">Project Name</label>
                <input
                  type="text"
                  placeholder="Project Name"
                  className="CsformText"
                  {...register("Project_name", {
                    required: "This field is Required",
                  })}
                  defaultValue={projectData[0].ProjectName}
                />
                {errors.Project_name && (
                  <small className="errmsg perrmsg">
                    {errors.Project_name.message}
                  </small>
                )}
              </Col>
              <Col lg={3} md={6} xs={12} className="col pjt_select">
                <label className="formlabel">Project Type</label>
                <Select
                  {...register("Project", {})}
                  className="formtext"
                  classNamePrefix="select"
                  isDisabled={false}
                  isLoading={false}
                  isRtl={false}
                  isSearchable={true}
                  name="color"
                  options={projectTypeStorage}
                  onChange={handleClintname}
                  defaultValue={projectData[0].ProjectType}
                />
                {errors.Project && (
                  <small className="errmsg perrmsg">
                    {errors.Project.message}
                  </small>
                )}
              </Col>
              <Col lg={3} md={6} xs={12} className="col">
                <label className="formlabel">Client Name</label>

                <Select
                  {...register("ClientName", {
                    // required: "This field is Required",
                  })}
                  placeholder="Select..."
                  className="pjt_select formtext"
                  classNamePrefix="select"
                  options={clientListStorage}
                  defaultValue={projectData[0].ClientName}
                  noOptionsMessage={() => "No Data Found"}
                />
                {errors.ClientName && (
                  <small className="errmsg perrmsg">
                    {errors.ClientName.message}
                  </small>
                )}
              </Col>
              <Col lg={3} md={6} xs={12} className="col">
                <label className="formlabel">Client Number (PO)#</label>
                <input
                  {...register("ClientNumber", {
                    // required: "This field is Required",
                  })}
                  type="text"
                  placeholder="Client Number (PO)#"
                  className="CsformText"
                  defaultValue={projectData[0].ClientNumber}
                />
                {errors.ClientNumber && (
                  <small className="errmsg perrmsg">
                    {errors.ClientNumber.message}
                  </small>
                )}
              </Col>
            </Row>
            <Row
              className="prj_rowone"
              style={{
                marginTop: "0px",
              }}
            >
              <Col lg={3} md={6} xs={12} className="col">
                <label
                  className="formlabel"
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  Sales Person
                </label>

                <Select
                  {...register("SalesPerson", {
                    // required: "This field is Required",
                  })}
                  placeholder="Select..."
                  className="pjt_select formtext"
                  classNamePrefix="select"
                  options={salePersionStorage}
                  defaultValue={projectData[0].SalePerson}
                  noOptionsMessage={() => "No Data Found"}
                />
                {errors.SalesPerson && (
                  <small className="errmsg perrmsg">
                    {errors.SalesPerson.message}
                  </small>
                )}
              </Col>
              <Col lg={3} md={6} xs={12} className="col">
                <label className="formlabel projlabel">
                  Primary Project Manager
                </label>

                <Select
                  {...register("PrimaryProjectManager", {
                    // required: "This field is Required",
                  })}
                  placeholder="Select..."
                  className="pjt_select formtext"
                  classNamePrefix="select"
                  options={pPManagerStorage}
                  defaultValue={projectData[0].PPManager}
                  noOptionsMessage={() => "No Data Found"}
                />
                {errors.PrimaryProjectManager && (
                  <small className="errmsg perrmsg">
                    {errors.PrimaryProjectManager.message}
                  </small>
                )}
              </Col>
              <Col lg={3} md={6} xs={12} className="col">
                <label
                  className="formlabel projlabel"
                  title="Secondary Project Manager"
                >
                  Secondary Project Manager
                </label>

                <Select
                  {...register("SecondaryProjectManager", {
                    // required: "This field is Required",/
                  })}
                  placeholder="Select..."
                  className="pjt_select formtext"
                  classNamePrefix="select"
                  options={sPManagerStorage}
                  defaultValue={projectData[0].SPManager}
                  noOptionsMessage={() => "No Data Found"}
                />
                {errors.SecondaryProjectManager && (
                  <small className="errmsg perrmsg">
                    {errors.SecondaryProjectManager.message}
                  </small>
                )}
              </Col>
              <Col lg={3} md={6} xs={12} className="col">
                <label
                  className="formlabel"
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  Status
                </label>

                <Select
                  {...register("Status", {
                    // required: "This field is Required",
                  })}
                  placeholder="Select..."
                  className="pjt_select formtext"
                  classNamePrefix="select"
                  options={projectStatusStorage}
                  defaultValue={projectData[0].Status}
                  noOptionsMessage={() => "No Data Found"}
                />

                {errors.Status && (
                  <small className="errmsg perrmsg">
                    {errors.Status.message}
                  </small>
                )}
              </Col>
            </Row>
            <Row
              className="prj_rowone"
              style={{
                marginTop: "7px",
              }}
            >
              <Col lg={3} md={6} xs={12} className="col">
                <label className="formlabel">Start Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="formtext"
                  {...register("received_date", { valueAsDate: true })}
                  autoComplete="off"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  defaultValue={projectData[0].StartDate}
                />
              </Col>
              <Col lg={3} md={6} xs={12} className="col">
                <label className="formlabel">End Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="formtext"
                  {...register("received_date", { valueAsDate: true })}
                  autoComplete="off"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                />
              </Col>
              <Col lg={3} md={6} xs={12} className="col">
                <label className="formlabel">Est. Revenue</label>
                <input
                  {...register("EstRevenue", {
                    // required: "This field is Required",
                  })}
                  type="text"
                  placeholder="Est. Revenue"
                  className="CsformText"
                  defaultValue={projectData[0].EstRevenue}
                />
                {errors.EstRevenue && (
                  <small className="errmsg perrmsg">
                    {errors.EstRevenue.message}
                  </small>
                )}
              </Col>
              <Col lg={3} md={6} xs={12} className="col">
                <label className="formlabel">Revised Est. Revenue</label>
                <input
                  {...register("RevisedEstRevenue", {
                    required: "This field is Required",
                  })}
                  type="text"
                  placeholder="Revised Est. Revenue"
                  className="CsformText"
                  defaultValue={projectData[0].RevistRevenue}
                />
                {errors.RevisedEstRevenue && (
                  <small className="errmsg perrmsg">
                    {errors.RevisedEstRevenue.message}
                  </small>
                )}
              </Col>
            </Row>
          </form>
          <div className="PrightLower">
            <div style={{ backgroundColor: "#929594", lineHeight: "2.1" }}>
              <p className=" Pheading">SC106647 - Project 3</p>
            </div>

            {genPopModel.map((e, index) => (
              <ProjectDetailMulipalForm
                index={index}
                genPopModel={genPopModel}
                props={props}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default ProjectDetail;
