import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "./SurveyTracking.css";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { MultiSelect } from "react-multi-select-component";
function EditSupplier(props) {
  const axiosPrivate = useAxiosPrivate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSaveButton = (e) => {
    e.preventDefault();
  };

  const handleClose = () => {
    props.props.setSetting(true);
    props.setEditSupplier(false);
  };
  useEffect(() => {
    get_Supplierlist_api();
  }, []);
  const [supplierList, setSupplierList] = useState([]);
  const [selected, setSelected] = useState([]);
  const get_Supplierlist_api = async () => {
    try {
      const response = await axiosPrivate.get("supplyinfo/get-supplier-list ");
      response.data.supplier.map((e) => {
        setSupplierList((val) => [...val, { label: e.name, value: e.id }]);
      });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Edit Supplier
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleSaveButton)}>
          <div>
            <Row>
              <Col lg={3} md={12} sm={12} xs={12} className="col csmulti">
                <label className="formlabelamit">Supplier Name</label>

                <MultiSelect
                  className="multiselectoption"
                  options={supplierList}
                  value={selected}
                  onChange={setSelected}
                  labelledBy={"Select"}
                  isCreatable={true}
                  disableSearch
                />
              </Col>
              <Col lg={2} md={4} sm={12} xs={12} className="col">
                <label className="formlabelamit">Quota</label>
                <input
                  {...register("Quota", {
                    required: "This field is Required",
                  })}
                  placeholder="Quota"
                  className="formtextamit"
                  type="text"
                />
                {errors.Quota && (
                  <small className="errmsg editsuperror">
                    {errors.Quota.message}
                  </small>
                )}
              </Col>
              <Col lg={2} md={4} sm={12} xs={12} className="col">
                <label className="formlabelamit">IR</label>
                <input
                  {...register("IR", {
                    required: "This field is Required",
                  })}
                  placeholder="IR"
                  className="formtextamit"
                  type="text"
                />
                {errors.IR && (
                  <small className="errmsg editsuperror">
                    {errors.IR.message}
                  </small>
                )}
              </Col>
              <Col lg={2} md={4} sm={12} xs={12} className="col">
                <label className="formlabelamit">LOI</label>
                <input
                  {...register("LOI", {
                    required: "This field is Required",
                  })}
                  placeholder="LOI"
                  className="formtextamit"
                  type="text"
                />
                {errors.LOI && (
                  <small className="errmsg editsuperror">
                    {errors.LOI.message}
                  </small>
                )}
              </Col>

              <Col lg={3} md={12} sm={12} xs={12} className="col">
                <label
                  className="formlabelamit projlabel"
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  Supplier Cost
                </label>
                <input
                  {...register("SupplierCost", {
                    required: "This field is Required",
                  })}
                  placeholder="Supplier Cost"
                  className="formtextamit"
                  type="text"
                />
                {errors.SupplierCost && (
                  <small className="errmsg ">
                    {errors.SupplierCost.message}
                  </small>
                )}
              </Col>
            </Row>
            <Row style={{}}>
              <Col className=" " lg={3} md={4} sm={4} xs={12}>
                <label className="formlabelamit">Variable 1</label>
                <input placeholder="RID" className="formtextamit" type="text" />
              </Col>
              <Col className=" " lg={3} md={4} sm={4} xs={12}>
                <label className="formlabelamit">Variable 2</label>
                <input className="formtextamit" type="text" />
              </Col>
              <Col className=" " lg={3} md={4} sm={4} xs={12}>
                <label className="formlabelamit">Variable 3</label>
                <input className="formtextamit" type="text" />
              </Col>
              <Col className=" " lg={3} md={4} sm={4} xs={12}>
                <label className="formlabelamit">Variable 4</label>
                <input className="formtextamit" type="text" />
              </Col>
            </Row>
            <Row>
              {" "}
              <Col lg={2} md={4} sm={4} xs={6}>
                <div className="rrr"></div>
                <div className="pswtchbtn q_edit_switcbtn">
                  <label className="formlabelamit">Qualiﬁcation screen</label>
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    width={60}
                    onlabel="ON"
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </div>
              </Col>
              <Col lg={2} md={4} sm={4} xs={6}>
                <div className="rrr"></div>
                <div className="pswtchbtn  q_edit_switcbtn">
                  <label className="formlabelamit">Screening</label>
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    width={60}
                    onlabel="ON"
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </div>
              </Col>
              <Col lg={2} md={4} sm={4} xs={6}>
                <div className="rrr"></div>
                <div className="pswtchbtn  q_edit_switcbtn">
                  <label className="formlabelamit">Country Check</label>
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    width={60}
                    onlabel="ON"
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </div>
              </Col>
              <Col lg={2} md={4} sm={4} xs={6}>
                <div className="rrr"></div>
                <div className="pswtchbtn  q_edit_switcbtn">
                  <label className="formlabelamit">Proxy Check</label>
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    width={60}
                    onlabel="ON"
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </div>
              </Col>
              <Col lg={2} md={4} sm={4} xs={6}>
                <div className="rrr"></div>
                <div className="pswtchbtn  q_edit_switcbtn">
                  <label className="formlabelamit">Duplicate IP</label>
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    width={60}
                    onlabel="ON"
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </div>
              </Col>
              <Col lg={2} md={4} sm={4} xs={6}>
                <div className="rrr"></div>
                <div className="pswtchbtn  q_edit_switcbtn">
                  <label className="formlabelamit">Soft Launch</label>
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    width={60}
                    onlabel="ON"
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </div>
              </Col>
            </Row>
            <div style={{ height: "10px" }}></div>
            <Row>
              {" "}
              <Col lg={2} md={4} sm={4} xs={6}>
                <div className="rrr"></div>
                <div className="pswtchbtn  q_edit_switcbtn">
                  <label className="formlabelamit">Screen Captcha</label>
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    width={60}
                    onlabel="ON"
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </div>
              </Col>
              <Col lg={2} md={4} sm={4} xs={6}>
                <div className="rrr"></div>
                <div className="pswtchbtn  q_edit_switcbtn">
                  <label className="formlabelamit">Red Herring Screen</label>
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    width={60}
                    onlabel="ON"
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </div>
              </Col>
              <Col lg={2} md={4} sm={4} xs={6}>
                <div className="rrr"></div>
                <div className="pswtchbtn  q_edit_switcbtn">
                  <label className="formlabelamit">Speeder Terminate</label>
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    width={60}
                    onlabel="ON"
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </div>
              </Col>
              <Col lg={2} md={4} sm={4} xs={6} className="suppliersetup">
                <div className="rrr"></div>
                <div className="pswtchbtn  q_edit_switcbtn">
                  <label className="formlabelamit">Exception Link</label>
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    width={60}
                    onlabel="ON"
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </div>
              </Col>
            </Row>
            <div style={{ height: "10px" }}></div>
            <Row>
              {" "}
              <Col className="suppliersetup" lg={12} md={12} sm={12} xs={12}>
                <div className="rrr"></div>

                <label className="formlabelamit">Completion Link</label>
                <input
                  placeholder="https://samplicity.wiseworksresearch.com/demand-api/transaction?transaction_id=[%transaction_id%]&v_id=[%vendor_id%]&s_id=[%survey_id%]&token=[%sc_hash%]&status=1"
                  className="formtextamit"
                  type="text"
                />
              </Col>
              <Col lg={12} md={12} sm={12} xs={12} className="suppliersetup">
                <div className="rrr"></div>
                <label className="formlabelamit">Termination Link</label>
                <input
                  placeholder="https://samplicity.wiseworksresearch.com/demand-api/transaction?transaction_id=[%transaction_id%]&v_id=[%vendor_id%]&s_id=[%survey_id%]&token=[%sc_hash%]&status=1"
                  className="formtextamit"
                  type="text"
                />
              </Col>
              <Col lg={12} md={12} sm={12} xs={12} className="suppliersetup">
                <div className="rrr"></div>
                <label className="formlabelamit">Quotafull Link</label>
                <input
                  placeholder="https://samplicity.wiseworksresearch.com/demand-api/transaction?transaction_id=[%transaction_id%]&v_id=[%vendor_id%]&s_id=[%survey_id%]&token=[%sc_hash%]&status=1"
                  className="formtextamit"
                  type="text"
                />
              </Col>
            </Row>
            <div style={{ height: "20px" }}></div>
            <hr />

            <Row style={{ margin: "0px" }}>
              <Col>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {" "}
                  <button
                    className="quota_surtrakbtn"
                    onClick={(e) => {
                      e.preventDefault();
                      props.props.setSetting(true);
                      props.setEditSupplier(false);
                    }}
                  >
                    Survey tracking
                  </button>
                  <div style={{ width: "20px" }}></div>
                  <button className="quota_savebtn" type="submit">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
export default EditSupplier;
