import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import DoughnutChart from "../../UI/Graphs/Doughnut";

const TotalBidsStatus = (props) => {
  return (
    <Row style={{ padding: "15px 25px" }}>
      <Col>
        <p className="legend mt-25">
          <span style={{ background: "rgba(255, 99, 132, 0.9)" }}></span> New
        </p>
        <p className="legend">
          <span style={{ background: "rgba(54, 162, 235, 0.9)" }}></span>{" "}
          Bidding
        </p>
        <p className="legend">
          <span style={{ background: "rgba(255, 206, 86, 0.9)" }}></span> Pass
        </p>
        <p className="legend">
          <span style={{ background: "rgba(75, 192, 192, 0.9)" }}></span> Won
        </p>
        <p className="legend">
          <span style={{ background: "rgba(153, 102, 255, 0.9)" }}></span> Lost
        </p>
        <p className="legend">
          <span style={{ background: "rgba(255, 159, 64, 0.9)" }}></span> Cancel
        </p>
      </Col>
      <Col style={{ width: "50%", verticalAlign: "middle" }}>
        <DoughnutChart props={props} />
      </Col>
    </Row>
  );
};

export default TotalBidsStatus;
