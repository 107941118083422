import { useState } from "react";
import Paginations from "../Paginations/Paginations";

import Table from "react-bootstrap/Table";
import { Col, Row } from "react-bootstrap";
import pageIcon from "../../assets/BidsIcons/bids.svg";
import DateRangePicker from "react-bootstrap-daterangepicker";

import Qualityheader from "./Qualityheader";
import downloadIcon from "../../assets/CSicons/icons/download.svg";

import fileIcon from "../../assets/CSicons/icons/file.svg";

import { FiFilter } from "react-icons/fi";
const Samplewastage = () => {
  const [show, setShow] = useState(false);
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const getPageSize = (size) => {
    setPagesize(size);
  };

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };
  return (
    <>
      <Qualityheader pname={"Samplewastage"} />
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachboxSupply">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" />
                Sample Wastage
              </h3>
            </div>

            <img
              src={downloadIcon}
              className="project_qualityIconColr"
              alt=""
              srcset=""
              style={{
                float: "right",

                cursor: "pointer",
                fill: "black",
              }}
            />

            <img
              src={fileIcon}
              className="project_qualityIconColr"
              alt=""
              srcset=""
              style={{
                float: "right",

                cursor: "pointer",
                fill: "black",
              }}
            />

            <FiFilter
              className="project_qualityIconColr"
              style={{
                float: "right",

                cursor: "pointer",
                fill: "black",
              }}
              onClick={() => setShow(!show)}
            />
          </div>
          <hr />

          {show ? (
            <div className="Quality_search-filter">
              {/* <Row className="Quality_sf"> */}

              <Row>
                <Col className="Quality_Col" lg={4} md={4} sm={4} xs={12}>
                  <div class="input-row-child flex flex-direction">
                    <label class="formlabel">Date Range</label>
                    <DateRangePicker
                      initialSettings={{
                        locale: {
                          format: "YYYY-MM-DD",
                        },
                        startDate: "2022-01-11",
                        endDate: "2022-01-12",
                      }}
                    >
                      <input
                        type="text"
                        className="dataPicker"
                        style={{
                          width: "100%",
                        }}
                      />
                    </DateRangePicker>
                  </div>
                </Col>
                <Col className="Quality_Col" lg={4} md={4} sm={4} xs={12}>
                  <div className="flex flex-direction">
                    <label class="formlabel" for="status">
                      Sample wastage
                    </label>
                    <select
                      name=""
                      id=""
                      className="formtext"
                      placeholder="SC Close"
                    >
                      <option value="">SC Close</option>
                      <option value="">SC Open</option>
                      <option value="">SC Pending</option>
                    </select>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={4} xs={12}>
                  <div className="Quality_subbtn flex flex-direction">
                    <button className="cbtn saveBtn">Submit</button>
                  </div>
                </Col>
              </Row>

              {/* </Row> */}
            </div>
          ) : null}
          <div className="mainContent mainContent2">
            <div className="table_container">
              <Table bordered={false} hover size="lg" className="customTable">
                <thead className="thead">
                  <tr>
                    <td>Project Name</td>
                    <td> Status</td>
                    <td>Total</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Project 3 - US Gen Pop 18+</td>

                    <td>Live</td>

                    <td>24</td>
                  </tr>
                  <tr>
                    <td>Project 3 - US Gen Pop 18+</td>

                    <td>Live</td>

                    <td>24</td>
                  </tr>
                  <tr>
                    <td>Project 3 - US Gen Pop 18+</td>

                    <td>Live</td>

                    <td>24</td>
                  </tr>
                  <tr>
                    <td>Project 3 - US Gen Pop 18+</td>

                    <td>Live</td>

                    <td>24</td>
                  </tr>
                  <tr>
                    <td>Project 3 - US Gen Pop 18+</td>

                    <td>Live</td>

                    <td>24</td>
                  </tr>
                  <tr>
                    <td>Project 3 - US Gen Pop 18+</td>

                    <td>Live</td>

                    <td>24</td>
                  </tr>
                  <tr>
                    <td>Project 3 - US Gen Pop 18+</td>

                    <td>Live</td>

                    <td>24</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <Paginations
            userlist={10}
            getPageSize={getPageSize}
            handlePageChnage={handlePageChnage}
          />
        </div>
      </div>
    </>
  );
};
export default Samplewastage;
