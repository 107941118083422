import "./Bidstopheader.css";
import { useNavigate } from "react-router";
const Bidstopheaderselect = (props) => {
  const clientid = props.clientid;
  const navigate = useNavigate();
  return (
    <>
    <div style={{padding:'0px 15px'}}>
      <select
        className="SelectHeader"
        value={props.namevalue}
        onChange={(e) => {
          if (e.target.value === "clients") {
            navigate(`/clients`);
          } else {
            if (e.target.value !== "select") {
              navigate(`/${e.target.value}/${clientid}`);
            }
          }
        }}
      >
        <option value="select">Select</option>
        <option value="clients"> Details</option>
        <option value="finance"> Finance details</option>
        <option value="client-contact"> Client contacts</option>
        <option value="region">Regions</option>
      </select>
      </div>
    </>
  );
};
export default Bidstopheaderselect;
