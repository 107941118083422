import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Navigate } from "react-router-dom";
import "./MailDescription.css";
import mailboxIco from "../../../assets/BidsIcons/bidMailbox.svg";
import { CreateNewFolder } from "@mui/icons-material";
import { MdDriveFileMoveOutline } from "react-icons/md";
import MailItem from "./MailItem";
import MoveTo from "../../../assets/BidsIcons/moveto.png";
import Reply from "../../../assets/BidsIcons/reply.svg";
import ReplyAll from "../../../assets/BidsIcons/replyAll.svg";
import Forward from "../../../assets/BidsIcons/forwardEmail.svg";
import DeleteIcon from "../../../assets/LoginIcons/delete.svg";
import "./Mailbox.css";
import ReplyBlock from "./ReplyBlock";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useParams } from "react-router";
import addIcon from "../../../assets/BidsIcons/add.svg";
import SpinnerLoader from "../../UI/Spinner";
import useAuth from "../../../hooks/useAuth";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import toast from "react-hot-toast";
const MailDescription = () => {
  const [emailData, setEmailData] = useState({ bid_id: null, is_read: 1 });
  const [cc, setCC] = useState([]);
  const [messageId, setMessageId] = useState();
  const [fromHeader, SetFromHeader] = useState([]);
  const [uid, setUID] = useState();
  const [r, setR] = useState();
  const axiosPrivate = useAxiosPrivate();
  const [subject, setSubjectsingle] = useState();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const { id, type } = useParams();
  const [showReplyTab, setReplyTab] = useState(true);
  const [body, setbody] = useState();
  const [replyFocus, setReplyFocus] = useState(false);
  const [showReplyBox, setReplyBox] = useState(false);
  const [lastEmailId, setLastEmailId] = useState();
  const [mailId, setMailId] = useState();
  const [folders, setFolders] = useState([]);
  const { auth } = useAuth();
  // ..........................permisssion.........
  const superUser = auth.superuser;
  const all_permission = auth.permission;
  const [anchorEl, setAnchorEl] = useState(null);
  const notifysuccess = (e) => toast.success(e);
  let filterValue = localStorage.getItem("filtername");
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function openReplyBlock(eid, l) {
    setReplyTab(false);
    var element = document.getElementById(eid);
    element.classList.add("show");

    const getEmail = async (id) => {
      if (l !== 3) {
        try {
          const response = await axiosPrivate.get(
            `mailbox/initialize-reply-email/${id}`,
            {}
          );

          if (l === 2) {
            setMessageId(response.data.msg_detail.message_id);
            setUID(response.data.msg_detail.uid);
            setbody(response.data.body_html);
            let dataTo = response.data.reply_to_all.to.split(",");
            SetFromHeader([]);
            dataTo.map((ele) => SetFromHeader((val) => [...val, ele]));
            if (response.data.reply_to_all.cc !== "") {
              const newString = response.data.reply_to_all.cc.replace(/^,/, "");
              let datacc = newString.split(",");
              setCC([]);
              datacc.map((ele) => setCC((val) => [...val, ele]));
            }
          } else if (l === 1) {
            setMessageId(response.data.msg_detail.message_id);
            setUID(response.data.msg_detail.uid);
            setbody(response.data.body_html);

            let dataTo = response.data.reply_to.to.split(",");

            SetFromHeader([]);
            dataTo.map((ele) => SetFromHeader((val) => [...val, ele]));
            if (response.data.msg_detail.cc !== "") {
              const newString = response.data.msg_detail.cc.replace(/^,/, "");
              let datacc = newString.split(",");
              setCC([]);
              datacc.map((ele) => setCC((val) => [...val, ele]));
            }
          }

          if (response.data.msg_detail.subject) {
            setSubjectsingle(response.data.msg_detail.subject);
          }

          setUID(response.data.msg_detail.uid);

          setbody(response.data.body_html);
        } catch (err) {}
      } else {
        try {
          const response = await axiosPrivate.get(
            `mailbox/initialize-forward-email/${id}`,
            {}
          );
          setMessageId(response.data.msg_detail.message_id);
          setUID(response.data.msg_detail.uid);
          if (response.data.msg_detail.subject) {
            setSubjectsingle(response.data.msg_detail.subject);
          }
          setbody(response.data.body_html);
        } catch (err) {}
      }
    };

    getEmail(lastEmailId);
  }

  const hideBlock = (eid) => {
    var element = document.getElementById(eid);
    element.classList.remove("show");
    setReplyTab(true);
  };

  useEffect(() => {
    emailDetail();
    var value = localStorage.getItem("filtername");

    if (value == "archived") {
      setFolders(["Inbox"]);
    }
    if (value == "inbox") {
      setFolders(["Archive"]);
    }
    if (value == "sent") {
      setFolders(["Inbox", "Archive"]);
    }
    if (value == "unassigned") {
      setFolders(["Archive"]);
    }
    if (value == "assigned") {
      setFolders(["Archive"]);
    }
    if (value == "starred") {
      setFolders(["Inbox", "Archive"]);
    }
    if (value === "" || value === undefined || value === null) {
      setFolders(["Inbox", "Archive"]);
    }
    // if (value == "starred") {
    //   setFolders(["Inbox", "Archive"]);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailDetail = async () => {
    try {
      const response = await axiosPrivate.get(
        `/mailbox/message-desc-list/${id}/simple`,
        {}
      );

      response.data.items.map((items, index) => {
        if (index === response.data.items.length - 1) {
          setLastEmailId(items.id);
        }
      });

      setSubjectsingle(
        response.data.items[response.data.items.length - 1].subject
      );
      // setMailId(response.data.items[response.data.items.length - 1].mailbox_id);
      setEmailData({
        bid_id: response.data.items[response.data.items.length - 1].bid_id,
        is_read: response.data.items[response.data.items.length - 1].is_read,
      });
      setData(response.data.items);
      setMailId(response.data.items[0].mailbox_id);

      if (
        response.data.items[response.data.items.length - 1].attachments !==
          null ||
        response.data.items[response.data.items.length - 1].inline_attachments
      ) {
        setAttachments(
          response.data.items[response.data.items.length - 1].attachments
        );
      }
    } catch (err) {}
  };

  const Moveto = async (foldername) => {
    // SetDeleteCount(0);
    // setIsCheck([]);
    try {
      await axiosPrivate
        .post(
          "/mailbox/move-to",
          JSON.stringify({
            msg_ids: [id],
            folder: foldername,
            mailbox_id: mailId,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysuccess(`Mail ${foldername} successfully`);
            navigate("/mailbox");
          }
        });

      // SetDeleteCount(0);
    } catch (err) {}
  };

  return (
    <>
      <div className="top_container">
        <div className="main_container clearfix">
          <div className="user_serachbox">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={mailboxIco} className="headingIcons" alt="" /> Mailbox
              </h3>
            </div>
            <div className="right-pane">
              <button
                className="cbtn closeBtn"
                onClick={() => {
                  navigate("/mailbox");
                }}
              >
                {" "}
                Close
              </button>
              {superUser === true ? (
                <>
                  <button
                    className="cbtn addBtn"
                    onClick={() => {
                      navigate("/create-bid/" + id);
                    }}
                  >
                    <img src={addIcon} alt="" /> &nbsp; Create Bid
                  </button>
                </>
              ) : (
                <>
                  {all_permission.includes("bid.bid_create") ? (
                    <>
                      <button
                        className="cbtn addBtn"
                        onClick={() => {
                          navigate("/create-bid/" + id);
                        }}
                      >
                        <img src={addIcon} alt="" /> &nbsp; Create Bid
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {filterValue === "all" ? (
                <></>
              ) : (
                <>
                  <span
                    title="Move to"
                    style={{
                      float: "right",
                      marginRight: "10px",
                      cursor: "pointer",
                      background: "#ddd",
                      padding: "0px",
                      borderRadius: "2px",
                      width: "14%",
                      textAlign: "center",
                    }}
                  >
                    <MdDriveFileMoveOutline
                      title="Move to"
                      style={{
                        margin: "3px 5px",
                        cursor: "pointer",
                        fontSize: "25px",
                      }}
                      onClick={handleClick}
                    />
                  </span>
                </>
              )}

              <Menu
                keepMounted
                anchorEl={anchorEl}
                onClose={handleClose}
                open={Boolean(anchorEl)}
              >
                {folders.map((iteml) => (
                  <MenuItem
                    onClick={(e) => {
                      handleClose(e);

                      Moveto(iteml.toLowerCase());
                    }}
                  >
                    {iteml}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
          <hr />
          <div className="mail-subject">
            <>
              <h4>
                {subject}
                {emailData.bid_id !== null ? (
                  <span className="status-badge badge-bidding">
                    BID CREATED
                  </span>
                ) : (
                  ""
                )}
              </h4>
              <hr />
            </>
          </div>
          <div className="mail-body">
            {superUser === true ? (
              <>
                {data.length !== 0 ? (
                  data.map((emailItem, index) => (
                    <MailItem
                      id={index}
                      data={emailItem}
                      length={data.length}
                      attachments={attachments}
                      setReplyTab={setReplyTab}
                      setReplyBox={setReplyBox}
                    />
                  ))
                ) : (
                  <SpinnerLoader />
                )}
              </>
            ) : (
              <>
                {all_permission.includes("mailbox.mailbox_message_desc") ? (
                  <>
                    {data.length !== 0 ? (
                      data.map((emailItem, index) => (
                        <MailItem
                          id={index}
                          data={emailItem}
                          length={data.length}
                          attachments={attachments}
                          setReplyTab={setReplyTab}
                          setReplyBox={setReplyBox}
                        />
                      ))
                    ) : (
                      <SpinnerLoader />
                    )}
                  </>
                ) : (
                  <Navigate to="/unauthorized" replace />
                )}
              </>
            )}
          </div>

          {showReplyTab ? (
            <div className={"mail-action "} style={{ height: "40px" }}>
              {superUser === true ? (
                <>
                  <button
                    className="mail-action-btn"
                    onClick={(e) => {
                      openReplyBlock("main", 1);
                      setR(1);
                      setReplyFocus(true);
                      setReplyBox(true);
                    }}
                  >
                    <img src={Reply} height={10} alt="Reply" /> Reply
                  </button>

                  <button
                    className="mail-action-btn"
                    onClick={(e) => {
                      openReplyBlock("main", 2);
                      setR(2);
                      setReplyFocus(true);
                      setReplyBox(true);
                    }}
                  >
                    <img src={ReplyAll} height={10} alt="ReplyAll" /> Reply All
                  </button>
                </>
              ) : (
                <>
                  {all_permission.includes("mailbox.mailbox_reply_email") ? (
                    <>
                      <button
                        className="mail-action-btn"
                        onClick={(e) => {
                          openReplyBlock("main", 1);
                          setR(1);
                          setReplyFocus(true);
                          setReplyBox(true);
                        }}
                      >
                        <img src={Reply} height={10} alt="Reply" /> Reply
                      </button>

                      <button
                        className="mail-action-btn"
                        onClick={(e) => {
                          openReplyBlock("main", 2);
                          setR(2);
                          setReplyFocus(true);
                          setReplyBox(true);
                        }}
                      >
                        <img src={ReplyAll} height={10} alt="ReplyAll" /> Reply
                        All
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}

              {superUser === true ? (
                <>
                  <button
                    className="mail-action-btn"
                    onClick={(e) => {
                      openReplyBlock("main", 3);
                      setR(3);
                      setReplyFocus(true);
                      setReplyBox(true);
                    }}
                  >
                    <img src={Forward} height={10} alt="Forward" /> Forward
                  </button>
                </>
              ) : (
                <>
                  {all_permission.includes("mailbox.mailbox_forward_email") ? (
                    <>
                      <button
                        className="mail-action-btn"
                        onClick={(e) => {
                          openReplyBlock("main", 3);
                          setR(3);
                          setReplyFocus(true);
                          setReplyBox(true);
                        }}
                      >
                        <img src={Forward} height={10} alt="Forward" /> Forward
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          ) : (
            <></>
          )}

          <div className="replyBlockContainer mtop" id="main">
            {showReplyBox ? (
              <>
                <ReplyBlock
                  fromHeader={fromHeader}
                  uid={uid}
                  messageId={messageId}
                  subject={subject}
                  cc={cc}
                  thread={type}
                  r={r}
                  id={lastEmailId}
                  attachments={attachments}
                  body={body}
                  setReplyFocus={setReplyFocus}
                  replyFocus={replyFocus}
                  setReplyBox={setReplyBox}
                  showReplyBox={showReplyBox}
                  setReplyTab={setReplyTab}
                  showReplyTab={showReplyTab}
                />

                <span
                  className="Close"
                  onClick={(e) => {
                    hideBlock("main");
                    setReplyFocus(false);
                  }}
                >
                  <img src={DeleteIcon} alt="Delete" height={20} />
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MailDescription;
