import TablePagination from "@mui/material/TablePagination";
import "./Paginations.css";
import { useEffect, useState } from "react";

const Mailboxpaginations = (props) => {
  const userdata = props.userlist;

  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("donot", true);
    props.handlePageChnage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));

    props.getPageSize(event.target.value, 0);
  };
  let stat = localStorage.getItem("statepage");
  let searchStat = localStorage.getItem("search");
  let filterLocal = localStorage.getItem("filter");
  let resetFilter = localStorage.getItem("ResetfilterState");
  let resetsearch = localStorage.getItem("ResetsearchPage");

  useEffect(() => {
    if (props.refreshState === true) {
      setPage(0);
      props.setRefreshState(false);
    }
    // localStorage.setItem("refreshmail", false);
  }, [props.refreshState]);
  useEffect(() => {
    if (searchStat == "true") {
      let npage = localStorage.getItem("rowperpage");
      let psize = localStorage.getItem("perpage");

      setPage(npage - 1);
      setRowsPerPage(parseInt(psize));
    }
    if (filterLocal == "true") {
      let npage = localStorage.getItem("rowperpage");
      let psize = localStorage.getItem("perpage");
      setPage(npage - 1);
      setRowsPerPage(parseInt(psize));
    }
    if (resetFilter === "true" || resetsearch === "true") {
      setPage(0);

      localStorage.setItem("ResetsearchPage", false);
      localStorage.setItem("ResetfilterState", false);
    }
  }, [userdata]);

  return (
    <>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        sx={{
          ".MuiTablePagination-selectIcon": {
            color: "#1a1a1a",
          },
          ".MuiTouchRipple-root": {
            width: "20px",
            height: "20px",
            marginTop: "10px",
            marginLeft: "10px",
          },
        }}
        component="div"
        count={userdata}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
export default Mailboxpaginations;
