import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import "bootstrap/dist/css/bootstrap.css";
// you will also need the css that comes with bootstrap-daterangepicker
import "../../UI/DateRangePicker.css";

import Header from "../../UI/Header/Header";
import Multiselect from "multiselect-react-dropdown";

import calendar from "../../../assets/BidsIcons/calendarIco.png";

import composeIco from "../../../assets/BidsIcons/composeEmail.svg";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import AccountWiseReport from "./AccountWiseReport";
import TotalBidsStatus from "./TotalBidsStatus";

import "./Dashboard.css";
import LineChart from "../../UI/Graphs/LineMultiple";
import TeamPerformance from "./TeamPerformance";
import RevenueShare from "./RevenueShare";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import moment from "moment";
import useAuth from "../../../hooks/useAuth";
import Spinner from "react-bootstrap/Spinner";
const BidsDashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const [User, setUser] = useState([]);
  const [data, setdata] = useState();
  const [apiCall, setApiCall] = useState(false);
  const [flag, setFlag] = useState(false);
  const [state, setState] = useState();
  const [revenueState, setRevenueState] = useState();
  const [teamperfState, setTeamPerfState] = useState();
  const [bidBooked, setBidBooked] = useState();
  const [account, setAccount] = useState();

  const [placeholder, setPlaceHolder] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { auth } = useAuth();

  // ..........................permisssion.........
  const superUser = auth.superuser;
  const all_permission = auth.permission;
  let newCurrentDate = moment().format("YYYY-MM-DD");
  let newEndDate = moment().subtract(7, "days").format("YYYY-MM-DD");
  const bid_number = 2;
  const handleCallback = (start, end) => {
    let CurrentDate = moment(start._d).format("YYYY-MM-DD");
    let EndDate = moment(end._d).format("YYYY-MM-DD");

    setState({ CurrentDate, EndDate });
  };

  const handleCallbackRevenue = (start, end) => {
    let CurrentDate = moment(start._d).format("YYYY-MM-DD");
    let EndDate = moment(end._d).format("YYYY-MM-DD");

    setRevenueState({ CurrentDate, EndDate });
  };

  const handleCallbackTeam = (start, end) => {
    let CurrentDate = moment(start._d).format("YYYY-MM-DD");
    let EndDate = moment(end._d).format("YYYY-MM-DD");

    setTeamPerfState({ CurrentDate, EndDate });
  };

  const handleCallbackBidBooked = (start, end) => {
    let CurrentDate = moment(start._d).format("YYYY-MM-DD");
    let EndDate = moment(end._d).format("YYYY-MM-DD");

    setBidBooked({ CurrentDate, EndDate });
  };
  const handleCallbackAccount = (start, end) => {
    let CurrentDate = moment(start._d).format("YYYY-MM-DD");
    let EndDate = moment(end._d).format("YYYY-MM-DD");

    setAccount({ CurrentDate, EndDate });
  };

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      try {
        const response = await axiosPrivate.get(
          `user/user-department/${bid_number}`,
          {}
        );
        isMounted && setUser(response.data.all_users);
      } catch (err) {}
    };
    getData();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    setIsSubmitting(true);
    setFlag(true);
    setApiCall(true);
    setIsSubmitting(false);
  };

  return (
    <>
      {/* <Header /> */}
      <div className="top_container dashboard">
        <div
          className="main_container"
          style={{
            margin: "0px",
            width: "100%",
            boxShadow: "none",
            background: "#eee",
            padding: "0px 20px 5px",
          }}
        >
          <Row style={{ textAlign: "right", marginBottom: "6px" }}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img src={composeIco} className="headingIcons" alt="" />{" "}
                  Dashboard
                </h3>
              </div>
              <div className="right-pane right-pane2">
                <div className="button-container ">
                  <button
                    disabled={isSubmitting}
                    className="cbtn saveBtn mr-0"
                    onClick={(e) => {
                      handleSubmit(e);
                      // setIsSubmitting(true);
                    }}
                  >
                    {isSubmitting && (
                      <Spinner
                        as="span"
                        style={{ color: "white" }}
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    &nbsp;Submit
                  </button>
                </div>
                <div className="dropdownContainer dropdownContainer2">
                  <div
                    style={{
                      width: "220px",
                      float: "right",
                      marginRight: "28px",
                    }}
                  >
                    <Multiselect
                      className="userDropdown"
                      // hideSelectedList
                      displayValue="name"
                      onKeyPressFn={function noRefCheck() {}}
                      onRemove={function noRefCheck(e) {
                        setPlaceHolder(true);
                        setdata(e);
                        if (e.length === 0) {
                          setPlaceHolder(false);
                        }
                      }}
                      onSearch={function noRefCheck() {}}
                      onSelect={function noRefCheck(e) {
                        setdata(e);
                        setPlaceHolder(true);
                      }}
                      options={User}
                      placeholder={placeholder ? "" : "Select User:"}
                      showCheckbox
                    />
                  </div>
                </div>
              </div>
            </div>
          </Row>

          <Row>
            {superUser === true ? (
              <>
                <Col lg={4} md={6} sm={6} xs={12}>
                  <div className="card">
                    <div className="card-header">
                      <Row>
                        <Col>
                          <h5 className="cardHeading">Total Bids Status</h5>
                        </Col>
                        <Col className="bl1">
                          <img src={calendar} alt="" className="calendarIcon" />
                          <DateRangePicker
                            initialSettings={{
                              locale: {
                                format: "YYYY-MM-DD",
                              },
                              startDate: newEndDate,
                              endDate: newCurrentDate,
                            }}
                            onCallback={handleCallback}
                          >
                            <input type="text" className="form-control" />
                          </DateRangePicker>
                        </Col>
                      </Row>
                    </div>
                    <div className="card-body mx-15">
                      {flag ? (
                        <TotalBidsStatus
                          state={state}
                          data={data}
                          setFlag={setFlag}
                          setApiCall={setApiCall}
                          apiCall={apiCall}
                        />
                      ) : (
                        <TotalBidsStatus state={state} />
                      )}
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <>
                {all_permission.includes("bid.dashboard_total_bid_status") ? (
                  <>
                    <Col lg={4} md={6} sm={6} xs={12}>
                      <div className="card">
                        <div className="card-header">
                          <Row>
                            <Col>
                              <h5 className="cardHeading">Total Bids Status</h5>
                            </Col>
                            <Col className="bl1">
                              <img
                                src={calendar}
                                alt=""
                                className="calendarIcon"
                              />
                              <DateRangePicker
                                initialSettings={{
                                  locale: {
                                    format: "YYYY-MM-DD",
                                  },
                                  startDate: newEndDate,
                                  endDate: newCurrentDate,
                                }}
                                onCallback={handleCallback}
                              >
                                <input type="text" className="form-control" />
                              </DateRangePicker>
                            </Col>
                          </Row>
                        </div>
                        <div className="card-body mx-15">
                          {flag ? (
                            <TotalBidsStatus
                              state={state}
                              data={data}
                              setFlag={setFlag}
                              setApiCall={setApiCall}
                              apiCall={apiCall}
                            />
                          ) : (
                            <TotalBidsStatus state={state} />
                          )}
                        </div>
                      </div>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            {/* {
        superUser===true?(<></>):(<>
        {
          all_permission.includes("")?(<></>):(<></>)
        }
        </>)
      } */}

            {superUser === true ? (
              <>
                <Col lg={4} md={6} sm={6} xs={12}>
                  <div className="card">
                    <div className="card-header">
                      <Row>
                        <Col>
                          <h5 className="cardHeading">Team Performance</h5>
                        </Col>
                        <Col className="bl1">
                          <img src={calendar} alt="" className="calendarIcon" />
                          <DateRangePicker
                            initialSettings={{
                              locale: {
                                format: "YYYY-MM-DD",
                              },
                              startDate: newEndDate,
                              endDate: newCurrentDate,
                            }}
                            onCallback={handleCallbackTeam}
                          >
                            <input type="text" className="form-control" />
                          </DateRangePicker>
                        </Col>
                      </Row>
                    </div>
                    <div
                      className="card-body"
                      style={{
                        overflow: "auto scroll",
                      }}
                    >
                      {flag ? (
                        <TeamPerformance
                          teamperfState={teamperfState}
                          data={data}
                          setFlag={setFlag}
                          setApiCall={setApiCall}
                          apiCall={apiCall}
                        />
                      ) : (
                        <TeamPerformance teamperfState={teamperfState} />
                      )}
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <>
                {all_permission.includes("bid.dashboard_team_performance") ? (
                  <>
                    <Col lg={4} md={6} sm={6} xs={12}>
                      <div className="card">
                        <div className="card-header">
                          <Row>
                            <Col>
                              <h5 className="cardHeading">Team Performance</h5>
                            </Col>
                            <Col className="bl1">
                              <img
                                src={calendar}
                                alt=""
                                className="calendarIcon"
                              />
                              <DateRangePicker
                                initialSettings={{
                                  locale: {
                                    format: "YYYY-MM-DD",
                                  },
                                  startDate: newEndDate,
                                  endDate: newCurrentDate,
                                }}
                                onCallback={handleCallbackTeam}
                              >
                                <input type="text" className="form-control" />
                              </DateRangePicker>
                            </Col>
                          </Row>
                        </div>
                        <div className="card-body">
                          {flag ? (
                            <TeamPerformance
                              teamperfState={teamperfState}
                              data={data}
                              setFlag={setFlag}
                              setApiCall={setApiCall}
                              apiCall={apiCall}
                            />
                          ) : (
                            <TeamPerformance teamperfState={teamperfState} />
                          )}
                        </div>
                      </div>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            {superUser === true ? (
              <>
                <Col lg={4} md={12} sm={12} xs={12}>
                  <div className="card">
                    <div className="card-header">
                      <Row>
                        <Col>
                          <h5 className="cardHeading">Bid vs Booked</h5>
                        </Col>

                        <Col className="bl1">
                          <img src={calendar} alt="" className="calendarIcon" />
                          <DateRangePicker
                            initialSettings={{
                              locale: {
                                format: "YYYY-MM-DD",
                              },
                              startDate: newEndDate,
                              endDate: newCurrentDate,
                            }}
                            onCallback={handleCallbackBidBooked}
                          >
                            <input type="text" className="form-control" />
                          </DateRangePicker>
                        </Col>
                      </Row>
                    </div>
                    <div className="card-body card-body2  mx-15">
                      <div style={{ height: "auto" }}>
                        {flag ? (
                          <LineChart
                            bidBooked={bidBooked}
                            data={data}
                            setFlag={setFlag}
                            setApiCall={setApiCall}
                            apiCall={apiCall}
                          />
                        ) : (
                          <LineChart bidBooked={bidBooked} />
                        )}
                      </div>
                      <Row style={{ marginTop: "10px" }}>
                        <p
                          className="legend"
                          style={{ width: "40%", float: "left" }}
                        >
                          <span
                            style={{
                              background: "rgba(255, 99, 132, 0.9)",
                            }}
                          ></span>{" "}
                          Bid Revenue
                        </p>
                        <p
                          className="legend"
                          style={{ width: "50%", float: "left" }}
                        >
                          <span
                            style={{
                              background: "rgba(54, 162, 235, 0.9)",
                            }}
                          ></span>{" "}
                          Booked Revenue
                        </p>
                      </Row>
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <>
                {all_permission.includes("bid.dashboard_bid_booked") ? (
                  <>
                    <Col lg={4} md={12} sm={12} xs={12}>
                      <div className="card">
                        <div className="card-header">
                          <Row>
                            <Col>
                              <h5 className="cardHeading">Bid vs Booked</h5>
                            </Col>

                            <Col className="bl1">
                              <img
                                src={calendar}
                                alt=""
                                className="calendarIcon"
                              />
                              <DateRangePicker
                                initialSettings={{
                                  locale: {
                                    format: "YYYY-MM-DD",
                                  },
                                  startDate: newEndDate,
                                  endDate: newCurrentDate,
                                }}
                                onCallback={handleCallbackBidBooked}
                              >
                                <input type="text" className="form-control" />
                              </DateRangePicker>
                            </Col>
                          </Row>
                        </div>
                        <div className="card-body card-body2  mx-15">
                          <div style={{ height: "auto" }}>
                            {flag ? (
                              <LineChart
                                bidBooked={bidBooked}
                                data={data}
                                setFlag={setFlag}
                                setApiCall={setApiCall}
                                apiCall={apiCall}
                              />
                            ) : (
                              <LineChart bidBooked={bidBooked} />
                            )}
                          </div>
                          <Row style={{ marginTop: "10px" }}>
                            <p
                              className="legend"
                              style={{ width: "40%", float: "left" }}
                            >
                              <span
                                style={{
                                  background: "rgba(255, 99, 132, 0.9)",
                                }}
                              ></span>{" "}
                              Bid Revenue
                            </p>
                            <p
                              className="legend"
                              style={{ width: "50%", float: "left" }}
                            >
                              <span
                                style={{
                                  background: "rgba(54, 162, 235, 0.9)",
                                }}
                              ></span>{" "}
                              Booked Revenue
                            </p>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Row>
          <Row>
            {superUser === true ? (
              <>
                <Col lg={6} md={6} sm={6} xs={12}>
                  <div className="card">
                    <div className="card-header">
                      <Row>
                        <Col>
                          <h5 className="cardHeading">Account Wise Report</h5>
                        </Col>
                        <Col className="bl1">
                          <img src={calendar} alt="" className="calendarIcon" />
                          <DateRangePicker
                            initialSettings={{
                              locale: {
                                format: "YYYY-MM-DD",
                              },
                              startDate: newEndDate,
                              endDate: newCurrentDate,
                            }}
                            onCallback={handleCallbackAccount}
                          >
                            <input type="text" className="form-control" />
                          </DateRangePicker>
                        </Col>
                      </Row>
                    </div>
                    <div
                      className="card-body"
                      style={{
                        height: "206px",
                        maxHeight: "208px",
                        overflow: "auto scroll",
                      }}
                    >
                      {flag ? (
                        <AccountWiseReport
                          account={account}
                          data={data}
                          setFlag={setFlag}
                          setApiCall={setApiCall}
                          apiCall={apiCall}
                        />
                      ) : (
                        <AccountWiseReport account={account} />
                      )}
                    </div>
                    <div className="card-footer">
                      <button className="view">
                        {/* <img src={view} height="20" /> View Report */}
                      </button>
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <>
                {all_permission.includes(
                  "bid.dashboard_account_wise_report"
                ) ? (
                  <>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <div className="card">
                        <div className="card-header">
                          <Row>
                            <Col>
                              <h5 className="cardHeading">
                                Account Wise Report
                              </h5>
                            </Col>
                            <Col className="bl1">
                              <img
                                src={calendar}
                                alt=""
                                className="calendarIcon"
                              />
                              <DateRangePicker
                                initialSettings={{
                                  locale: {
                                    format: "YYYY-MM-DD",
                                  },
                                  startDate: newEndDate,
                                  endDate: newCurrentDate,
                                }}
                                onCallback={handleCallbackAccount}
                              >
                                <input type="text" className="form-control" />
                              </DateRangePicker>
                            </Col>
                          </Row>
                        </div>
                        <div
                          className="card-body"
                          style={{
                            height: "206px",
                            maxHeight: "208px",
                            overflow: "auto scroll",
                          }}
                        >
                          {flag ? (
                            <AccountWiseReport
                              account={account}
                              data={data}
                              setFlag={setFlag}
                              setApiCall={setApiCall}
                              apiCall={apiCall}
                            />
                          ) : (
                            <AccountWiseReport account={account} />
                          )}
                        </div>
                        <div className="card-footer">
                          <button className="view">
                            {/* <img src={view} height="20" /> View Report */}
                          </button>
                        </div>
                      </div>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            {superUser === true ? (
              <>
                <Col lg={6} md={6} sm={6} xs={12}>
                  <div className="card">
                    <div className="card-header">
                      <Row>
                        <Col>
                          <h5 className="cardHeading">Revenue Share</h5>
                        </Col>
                        <Col className="bl1">
                          <img src={calendar} alt="" className="calendarIcon" />
                          <DateRangePicker
                            initialSettings={{
                              locale: {
                                format: "YYYY-MM-DD",
                              },
                              startDate: newEndDate,
                              endDate: newCurrentDate,
                            }}
                            onCallback={handleCallbackRevenue}
                          >
                            <input type="text" className="form-control" />
                          </DateRangePicker>
                        </Col>
                      </Row>
                    </div>
                    <div
                      className="card-body"
                      style={{ overflow: "auto scroll" }}
                    >
                      {flag ? (
                        <RevenueShare
                          revenueState={revenueState}
                          data={data}
                          setFlag={setFlag}
                          setApiCall={setApiCall}
                          apiCall={apiCall}
                          setIsSubmitting={setIsSubmitting}
                        />
                      ) : (
                        <RevenueShare revenueState={revenueState} />
                      )}
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <>
                {all_permission.includes("bid.dashboard_revenue_share") ? (
                  <>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <div className="card">
                        <div className="card-header">
                          <Row>
                            <Col>
                              <h5 className="cardHeading">Revenue Share</h5>
                            </Col>
                            <Col className="bl1">
                              <img
                                src={calendar}
                                alt=""
                                className="calendarIcon"
                              />
                              <DateRangePicker
                                initialSettings={{
                                  locale: {
                                    format: "YYYY-MM-DD",
                                  },
                                  startDate: newEndDate,
                                  endDate: newCurrentDate,
                                }}
                                onCallback={handleCallbackRevenue}
                              >
                                <input type="text" className="form-control" />
                              </DateRangePicker>
                            </Col>
                          </Row>
                        </div>
                        <div
                          className="card-body"
                          style={{ overflow: "auto scroll" }}
                        >
                          {flag ? (
                            <RevenueShare
                              revenueState={revenueState}
                              data={data}
                              setFlag={setFlag}
                              setApiCall={setApiCall}
                              apiCall={apiCall}
                              setIsSubmitting={setIsSubmitting}
                            />
                          ) : (
                            <RevenueShare revenueState={revenueState} />
                          )}
                        </div>
                      </div>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};
export default BidsDashboard;
