import Header from "../../UI/Header/Header";

import Table from "react-bootstrap/Table";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import "../BidsTable/BidsTable.css";
import mailboxIco from "../../../assets/BidsIcons/bidMailbox.svg";
import addIcon from "../../../assets/BidsIcons/add.svg";
import moment from "moment";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TableCell, TableRow, TableBody } from "@mui/material";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MoveTo from "../../../assets/BidsIcons/moveto.png";
import RefreshIcon from "../../../assets/BidsIcons/refresh.svg";
import AttachmentIcon from "../../../assets/BidsIcons/link.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Modal from "react-modal";
import BidsSubHeader from "../BidsSubHeader/BidsSubHeader";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Paginations from "../../Paginations/Paginations";
import SpinnerLoader from "../../UI/Spinner";
import DeleteIcon from "@mui/icons-material/Delete";
import { MultiSelect } from "react-multi-select-component";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
import searchIcon from "../../../assets/images/icons8-search-60.png";
import useAuth from "../../../hooks/useAuth";
import Mailboxpaginations from "../../Paginations/Mailboxpagination";
import { MdDriveFileMoveOutline } from "react-icons/md";
const Mailbox = () => {
  moment.locale("en");
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [shownewbid, setshowNewBid] = useState(null);
  const [Bidcreate, setBidCreate] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElUserBid, setAnchorElUserBid] = useState(null);
  const [anchorElUse, setAnchorElUse] = useState(null);
  const [placeholder, setPlaceHolder] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selecteduser, setSelectedUser] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  // const bidcreated = ["Assign Email", "Update Bid"];
  const bidcreated = [
    {
      code: "mailbox.mailbox_assign_email",
      title_name: "Assign Email",
    },
    {
      code: "bid.bid_update",
      title_name: "Update Bid",
    },
  ];
  // const newbid = ["Assign Email", "Create Bid"];
  const newbid = [
    {
      code: "mailbox.mailbox_assign_email",
      title_name: "Assign Email",
    },

    {
      code: "bid.bid_create",
      title_name: "Create Bid",
    },
  ];
  const [refreshState, setRefreshState] = useState(false);
  const [userSearch, setUserSearch] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [starTitle, setstarTitle] = useState();
  const { auth } = useAuth();
  // ..........................permisssion.........
  const superUser = auth.superuser;
  const all_permission = auth.permission;
  const bid_number = 2;

  const [folders, setFolders] = useState(["Archive"]);
  const [folderId, setFolderId] = useState();
  const [onerend, setOnernd] = useState(true);
  const [data, setData] = useState([]);
  const [userlist, setUserLIst] = useState([]);
  const [assignMailId, setAssignMailId] = useState([]);
  const [emailId, setEmailId] = useState([]);
  const [pagesize, setPagesize] = useState(50);
  const [nextPage, setNextPage] = useState(1);
  const [dataCount, setDataCount] = useState();
  const [dataLoader, setDataLoader] = useState(true);
  const [deleteCount, SetDeleteCount] = useState(0);
  const [searchItem, setSearchItem] = useState("");
  const [filterName, setFilterName] = useState("inbox");
  const [hideCount, setHideCount] = useState(0);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [mailboxId, setMailBoxId] = useState();
  const [anchorElFolder, setAnchorElFolder] = useState(false);
  // localStorage.setItem("setUsersearchpage", 1);
  let filterLocal = localStorage.getItem("filter");
  let filterLOcalName = localStorage.getItem("filtername");
  let stat = localStorage.getItem("statepage");
  let searchLOcalName = localStorage.getItem("searchItem");
  let searchStat = localStorage.getItem("search");
  let donot = localStorage.getItem("donot");
  // localStorage.setItem("filterdrpSearch", 1);
  let MoveTo = JSON.parse(localStorage.getItem("moveTo"));
  const notifysuccess = (e) => toast.success(e);
  useEffect(() => {
    setList(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const getPageSize = (size) => {
    setPagesize(size);

    setNextPage(1);
    setIsCheck([]);
    setIsCheckAll(false);
    SetDeleteCount(0);
  };

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
    localStorage.setItem("donot", true);
    setIsCheck([]);
    setIsCheckAll(false);
    SetDeleteCount(0);
  };
  window.onbeforeunload = function (event) {
    localStorage.setItem("donot", true);
  };

  useEffect(() => {
    // window.onload(localStorage.setItem("donot", true));
    if (filterLocal === "true" || searchStat === "true") {
      handlemailsearch(searchLOcalName, filterLOcalName);

      allUserlist();
    } else if (
      (userSearch.length !== 0 && filterName) ||
      (userSearch.length !== 0 && searchItem)
    ) {
      allUserlist();
      handleUserMail(userSearch, searchItem, filterName);
    } else if (
      filterName !== undefined &&
      donot !== "false" &&
      userSearch.length === 0
    ) {
      localStorage.setItem("donot", true);
      handlemailsearch(searchItem, filterName);

      allUserlist();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesize, nextPage]);

  const allUserlist = async () => {
    try {
      const response = await axiosPrivate.get(
        `user/user-department/${bid_number}`,
        {}
      );

      // isMounted && setUserLIst(response.data.all_users);
      let data = response.data.all_users;

      var clientlist = [...data];
      const clientdata = clientlist.map((item, i) =>
        Object.assign(item, {
          value: parseInt(item._id),
          label: item.name,
        })
      );
      setUserLIst(clientdata);
    } catch (err) {
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const getRoleList = async () => {
    setDataLoader(true);

    try {
      const response = await axiosPrivate.get(
        `/mailbox/inbox?page=${nextPage}&size=${pagesize}&sort=-uid`,
        {}
      );
      setData(response.data.items);
      setRefreshState(false);
      setDataCount(response.data.total);
    } catch (err) {}
    setDataLoader(false);
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "350px",
    },
  };

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setAnchorElUse(null);
    setAnchorElFolder(null);
    setAnchorElUserBid(null);
  };
  const handleOpenFolderMenu = (event) => {
    setAnchorElFolder(event.currentTarget);
  };
  const handleOpenUserMenu = (event, bid_id) => {
    bid_id !== null ? setBidCreate(bid_id) : setBidCreate(false);
    setAnchorElUser(event.currentTarget);
    setAnchorElUse(event.currentTarget);
    setAnchorElUserBid(event.currentTarget);
  };

  const handleOpeNewbid = () => {
    setshowNewBid(true);
    setBidCreate(false);
  };
  const handleOptionEdit = (index, bid_id) => {
    if (index === 0) {
      setModalIsOpen(true);
    }
    if (index === 1) {
      navigate("/update-bid/" + bid_id);
    }
  };
  const handleOptionNew = (index, mid) => {
    if (index === 0) {
      setModalIsOpen(true);
    }
    if (index === 1) {
      navigate("/create-bid/" + mid);
    }
  };

  const handleSelectAll = (e) => {
    SetDeleteCount(0);

    data.map((element) => {
      setMailBoxId(element.mailbox_id);
    });

    setIsCheckAll(!isCheckAll);
    setIsCheck(data.map((li) => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleSingleSelect = (e, msgid, mailid) => {
    setIsCheckAll(false);

    if (e.target.checked === true) {
      setMailBoxId(mailid);
      SetDeleteCount(deleteCount + 1);
    } else {
      SetDeleteCount(deleteCount - 1);
    }

    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const ArchiveMail = async () => {
    SetDeleteCount(0);
    setIsCheck([]);
    try {
      await axiosPrivate
        .post(
          "/mailbox/archive-email",
          JSON.stringify({
            msg_ids: isCheck,
            mailbox_id: mailboxId,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysuccess("Mail archived successfully");
            getRoleList();
            allUserlist();
          }
        });

      SetDeleteCount(0);
    } catch (err) {}
  };

  const Moveto = async (foldername) => {
    SetDeleteCount(0);
    setIsCheck([]);
    try {
      await axiosPrivate
        .post(
          "/mailbox/move-to",
          JSON.stringify({
            msg_ids: isCheck,
            folder: foldername,
            mailbox_id: mailboxId,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysuccess(`Mail ${foldername} successfully`);
            // getRoleList();
            setIsCheck([]);
            setIsCheckAll(false);
            allUserlist();
            handlemailsearch("", filterName);
          }
        });

      SetDeleteCount(0);
    } catch (err) {}
  };

  const ImageCreater = (a) => {
    var data = a;
    if (data === null) {
      return (
        <>
          <div
            className="profileImage1"
            style={{ float: "left", display: "block" }}
          >
            <div> </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="profileImage"
            style={{ float: "left", display: "block" }}
          >
            <div className="profileImageText"> {data.initial}</div>
          </div>
        </>
      );
    }
  };

  const showHiddenSearchBox = () => {
    if (hideCount === 0) {
      let element = document.getElementById("SearchshowBox");
      element.classList.add("show");
      setHideCount(1);
    } else if (hideCount === 1) {
      var elementagain = document.getElementById("SearchshowBox");
      elementagain.classList.remove("show");

      setHideCount(0);
    }
  };

  const handleAssignMail = async () => {
    setModalIsOpen(false);
    try {
      await axiosPrivate
        .patch(
          "/mailbox/assign-email",
          JSON.stringify({
            mesg_id: emailId,
            assign_to: assignMailId,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          if (e.statusText === "OK") {
            setModalIsOpen(false);

            handlemailsearch("", filterName);
          } else {
          }
        });
    } catch (err) {}
  };

  const handleRefreshmail = async (seachName, filterNames) => {
    localStorage.setItem("donot", true);
    let inputSearch;
    let inputFilter;
    if (seachName === undefined) {
      inputSearch = "";
    } else {
      inputSearch = seachName;
    }
    if (filterNames === undefined) {
      inputFilter = "";
    } else {
      inputFilter = filterNames;
    }
    setDataLoader(true);
    try {
      const response = await axiosPrivate
        .get(
          `/mailbox/inbox?query_filter=search%3D${inputSearch}%26filter%3D${filterNames}&page=${1}&size=${pagesize}&sort=-uid`
        )
        // (`/mailbox/inbox?page=1&size=10&sort=-uid`, {})

        .then((res) => {
          if (res.status === 200) {
            setData(res.data.items);
            setRefreshState(true);
            setSearchItem();
            setDataCount(res.data.total);
            setDataLoader(false);
            setIsCheck([]);
            setIsCheckAll(false);
            SetDeleteCount(0);
          }
        });
    } catch (err) {}
    return () => {};
  };

  const handleRefreshmailAuto = async (seachName) => {
    localStorage.setItem("donot", true);
    if (
      searchItem === undefined &&
      filterLocal === "false" &&
      searchStat === "false" &&
      userSearch.length === 0 &&
      nextPage === 1 &&
      filterName === "inbox"
    ) {
      try {
        const response = await axiosPrivate

          .get(`/mailbox/inbox?page=1&size=${pagesize}&sort=-uid`, {})

          .then((res) => {
            if (res.status === 200) {
              if (res.data.total > dataCount) {
                setData(res.data.items);
                setRefreshState(true);
                setSearchItem();
              }
              setDataCount(res.data.total);
              setIsCheck([]);
              setIsCheckAll(false);
              SetDeleteCount(0);
            }
          });
      } catch (err) {}
      return () => {};
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleRefreshmailAuto();
    }, 20000);

    return () => clearInterval(interval);
  });

  const handleUid = () => {};
  const handleUserMail = async (userId, searchItem, filterName) => {
    let setsearchpage = localStorage.getItem("setUsersearchpage");

    setIsSubmitting(true);
    setDataLoader(true);
    let inputSearch;
    let inputFilter;
    if (searchItem === undefined) {
      inputSearch = "";
    } else {
      inputSearch = searchItem;
    }
    let user = "";
    if ((userId && setsearchpage === "0") || setsearchpage === null) {
      userId.map((e) => {
        if (userId[userId.length - 1]._id !== e._id) {
          user = user + `${e._id}-`;
        } else {
          user = user + `${e._id}`;
        }
      });

      try {
        const response = await axiosPrivate.get(
          `/mailbox/inbox?query_filter=assigned_to%3D${user}%26filter%3D%26search%3D${inputSearch}%26filter%3D${filterName}&page=${nextPage}&size=${pagesize}&sort=-uid`
        );

        setData(response.data.items);

        setDataCount(response.data.total);
        localStorage.setItem("statepage", false);
        localStorage.setItem("search", false);

        localStorage.setItem("searchItem", "");
        localStorage.setItem("statepage", false);
      } catch (err) {}
    } else if (userId && setsearchpage === "1") {
      userId.map((e) => {
        if (userId[userId.length - 1]._id !== e._id) {
          user = user + `${e._id}-`;
        } else {
          user = user + `${e._id}`;
        }
      });

      try {
        const response = await axiosPrivate.get(
          `/mailbox/inbox?query_filter=assigned_to%3D${user}%26filter%3D%26search%3D${inputSearch}%26filter%3D${filterName}&page=${1}&size=${pagesize}&sort=-uid`
        );

        setData(response.data.items);

        setDataCount(response.data.total);
        localStorage.setItem("setUsersearchpage", 0);
        setNextPage(1);
        localStorage.setItem("statepage", false);
        localStorage.setItem("search", false);
        localStorage.setItem("searchItem", "");
        localStorage.setItem("statepage", false);
      } catch (err) {}
    }

    setIsSubmitting(false);
    setDataLoader(false);
  };

  const handleUserSearch = async (userId, searchname) => {
    setIsSubmitting(true);
    setDataLoader(true);
    let user = "";
    if (userId) {
      userId.map((e) => {
        if (userId[userId.length - 1]._id !== e._id) {
          user = user + `${e._id}-`;
        } else {
          user = user + `${e._id}`;
        }
      });
    }

    try {
      const response = await axiosPrivate.get(
        `/mailbox/inbox?query_filter=assigned_to%3D${user}%26filter%3D%26search%3D${searchname}&page=${nextPage}&size=${pagesize}&sort=-uid`
      );
      setData(response.data.items);
      setDataCount(response.data.total);
      localStorage.setItem("statepage", false);
      localStorage.setItem("search", false);
      localStorage.setItem("searchItem", "");
      localStorage.setItem("statepage", false);
    } catch (err) {}

    setIsSubmitting(false);
    setDataLoader(false);
  };
  const colors = ["grey", "gold", "red", "blue", "green"];

  const handleStarReq = async (id, mail_id, isStarr) => {
    let eleement = document.getElementById("item" + id);
    let activeClass = eleement.classList;
    let activeIndex = colors.indexOf(activeClass[1]);
    let updatedIndex;

    if (activeIndex < 4) {
      updatedIndex = activeIndex + 1;
    } else {
      updatedIndex = 0;
    }
    let updatedClass = colors[updatedIndex];
    setstarTitle(updatedClass);

    try {
      await axiosPrivate
        .post(
          "mailbox/starred-email",
          JSON.stringify({
            mailbox_id: mail_id,
            msg_id: id,
            star_color: updatedIndex,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            eleement.classList.remove(activeClass[1]);
            eleement.classList.add(updatedClass);
          }
        });
    } catch (err) {}
  };

  const handlemailsearch = async (seachName, filterNames) => {
    let inputSearch;
    let inputFilter;

    if (seachName === "" || seachName === undefined || seachName === null) {
      inputSearch = "";
      inputFilter = filterNames;
    } else if (
      seachName !== "" ||
      seachName !== undefined ||
      seachName !== null
    ) {
      inputSearch = seachName;
      inputFilter = "";
    }

    setDataLoader(true);
    let searchStat = localStorage.getItem("search");
    let filterlocal = localStorage.getItem("filter");
    let filterdrp = localStorage.getItem("filterdrpSearch");

    let stat = localStorage.getItem("statepage");
    let setsearchpage = localStorage.getItem("setsearchpage");

    let npage = localStorage.getItem("rowperpage");
    let psize = localStorage.getItem("perpage");
    if (
      filterlocal === "true" ||
      searchStat === "true" ||
      userSearch === undefined
    ) {
      try {
        const response = await axiosPrivate.get(
          `/mailbox/inbox?query_filter=search%3D${inputSearch}%26filter%3D${inputFilter}&page=${npage}&size=${psize}&sort=-uid`
        );

        setNextPage(npage);
        setPagesize(psize);
        setData(response.data.items);
        localStorage.setItem("statepage", false);
        localStorage.setItem("filter", false);
        setFilterName(filterLOcalName);
        setFolders(MoveTo);
        localStorage.setItem("filtername", "");
        setDataCount(response.data.total);
        localStorage.setItem("statepage", false);
        localStorage.setItem("search", false);
        setSearchItem(searchLOcalName);
        localStorage.setItem("searchItem", "");
      } catch (err) {}
    } else if (setsearchpage === "1" || filterdrp === "1") {
      try {
        const response = await axiosPrivate.get(
          `/mailbox/inbox?query_filter=search%3D${inputSearch}%26filter%3D${inputFilter}&page=${1}&size=${pagesize}&sort=-uid`
        );
        setNextPage(1);
        localStorage.setItem("setsearchpage", 0);
        localStorage.setItem("filterdrpSearch", 0);
        setData(response.data.items);
        setDataCount(response.data.total);
      } catch (err) {}
    } else {
      try {
        const response = await axiosPrivate.get(
          `/mailbox/inbox?query_filter=search%3D${inputSearch}%26filter%3D${inputFilter}&page=${nextPage}&size=${pagesize}&sort=-uid`
        );
        setData(response.data.items);
        setDataCount(response.data.total);
      } catch (err) {}
    }
    setDataLoader(false);
  };

  return (
    <>
      <BidsSubHeader pname="mailbox" />
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachbox">
            <div style={{ float: "left" }} className="topleft">
              <h3 className="tableHeading mailbox">
                <img src={mailboxIco} className="headingIcons" alt="Mailbox" />{" "}
                Bids Mailbox
              </h3>
              <select
                className="mailboxFilter"
                name=""
                id=""
                onChange={(e) => {
                  setNextPage(1);
                  localStorage.setItem("filterdrpSearch", 1);
                  localStorage.setItem("setUsersearchpage", 1);
                  setIsCheck([]);
                  setIsCheckAll(false);
                  SetDeleteCount(0);
                  ref.current.value = "";
                  setFilterName(e.target.value);

                  if (e.target.value == "archived") {
                    setFolders(["Inbox"]);
                    localStorage.setItem("moveTo", JSON.stringify(["Inbox"]));
                  }
                  if (e.target.value == "inbox") {
                    setFolders(["Archive"]);
                    localStorage.setItem("moveTo", JSON.stringify(["Archive"]));
                  }
                  if (e.target.value == "sent") {
                    setFolders(["Inbox", "Archive"]);
                    localStorage.setItem(
                      "moveTo",
                      JSON.stringify(["Inbox", "Archive"])
                    );
                  }
                  if (e.target.value == "starred") {
                    setFolders(["Inbox", "Archive"]);
                    localStorage.setItem(
                      "moveTo",
                      JSON.stringify(["Inbox", "Archive"])
                    );
                  }
                  if (e.target.value == "assigned") {
                    setFolders(["Archive"]);
                    localStorage.setItem("moveTo", JSON.stringify(["Archive"]));
                  }
                  if (e.target.value == "unassigned") {
                    setFolders(["Archive"]);
                    localStorage.setItem("moveTo", JSON.stringify(["Archive"]));
                  }

                  setSearchItem();
                  if (
                    userSearch === undefined ||
                    (userSearch.length === 0 && e.target.value)
                  ) {
                    handlemailsearch("", e.target.value);
                  } else if (
                    userSearch === undefined ||
                    (userSearch.length !== 0 && e.target.value)
                  ) {
                    setNextPage(1);

                    handleUserMail(userSearch, "", e.target.value);
                  }
                  localStorage.setItem("ResetfilterState", true);
                }}
                value={filterName === undefined ? filterLOcalName : filterName}
                // value={filterLOcalName || filterName}
              >
                <option value="inbox">Inbox</option>
                <option value="all">All</option>
                <option value="assigned">Assigned</option>
                <option value="unassigned">UnAssigned</option>
                <option value="archived">Archived</option>
                <option value="sent">Sent</option>
                <option value="starred">Starred</option>
                <option value="">Select</option>
              </select>
            </div>

            <div className="toprightBlock">
              {superUser === true ? (
                <>
                  <button
                    className="cbtn addBtn mr"
                    onClick={() => {
                      navigate("/compose");
                    }}
                  >
                    <img src={addIcon} alt="Compose" /> &nbsp; Compose{" "}
                  </button>
                </>
              ) : (
                <>
                  {all_permission.includes("mailbox.mailbox_compose_email") ? (
                    <>
                      <button
                        className="cbtn addBtn mr"
                        onClick={() => {
                          navigate("/compose");
                        }}
                      >
                        <img src={addIcon} alt="Compose" /> &nbsp; Compose{" "}
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}

              <div className="searchtoggle">
                <img
                  style={{ float: "right" }}
                  src={searchIcon}
                  alt=""
                  className="headingIcons hiddenIcon"
                  onClick={() => {
                    showHiddenSearchBox();
                  }}
                />
              </div>
            </div>

            <div className="middleBlock" id="SearchshowBox">
              <div className="userSearchBox">
                <button
                  className="cbtn saveBtn wd"
                  style={{ margin: "0px 10px" }}
                  onClick={(e) => {
                    if (
                      (userSearch.length !== 0 && filterName) ||
                      (userSearch.length !== 0 && searchItem)
                    ) {
                      // setNextPage(1);

                      allUserlist();
                      handleUserMail(userSearch, searchItem, filterName);

                      setRefreshState(true);
                    } else if (searchItem && userSearch.length === 0) {
                      setNextPage(1);
                      handlemailsearch(searchItem, filterName);
                      setRefreshState(true);
                      setFilterName("");
                    }
                  }}
                >
                  {isSubmitting && (
                    <Spinner
                      style={{ color: "white" }}
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp; Search
                </button>
                <div
                  className="asgn"
                  style={{
                    width: "200px",
                    float: "right",
                    marginLeft: "8px",
                  }}
                >
                  <MultiSelect
                    className="multiselectoption"
                    style={{ width: "29px" }}
                    options={userlist}
                    value={selected}
                    onChange={(e) => {
                      setSelected(e);
                      if (e.length !== 0) {
                        setUserSearch(e);
                        // setPlaceHolder(true);
                        localStorage.setItem("setUsersearchpage", 1);
                      } else if (e.length === 0) {
                        // setPlaceHolder(false);
                        // ref.current.value = "";
                        localStorage.setItem("setUsersearchpage", 0);
                        localStorage.setItem("filterdrpSearch", 1);
                        setUserSearch(e);
                        handlemailsearch(searchItem, filterName);
                        allUserlist();
                      }
                    }}
                    labelledBy={"Select Option"}
                    isCreatable={true}
                    disableSearch={false}
                  />
                </div>
                {/* </div> */}

                <input
                  type="search"
                  className="tableSearch wdsrch"
                  style={{ width: "280px", fontSize: "15px" }}
                  placeholder="Search Text..."
                  ref={ref}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      setNextPage(1);
                      // handleSearch(searchItem, filterName);

                      localStorage.setItem("ResetsearchPage", true);

                      if (userSearch.length !== 0 && searchItem) {
                        handleUserMail(userSearch, searchItem, filterName);
                      } else if (searchItem && userSearch.length === 0) {
                        setFilterName("");
                        handlemailsearch(searchItem, "");
                      }
                    }
                  }}
                  onChange={(e) => {
                    // setNextPage(1);
                    localStorage.setItem("setsearchpage", 1);
                    localStorage.setItem("setUsersearchpage", 1);
                    setSearchItem(e.target.value.trim());
                  }}
                />
              </div>
            </div>
          </div>

          <hr />
          <div className="mainContent">
            <div className="mainContentUnderDiv">
              <span>
                <input
                  style={{ cursor: "pointer" }}
                  className="headerCheckbox"
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                />
              </span>
              <span
                className="refresh actionElement"
                onClick={() => {
                  handleRefreshmail("", filterName);
                }}
              >
                <img
                  src={RefreshIcon}
                  alt="Refresh"
                  height="16"
                  style={{ marginTop: "18px" }}
                />
              </span>
              {filterName === "all" ? (
                <></>
              ) : (
                <>
                  {" "}
                  {deleteCount >= 1 || isCheck.length > 0 ? (
                    <span title="Move to">
                      <MdDriveFileMoveOutline
                        title="Move to"
                        style={{
                          margin: "16px 10px 5px",
                          cursor: "pointer",
                          fontSize: "20px",
                        }}
                        onClick={(event) => {
                          setIsOpen(true);
                          handleOpenFolderMenu(event);
                        }}
                      />
                    </span>
                  ) : (
                    <></>
                  )}
                </>
              )}{" "}
              <Menu
                isOpen={isOpen}
                sx={{ mt: "22px" }}
                id="menu-folder"
                anchorEl={anchorElFolder}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElFolder)}
                onClose={handleCloseUserMenu}
              >
                {folders.map((sett, i) => (
                  <MenuItem key={i} onClick={handleCloseUserMenu}>
                    <Typography
                      value={sett}
                      textAlign="center"
                      onClick={(e) => {
                        Moveto(sett.toLowerCase());
                      }}
                      onChange={() => {
                        setFolderId(sett.toLowerCase());
                      }}
                    >
                      {sett}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div className="table_container">
              <Table id="mailbox" style={{ marginBottom: "0px" }}>
                <TableBody
                  className="TableCent"
                  // style={{
                  //   height: "390px",
                  // }}
                >
                  {dataLoader ? (
                    <SpinnerLoader />
                  ) : data.length === 0 ? (
                    <p
                      style={{
                        margin: "5px 0px",
                        marginLeft: "0px",
                        textAlign: "center",
                        color: "#1a1a1a",
                        fontSize: "14px",
                      }}
                    >
                      No Data Found
                    </p>
                  ) : (
                    data.map((row) => (
                      <TableRow
                        key={row.id}
                        style={{
                          height: "38px",
                          maxHeight: "38px",
                        }}
                      >
                        <TableCell
                          className="fstd"
                          align="left"
                          style={{ width: "7%" }}
                        >
                          <input
                            style={{
                              height: "24px",
                              width: "18px",
                              cursor: "pointer",
                              display: "block",
                              float: "left",
                              margin: "0px",
                            }}
                            key={row._id}
                            type="checkbox"
                            name={row.from_header}
                            id={row._id}
                            onChange={(e) => {
                              handleSingleSelect(e, row._id, row.mailbox_id);
                            }}
                            checked={isCheck.includes(row._id)}
                          />

                          <span
                            className={
                              row.is_starred === 1 ? (
                                "startborderoutline gold"
                              ) : row.is_starred === 2 ? (
                                "startborderoutline red"
                              ) : row.is_starred === 3 ? (
                                "startborderoutline blue"
                              ) : row.is_starred === 4 ? (
                                "startborderoutline green"
                              ) : row.is_starred === 0 ? (
                                "startborderoutline grey"
                              ) : (
                                <></>
                              )
                            }
                            id={"item" + row._id}
                            onClick={() => {
                              handleStarReq(row._id, row.mailbox_id);
                            }}
                          >
                            <StarBorderOutlinedIcon

                            // className="startborderoutline"
                            />
                          </span>
                        </TableCell>
                        <TableCell
                          className="rowHeader hiddenSm"
                          style={{ width: "15%" }}
                          onClick={() => {
                            localStorage.setItem("donot", false);
                            localStorage.setItem("perpage", pagesize);
                            localStorage.setItem("rowperpage", nextPage);
                            if (filterName === "inbox") {
                              localStorage.setItem(
                                "moveTo",
                                JSON.stringify(["Archive"])
                              );
                            }
                            if (!filterName && !searchItem) {
                              localStorage.setItem("statepage", true);
                              localStorage.setItem(
                                "filtername",
                                filterName.toLowerCase()
                              );
                            }
                            if (searchItem) {
                              localStorage.setItem("search", true);
                              localStorage.setItem("searchItem", searchItem);
                              localStorage.setItem(
                                "filtername",
                                searchItem.toLowerCase()
                              );
                            }
                            if (filterName) {
                              localStorage.setItem("filter", true);
                              localStorage.setItem(
                                "filtername",
                                filterName.toLowerCase()
                              );
                            }
                            navigate(`/message-detail/${row._id}`);
                          }}
                        >
                          {row.is_read === 0 ? (
                            row.count_thread > 0 ? (
                              <>
                                <span>
                                  <b>{row.from_header}</b>&nbsp;
                                  {row.count_thread}
                                </span>
                              </>
                            ) : (
                              <b>{row.from_header}</b>
                            )
                          ) : row.count_thread > 0 ? (
                            <>
                              <span>
                                {row.from_header}&nbsp;
                                {row.count_thread}
                              </span>
                            </>
                          ) : (
                            row.from_header
                          )}
                        </TableCell>
                        <TableCell className="subject">
                          <p
                            className="mailFrom"
                            style={{ width: "100%" }}
                            onClick={() => {
                              localStorage.setItem("donot", false);
                              localStorage.setItem("perpage", pagesize);
                              localStorage.setItem("rowperpage", nextPage);
                              // if (!filterName && !searchItem) {
                              //   localStorage.setItem("statepage", true);
                              // }
                              if (filterName === "inbox") {
                                localStorage.setItem(
                                  "moveTo",
                                  JSON.stringify(["Archive"])
                                );
                              }
                              if (filterName) {
                                localStorage.setItem("filter", true);
                                localStorage.setItem(
                                  "filtername",
                                  filterName.toLowerCase()
                                );
                              }
                              if (searchItem) {
                                localStorage.setItem("search", true);
                                localStorage.setItem("searchItem", searchItem);
                              }
                              navigate(`/message-detail/${row._id}`);
                            }}
                          >
                            {row.is_read === 0 ? (
                              row.count_thread > 0 ? (
                                <>
                                  <span>
                                    <b>{row.from_header}</b>&nbsp;
                                    {row.count_thread}
                                  </span>
                                </>
                              ) : (
                                <b>{row.from_header}</b>
                              )
                            ) : row.count_thread > 0 ? (
                              <>
                                <span>
                                  {row.from_header}&nbsp;
                                  {row.count_thread}
                                </span>
                              </>
                            ) : (
                              row.from_header
                            )}
                          </p>
                          <p
                            className="mailContent"
                            onClick={() => {
                              localStorage.setItem("donot", false);
                              localStorage.setItem("perpage", pagesize);
                              localStorage.setItem("rowperpage", nextPage);
                              if (filterName === "inbox") {
                                localStorage.setItem(
                                  "moveTo",
                                  JSON.stringify(["Archive"])
                                );
                              }
                              if (!filterName && !searchItem) {
                                localStorage.setItem("statepage", true);
                              }
                              if (filterName) {
                                localStorage.setItem("filter", true);
                                localStorage.setItem(
                                  "filtername",
                                  filterName.toLowerCase()
                                );
                              }
                              if (searchItem) {
                                localStorage.setItem("search", true);
                                localStorage.setItem("searchItem", searchItem);
                              }

                              navigate(`/message-detail/${row._id}`);
                            }}
                          >
                            {row.bid_id !== null ? (
                              <>
                                <span className="slabel bCreated">
                                  BID CREATED
                                </span>{" "}
                                <span>&nbsp;</span>
                              </>
                            ) : (
                              ""
                            )}
                            {row.is_read === 0 ? (
                              <>
                                <b onClick={handleUid}>{row.subject}</b>
                                {row.body_text.length > 0 ? <> - </> : <></>}
                                {row.body_text}
                              </>
                            ) : (
                              <>
                                <span style={{ color: "#000" }}>
                                  {row.subject}
                                </span>
                                {row.body_text.length > 0 ? <> - </> : <></>}
                                {row.body_text}
                              </>
                            )}
                          </p>
                        </TableCell>
                        <TableCell
                          style={{ width: "12%", position: "relative" }}
                        >
                          <div
                            style={{
                              // width: "50%",
                              height: "20px",
                              marginTop: "0px",
                              color: "black",
                              float: "left",
                              width: "20%",
                            }}
                          >
                            {row.attachments === null ? (
                              ""
                            ) : (
                              <img
                                style={{
                                  height: "17px",
                                  marginTop: "8px",
                                }}
                                src={AttachmentIcon}
                                height="16"
                                alt="Attachment"
                              />
                            )}
                          </div>

                          {ImageCreater(row.assigned_to)}
                          {superUser === true ? (
                            <>
                              <MoreVertIcon
                                className="moreverticon"
                                style={{
                                  float: "left",
                                  width: "20%",
                                  display: "block",
                                }}
                                onClick={(e) => {
                                  handleOpeNewbid();
                                  handleOpenUserMenu(e, row.bid_id);
                                  setEmailId(row._id);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              {row.bid_id !== null ? (
                                <>
                                  {all_permission.includes(
                                    "mailbox.mailbox_assign_email"
                                  ) &&
                                  all_permission.includes("bid.bid_update") ? (
                                    <>
                                      <MoreVertIcon
                                        className="moreverticon"
                                        style={{
                                          float: "left",
                                          width: "20%",
                                          display: "block",
                                        }}
                                        onClick={(e) => {
                                          handleOpeNewbid();
                                          handleOpenUserMenu(e, row.bid_id);
                                          setEmailId(row._id);
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {all_permission.includes(
                                        "mailbox.mailbox_assign_email"
                                      ) ? (
                                        <>
                                          <MoreVertIcon
                                            className="moreverticon"
                                            style={{
                                              float: "left",
                                              width: "20%",
                                              display: "block",
                                            }}
                                            onClick={(e) => {
                                              handleOpeNewbid();
                                              handleOpenUserMenu(e, row.bid_id);
                                              setEmailId(row._id);
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {all_permission.includes(
                                            "bid.bid_update"
                                          ) ? (
                                            <>
                                              <MoreVertIcon
                                                className="moreverticon"
                                                style={{
                                                  float: "left",
                                                  width: "20%",
                                                  display: "block",
                                                }}
                                                onClick={(e) => {
                                                  handleOpeNewbid();
                                                  handleOpenUserMenu(
                                                    e,
                                                    row.bid_id
                                                  );
                                                  setEmailId(row._id);
                                                }}
                                              />
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {row.bid_id === null ? (
                                    <>
                                      {all_permission.includes(
                                        "mailbox.mailbox_assign_email"
                                      ) &&
                                      all_permission.includes(
                                        "bid.bid_create"
                                      ) ? (
                                        <>
                                          <MoreVertIcon
                                            className="moreverticon"
                                            style={{
                                              float: "left",
                                              width: "20%",
                                              display: "block",
                                            }}
                                            onClick={(e) => {
                                              handleOpeNewbid();
                                              handleOpenUserMenu(e, row.bid_id);
                                              setEmailId(row._id);
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {all_permission.includes(
                                            "mailbox.mailbox_assign_email"
                                          ) ? (
                                            <>
                                              <MoreVertIcon
                                                className="moreverticon"
                                                style={{
                                                  float: "left",
                                                  width: "20%",
                                                  display: "block",
                                                }}
                                                onClick={(e) => {
                                                  handleOpeNewbid();
                                                  handleOpenUserMenu(
                                                    e,
                                                    row.bid_id
                                                  );
                                                  setEmailId(row._id);
                                                }}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              {all_permission.includes(
                                                "bid.bid_create"
                                              ) ? (
                                                <>
                                                  <MoreVertIcon
                                                    className="moreverticon"
                                                    style={{
                                                      float: "left",
                                                      width: "20%",
                                                      display: "block",
                                                    }}
                                                    onClick={(e) => {
                                                      handleOpeNewbid();
                                                      handleOpenUserMenu(
                                                        e,
                                                        row.bid_id
                                                      );
                                                      setEmailId(row._id);
                                                    }}
                                                  />
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {/* {
                            <MoreVertIcon
                              className="moreverticon"
                              style={{
                                float: "left",
                                width: "20%",
                                display: "block",
                              }}
                              onClick={(e) => {
                                handleOpeNewbid();
                                handleOpenUserMenu(e, row.bid_id);
                                setEmailId(row._id);
                              }}
                            />
                          } */}
                        </TableCell>
                        <TableCell className="date" style={{ width: "6%" }}>
                          {moment(row.email_date).format("DD/MM/YYYY") ===
                          moment().format("DD/MM/YYYY") ? (
                            <>{moment(row.email_date).format("LT")}</>
                          ) : (
                            <>{moment(row.email_date).format("DD/MM/YYYY")}</>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
                <Menu
                  sx={{ mt: "15px" }}
                  id="menu-appbar"
                  className="bid_menu_mail myMenu"
                  anchorEl={anchorElUserBid}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUserBid)}
                  onClose={handleCloseUserMenu}
                >
                  {superUser === true ? (
                    <>
                      {Bidcreate ? (
                        bidcreated.map((setting, index) => (
                          <MenuItem key={setting} onClick={handleCloseUserMenu}>
                            <Typography
                              textAlign="center"
                              onClick={() => {
                                handleOptionEdit(index, Bidcreate);
                              }}
                            >
                              {setting.title_name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : shownewbid ? (
                        newbid.map((setting, index) => (
                          <MenuItem key={setting} onClick={handleCloseUserMenu}>
                            <Typography
                              textAlign="center"
                              onClick={() => {
                                handleOptionNew(index, emailId);
                              }}
                            >
                              {setting.title_name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {Bidcreate ? (
                        <>
                          {all_permission.includes(
                            "mailbox.mailbox_assign_email"
                          ) && all_permission.includes("bid.bid_update") ? (
                            <>
                              {bidcreated.map((setting, index) => (
                                <MenuItem
                                  key={setting}
                                  onClick={handleCloseUserMenu}
                                >
                                  <Typography
                                    textAlign="center"
                                    onClick={() => {
                                      handleOptionEdit(index, Bidcreate);
                                    }}
                                  >
                                    {setting.title_name}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </>
                          ) : (
                            <>
                              {Bidcreate ? (
                                <>
                                  {
                                    // bidcreated.map((setting) =>
                                    all_permission.includes(
                                      "bid.bid_update"
                                    ) ? (
                                      <>{bidcreated[1].title_name}</>
                                    ) : (
                                      <>
                                        {all_permission.includes(
                                          "mailbox.mailbox_assign_email"
                                        ) ? (
                                          <>{bidcreated[0].title_name}</>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )
                                    // )
                                  }
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {shownewbid ? (
                            <>
                              {all_permission.includes(
                                "mailbox.mailbox_assign_email"
                              ) && all_permission.includes("bid.bid_create") ? (
                                <>
                                  {newbid.map((setting, index) => (
                                    <MenuItem
                                      key={setting}
                                      onClick={handleCloseUserMenu}
                                    >
                                      <Typography
                                        textAlign="center"
                                        onClick={() => {
                                          handleOptionNew(index, emailId);
                                        }}
                                      >
                                        {setting.title_name}
                                      </Typography>
                                    </MenuItem>
                                  ))}
                                </>
                              ) : (
                                <>
                                  {shownewbid ? (
                                    <>
                                      {
                                        // newbid.map((setting) =>
                                        all_permission.includes(
                                          "mailbox.mailbox_assign_email"
                                        ) ? (
                                          <>{newbid[0].title_name}</>
                                        ) : (
                                          <>
                                            {all_permission.includes(
                                              "bid.bid_create"
                                            ) ? (
                                              <>{newbid[1].title_name}</>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )
                                        // )
                                      }
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Menu>
              </Table>
            </div>
            <Mailboxpaginations
              userlist={dataCount}
              getPageSize={getPageSize}
              handlePageChnage={handlePageChnage}
              refreshState={refreshState}
              setRefreshState={setRefreshState}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <button onClick={setModalIsOpenToFalse} className="closeModal">
          x
        </button>
        <h5>Assign Mail Box</h5>
        <hr />
        <select
          className="formtext"
          required
          style={{ marginBottom: "8px" }}
          onChange={(e) => {
            setAssignMailId(e.target.value);
          }}
        >
          <option>select</option>
          {userlist.map((e) => (
            <option value={e._id}>{e.name}</option>
          ))}
        </select>
        <div>
          <button
            className="cbtn closeBtn"
            style={{ marginRight: "0" }}
            onClick={setModalIsOpenToFalse}
          >
            Close{" "}
          </button>

          <button className="cbtn saveBtn" style={{marginRight:'10px'}} onClick={handleAssignMail}>
            {" "}
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};
export default Mailbox;
