export const ClientId = (id) => {
  return {
    type: "DetailClient",
    payload: id,
  };
};

export const twoClientId = (id, ckey) => {
  return {
    type: "TWOCLIENT",
    payload: id,
    ckey: ckey,
  };
};
