import { useNavigate } from "react-router-dom"

const Error = (props) => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <div className="top_container">
        <div className="main_container ">
            <div className="mainContent">          
                <article style={{ padding: "80px" , textAlign:"center"}}>
            <h1 style={{fontSize: "150px"}}>500</h1>
            <h5>Internal Server Error</h5><br></br>
            <p>The server encountered an error and could not complete your request.</p>
            <div className="flexGrow">
                <button onClick={goBack}>Go Back</button>
            </div>
        </article>
        </div></div></div>
    )
}

export default Error
