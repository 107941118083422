import Modal from "react-bootstrap/Modal";
import React from "react";
import { Row, Col } from "react-bootstrap";
function SupplierLink(props) {
  const handleClose = () => {
    props.props.setProject(true);
    props.setSupplierLink(false);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Supplier Links (PN# Other study)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={2} md={4} sm={12} xs={12}>
            <label className="formlabelamit">Completion Link</label>
          </Col>
          <Col lg={10} md={4} sm={12} xs={12}>
            {" "}
            <h6 className="quta_link_text">
              https://samplicity.wiseworksresearch.com/demand-api/transaction?transaction_id=[%transaction_id%]&v_id=[%vendor_id%]&s_id=
            </h6>
          </Col>
        </Row>
        <div style={{ height: "10px" }}></div>
        <Row>
          <Col lg={2} md={4} sm={12} xs={12}>
            <label className="formlabelamit">Termination Link</label>
          </Col>
          <Col lg={10} md={4} sm={12} xs={12}>
            {" "}
            <h6 className="quta_link_text">
              https://samplicity.wiseworksresearch.com/demand-api/transaction?transaction_id=[%transaction_id%]&v_id=[%vendor_id%]&s_id=
            </h6>
          </Col>
        </Row>
        <div style={{ height: "10px" }}></div>
        <Row>
          <Col lg={2} md={4} sm={12} xs={12}>
            <label className="formlabelamit">Quotafull Link</label>
          </Col>
          <Col lg={10} md={4} sm={12} xs={12}>
            <h6 className="quta_link_text">
              https://samplicity.wiseworksresearch.com/demand-api/transaction?transaction_id=[%transaction_id%]&v_id=[%vendor_id%]&s_id=
            </h6>
          </Col>
        </Row>
        <hr />
        <Row style={{ margin: "0px" }}>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {" "}
              <button
                className="quota_surtrakbtn"
                style={{
                  marginRight: "-15px",
                }}
                onClick={() => {
                  props.props.setProject(true);
                  props.setSupplierLink(false);
                }}
              >
                Survey tracking
              </button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
export default SupplierLink;
