import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import Select from "react-select";
function Editsupplyquality(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Project Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row>
            <Col lg={3} md={6} sm={6} xs={12}>
              <div className="rrr"></div>
              <label className="formlabelamit">Project Name</label>
              <input
                placeholder="Project Name"
                className="formtextamit"
                type="text"
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <label className="formlabelamit">Project Type</label>
              <input
                placeholder="Project Type"
                className="formtextamit"
                type="text"
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <label className="formlabelamit">Client Name</label>
              <input
                placeholder="Client Name"
                className="formtextamit"
                type="text"
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <label className="formlabelamit">Client Number (PO)#</label>
              <input
                placeholder="Client Number (PO)#"
                className="formtextamit"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6} sm={6} xs={12}>
              <label className="formlabelamit">Sales Person</label>
              <input
                placeholder="Sales Person"
                className="formtextamit"
                type="text"
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <label className="formlabelamit">Primary Project Manager</label>
              <input
                placeholder="Primary Project Manager"
                className="formtextamit"
                type="text"
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <label className="formlabelamit">Secondary Project Manager</label>
              <input
                placeholder="Secondary Project Manager"
                className="formtextamit"
                type="text"
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <label className="formlabelamit">Status</label>
              <input
                placeholder="Status"
                className="formtextamit"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6} sm={6} xs={12}>
              <label className="formlabelamit">Start Date</label>
              <input
                placeholder="Start Date"
                className="formtextamit"
                type="text"
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <label className="formlabelamit">End Date</label>
              <input
                placeholder="End Date"
                className="formtextamit"
                type="text"
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <label className="formlabelamit">Est. Revenue</label>
              <input
                placeholder="Est. Revenue"
                className="formtextamit"
                type="text"
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <label className="formlabelamit">Revised Est. Revenue</label>
              <input
                placeholder="Revised Est. Revenue"
                className="formtextamit"
                type="text"
              />
            </Col>
          </Row>
          <div style={{ height: "20px" }}></div>
          <Row
            style={{
              backgroundColor: "#979898",
              margin: "0px",
            }}
          >
            <Col
              style={{
                padding: "3px 15px 3px 16px",
                color: "#f1f1f1",
                fontSize: "12px",
                fontWeight: "bolder",
                height: "30px",
                // marginBottom: "15px",
                paddingTop: "8px",
              }}
            >
              SC106647 - Project 3
            </Col>
          </Row>
          <Row style={{ border: "1px solid #f2f0f0", margin: "0px" }}>
            <Col>
              <Row>
                {" "}
                <Col
                  style={{
                    backgroundColor: "#f2f0f0",
                    margin: "15px",
                    padding: "5px",
                    fontSize: "12px",
                    fontWeight: "bolder",
                    height: "30px",
                    marginTop: "15px",
                  }}
                >
                  US Gen Pop 18+
                  <button
                    style={{
                      padding: "0px",
                      margin: "0px",
                    }}
                    className="liveBtnA"
                  >
                    Live
                  </button>
                </Col>
                <Row style={{ margin: "0px" }}>
                  <Col lg={3} md={6} sm={6} xs={12}>
                    <label className="formlabelamit">Country*</label>
                    <input
                      placeholder="Country"
                      className="formtextamit"
                      type="text"
                    />
                  </Col>{" "}
                  <Col lg={3} md={6} sm={6} xs={12}>
                    <label className="formlabelamit">Language*</label>
                    <input
                      placeholder="Language"
                      className="formtextamit"
                      type="text"
                    />
                  </Col>{" "}
                  <Col lg={3} md={6} sm={6} xs={12}>
                    <label className="formlabelamit">Type*</label>
                    <input
                      placeholder="Type"
                      className="formtextamit"
                      type="text"
                    />
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={12}>
                    <label className="formlabelamit">IR*</label>
                    <input
                      placeholder="IR"
                      className="formtextamit"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row style={{ margin: "0px", paddingBottom: "20px" }}>
                  <Col lg={3} md={6} sm={6} xs={12}>
                    <label className="formlabelamit">LOI*</label>
                    <input
                      placeholder="LOI*"
                      className="formtextamit"
                      type="text"
                    />
                  </Col>{" "}
                  <Col lg={3} md={6} sm={6} xs={12}>
                    <label className="formlabelamit">CPI*</label>
                    <input
                      placeholder="CPI"
                      className="formtextamit"
                      type="text"
                    />
                  </Col>{" "}
                  <Col lg={3} md={6} sm={6} xs={12}>
                    <label className="formlabelamit">Quota*</label>
                    <input
                      placeholder="Quota"
                      className="formtextamit"
                      type="text"
                    />
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={12}>
                    <label className="formlabelamit">Margin Threshold</label>
                    <input
                      placeholder="Margin Threshold"
                      className="formtextamit"
                      type="text"
                    />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
          <></>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default Editsupplyquality;
