import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import "./SurveyListingPopUp.css";
function SurveyListingPopUp(props) {
  const devicetypearray = [
    { label: "Mobile", value: "Mobile" },
    { label: "Tablet", value: "Tablet" },
    { label: "Laptop", value: "Laptop" },
  ];
  const [devicetype, setDeviceTYpe] = useState([]);
  const devicetypearrayCopy = [
    { label: "Mobile", value: "Mobile" },
    { label: "Tablet", value: "Tablet" },
    { label: "Laptop", value: "Laptop" },
  ];
  const [devicetypeCopy, setDeviceTYpeCopy] = useState([]);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        // show={lgShow}
        centered
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Export Result
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6} md={6} sm={6} xs={12} className="col">
              <label className="formlabel">Vendor</label>
              <div className="multiExport">
                <MultiSelect
                  className="multiwidth"
                  options={devicetypearray}
                  value={devicetype}
                  onChange={(e) => {
                    setDeviceTYpe(e);
                  }}
                  labelledBy={"Select"}
                  isCreatable={true}
                  disableSearch
                />
              </div>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12} className="col">
              <label className="formlabel">Select Coloumns</label>
              <div className="multiExport">
                <MultiSelect
                  options={devicetypearrayCopy}
                  value={devicetypeCopy}
                  onChange={(e) => {
                    setDeviceTYpeCopy(e);
                  }}
                  labelledBy={"Select"}
                  isCreatable={true}
                  disableSearch
                />
              </div>
            </Col>
          </Row>
          <Row>
            {" "}
            <Col>
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  onClick={() => {
                    props.setLgShow(false);
                  }}
                  style={{ marginRight: "10px", backgroundColor: "#999" }}
                  className="supply_setupbtn"
                >
                  Cancle
                </button>
                <button className="supply_setupbtn">Export</button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SurveyListingPopUp;
