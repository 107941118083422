import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import userIco from "../../../assets/LoginIcons/users.svg";
import "../Department/Update_department.css";
import { useNavigate, useLocation } from "react-router";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useState } from "react";
import { Spinner } from "react-bootstrap";

const AddRole = () => {
  const navigate = useNavigate();
  const [all_departments, setAll_department] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    findDepartment();
  }, []);

  const notifyerror = (e) => toast.error(`${e} Error Occure`);

  const notifyerror1 = () => toast.error("Role already exists");

  const handleSaveButton = async (e) => {
    setIsSubmitting(true);
    try {
      await axiosPrivate
        .post(
          "/role/create",
          JSON.stringify({
            name: e.RoleName,
            description: e.Description,
            dept_id: e.Department,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          if (e.statusText === "OK") {
            localStorage.setItem("addrole", "1");
            navigate("/roles");
          } else {
          }
        });
    } catch (err) {
      {
        if (err.response.statusText === "Conflict") {
          notifyerror1();
        } else {
          notifyerror(err.response.statusText);
        }
      }
    }
    setIsSubmitting(false);
  };

  const findDepartment = async () => {
    let isMounted = true;
    try {
      const response = await axiosPrivate.get(
        "/department/all-departments",
        {}
      );
      isMounted && setAll_department(response.data.all_departments);
    } catch (err) {
      navigate("/login", { state: { from: location }, replace: true });
    }
    return () => {
      isMounted = false;
    };
  };

  return (
    <>
      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(handleSaveButton)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img src={userIco} className="headingIcons" alt="Add" /> Add
                  New Role
                </h3>
              </div>
              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={() => {
                    navigate("/roles");
                  }}
                >
                  {" "}
                  Close
                </button>

                <button disabled={isSubmitting} className="cbtn saveBtn">
                  {isSubmitting && (
                    <Spinner
                      as="span"
                      style={{ color: "white" }}
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp; Save
                </button>
              </div>
            </div>
            <hr />
            <div className="mainContent">
              <p className="small note">* denotes required fields</p>
              <Row>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Role Name*</label>
                  <input
                    type="text"
                    placeholder="Role Name"
                    className="formtext"
                    {...register("RoleName", {
                      required: "This field is Required",
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ,-.]*$/,
                        message:
                          "Only letters and Specal Character are allowed",
                      },
                    })}
                  />
                  {errors.RoleName && (
                    <small className="errmsg">{errors.RoleName.message}</small>
                  )}
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Department*</label>
                  <select
                    className="formtext"
                    {...register("Department", {
                      required: "This field is Required",
                    })}
                  >
                    <option value="">Select Option</option>
                    {all_departments.map((e) => (
                      <option value={e._id}>{e.name}</option>
                    ))}
                  </select>
                  {errors.Department && (
                    <small className="errmsg">
                      {errors.Department.message}
                    </small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Description*</label>
                  <input
                    type="text"
                    placeholder="Description"
                    className="formtext"
                    {...register("Description", {
                      required: "This field is Required",
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                        message: "Only letters are allowed",
                      },
                    })}
                  />
                  {errors.Description && (
                    <small className="errmsg">
                      {errors.Description.message}
                    </small>
                  )}
                </Col>
              </Row>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default AddRole;
