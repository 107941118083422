import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Header from "../../../UI/Header/Header";
import { useNavigate, useLocation } from "react-router";
import userIco from "../../../../assets/LoginIcons/users.svg";
import EditIcon from "../../../../assets/LoginIcons/edit.svg";

import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import toast from "react-hot-toast";
import "react-confirm-alert/src/react-confirm-alert.css";
import SpinnerLoader from "../../../UI/Spinner";
import InsufficientPermisiion from "../../../AdminModule/User/InsufficiantPermission";

const MailboxConfigList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterUser, setFilterUser] = useState(null);
  const [filter_data, setFilter_data] = useState([]);
  const [referesh] = useState(0);
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const [pending, setPending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const [dataLoader, setDataLoader] = useState(true);
  const [permission, setPermission] = useState("");
  const notifySuccess = (msg) => {
    toast.success((t) => (
      <span style={{ color: "white" }}>
        {msg}
        <button className="closeToaster" onClick={() => toast.dismiss(t.id)}>
          x
        </button>
      </span>
    ));
  };

  useEffect(() => {
    let role = localStorage.getItem("addrole");
    let updaterole = localStorage.getItem("updaterole");
    if (role === "1") {
      notifySuccess("Mailbox added successfully");
    }
    if (updaterole === "1") {
      notifySuccess("Mailbox updated successfully");
    }
    localStorage.setItem("updaterole", 0);
    localStorage.setItem("addrole", 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRoleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referesh]);

  const getRoleList = async () => {
    setDataLoader(true);
    let isMounted = true;
    try {
      const response = await axiosPrivate.get("mailbox/all-mailbox", {});
      isMounted && setFilter_data(response.data.all_mailbox);
      setPending(false);
    } catch (err) {
      if (
        err.response.data.return_status.message[0] == "Insufficient permissions"
      ) {
        setPermission("Insufficient permissions");
      }
      // navigate("/login", { state: { from: location }, replace: true });
    }
    setDataLoader(false);
    return () => {
      isMounted = false;
    };
  };

  const columns = [
    {
      id: "sno",
      name: "Sno.",
      selector: (row, i) => currentRowsPerPage * (currentPage - 1) + (i + 1),
      width: "100px",
      grow: 0,
    },
    {
      id: "Firstname",
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      id: "email_id",
      name: "Email",
      selector: (row) => row.email_id,
      sortable: true,
    },
    {
      id: "label",
      name: "Label",
      selector: (row) => row.label,
      sortable: true,
    },
    {
      id: "cc_email",
      name: "CC Email",
      selector: (row) => row.cc_email,
      sortable: true,
    },
    {
      id: "bcc_email",
      name: "BCC Email",
      selector: (row) => row.bcc_email,
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => (
        <td>
          <span
            className="actionButton"
            onClick={() => {
              navigate(`/update-mailbox-config/${row._id}`);
            }}>
            <img src={EditIcon} alt="" />
          </span>
        </td>
      ),
      width: "110px",
    },
  ];

  const showSearch = (searchText) => {
    const copyOfUsers = [...filter_data];
    setSearch(searchText);
    const result = copyOfUsers.filter((points) => {
      return (
        points.name
          .toString()
          .toLowerCase()
          .match(searchText.toString().toLowerCase()) ||
        points.email_id
          .toString()
          .toLowerCase()
          .match(searchText.toString().toLowerCase()) ||
        points.label
          .toString()
          .toLowerCase()
          .match(searchText.toString().toLowerCase()) ||
        points.cc_email
          .toString()
          .toLowerCase()
          .match(searchText.toString().toLowerCase()) ||
        points.bcc_email
          .toString()
          .toLowerCase()
          .match(searchText.toString().toLowerCase())
      );
    });
    setFilterUser(result);
  };

  return (
    <>
      {dataLoader === true ? (
        <>
          <SpinnerLoader />
        </>
      ) : (
        <>
          {" "}
          {permission === "Insufficient permissions" ? (
            <>
              {" "}
              <InsufficientPermisiion />
            </>
          ) : (
            <>
              {" "}
              <div className="top_container">
                <div className="main_container ">
                  <div className="user_serachbox">
                    <div style={{ float: "left" }}>
                      <h3 className="tableHeading">
                        <img
                          src={userIco}
                          className="headingIcons"
                          alt="Roles"
                        />{" "}
                        Mailbox Config
                      </h3>
                    </div>
                    <input
                      type="search"
                      className="tableSearch"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => showSearch(e.target.value)}
                    />
                  </div>
                  <hr />

                  <div className="mainContent dTable">
                    <DataTable
                      columns={columns}
                      data={filterUser || filter_data}
                      pagination
                      fixedHeader
                      onChangePage={(page, totalRows) => {
                        setCurrentPage(page);
                      }}
                      onChangeRowsPerPage={(
                        currentRowsPerPage,
                        currentPage
                      ) => {
                        setCurrentRowsPerPage(currentRowsPerPage);
                      }}
                      highlightOnHover
                      defaultSortFieldId="Firstname"
                      defaultSortAsc={true}
                      progressPending={pending}
                      progressComponent={<SpinnerLoader />}
                      noDataComponent="No Data Found"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MailboxConfigList;
