import Modal from "react-bootstrap/Modal";
import React from "react";
import Table from "react-bootstrap/Table";
import { FiDownload, FiEdit } from "react-icons/fi";
import { GrLink } from "react-icons/gr";

import SupplierLink from "../PopUpPage/SupplierLInkquota";
import EditSupplier from "../PopUpPage/EditSupplierquota";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Project3(props) {
  const [editSupplier, setEditSupplier] = useState(false);
  const [supplierLink, setSupplierLink] = useState(false);
  return (
    <>
      <EditSupplier
        show={editSupplier}
        onHide={() => setEditSupplier(false)}
        props={props}
        setEditSupplier={setEditSupplier}
      />
      <SupplierLink
        show={supplierLink}
        onHide={() => setSupplierLink(false)}
        props={props}
        setSupplierLink={setSupplierLink}
      />
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="quota_survey_track_title"
            id="contained-modal-title-vcenter"
            style={{
              fontSize: "14px",
              paddingLeft: "3px",
            }}
          >
            Project 3 - US Gen Pop 18+
            <div className="">
              <button className="RespData">Respondent Data</button>
              <button className="Screendata">Screening Data</button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr style={{ backgroundColor: "#f1efef" }} className="tabledata">
                <th
                  style={{
                    borderBottom: "none",
                  }}
                >
                  Survey Parameter
                </th>
                <th>Survey Details</th>
                <th>Disqo API</th>
                <th>Fulcrum API</th>
                <th>Theorem API</th>
                <th>Prodege API</th>
                <th>Purespectrum API</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "11px" }}>
              <tr>
                <td
                  style={{
                    backgroundColor: "#f1efef",
                    borderBottom: "none",
                  }}
                ></td>
                <td>
                  <span className="fifteenspan">0/</span>
                  15
                </td>
                <td>
                  <span className="fifteenspan">0/</span>
                  15
                </td>
                <td>
                  <span className="fifteenspan">0/</span>
                  15
                </td>
                <td>
                  <span className="fifteenspan">0/</span>
                  15
                </td>
                <td>
                  <span className="fifteenspan">0/</span>
                  15
                </td>
                <td>
                  <span className="fifteenspan">0/</span>
                  15
                </td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">PreScreen</td>
                <td>
                  <div className="inactive_qutobtn">Inactive</div>
                </td>
                <td>
                  <div className="off_qutobtn">OFF</div>
                </td>
                <td>
                  <div className="off_qutobtn">OFF</div>
                </td>{" "}
                <td>
                  <div className="off_qutobtn">OFF</div>
                </td>{" "}
                <td>
                  <div className="off_qutobtn">OFF</div>
                </td>{" "}
                <td>
                  <div className="off_qutobtn">OFF</div>
                </td>
              </tr>
              <tr className="tabledata">
                <td
                  style={{
                    backgroundColor: "#f1efef",
                    borderBottom: "none",
                  }}
                >
                  Started
                </td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
              </tr>
              <tr className="tabledata">
                <td
                  style={{
                    backgroundColor: "#f1efef",
                    borderBottom: "none",
                  }}
                >
                  Incomplete SC/CL
                </td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Started</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Started</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
              </tr>
              <tr className="tabledata">
                <td
                  // style={{
                  //   backgroundColor: "#f1efef",
                  //   borderBottom: "none",
                  // }}
                  className="qutasiderow"
                >
                  Incomplete SC/CL
                </td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Started</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
              </tr>{" "}
              <tr className="tabledata">
                <td className="qutasiderow">Started</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Incomplete SC/CL</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
                <td>1/20</td>
              </tr>
              <tr className="tabledata">
                <td className="qutasiderow">Started</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
                <td>65</td>
              </tr>
              {
                <tr
                  style={{
                    fontSize: "18px",
                    backgroundColor: "#f0efef",
                    borderBottom: "none",
                  }}
                >
                  <td></td>
                  <td>
                    <FiDownload />
                    &nbsp;
                    <GrLink
                      onClick={() => {
                        setSupplierLink(true);
                        props.setProject(false);
                      }}
                    />
                    &nbsp;
                    <FiEdit
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditSupplier(true);
                        props.setProject(false);
                      }}
                    />
                  </td>
                  <td>
                    <FiDownload />
                    &nbsp;
                    <GrLink
                      onClick={() => {
                        setSupplierLink(true);
                        props.setProject(false);
                      }}
                    />
                    &nbsp;
                    <FiEdit
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditSupplier(true);
                        props.setProject(false);
                      }}
                    />
                  </td>{" "}
                  <td>
                    <FiDownload />
                    &nbsp;
                    <GrLink
                      onClick={() => {
                        setSupplierLink(true);
                        props.setProject(false);
                      }}
                    />
                    &nbsp;
                    <FiEdit
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditSupplier(true);
                        props.setProject(false);
                      }}
                    />
                  </td>{" "}
                  <td>
                    <FiDownload />
                    &nbsp;
                    <GrLink
                      onClick={() => {
                        setSupplierLink(true);
                        props.setProject(false);
                      }}
                    />
                    &nbsp;
                    <FiEdit
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditSupplier(true);
                        props.setProject(false);
                      }}
                    />
                  </td>{" "}
                  <td>
                    <FiDownload />
                    &nbsp;
                    <GrLink
                      onClick={() => {
                        setSupplierLink(true);
                        props.setProject(false);
                      }}
                    />
                    &nbsp;
                    <FiEdit
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditSupplier(true);
                        props.setProject(false);
                      }}
                    />
                  </td>{" "}
                  <td>
                    <FiDownload />
                    &nbsp;
                    <GrLink
                      onClick={() => {
                        setSupplierLink(true);
                        props.setProject(false);
                      }}
                    />
                    &nbsp;
                    <FiEdit
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditSupplier(true);
                        props.setProject(false);
                      }}
                    />
                  </td>
                </tr>
              }
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Project3;
