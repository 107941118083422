import Supplyheader from "./Supplyheader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import toast from "react-hot-toast";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useNavigate } from "react-router";
import userIco from "../../assets/LoginIcons/users.svg";
import Select from "react-select";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import SpinnerLoader from "../UI/Spinner";
function Editsupplierquality(props) {
  const [selected, setSelected] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prescreening, setprescreening] = useState();
  const [qualiﬁcation, setQualiﬁcation] = useState();
  const [screen, setScreen] = useState();
  const [herring, setHerring] = useState();

  const axiosPrivate = useAxiosPrivate();
  const [countryselect, setCountrySelect] = useState();
  const [countryListStorage, setCountryListStorage] = useState([{}]);
  const [data, setData] = useState([]);
  const [firstName, setfirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [respIdVar, setRespIdVar] = useState("");
  const [reconRespVar, setReconRespVar] = useState("");
  const [cpiLogic, setCpiLogic] = useState("");
  const [isApi, setIsApi] = useState("");
  const [isPublisher, setIsPublisher] = useState("");
  const [vendorAbbr, setVendorAbbr] = useState("");
  const [preScreening, setPreScreening] = useState("");
  const [qualificationScreen, setQualificationScreen] = useState("");
  const [screenCaptcha, setScreenCaptcha] = useState("");
  const [redHerringScreen, setRedHerringScreen] = useState("");
  const [recontactOldIdVar, setRecontactOldIdVar] = useState("");
  const [status, setStatus] = useState("");
  const [loder, setLoder] = useState(false);
  const [cloader, setCloader] = useState(false);
  const [formData, setFormData] = useState({
    fname: "",
    email: "",
    phone: "",
    billingAddress: "",
    countryid: "",
    countryname: "",
    state: "",
    city: "",
    zipcode: "",
    respIdVar: "",
    reconRespVar: "",
    cpiLogic: "",
    isAPI: "",
    isPublisher: "",
    vendorAbbr: "",
    preScreening: "",
    qualificationScreen: "",
    screenCaptcha: "",
    redHerringScreen: "",
    recontactOldIdVar: "",
    status: "",
  });
  const { id } = useParams();
  useEffect(() => {
    fetchDataWithToken();
  }, []);
  const notifysucces = () => toast.success("Supplier updated successfully");
  const fetchDataWithToken = async () => {
    setLoder(false);
    setCloader(true);
    const token = "YOUR_API_TOKEN"; // Replace 'YOUR_API_TOKEN' with your actual API token
    const url = `http://52.13.201.242:8003/api/v1/supplyinfo/get-supplier/${id}`; // Replace 'YOUR_API_URL' with the URL of the API you want to fetch

    try {
      const response = await axios.get(url, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "access-token":
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIyOTExMzgsIm5iZiI6MTY5MjI5MTEzOCwianRpIjoiZDgxOWRiYjgtYjBkYi00MjAwLTk3YjMtY2M4M2FhYzUzYTkzIiwiZXhwIjoxNjkyNzIzMTM4LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.ec0MG1JkCkJokFGtZ-1sMNrehSE1qCIAhywLxXRC1sg",
        },
      });
      setFormData((prevData) => ({
        ...prevData,
        fname: response.data.supplier.name,
        email: response.data.supplier.email,
        phone: response.data.supplier.phone,
        billingAddress: response.data.supplier.address.billing_address,
        countryid: response.data.supplier.address.country.id,
        countryname: response.data.supplier.address.country.name,
        state: response.data.supplier.address.state,
        city: response.data.supplier.address.city,
        zipcode: response.data.supplier.address.zipcode,
        respIdVar: "",
        reconRespVar: "",
        cpiLogic: "",
        isAPI: response.data.supplier.is_api,
        isPublisher: response.data.supplier.is_publisher,
        vendorAbbr: response.data.supplier.supplier_abbr,
        preScreening: response.data.supplier.screening.has_screening,
        qualificationScreen: response.data.supplier.screening.has_qualification,

        screenCaptcha: response.data.supplier.screening.screen_captcha,
        redHerringScreen: response.data.supplier.screening.has_job_type,
        recontactOldIdVar: "",
        status: response.data.supplier.status,
      }));
      setCountrySelect(response.data.supplier.address.country.id);
      setprescreening(response.data.supplier.screening.has_screening);
      setQualiﬁcation(response.data.supplier.screening.has_qualification);
      setScreen(response.data.supplier.screening.screen_captcha);
      setHerring(response.data.supplier.screening.has_job_type);
      setLoder(true);
      setCloader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const get_ProjectType_api = async () => {
    setLoder(false);
    setCloader(true);
    try {
      const response = await axiosPrivate.get(`supplyinfo/get-supplier/${id}`);
      // setData(response.data.supplier);
      setFormData((prevData) => ({
        ...prevData,
        fname: response.data.supplier.name,
        email: response.data.supplier.email,
        phone: response.data.supplier.phone,
        billingAddress: response.data.supplier.address.billing_address,
        countryid: response.data.supplier.address.country.id,
        countryname: response.data.supplier.address.country.name,
        state: response.data.supplier.address.state,
        city: response.data.supplier.address.city,
        zipcode: response.data.supplier.address.zipcode,
        respIdVar: "",
        reconRespVar: "",
        cpiLogic: "",
        isAPI: response.data.supplier.is_api,
        isPublisher: response.data.supplier.is_publisher,
        vendorAbbr: response.data.supplier.supplier_abbr,
        preScreening: response.data.supplier.screening.has_screening,
        qualificationScreen: response.data.supplier.screening.has_qualification,

        screenCaptcha: response.data.supplier.screening.screen_captcha,
        redHerringScreen: response.data.supplier.screening.has_job_type,
        recontactOldIdVar: "",
        status: response.data.supplier.status,
      }));
      setCountrySelect(response.data.supplier.address.country.id);
      setprescreening(response.data.supplier.screening.has_screening);
      setQualiﬁcation(response.data.supplier.screening.has_qualification);
      setScreen(response.data.supplier.screening.screen_captcha);
      setHerring(response.data.supplier.screening.has_job_type);

      setLoder(true);
      setCloader(false);
    } catch (err) {
      console.error(err);
    }
  };

  const pname = "SupplierDetails";
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  useEffect(() => {
    get_Country_api();
  }, []);
  const get_Country_api = async () => {
    setCloader(true);
    try {
      const response = await axiosPrivate.get("lookup/country-list");
      response.data.all_countries.map((e) => {
        setCountryListStorage((val) => [
          ...val,
          { label: e.country_name, value: e._id },
        ]);
      });
      setCloader(false);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    setValue("Country", formData.countryid);
  }, [formData.countryid]);

  const handleSubmitForm = async (e) => {
    var postData = {
      name: e.firstName,
      supplier_abbr: e.VendorAbbr,
      email: e.email,
      phone: e.contactNumber,
      address: {
        billing_address: e.BillingAddress,
        country_id: countryselect,
        state: e.state,
        city: e.city,
        zipcode: e.zipcode,
      },
      screening: {
        has_screening: prescreening,
        has_qualification: qualiﬁcation,
        screen_captcha: screen,
        has_job_type: herring,
      },
      // is_api: true,
      // is_publisher: true,
      is_publisher: e.Publisher,
      is_api: e.isApi,
      status: e.status,
    };
    const response = await fetch(
      `http://52.13.201.242:8003/api/v1/supplyinfo/update-supplier/${id}`,
      {
        method: "POST",
        body: JSON.stringify({ postData }),
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "access-token":
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIyOTExMzgsIm5iZiI6MTY5MjI5MTEzOCwianRpIjoiZDgxOWRiYjgtYjBkYi00MjAwLTk3YjMtY2M4M2FhYzUzYTkzIiwiZXhwIjoxNjkyNzIzMTM4LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.ec0MG1JkCkJokFGtZ-1sMNrehSE1qCIAhywLxXRC1sg",
        },
      }
    );
    const result = await response.json();
    setIsSubmitting(false);
  };
  const handleSaveButton = async (e, data) => {
    setIsSubmitting(true);
    try {
      await axiosPrivate
        .post(
          `supplyinfo/update-supplier/${id}`,
          JSON.stringify({
            name: e.firstName,
            supplier_abbr: e.VendorAbbr,
            email: e.email,
            phone: e.contactNumber,
            address: {
              billing_address: e.BillingAddress,
              country_id: countryselect,
              state: e.state,
              city: e.city,
              zipcode: e.zipcode,
            },
            screening: {
              has_screening: prescreening,
              has_qualification: qualiﬁcation,
              screen_captcha: screen,
              has_job_type: herring,
            },
            // is_api: true,
            // is_publisher: true,
            recon_resp: e.ReconId,
            resp_id: e.RespId,
            recon_old: e.Recontact,
            is_publisher: e.Publisher,
            is_api: e.isApi,
            status: e.status,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysucces();
            navigate("/supplier-details");
          }
        });
      setIsSubmitting(false);
    } catch (err) {}
  };

  return (
    <>
      <Supplyheader pname={pname} />
      {loder ? (
        <Row className="supplierParents csmulti">
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Row className="suppliyChild">
              <div className="user_serachbox">
                <div style={{ float: "left" }}>
                  <h3 className="tableHeading">
                    <img src={userIco} className="headingIcons" alt="" />
                    {formData.fname}
                  </h3>
                </div>
                <div className="right-pane">
                  <button
                    className="cbtn closeBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/supplier-details");
                    }}
                  >
                    {" "}
                    Close
                  </button>

                  <button
                    // disabled={isSubmitting}
                    className="cbtn saveBtn"
                    type="submit"
                  >
                    &nbsp;&nbsp; Save
                  </button>
                </div>
              </div>
              <hr
                style={{
                  marginTop: "20px",
                }}
              />
              {loder ? (
                <>
                  <Row style={{ margin: "0px" }}>
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">Name*</label>
                      <input
                        placeholder="Name"
                        className="formtextamit"
                        type="text"
                        {...register("firstName", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                        defaultValue={formData.fname}
                      />
                      {errors.firstName && (
                        <small className="errmsg">
                          {errors.firstName.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">Email*</label>
                      <input
                        placeholder="Email"
                        className="formtextamit"
                        type="text"
                        {...register("email", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line
                            message: "Enter Valid Email Id",
                          },
                        })}
                        defaultValue={formData.email}
                      />
                      {errors.email && (
                        <small className="errmsg">{errors.email.message}</small>
                      )}
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">Phone*</label>
                      <input
                        placeholder="Phone"
                        className="formtextamit"
                        type="text"
                        {...register("contactNumber", {
                          required: "This field is Required",
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only numbers are allowed",
                          },
                        })}
                        defaultValue={formData.phone}
                      />
                      {errors.contactNumber && (
                        <small className="errmsg">
                          {errors.contactNumber.message}
                        </small>
                      )}
                    </Col>{" "}
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">Billing Address*</label>
                      <input
                        placeholder="Billing Address"
                        className="formtextamit"
                        type="text"
                        {...register("BillingAddress", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                        defaultValue={formData.billingAddress}
                      />
                      {errors.BillingAddress && (
                        <small className="errmsg">
                          {errors.BillingAddress.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">Country*</label>

                      {/* <Select
                        placeholder="Select..."
                        className="pjt_select formtext"
                        classNamePrefix="select"
                        {...register("Country", {
                          required: "This field is Required",
                        })}
                        isLoading={cloader}
                        options={countryListStorage.map((option) => ({
                          value: option.value,
                          label: option.label,
                          key: option.value, // Ensure each option has a unique key
                        }))}
                        onChange={(e) => {
                          setCountrySelect(e.value, e.key);
                        }}
                        // defaultValue={countryListStorage.find(
                        //   (option) => option.value === countryselect
                        // )} // Set the value using find()
                        defaultValue={countryListStorage[countryselect]}
                        noOptionsMessage={() => "No Data Found"}
                      /> */}
                      {/* <Select
                        placeholder="Select..."
                        className="pjt_select formtext"
                        classNamePrefix="select"
                        {...register("Country", {
                          required: "This field is Required",
                        })}
                        // isLoading={cloader}
                        options={countryListStorage.map((option) => ({
                          value: option.value,
                          label: option.label,
                          key: option.value, // Ensure each option has a unique key
                        }))}
                        onChange={(e) => {
                          setCountrySelect(e.value, e.key);
                          setFormData((prevData) => ({
                            ...prevData,
                            countryid: e.value,
                          }));
                        }}
                        // defaultValue={countryListStorage.find(
                        //   (option) => option.value === formData.countryid
                        // )} // Set the value using key
                        defaultValue={countryListStorage[4]}
                        noOptionsMessage={() => "No Data Found"}
                      /> */}
                      <Select
                        placeholder="Select..."
                        className="pjt_select formtext"
                        classNamePrefix="select"
                        {...register("Country", {
                          required: "This field is Required",
                        })}
                        isLoading={cloader}
                        options={countryListStorage.map((option) => ({
                          value: option.value,
                          label: option.label,
                          key: option.value, // Ensure each option has a unique key
                        }))}
                        onChange={(e) => {
                          setCountrySelect(e.value, e.key);

                          setFormData((prevData) => ({
                            ...prevData,
                            countryid: e.value,
                          }));
                        }}
                        value={countryListStorage.find(
                          (option) => option.value === formData.countryid
                        )} // Set the initial value and make the field editable
                        noOptionsMessage={() => "No Data Found"}
                      />

                      {countryselect ? (
                        <></>
                      ) : (
                        <>
                          {errors.Country && (
                            <small className="errmsg perrmsg">
                              {errors.Country.message}
                            </small>
                          )}
                        </>
                      )}
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">State</label>
                      <input
                        placeholder="State"
                        className="formtextamit"
                        type="text"
                        {...register("state", {
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                        defaultValue={formData.state}
                      />
                      {errors.state && (
                        <small className="errmsg">{errors.state.message}</small>
                      )}
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">City</label>
                      <input
                        placeholder="City"
                        className="formtextamit"
                        type="text"
                        {...register("city", {
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                        defaultValue={formData.city}
                      />
                      {errors.city && (
                        <small className="errmsg">{errors.city.message}</small>
                      )}
                    </Col>{" "}
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">Zipcode</label>
                      <input
                        placeholder="Zipcode"
                        className="formtextamit"
                        type="text"
                        {...register("Zipcode", {
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only numbers are allowed",
                          },
                        })}
                        defaultValue={formData.zipcode}
                      />
                      {errors.Zipcode && (
                        <small className="errmsg">
                          {errors.Zipcode.message}
                        </small>
                      )}
                    </Col>
                  </Row>{" "}
                  <Row style={{ margin: "0px" }}>
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">Resp id var*</label>
                      <input
                        placeholder="None"
                        className="formtextamit"
                        type="text"
                        {...register("RespId", {
                          required: "This field is Required",
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only numbers are allowed",
                          },
                        })}
                      />
                      {errors.RespId && (
                        <small className="errmsg">
                          {errors.RespId.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">Recon resp var*</label>
                      <input
                        placeholder="None"
                        className="formtextamit"
                        type="text"
                        {...register("ReconId", {
                          required: "This field is Required",
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only numbers are allowed",
                          },
                        })}
                      />
                      {errors.ReconId && (
                        <small className="errmsg">
                          {errors.ReconId.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">CPI Logic (in %)*</label>
                      <input
                        placeholder="CPI Logic"
                        className="formtextamit"
                        type="text"
                        {...register("CPI", {
                          required: "This field is Required",
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only numbers are allowed",
                          },
                        })}
                      />
                      {errors.CPI && (
                        <small className="errmsg">{errors.CPI.message}</small>
                      )}
                    </Col>{" "}
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">Is API*</label>
                      {/* <input
                      placeholder="Select Choice"
                      className="formtextamit"
                      type="text"
                      {...register("isApi", {
                        required: "This field is Required",
                      })}
                      defaultValue={formData.isAPI}
                    /> */}
                      <select
                        className="formtext"
                        {...register("isApi", {
                          required: "This field is Required",
                        })}
                        defaultValue={formData.isAPI === true ? 1 : 0}
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                      {errors.isApi && (
                        <small className="errmsg">{errors.isApi.message}</small>
                      )}
                    </Col>
                  </Row>{" "}
                  <Row style={{ margin: "0px" }}>
                    <Col lg={6} md={12} sm={12} xs={12}>
                      <Row>
                        {" "}
                        <Col lg={6} md={6} sm={6} xs={12}>
                          <label className="formlabelamit">Is Publisher*</label>
                          {/* <input
                            placeholder="No"
                            className="formtextamit"
                            type="text"
                            {...register("Publisher", {
                              required: "This field is Required",
                              pattern: {
                                value:
                                  /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                                message: "Only letters are allowed",
                              },
                            })}
                            defaultValue={formData.isPublisher}
                          />
                          {errors.Publisher && (
                            <small className="errmsg">
                              {errors.Publisher.message}
                            </small>
                          )} */}
                          <select
                            className="formtext"
                            {...register("Publisher", {
                              required: "This field is Required",
                            })}
                            defaultValue={formData.isPublisher === true ? 1 : 0}
                          >
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                          {errors.Publisher && (
                            <small className="errmsg">
                              {errors.Publisher.message}
                            </small>
                          )}
                        </Col>{" "}
                        <Col lg={6} md={6} sm={6} xs={12}>
                          <label className="formlabelamit">Vendor Abbr*</label>
                          <input
                            placeholder="Vendor Abbr"
                            className="formtextamit"
                            type="text"
                            {...register("VendorAbbr", {
                              required: "This field is Required",
                              pattern: {
                                value:
                                  /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                                message: "Only letters are allowed",
                              },
                            })}
                            defaultValue={formData.vendorAbbr}
                          />
                          {errors.VendorAbbr && (
                            <small className="errmsg">
                              {errors.VendorAbbr.message}
                            </small>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12}>
                      <Row>
                        {/* <Col lg={3} md={3} sm={6} xs={6}>
                          <label className="formlabelamit">Pre-Screening</label>
                          <div className="tooglecenter pswtchbtn">
                            <BootstrapSwitchButton
                              {...register("Screening")}
                              className="switch btn on btn-outline-success btn-xs"
                              size="xs"
                              width={60}
                              onlabel="On "
                              offlabel="Off"
                              onstyle="outline-success"
                              offstyle="outline-danger"
                              onChange={(e) => {
                                setprescreening(e);
                              }}
                              checked={preScreening}
                            />
                          </div>
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={6}>
                          <label className="formlabelamit">
                            Qualiﬁcation screen
                          </label>
                          <div className="tooglecenter pswtchbtn">
                            <BootstrapSwitchButton
                              {...register("Qualiﬁcation")}
                              className="switch btn on btn-outline-success btn-xs"
                              size="xs"
                              width={60}
                              onlabel="On "
                              offlabel="Off"
                              onstyle="outline-success"
                              offstyle="outline-danger"
                              onChange={(e) => {
                                setQualiﬁcation(e);
                              }}
                              checked={qualiﬁcation}
                            />
                          </div>
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={6}>
                          <label className="formlabelamit">
                            Screen Captcha
                          </label>
                          <div className="tooglecenter pswtchbtn">
                            <BootstrapSwitchButton
                              className="switch btn on btn-outline-success btn-xs"
                              size="xs"
                              width={60}
                              onlabel="On "
                              offlabel="Off"
                              onstyle="outline-success"
                              offstyle="outline-danger"
                              onChange={(e) => {
                                setScreen(e);
                              }}
                              checked={screen}
                            />
                          </div>
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={6}>
                          <label className="formlabelamit">
                            Red Herring Screen
                          </label>
                          <div className="tooglecenter pswtchbtn">
                            <BootstrapSwitchButton
                              className="switch btn on btn-outline-success btn-xs"
                              size="xs"
                              width={60}
                              onlabel="On "
                              offlabel="Off"
                              onstyle="outline-success"
                              offstyle="outline-danger"
                              onChange={(e) => {
                                setHerring(e);
                              }}
                              checked={herring}
                            />
                          </div>
                        </Col> */}
                        <Col lg={6} md={3} sm={6} xs={12}>
                          <label className="formlabelamit">
                            Recontact Old Id Var
                          </label>
                          <input
                            placeholder="Quota"
                            className="formtextamit"
                            type="text"
                            {...register("Recontact", {
                              pattern: {
                                value:
                                  /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                                message: "Only letters are allowed",
                              },
                            })}
                          />
                        </Col>
                        <Col lg={6} md={3} sm={6} xs={12}>
                          <label className="formlabelamit">Status</label>
                          <input
                            placeholder="Status"
                            className="formtextamit"
                            type="text"
                            {...register("Status", {
                              pattern: {
                                value:
                                  /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                                message: "Only letters are allowed",
                              },
                            })}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>{" "}
                  <Row style={{ margin: "0px" }}>
                    {/* <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">
                        Recontact Old Id Var
                      </label>
                      <input
                        placeholder="Quota"
                        className="formtextamit"
                        type="text"
                        {...register("Recontact", {
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                      />
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <label className="formlabelamit">Status</label>
                      <input
                        placeholder="Status"
                        className="formtextamit"
                        type="text"
                        {...register("Status", {
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                      />
                    </Col> */}
                    <Col lg={3} md={3} sm={6} xs={6}>
                      <label className="formlabelamit">Pre-Screening</label>
                      <div className="tooglecenter pswtchbtn">
                        <BootstrapSwitchButton
                          {...register("Screening")}
                          className="switch btn on btn-outline-success btn-xs"
                          size="xs"
                          width={60}
                          onlabel="On "
                          offlabel="Off"
                          onstyle="outline-success"
                          offstyle="outline-danger"
                          onChange={(e) => {
                            setprescreening(e);
                          }}
                          checked={preScreening}
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                      <label className="formlabelamit">
                        Qualiﬁcation screen
                      </label>
                      <div className="tooglecenter pswtchbtn">
                        <BootstrapSwitchButton
                          {...register("Qualiﬁcation")}
                          className="switch btn on btn-outline-success btn-xs"
                          size="xs"
                          width={60}
                          onlabel="On "
                          offlabel="Off"
                          onstyle="outline-success"
                          offstyle="outline-danger"
                          onChange={(e) => {
                            setQualiﬁcation(e);
                          }}
                          checked={qualiﬁcation}
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                      <label className="formlabelamit">Screen Captcha</label>
                      <div className="tooglecenter pswtchbtn">
                        <BootstrapSwitchButton
                          className="switch btn on btn-outline-success btn-xs"
                          size="xs"
                          width={60}
                          onlabel="On "
                          offlabel="Off"
                          onstyle="outline-success"
                          offstyle="outline-danger"
                          onChange={(e) => {
                            setScreen(e);
                          }}
                          checked={screen}
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                      <label className="formlabelamit">
                        Red Herring Screen
                      </label>
                      <div className="tooglecenter pswtchbtn">
                        <BootstrapSwitchButton
                          className="switch btn on btn-outline-success btn-xs"
                          size="xs"
                          width={60}
                          onlabel="On "
                          offlabel="Off"
                          onstyle="outline-success"
                          offstyle="outline-danger"
                          onChange={(e) => {
                            setHerring(e);
                          }}
                          checked={herring}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <SpinnerLoader />
              )}
            </Row>
          </form>
        </Row>
      ) : (
        <SpinnerLoader />
      )}
    </>
  );
}
export default Editsupplierquality;
