import DisputedError from "../PopUpPage/DisputedError";
import ErrorCreated from "../PopUpPage/ErrorCreated";
import Project3 from "../PopUpPage/Project3";
import SurveyLogs from "../PopUpPage/SurveyLogs";
import QualityAuditLog from "./QualityAuditLOgs";
import { useState } from "react";
const Demo = () => {
  const [DisError, setDisError] = useState(false);
  const [ErrCreated, setErrCreated] = useState(false);
  const [Project, setProject] = useState(false);
  const [SurLogs, setSurLogs] = useState(false);
  const [Audit, setAudit] = useState(false);

  return (
    <>
      <DisputedError show={DisError} onHide={() => setDisError(false)} />
      <ErrorCreated show={ErrCreated} onHide={() => setErrCreated(false)} />
      <Project3 show={Project} onHide={() => setProject(false)} />
      <SurveyLogs show={SurLogs} onHide={() => setSurLogs(false)} />
      <QualityAuditLog show={Audit} onHide={() => setAudit(false)} />

      <button
        onClick={() => {
          setErrCreated(true);
        }}>
        ErrorCreated
      </button>
      <button
        onClick={() => {
          setDisError(true);
        }}>
        DisputedError
      </button>

      <button
        onClick={() => {
          setProject(true);
        }}>
        Project3
      </button>
      <button
        onClick={() => {
          setSurLogs(true);
        }}>
        SurveyLogs
      </button>
      <button
        onClick={() => {
          setAudit(true);
        }}>
        Audit
      </button>
    </>
  );
};

export default Demo;
