import "./CreateBid.css";
import Header from "../../UI/Header/Header";
import BidsSubHeader from "../BidsSubHeader/BidsSubHeader";
import { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router";
import userIco from "../../../assets/LoginIcons/users.svg";
import DeleteIcon from "../../../assets/LoginIcons/delete.svg";
import toast from "react-hot-toast";
import ButtonSpinner from "../../UI/ButtonSpinner";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useParams } from "react-router";
import SpinnerLoader from "../../UI/Spinner";
import Countries from "../BidDetails/CommonServices/Countries";
import ProjectType from "../BidDetails/CommonServices/ProjectType";
import ServiceType from "../BidDetails/CommonServices/ServiceType";
import PMList from "../BidDetails/CommonServices/PMList";
import BidMarginPercent from "../BidDetails/CommonServices/BidMarginPercent";
import { CalcBidAmt } from "./CalcBidAmt";
import Modal from "react-modal";
import { ModalBody, ModalHeader } from "react-bootstrap";
import useAuth from "../../../hooks/useAuth";
const EditOpportunity = () => {
  const navigate = useNavigate();
  var today = new Date();
  var nextweek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 7
  );
  var nextmonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );
  const [editorData, setEditorData] = useState("");
  const [receivedDate, setReceivedDate] = useState(today);
  const [closeDate, setCloseDate] = useState(nextmonth);
  const [projectStartDate, setProjectStartDate] = useState(today);
  const [projectEndDate, setProjectEndDate] = useState(nextweek);
  const [bidAmt, setBidAmt] = useState(0);
  const [bookedAmt, setBookedAmt] = useState(0);
  const [dollarAmount, setDollarAmount] = useState();
  const [oppStatusList, setOppStatusList] = useState([]);
  const [opportunityStatus, setOpportunityStatus] = useState();
  const [pm, setPM] = useState();
  const [projectType, setProjectType] = useState();
  const [passReason, setPassReason] = useState();
  const [oppData, setOppData] = useState([]);
  const [bidPercentage, setBidPercentage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const showOpportunity = ["2", "3", 2, 3, ""];
  const { bidid } = useParams();
  const { oppid } = useParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [emailCont, setEmailCont] = useState();
  const [subject, setSubject] = useState();
  const [flag, setFlag] = useState(false);
  const { auth } = useAuth();

  // ..........................permisssion.........
  const superUser = auth.superuser;
  const all_permission = auth.permission;

  const notifyerr = (msg) => toast.error(msg);
  const axiosPrivate = useAxiosPrivate();
  const updateCloseDate = (rdate) => {
    let date = new Date(rdate);
    date.setDate(date.getDate() + 30);
    setCloseDate(date);
    setValue("close_date", date);
  };

  const {
    register,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      opportunities: [
        {
          lineItem: "",
          service: "",
          geography: "",
          ir: "",
          loi: "",
          n: "",
          cpi: "",
        },
      ],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "opportunities",
  });

  const oppStatus = watch("opp_status");
  const pReason = watch("pass_reason");

  useEffect(() => {
    oppDetail();
    getServiceType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServiceType = async () => {
    try {
      await axiosPrivate.get("lookup/get-service-type", {});
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getOppStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityStatus]);

  const oppDetail = async () => {
    try {
      //setIsLoading(false)
      const response = await axiosPrivate.get(
        `bid/get-bid/${bidid}/${oppid}`,
        {}
      );
      setIsLoading(false);
      let opportunity = response.data.opportunity.opportunity[0];
      if (opportunity != null && opportunity) {
        setOppData(opportunity);
        setProjectType(opportunity.project_type.id);
        setOpportunityStatus(opportunity.status.id);
        setEditorData(opportunity.client_bid_details);
        setBidAmt(opportunity.bid_amount);
        setReceivedDate(opportunity.received_date);
        setCloseDate(opportunity.closed_date);

        if (parseInt(opportunity.status.id) === 6) {
          setPassReason(opportunity.pass_reason.id);
        }
        if (parseInt(opportunity.status.id) === 3) {
          setBookedAmt(opportunity.booked_amount);
          setBidPercentage(opportunity.margin_bid_amount.id);
          if ("project_start_date" in opportunity) {
            setProjectStartDate(opportunity.project_start_date);
          }
          if ("project_end_date" in opportunity) {
            setProjectEndDate(opportunity.project_end_date);
          }
          if ("opp_assign_user" in opportunity) {
            setPM(opportunity.opp_assign_user.id);
          }
        }
        if (opportunity.mailbox_message_id) {
          emailDetail(opportunity.mailbox_message_id);
        }
      }
    } catch (err) {
      setIsLoading(true);
      if (err.response) {
        navigate("/404");
      } else if (err.response.data === undefined) {
        navigate("/404");
      }
    }
    return () => {};
  };
  const getOppStatus = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get_bid_status", {});
      let data = response.data.all_status;
      if (opportunityStatus === 3) {
        setOppStatusList((current) =>
          data.filter((status) => {
            return status.id !== 1 && status.id !== 2;
          })
        );
      } else if (
        opportunityStatus === 4 ||
        opportunityStatus === 5 ||
        opportunityStatus === 6
      ) {
        setOppStatusList((current) =>
          data.filter((status) => {
            return status.id !== 1 && status.id !== 2 && status.id !== 3;
          })
        );
      } else {
        setOppStatusList(data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const emailDetail = async (mid) => {
    const response = await axiosPrivate.get(
      `mailbox/message-detail/${mid}`,
      {}
    );
    setSubject(response.data.subject);
    setEmailCont(response.data.body_html);
  };
  const showMailContent = (e) => {
    e.preventDefault();
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };
  useEffect(() => {
    setValue("project_start_date", projectStartDate);
    setValue("project_end_date", projectEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStartDate, projectEndDate]);

  useEffect(() => {
    setValue("pass_reason", passReason);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passReason]);

  useEffect(() => {
    setValue("project_name", oppData.project_name);
    setValue("ptype", projectType);
    setValue("no_of_waves", oppData.no_waves);
    setValue("frequency", oppData.frequency_id);
    setValue("opp_status", opportunityStatus);
    setValue("bid_amount", oppData.bid_amount);
    setValue("project_manager", pm);
    setValue("pass_reason", passReason);
    setValue("received_date", receivedDate);
    setValue("close_date", closeDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectType, oppData, receivedDate, closeDate, isLoading]);

  useEffect(() => {
    let opplineItems = oppData.line_item;
    if (opplineItems?.length > 0) {
      opplineItems.map((oppitem, i) => {
        Object.assign(oppitem, {
          lineItem: oppitem.name,
          geography: oppitem.country.id,
          service: oppitem.category.id,
        });
      });
      let newOpp = opplineItems.map((obj) => {
        delete obj.category;
        delete obj.country;
        delete obj.name;
        return obj;
      });
      setValue("opportunities", newOpp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oppData]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    var opp = [...data.opportunities];
    opp.map((oppitem, i) =>
      Object.assign(oppitem, {
        status: 1,
        is_project_created: 0,
        name: oppitem.lineItem,
        country: { id: parseInt(oppitem.geography) },
        category: { id: parseInt(oppitem.service), name: "string" },
      })
    );
    let lineItems = opp.map((obj) => {
      delete obj.lineItem;
      delete obj.geography;
      delete obj.service;
      return obj;
    });

    let passReason = "",
      oppLabel = "",
      pTypeLabel = "";
    let pmLabel = "";
    let passComment = "",
      passReasonLabel = "";
    if (parseInt(data.opp_status) === 6) {
      passReason = data.pass_reason;
      var sel = document.getElementById("pass_reason");
      passReasonLabel = sel.options[sel.selectedIndex].text;

      if (data.pass_comment) {
        passComment = data.pass_comment;
      }
    }
    if (data.opp_status) {
      let sel = document.getElementById("oppStatus");
      oppLabel = sel.options[sel.selectedIndex].text;
    }
    if (data.project_type) {
      let sel = document.getElementById("projectType");
      pTypeLabel = sel.options[sel.selectedIndex].text;
    }
    if (data.pm && parseInt(data.opp_status) === 3) {
      let sel = document.getElementById("pm");
      pmLabel = sel.options[sel.selectedIndex].text;
    }

    const payloadData = {
      project_name: data.project_name,
      ...(receivedDate && {
        received_date: moment(receivedDate).toISOString(),
      }),
      ...(closeDate && { closed_date: moment(closeDate).toISOString() }),
      ...(data.opp_status && {
        status: {
          id: data.opp_status,
          name: oppLabel,
        },
      }),
      ...(passReason &&
        parseInt(data.opp_status) === 6 && {
          pass_reason: {
            id: passReason ? parseInt(passReason) : 0,
            name: passReasonLabel,
          },
          pass_comment: passComment || "",
        }),
      project_type: {
        id: parseInt(projectType),
        name: pTypeLabel,
      },
      ...(data.no_of_waves && { no_waves: parseInt(data.no_of_waves) }),
      ...(data.frequency && { frequency_id: parseInt(data.frequency) }),
      ...(projectStartDate &&
        parseInt(data.opp_status) === 3 && {
          project_start_date: moment(projectStartDate).toISOString(),
        }),
      ...(projectEndDate &&
        parseInt(data.opp_status) === 3 && {
          project_end_date: moment(projectEndDate).toISOString(),
        }),
      ...(data.pm &&
        parseInt(data.opp_status) === 3 && {
          opp_assign_user: {
            id: data.pm ? parseInt(data.pm) : 0,
            name: pmLabel,
          },
        }),
      ...(data.pm_notes &&
        parseInt(data.opp_status) === 3 && { notes_user: data.pm_notes }),
      client_bid_details: editorData,
      bid_amount: data.bid_amount ? parseInt(data.bid_amount) : 0,
      ...(data.bid_percent &&
        parseInt(data.opp_status) === 3 && {
          margin_bid_amount: {
            id: parseInt(data.bid_percent),
            name: data.bid_percent,
          },
        }),
      ...(data.booked_amount &&
        parseInt(data.opp_status) === 3 && {
          booked_amount: parseInt(data.booked_amount),
        }),
      is_project_created: 0,
      ...((data.opp_status === "2" ||
        data.opp_status === "3" ||
        data.opp_status === 2 ||
        data.opp_status === 3) && { line_item: lineItems }),
      ...((data.opp_status === "4" ||
        data.opp_status === "5" ||
        data.opp_status === "6" ||
        data.opp_status === 4 ||
        data.opp_status === 5 ||
        data.opp_status === 6) && { line_item: [] }),
      bid_id: bidid,
      opportunity_id: parseInt(oppid),
    };

    try {
      await axiosPrivate
        .patch("/bid/update-opportunity", payloadData, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((e) => {
          if (e.status === 200) {
            localStorage.setItem("updateOpp", "1");
            navigate(`/update-bid/${bidid}`);
          }
        });
    } catch (err) {
      if (err.response.status === 409) {
        notifyerr(err.response);
      }
    }
    setIsSubmitting(false);
  };

  const changeBookedAmount = (e, amt) => {
    setBookedAmt(amt);
  };

  useEffect(() => {
    if (flag) {
      setBookedAmt(bidAmt);
      setValue("booked_amount", bidAmt);
    } else {
      setBookedAmt(bookedAmt);
      setValue("booked_amount", bookedAmt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidAmt]);

  /************Calculate Final Amount from booked Amount***************/
  const bidAmtReg = register("bid_amount", {
    required: "This field is Required",
    pattern: {
      value: /^[0-9]+.?[0-9]*/,
      message: "Only numbers are allowed",
    },
  });

  const oppStatusReg = register("opp_status", {
    required: "This field is Required",
  });
  const pTypeReg = register("ptype", {
    required: "This field is Required",
  });

  const updateBidAmount = (e, opp) => {
    e.preventDefault();
    if (opp === "4" || opp === "5" || opp === "6") {
      setValue("bid_amount", 0);
    }
    if (opp === "3") {
      setValue("booked_amount", bidAmt);
    }
  };

  useEffect(() => {
    setValue("pm", pm);
    const amt = getValues("booked_amount");
    let finalamt = amt * (bidPercentage / 100);

    setDollarAmount(finalamt);
    setValue("bid_percent", bidPercentage);
    setValue("amount_dollar", finalamt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookedAmt, bidPercentage, pm]);

  const addLineItem = (e) => {
    e.preventDefault();

    let index = parseInt(fields.length) - 1;
    let lineitemname = getValues("opportunities[" + index + "].lineItem");
    let cat = getValues("opportunities[" + index + "].service");
    let geog = getValues("opportunities[" + index + "].geography");
    let irval = getValues("opportunities[" + index + "].ir");
    let loival = getValues("opportunities[" + index + "].loi");
    let nval = getValues("opportunities[" + index + "].n");
    let cpival = getValues("opportunities[" + index + "].cpi");
    append({
      lineItem: lineitemname,

      service: cat,
      geography: geog,
      ir: irval,
      loi: loival,
      n: nval,
      cpi: cpival,
    });
  };

  /************Calculate Final Amount from booked Amount***************/
  const calculateAmount = () => {
    const percentage = getValues("bid_percent");
    const amt = getValues("booked_amount");

    var finalamt = parseFloat(amt) * (parseFloat(percentage) / 100);
    finalamt = Number.isNaN(finalamt) ? 0 : finalamt;
    setDollarAmount(finalamt);
    setValue("amount_dollar", finalamt);
  };

  return (
    <>
      {/* <Header /> */}
      <BidsSubHeader pname="biddetails" />
      <div className="top_container">
        <div className="main_container ">
          {isLoading ? (
            <SpinnerLoader />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="user_serachbox">
                <div style={{ float: "left" }}>
                  <h3 className="tableHeading">
                    <img src={userIco} className="headingIcons" alt="" />
                    Update Opportunity - {oppid}
                  </h3>
                </div>
                <div className="right-pane">
                  <button
                    className="cbtn closeBtn"
                    onClick={() => {
                      navigate(`/update-bid/${bidid}`);
                    }}
                  >
                    {" "}
                    Close
                  </button>
                  {/* bid.bid_update_opportunity */}
                  {superUser === true ? (
                    <>
                      <button disabled={isSubmitting} className="cbtn saveBtn">
                        {isSubmitting && <ButtonSpinner />} &nbsp; Save
                      </button>
                    </>
                  ) : (
                    <>
                      {all_permission.includes("bid.bid_update_opportunity") ? (
                        <>
                          <button
                            disabled={isSubmitting}
                            className="cbtn saveBtn"
                          >
                            {isSubmitting && <ButtonSpinner />} &nbsp; Save
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
              <hr />
              <div className="Deatail_to_client">
                <div className="tabs active-tabss">Details</div>
              </div>
              <hr style={{ marginTop: "0px" }} />

              <div className="mainContent">
                <Row>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Project name*</label>
                    <input
                      type="text"
                      placeholder="Project name"
                      className="formtext"
                      {...register("project_name", {
                        required: "This field is Required",
                      })}
                      defaultValue={oppData.project_name}
                    />
                    {errors.project_name && (
                      <small className="errmsg">
                        {errors.project_name.message}
                      </small>
                    )}
                  </Col>

                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Received Date</label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="formtext"
                      {...register("received_date", { valueAsDate: true })}
                      autoComplete="off"
                      selected={receivedDate ? new Date(receivedDate) : null}
                      onChange={(date) => {
                        setReceivedDate(date);
                        updateCloseDate(date);
                      }}
                    />
                  </Col>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Close Date</label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="formtext"
                      {...register("close_date")}
                      selected={closeDate ? new Date(closeDate) : null}
                      autoComplete="off"
                      onChange={(date) => setCloseDate(date)}
                    />
                  </Col>

                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Project Type*</label>
                    <select
                      className="formtext"
                      {...pTypeReg}
                      id="projectType"
                      onChange={(e) => {
                        pTypeReg.onChange(e);
                        setProjectType(e.target.value);
                      }}
                      defaultValue={projectType}
                    >
                      <option value="">Select Option</option>
                      <ProjectType selectedVal={projectType} />
                    </select>
                    {errors.ptype && (
                      <small className="errmsg">{errors.ptype.message}</small>
                    )}
                  </Col>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">No of Waves</label>
                    <select className="formtext" {...register("no_of_waves")}>
                      <option value="">Select Option</option>
                      {projectType === "5" || projectType === 5 ? (
                        <>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </select>
                    {errors.no_of_waves && (
                      <small className="errmsg">
                        {errors.no_of_waves.message}
                      </small>
                    )}
                  </Col>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Frequency</label>

                    <select className="formtext" {...register("frequency")}>
                      <option value="">Select Option</option>
                      {projectType === "5" || projectType === 5 ? (
                        <>
                          <option value="1">Weekly</option>
                          <option value="2">Monthly</option>
                          <option value="3">Quaterly</option>{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </select>
                  </Col>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Opportunity Status*</label>
                    <select
                      id="oppStatus"
                      className="formtext"
                      {...oppStatusReg}
                      onChange={(e) => {
                        oppStatusReg.onChange(e);
                        setOpportunityStatus(e.target.value);
                        updateBidAmount(e, e.target.value);
                      }}
                      value={opportunityStatus}
                    >
                      {oppStatusList.map((status, i) => (
                        <option value={status?.id} key={i}>
                          {status?.name}
                        </option>
                      ))}
                    </select>
                    {errors.opp_status && (
                      <small className="errmsg">
                        {errors.opp_status.message}
                      </small>
                    )}
                  </Col>
                </Row>
                {(oppStatus === "6" || opportunityStatus === 6) && (
                  <Row>
                    <Col lg={3} xs={12} className="col">
                      <label className="formlabel">Pass Reason*</label>
                      <select
                        id="pass_reason"
                        className="formtext"
                        {...register("pass_reason", {
                          required: "This field is Required",
                        })}
                        defaultValue={passReason}
                      >
                        <option value="">Select Option</option>
                        <option value="1">No Feasibility</option>
                        <option value="2">CPI Offered Low</option>
                        <option value="3">Difficult Target</option>
                        <option value="4">Others</option>
                      </select>
                      {errors.pass_reason && (
                        <small className="errmsg">
                          {errors.pass_reason.message}
                        </small>
                      )}
                    </Col>
                    {(pReason === "4" || passReason === 4) && (
                      <Col lg={9} xs={12} className="col">
                        <div className="form-group">
                          <label className="formlabel"></label>
                          <input
                            type="text"
                            placeholder="If Others,  define"
                            className="formtext"
                            {...register("pass_comment", {
                              required: "This field is Required",
                            })}
                            defaultValue={oppData.pass_comment}
                          />
                          {errors.pass_comment && (
                            <small className="errmsg">
                              {errors.pass_comment.message}
                            </small>
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>
                )}
                <Row style={{ marginTop: "10px" }}>
                  <div className="opportunity_main formCont">
                    {(oppStatus === "3" ||
                      oppStatus === 3 ||
                      oppStatus === "2" ||
                      oppStatus === 2) && (
                      <CalcBidAmt
                        control={control}
                        setValue={setValue}
                        //setBookedAmt={setBookedAmt}
                        setBidAmt={setBidAmt}
                        style={{ visibility: "hidden" }}
                      />
                    )}
                    {(showOpportunity.indexOf(oppStatus) > -1 ||
                      showOpportunity.indexOf(opportunityStatus) > -1) && (
                      <Accordion preExpanded={[0]}>
                        {fields.map((item, index) => (
                          <AccordionItem id={index} key={item.id} uuid={index}>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div className="line_item">
                                  <label className="formlabel">
                                    Line Item #{index + 1}*
                                  </label>
                                  <input
                                    type="text"
                                    {...register(
                                      `opportunities[${index}].lineItem`,
                                      {
                                        required: "This field is Required",
                                      }
                                    )}
                                    defaultValue={item.lineItem}
                                    className="formtext"
                                  />
                                  {errors.opportunities &&
                                    errors.opportunities[`${index}`] && (
                                      <small className="errmsg">
                                        &nbsp; &nbsp; All fields in line items
                                        are required
                                      </small>
                                    )}
                                </div>
                                <img
                                  src={DeleteIcon}
                                  alt="Delete Row"
                                  className="deleteBtn"
                                  onClick={() => {
                                    confirmAlert({
                                      message:
                                        "Are you sure, you want to delete this?",
                                      buttons: [
                                        {
                                          label: "Yes",
                                          onClick: () => remove(index),
                                        },
                                        {
                                          label: "No",
                                        },
                                      ],
                                    });
                                  }}
                                />
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div
                                className="extra_client_input_header"
                                key="1"
                              >
                                <Row>
                                  <Col
                                    lg={3}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="col"
                                  >
                                    <label className="formlabel">
                                      Service Type*
                                    </label>
                                    <select
                                      className="formtext"
                                      {...register(
                                        `opportunities[${index}].service`,
                                        {
                                          required: "This field is Required",
                                        }
                                      )}
                                      //value={item.service}
                                    >
                                      <option value="">Select Option</option>
                                      <ServiceType selectedVal={item.service} />
                                    </select>
                                  </Col>

                                  <Col
                                    lg={3}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="col"
                                  >
                                    <label className="formlabel">
                                      Geography*
                                    </label>
                                    <select
                                      className="formtext"
                                      {...register(
                                        `opportunities[${index}].geography`,
                                        {
                                          required: "This field is Required",
                                        }
                                      )}
                                    >
                                      <option value="">Select Option</option>
                                      <Countries selectedVal={item.geography} />
                                    </select>
                                  </Col>

                                  <Col
                                    lg={6}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="col"
                                  >
                                    <Row>
                                      <Col
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        xs={6}
                                        className="col"
                                      >
                                        <label className="formlabel">IR%</label>
                                        <input
                                          type="number"
                                          className="formtext"
                                          min={0}
                                          {...register(
                                            `opportunities[${index}].ir`,
                                            {
                                              required:
                                                "This field is Required",
                                            }
                                          )}
                                          defaultValue={item.ir}
                                        />
                                      </Col>

                                      <Col
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        xs={6}
                                        className="col"
                                      >
                                        <label className="formlabel">
                                          LOI(Min)
                                        </label>
                                        <input
                                          type="number"
                                          className="formtext"
                                          min={0}
                                          {...register(
                                            `opportunities[${index}].loi`,
                                            {
                                              required:
                                                "This field is Required",
                                            }
                                          )}
                                          defaultValue={item.loi}
                                        />
                                      </Col>

                                      <Col
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        xs={6}
                                        className="col"
                                      >
                                        <label className="formlabel">N*</label>
                                        <Controller
                                          name={`opportunities[${index}].n`}
                                          {...register(
                                            `opportunities[${index}].n`
                                          )}
                                          control={control}
                                          rules={{
                                            required: "This field is required",
                                          }}
                                          value={item.n}
                                          render={({ field }) => (
                                            <input
                                              type="number"
                                              className="formtext"
                                              min={0}
                                              value={field.value}
                                              // onChange={field.onChange}
                                              ref={field.ref}
                                              onChange={(e) => {
                                                field.onChange(e);
                                                setFlag(true);
                                              }}
                                            />
                                          )}
                                        />
                                      </Col>

                                      <Col
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        xs={6}
                                        className="col"
                                      >
                                        <label className="formlabel">
                                          CPI*
                                        </label>
                                        <Controller
                                          name={`opportunities[${index}].cpi`}
                                          {...register(
                                            `opportunities[${index}].cpi`
                                          )}
                                          control={control}
                                          rules={{
                                            required: "This field is required",
                                            pattern: {
                                              value: /^[+-]?([0-9]*[.])?[0-9]+/,
                                              message: "Enter numeric value",
                                            },
                                          }}
                                          value={item.cpi}
                                          render={({ field }) => (
                                            <input
                                              type="text"
                                              className="formtext"
                                              value={field.value}
                                              // onChange={field.onChange}
                                              ref={field.ref}
                                              onChange={(e) => {
                                                field.onChange(e);
                                                setFlag(true);
                                              }}
                                            />
                                          )}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    )}

                    {(showOpportunity.indexOf(oppStatus) > -1 ||
                      showOpportunity.indexOf(opportunityStatus) > -1) && (
                      <button
                        className="cbtn addBtn"
                        onClick={(e) => {
                          addLineItem(e);
                          setFlag(true);
                        }}
                      >
                        + Add Line Item
                      </button>
                    )}
                    {oppData.mailbox_message_id ? (
                      <button
                        className="cbtn closeBtn"
                        onClick={(e) => {
                          showMailContent(e);
                        }}
                      >
                        View Mail
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </Row>

                <Row>
                  <Col lg={3} md={3} sm={6} xs={12} className="col">
                    <label className="formlabel">Bid Amount*</label>
                    <input
                      type="text"
                      className="formtext"
                      {...bidAmtReg}
                      readOnly={oppStatus === "3" || oppStatus === 3}
                      onChange={(e) => {
                        bidAmtReg.onChange(e);
                        setBookedAmt(e.target.value);
                      }}
                      defaultValue={oppData.bid_amount}
                    />
                    {errors.bid_amount && (
                      <small className="errmsg">
                        {errors.bid_amount.message}
                      </small>
                    )}
                  </Col>

                  {(oppStatus === "3" || opportunityStatus === 3) && (
                    <>
                      <Col lg={3} md={3} sm={6} xs={12} className="col">
                        <label className="formlabel">Booked Amount*</label>
                        <Controller
                          name={"booked_amount"}
                          {...register("booked_amount")}
                          control={control}
                          rules={{
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only numbers are allowed",
                            },
                          }}
                          value={bookedAmt}
                          render={({ field }) => (
                            <input
                              type="text"
                              className="formtext"
                              value={field.value}
                              ref={field.ref}
                              placeholder="Booked Amount"
                              onChange={(e) => {
                                field.onChange(e);
                                changeBookedAmount(e, e.target.value);
                              }}
                            />
                          )}
                        />
                        {errors.booked_amount && (
                          <small className="errmsg">
                            {errors.booked_amount.message}
                          </small>
                        )}
                      </Col>
                      <Col lg={3} md={3} sm={6} xs={12} className="col">
                        <label className="formlabel">% of Booked Amount*</label>
                        <Controller
                          name={"bid_percent"}
                          {...register("bid_percent")}
                          control={control}
                          rules={{
                            required: "This field is required",
                          }}
                          value={bidPercentage}
                          render={({ field }) => (
                            <select
                              className="formtext"
                              value={field.value}
                              ref={field.ref}
                              onChange={(e) => {
                                field.onChange(e);
                                setBidPercentage(e.target.value);
                                calculateAmount();
                              }}
                            >
                              <BidMarginPercent selectedVal={bidPercentage} />
                            </select>
                          )}
                        />
                        {errors.bid_percent && (
                          <small className="errmsg">
                            {errors.bid_percent.message}
                          </small>
                        )}
                      </Col>
                      <Col lg={3} md={3} sm={6} xs={12}>
                        <label className="formlabel">Final Amount($)</label>
                        <input
                          type="text"
                          className="formtext"
                          //readOnly
                          {...register("amount_dollar")}
                          defaultValue={dollarAmount}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                {(oppStatus === "3" || opportunityStatus === 3) && (
                  <Row id="bidWon">
                    <Col lg={2} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Project Start Date</label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        className="formtext"
                        {...register("project_start_date")}
                        autoComplete="off"
                        selected={new Date(projectStartDate)}
                        onChange={(date) => setProjectStartDate(date)}
                      />
                      {errors.project_start_date && (
                        <small className="errmsg">
                          {errors.project_start_date.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Project End Date</label>
                      <DatePicker
                        className="formtext"
                        dateFormat="dd/MM/yyyy"
                        {...register("project_end_date")}
                        autoComplete="off"
                        selected={new Date(projectEndDate)}
                        onChange={(date) => setProjectEndDate(date)}
                      />
                      {errors.project_end_date && (
                        <small className="errmsg">
                          {errors.project_end_date.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">PM*</label>
                      <Controller
                        control={control}
                        name="pm"
                        rules={{
                          required: "This field is required",
                        }}
                        value={pm}
                        render={({ field }) => (
                          <select
                            {...field}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                              setPM(e.target.value);
                            }}
                            className="formtext"
                            id="pm"
                          >
                            <option value="">Select Option</option>
                            <PMList selectedVal={pm} />
                            <option value="0">Others</option>
                          </select>
                        )}
                      />
                      {errors.pm && (
                        <small className="errmsg">{errors.pm.message}</small>
                      )}
                    </Col>

                    <Col lg={6} md={12} sm={6} xs={12}>
                      <label className="formlabel">Notes for PM</label>
                      <input
                        type="text"
                        placeholder="If Others,  define"
                        className="formtext"
                        {...register("pm_notes")}
                        {...register("pm_notes")}
                        defaultValue={oppData.notes_user}
                      />
                      {errors.pm_notes && (
                        <small className="errmsg">
                          {errors.pm_notes.message}
                        </small>
                      )}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <label className="formlabel">Client Bid Details</label>
                    <CKEditor
                      editor={Editor}
                      data={editorData}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setEditorData(data);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </form>
          )}
        </div>
      </div>
      <Modal isOpen={modalIsOpen}>
        <button onClick={setModalIsOpenToFalse} className="closeModal">
          x
        </button>
        <ModalHeader>
          <h5>{subject}</h5>
          <hr />
        </ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: emailCont }} />
        </ModalBody>
      </Modal>
    </>
  );
};
export default EditOpportunity;
