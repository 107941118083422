import React, { useState, useEffect, useRef, useMemo } from "react";
import Row from "react-bootstrap/Row";
import { ReactMultiEmail } from "react-multi-email";
import addIcon from "../../../assets/LoginIcons/add.svg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import JoditEditor from "jodit-react";
import { Jodit } from "jodit-react";
import Spinner from "react-bootstrap/Spinner";
import SpinnerLoader from "../../UI/Spinner";
import "./ReplyBlock.css";
import { CheckCircle, Delete, ErrorRounded } from "@mui/icons-material";
import DeleteIcon from "../../../assets/LoginIcons/delete.svg";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
const ReplyBlock = (props) => {
  const navigate = useNavigate();
  const [editorData, setEditorData] = useState();
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);

  const progressInfosRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();

  const [subject, setSubject] = useState(props.subject);
  const [cc, setCC] = useState([]);
  const [forwardCC, setForwardCC] = useState([]);
  const [bcc, setBcc] = useState([]);

  const [attPayload, setAttPayload] = useState([]);
  const [showAttach, setShowAttach] = useState([]);
  const [test, setTest] = useState([]);
  const [editorState, setEditorState] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userList, setUserList] = useState([]);
  const [emails, setEmails] = useState([]);
  const [emailForward, setEmailForward] = useState([]);

  const [selectUser, setSelectUser] = useState();
  const [selectCC, setSelectCC] = useState();
  const [selectBCC, setSelectBCC] = useState();
  const [bccDrop, setBCCDRop] = useState([]);
  const [userDrop, setUserDropState] = useState([]);
  const [ccDrop, setCCDRop] = useState([]);
  const [toStatus, setToStatus] = useState(true);
  const [cursorState, setCursorState] = useState(true);

  const editor = useRef(null);
  const focusEditor = useRef();
  const { auth } = useAuth();

  // ..........................permisssion.........
  const superUser = auth.superuser;
  const all_permission = auth.permission;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const notifysuccess = (e) => toast.success(e);
  useEffect(() => {
    allUserMail();

    setEmails(props.fromHeader);

    setCC(props.cc);

    setSubject(props.subject);
    if (props.attachments) {
      props.attachments.map((item) =>
        setAttPayload((value) => [...value, item])
      );
      setShowAttach(props.attachments);
    }
    let content = props.body;

    let mailContent = "<p></p><br/><blockquote>" + content + "</blockquote>";

    setEditorData(mailContent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fromHeader, props.cc, props.body]);

  const fileupload = (file, onUploadProgress) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("uid", `${props.uid}`);

    setAttPayload((value) => [...value, file.name]);
    setTest((value) => [...value, file]);
    return axiosPrivate.post("/mailbox/add-attachment", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress,
    });
  };

  const allUserMail = async () => {
    const response = await axiosPrivate.get(
      `mailbox/search-mailbox-contacts/{search-text}?search_text=a`,
      {}
    );

    setUserList(
      response.data.contact_list.map((emailitem) => ({
        id: emailitem._id,
        email_id: emailitem.contact_id,
      }))
    );
  };

  const handleSaveButton = async (e) => {
    setIsSubmitting(true);
    setValue("To", "");
    setValue("CC", "");
    setValue("BCC", "");
    setValue("Subject", "");
    setValue("bodyT", "");
    if (props.r === 3) {
      try {
        await axiosPrivate
          .post(
            "mailbox/forward-email",
            JSON.stringify({
              subject: e.Subject,
              to: e.To.toString(),
              cc: e.CC.toString(),
              bcc: e.BCC.toString(),
              uid: `${props.uid}`,
              body: e.bodyT,
              message_id: props.messageId,
              id: props.id,
              attachments: attPayload || [],
            }),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              notifysuccess("Mail Sent successfully");
            }
          });
      } catch (err) {}
    } else {
      try {
        await axiosPrivate
          .post(
            "mailbox/send-reply-email",
            JSON.stringify({
              subject: e.Subject,
              to: e.To.toString(),
              cc: e.CC.toString(),
              bcc: e.BCC.toString(),
              uid: `${props.uid}`,
              body: e.bodyT,
              message_id: props.messageId,
            }),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              notifysuccess("Mail sent successfully");
              if (props.thread === "simple") {
                navigate(`/mail-description/${props.id}/thread`);
              }
            }
          });
      } catch (err) {}
    }
    if (props.replySimple === true) {
      props.setReplySimple(false);
      props.setMailnReplyTab(true);
    }
    if (props.showReplyBox) {
      props.setReplyBox(false);
      props.setReplyTab(true);
    }
    setIsSubmitting(false);
  };

  const deletattach = (e, i) => {
    const el = showAttach.filter((User, index) => i !== index);
    setShowAttach(el);
    setAttPayload(el);
  };

  const handleSUBJECT = (e) => {
    setSubject(e);
  };
  const upload = (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    return fileupload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      setProgressInfos({ val: _progressInfos });
    })
      .then(() => {
        setMessage((prevMessage) => [...prevMessage, "success"]);
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;

        setProgressInfos({ val: _progressInfos });
        setMessage((prevMessage) => [...prevMessage, "error"]);
      });
  };

  const uploadFiles = (event) => {
    const files = Array.from(event.target.files);

    let merged = files.concat(test);
    merged = merged.filter((item, index) => {
      return merged.indexOf(item) === index;
    });

    let _progressInfos = merged.map((file) => ({
      percentage: 0,
      fileName: file.name,
    }));
    progressInfosRef.current = {
      val: _progressInfos,
    };

    const uploadPromises = merged.map((file, i) => upload(i, file));

    setMessage([]);
  };

  const deleteHandler = async (e, i) => {
    const del = progressInfos.val.filter((User, index) => i !== index);

    const bar = test.filter((User, index) => i !== index);
    const el = progressInfos.val.filter((User, index) => i === index);
    setTest(bar);

    setProgressInfos({ val: del });

    try {
      await axiosPrivate.get(
        `mailbox/delete-attachment/${props.uid}/${el[0].fileName}`,
        {}
      );
    } catch (err) {}
  };

  useEffect(() => {
    if (props.r !== 1 && props.r !== 3) {
      setValue(
        "CC",
        cc.map((ccmail) => ccmail)
      );
    } else {
      setValue("CC", forwardCC);
    }

    if (props.r !== 3) {
      setValue(
        "To",
        emails.map((toemail) => toemail)
      );
    } else {
      setValue("To", emailForward);
    }

    setValue("Subject", props.subject);

    if (props.replyFocus === true) {
      scrollToBottom();
      if (props.r === 3) {
        let x = document.getElementById("editorId");

        x.focus();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.r, props.replyFocus, props.body]);

  useEffect(() => {
    if (props.r !== 1 && props.r !== 3) {
      setValue(
        "CC",
        cc.map((ccmail) => ccmail)
      );
    } else {
      setValue("CC", forwardCC);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cc, forwardCC]);
  useEffect(() => {
    setValue("BCC", bcc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bcc]);
  useEffect(() => {
    if (props.r !== 3) {
      setValue(
        "To",
        emails.map((toemail) => toemail)
      );
    } else {
      setValue("To", emailForward);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails, emailForward]);

  const scrollToBottom = (id) => {
    if (focusEditor.current) {
      focusEditor.current.focus();

      props.setReplyFocus(false);
    }
  };

  const To = register("To", {
    required: "This field is Required",
  });
  const Subject = register("Subject");
  useEffect(() => {
    if (editorData !== undefined && editorData !== "") {
      setEditorState(false);
    } else {
      setEditorState(true);
    }
  }, [editorData]);

  const BodyT = register("bodyT", {
    required: "This field is Required",
  });

  // ======= to Hide and show  DropDown===========
  const showDrop = (e) => {
    e.preventDefault();
    let element = e.target;
    let wrapper = element.closest(".formgroupCompose");
    let toshow = wrapper && wrapper.querySelector(".drop.hide");
    toshow.classList.remove("hide");
  };

  const hideDrop = (e) => {
    e.preventDefault();
    let element = e.target;
    let parent = element.closest(".drop");
    parent.classList.add("hide");
  };

  /**************Show Cc/Bcc block on click***************** */
  const handleCcBcc = (e, cid) => {
    e.preventDefault();
    var element = e.target;
    var parent = element.closest(".ReplyBlock");
    var target = parent.querySelector("#" + cid);
    target.classList.add("show");
    element.classList.add("hide");
  };

  // =========UserDrrop Down=================

  const Drop = () => {
    return (
      <>
        {userDrop.map((ele) => (
          <h6
            onClick={(e) => {
              if (props.r !== 3) {
                setEmails((val) => [...val, e.target.innerText]);
              } else {
                setEmailForward((val) => [...val, e.target.innerText]);
                setToStatus(false);
              }

              hideDrop(e);
            }}
          >
            {ele}
          </h6>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (selectUser !== "" && selectUser !== undefined) {
      function test(userList, sub) {
        setUserDropState([]);
        userList.map((str) => {
          if (
            str.email_id
              .toLowerCase()
              .startsWith(sub.slice(0, Math.max(str.email_id.length - 1, 1)))
          ) {
            setUserDropState((val) => [...val, str.email_id]);
          }
        });
      }
      const userLast = selectUser;

      test(userList, userLast);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectUser, userList]);

  // ======= DropCC code======================

  const DropCC = () => {
    return (
      <>
        {ccDrop.map((ele) => (
          <h6
            onClick={(e) => {
              if (props.r !== 1 && props.r !== 3) {
                setCC((val) => [...val, e.target.innerText]);
              } else {
                setForwardCC((val) => [...val, e.target.innerText]);
              }

              hideDrop(e);
            }}
          >
            {ele}
          </h6>
        ))}
      </>
    );
  };

  //===================== UseEFFECT for DropCC============

  useEffect(() => {
    if (selectCC !== "" && selectCC !== undefined) {
      function test(userList, sub) {
        setCCDRop([]);
        userList.map((str) => {
          if (
            str.email_id
              .toLowerCase()
              .startsWith(sub.slice(0, Math.max(str.email_id.length - 1, 1)))
          ) {
            setCCDRop((val) => [...val, str.email_id]);
          } else {
          }
        });
      }
      const userLast = selectCC;

      test(userList, userLast);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCC, userList]);

  // ========= dropBcc==========================

  const DropBCC = (namev) => {
    return (
      <>
        {bccDrop.map((ele) => (
          <h6
            onClick={(e) => {
              setBcc((val) => [...val, e.target.innerText]);

              hideDrop(e);
            }}
          >
            {ele}
          </h6>
        ))}
      </>
    );
  };

  // =================DRopBcc UseEffect===========================

  useEffect(() => {
    if (selectBCC !== "" && selectBCC !== undefined) {
      function test(userList, sub) {
        setBCCDRop([]);
        userList.map((str) => {
          if (
            str.email_id
              .toLowerCase()
              .startsWith(sub.slice(0, Math.max(str.email_id.length - 1, 1)))
          ) {
            // setUserDropState(str);
            setBCCDRop((val) => [...val, str.email_id]);
          }
        });
      }
      const userLast = selectBCC.split(",");

      test(userList, userLast[userLast.length - 1]);
    }
  }, [selectBCC, userList]);

  const expandContent = (e) => {
    e.preventDefault();
    var element = e.target;
    var parent = element.closest(".ReplyBlock");
    var target = parent.querySelector(".gmail_quote");
    var target1 = parent.querySelector(".gmail_attr");
    target.classList.add("show");
    target1.classList.add("show");
    element.classList.add("hide");
  };

  // =========== autoFocos of Editor ======================
  let config = {
    autofocus: true,
    cursorAfterAutofocus: "start", // 'end';
    saveSelectionOnBlur: true,
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "font",
      "fontsize",
      "brush",
      "|",
      "ul",
      "ol",
      "table",
      "|",
      "center",
      "left",
      "right",
      "justify",
      "|",
      "link",
      "image",
      "file",
    ],
    uploader: { insertImageAsBase64URI: true, insertFileAsBase64URI: true },
    removeButtons: [],
    toolbarAdaptive: false,
  };
  let configm = {
    autofocus: false,
    cursorAfterAutofocus: "start", // 'end';
    saveSelectionOnBlur: true,
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "font",
      "fontsize",
      "brush",
      "|",
      "ul",
      "ol",
      "table",
      "|",
      "center",
      "left",
      "right",
      "justify",
      "|",
      "link",
      "image",
      "file",
    ],
    uploader: { insertImageAsBase64URI: true, insertFileAsBase64URI: true },
    removeButtons: [],
    toolbarAdaptive: false,
  };
  let configforward = {
    autofocus: false,
    cursorAfterAutofocus: "start", // 'end';
    saveSelectionOnBlur: true,
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "font",
      "fontsize",
      "brush",
      "|",
      "ul",
      "ol",
      "table",
      "|",
      "center",
      "left",
      "right",
      "justify",
      "|",
      "link",
      "image",
      "file",
    ],
    uploader: { insertImageAsBase64URI: true, insertFileAsBase64URI: true },
    removeButtons: [],
    toolbarAdaptive: false,
  };

  // =========== autoFocos of Editor ======================

  return (
    <form onSubmit={handleSubmit(handleSaveButton)}>
      <div className="ReplyBlock rpedit" id="demo">
        <Row>
          <div className="formgroupCompose">
            <label className="">To</label>
            <ReactMultiEmail
              {...To}
              id="editorId"
              tabIndex="5"
              style={{ borderBottom: "1px solid #eee", paddingLeft: "30px" }}
              emails={props.r !== 3 ? emails : emailForward}
              onChange={(e) => {
                setEmails(e);
                setEmailForward(e);
                setToStatus(false);
              }}
              onKeyUp={(e) => {
                if (e.key !== "Enter") {
                  setSelectUser(e.target.value);
                  showDrop(e);
                  Drop();
                }
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
            <div className="drop dropTo">
              <Drop />
            </div>

            <span
              className="rbtn cc"
              onClick={(e) => {
                handleCcBcc(e, "cc");
              }}
            >
              Cc
            </span>
            <span
              className="rbtn bcc"
              onClick={(e) => {
                handleCcBcc(e, "bcc");
              }}
            >
              Bcc
            </span>
            {toStatus === true ? (
              errors.To && <small className="errmsg">{errors.To.message}</small>
            ) : (
              <></>
            )}
            {/* {errors.To && <small className="errmsg">{errors.To.message}</small>} */}
          </div>
        </Row>
        <Row className="ccblock" id="cc">
          <div className="formgroupCompose">
            <label className="">CC</label>
            <ReactMultiEmail
              className="pl"
              style={{ borderBottom: "1px solid #eee", paddingLeft: "30px" }}
              emails={props.r === 2 ? cc : forwardCC}
              onChange={(e) => {
                setCC(e);
                setForwardCC(e);
              }}
              // autoFocus={true}
              onKeyUp={(e) => {
                if (e.key !== "Enter") {
                  setSelectCC(e.target.value);
                  DropCC();
                  showDrop(e);
                }
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item className="mw">
                      {email}
                    </div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
            <div className="drop dropTo">
              <DropCC />
            </div>
            {/* {errors.CC && <small className="errmsg">{errors.CC.message}</small>} */}
          </div>
        </Row>
        <Row className="bccblock" id="bcc">
          <div className="formgroupCompose">
            <label className="">BCC</label>
            <ReactMultiEmail
              style={{ borderBottom: "1px solid #eee", paddingLeft: "40px" }}
              emails={bcc}
              onChange={(e) => {
                setBcc(e);
              }}
              // autoFocus={true}
              onKeyUp={(e) => {
                if (e.key !== "Enter") {
                  setSelectBCC(e.target.value);
                  showDrop(e);
                  Drop();
                }
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
            <div className="drop dropTo">
              <DropBCC />
            </div>
            {errors.BCC && (
              <small className="errmsg">{errors.BCC.message}</small>
            )}
          </div>
        </Row>
        <Row style={{ display: "block" }}>
          <div className="formgroupCompose">
            <label className="">Subject</label>
            <input
              {...Subject}
              type="text"
              placeholder=""
              autoComplete="off"
              defaultValue={subject}
              className="etext"
              // style={{ width: "100px" }}
              onChange={(e) => {
                handleSUBJECT(e.target.value);
              }}
            />
          </div>
        </Row>

        <div className="mailContentBlock" id={"editorBlock" + props.r}>
          <div ref={focusEditor} tabIndex={"1"}></div>
          {props.body ? (
            <>
              {cursorState === true ? (
                <>
                  <JoditEditor
                    {...BodyT}
                    ref={editor}
                    config={props.r !== 3 && cursorState === true ? config : configforward}
                    tabIndex="2"
                    value={editorData}
                    onChange={(newContent) => {
                      setValue("bodyT", newContent);
                    }}
                    onBlur={() => {
                      setCursorState(false);
                    }}
                  />
                </>
              ) : (
                <>
                  <JoditEditor
                    {...BodyT}
                    ref={editor}
                    config={props.r !== 3 ? configm : configforward}
                    tabIndex="2"
                    value={editorData}
                    onChange={(newContent) => {
                      setValue("bodyT", newContent);
                    }}
                    onBlur={() => {
                      setCursorState(false);
                    }}
                  />
                </>
              )}
              {/*           
              <JoditEditor
                {...BodyT}
                ref={editor}
                config={
                  props.r !== 3 && cursorState === true ? config : configm
                }
                // tabIndex="2"
                value={editorData}
                onChange={(newContent) => {
                  setValue("bodyT", newContent);
                }}
              /> */}

              {editorState ? (
                errors.bodyT && (
                  <small className="errmsg">{errors.bodyT.message}</small>
                )
              ) : (
                <></>
              )}
              {props.r === 3 ? (
                <>
                  {/* {props.attachments} */}

                  {showAttach.map((em, index) => (
                    <>
                      <p style={{ fontSize: "15px" }}>
                        {" "}
                        {em}
                        {superUser === true ? (
                          <>
                            <img
                              style={{ width: "12px" }}
                              src={DeleteIcon}
                              onClick={(e) => deletattach(e, index)}
                              alt="Delete"
                              height={20}
                            />
                          </>
                        ) : (
                          <>
                            {all_permission.includes(
                              "mailbox.mailbox_delete_attachment"
                            ) ? (
                              <>
                                <img
                                  style={{ width: "12px" }}
                                  src={DeleteIcon}
                                  onClick={(e) => deletattach(e, index)}
                                  alt="Delete"
                                  height={20}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </p>
                    </>
                  ))}

                  {/* })} */}
                </>
              ) : (
                <></>
              )}
              <div className="attachmentContainer">
                {progressInfos &&
                  progressInfos.val.length > 0 &&
                  progressInfos.val.map((progressInfo, index) => (
                    <div key={index} className="attachmentItem">
                      <div className="attachmentContent">
                        <span className="fileInfo">
                          {progressInfo.fileName}
                        </span>
                        {message[index] === "success" ? (
                          <CheckCircle className="success" />
                        ) : (
                          <ErrorRounded className="error" />
                        )}

                        <div className="progress">
                          <div
                            className="progress-bar progress-bar-default"
                            role="progressbar"
                            aria-valuenow={progressInfo.percentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progressInfo.percentage + "%" }}
                          >
                            {progressInfo.percentage}%
                          </div>
                        </div>

                        <Delete
                          className="deleteAttachment"
                          title="Remove Attachment"
                          onClick={(e) => {
                            deleteHandler(e, index);
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <SpinnerLoader />
          )}
        </div>
        {props.r !== 3 ? (
          <div className="expand-mail-container">
            <span
              className="expand-mail"
              onClick={(e) => {
                expandContent(e);
              }}
              title="Show trimmed content"
            >
              ...
            </span>
          </div>
        ) : (
          <></>
        )}

        <hr />
        <Row>
          <button
            className="cbtn saveBtn"
            style={{ marginLeft: "15px", marginRight: "15px" }}
          >
            {isSubmitting && (
              <Spinner
                style={{ color: "white" }}
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Send
          </button>

          {superUser === true ? (
            <>
              <label className="cbtn addBtn">
                <img src={addIcon} className="headingIcons" alt="" /> Attachment
                <input
                  type="file"
                  // multiple
                  onChange={uploadFiles}
                  className="attachInput"
                />
              </label>
            </>
          ) : (
            <>
              {all_permission.includes("mailbox.mailbox_add_attachment") ? (
                <>
                  <label className="cbtn addBtn">
                    <img src={addIcon} className="headingIcons" alt="" />{" "}
                    Attachment
                    <input
                      type="file"
                      // multiple
                      onChange={uploadFiles}
                      className="attachInput"
                    />
                  </label>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <br />
        </Row>
      </div>
    </form>
  );
};

export default ReplyBlock;
