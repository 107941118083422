import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import moment from "moment";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
const OpportunityList = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [bidOpportunity, setBidOpportunity] = useState([]);
  useEffect(() => {
    getBidOpportunity();
  }, []);

  const getBidOpportunity = async () => {
    try {
      const response = await axiosPrivate.get(
        `bid/opportunity-list/${props.bidId}`,
        {}
      );
      setBidOpportunity(response.data.all_opportunity_list);
    } catch (err) {}
  };
  return (
    <div className="opportunity_div">
      <div className="opportunity_headings ono" id="div3">
        Opp. No.
      </div>
      <div className="opportunity_headings oname" id="div3">
        Opportunity Name
      </div>
                      <div className="opportunity_headings rdate" id="div3">
                        Created On
                      </div>
                      <div className="opportunity_headings status" id="div3">
                        Status
                      </div>
                      {bidOpportunity.map((e) => (
                        <>
                          <div
                            className="opportunity_headings ono"
                            id="div4"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(`/edit-opportunity/${props.bidId}/${e.id}`);
                            }}
                          >
                            <span>{e.opportunity_number}</span>
                          </div>
                          <div className="opportunity_headings oname" style={{ cursor: "pointer" }} id="div4" onClick={() => {
                              navigate(`/edit-opportunity/${props.bidId}/${e.id}`);
                            }}> {e.project_name}</div>
                          <div className="opportunity_headings rdate" id="div4">                          
                          {(e.received_date) ? (moment(e.created_at).format('DD-MM-YYYY')) : "-"
                          }
                          </div>
                          <div  className="opportunity_headings status" id="div4" >
                            {e.status.name}
                          </div>
                        </>
                      ))}
                    </div>
  )
}

export default OpportunityList;
