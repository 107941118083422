import { Link, useNavigate } from "react-router-dom"
import Header from "./UI/Header/Header"
const Missing = () => {
    const navigate = useNavigate();
    const goBack = () => navigate(-3);
    return (
        <>
        <div className="top_container">
            <div className="main_container ">
                <div className="mainContent">          
                    <article style={{ padding: "80px" , textAlign:"center"}}>
            <h1 style={{fontSize: "150px"}}>404</h1>
            <h5>Oops, page not found</h5><br></br>
            <p>The page you are looking for doesn't exist or an other error occured.</p>
            <div className="flexGrow">                
                <button onClick={goBack}>Go Back</button>
            </div>
        </article>
                </div>
            </div>
        </div>
        </>
       
    )
}

export default Missing
