import React, { useState, useEffect, useRef } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import moment from "moment";
const DoughnutChart = (props) => {
  // let newEndDate;
  // let newCurrentDate;
  let newCurrentDate = moment().subtract(7, "days").format("YYYY-MM-DD");
  let newEndDate = moment().format("YYYY-MM-DD");

  // let newCurrentDate = "2022-08-01";
  // let newEndDate = "2022-11-01";

  const [data, setData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  let startD = useRef();
  let endD = useRef();
  useEffect(() => {
    if (props.props.state === undefined) {
      startD.current = newCurrentDate;
      endD.current = newEndDate;
    } else {
      startD.current = props.props.state.CurrentDate;
      endD.current = props.props.state.EndDate;
    }
    if (props.props.data) {
      props.props.setFlag(false);
      let user = "";
      props.props.data.map((e) => {
        if (props.props.data[props.props.data.length - 1]._id !== e._id) {
          user = user + `user${e._id}&`;
        } else {
          user = user + `user${e._id}`;
        }
      });

      getData(startD, endD, user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.props.data, props.props.state]);

  const getData = async (startD, endD, user) => {
    try {
      const response = await axiosPrivate.get(
        `bid-dashboard/total-bid-status?start_time=${startD.current}&end_time=${endD.current}&${user}`,
        {}
      );
      // total-bid-status?start_time=2022-09-01&end_time=2022-10-01

      setData(response.data.total_bid_status);
    } catch (err) {}
  };

  useEffect(() => {
    if (props.props.state === undefined) {
      startD.current = newCurrentDate;
      endD.current = newEndDate;
    } else {
      startD.current = props.props.state.CurrentDate;
      endD.current = props.props.state.EndDate;
    }
    getDataUser(startD, endD);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.props.state]);

  const getDataUser = async (startD, endD) => {
    try {
      const response = await axiosPrivate.get(
        `bid-dashboard/total-bid-status?start_time=${startD.current}&end_time=${endD.current}`,
        {}
      );

      setData(response.data.total_bid_status);
    } catch (err) {}
  };

  ChartJS.register(ArcElement, Tooltip, Legend);

  const dataa = {
    // labels: ["New", "Bidding", "Pass", "Won", "Lost", "Cancel"],
    labels: data.map((e) => e.bid_status),
    datasets: [
      {
        label: "Total Bids Status",

        data: data.map((e) => e.total),
        backgroundColor: data.map((e) => {
          if (e.bid_status === "New") {
            return "rgba(255, 99, 132, 0.9)";
          }
          if (e.bid_status === "Bidding") {
            return "rgba(54, 162, 235, 0.9)";
          }
          if (e.bid_status === "Bid Won") {
            return "rgba(75, 192, 192, 0.9)";
          }
          if (e.bid_status === "Lost") {
            return "rgba(153, 102, 255, 0.9)";
          }
          if (e.bid_status === "Cancel") {
            return "rgba(255, 159, 64, 0.9)";
          }
          if (e.bid_status === "Pass") {
            return "rgba(255, 206, 86, 0.9)";
          }
        }),

        cutout: "80%",
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      maintainAspectRatio: true,
    },
    responsive: true,
    maintainAspectRatio: true,
  };
  return (
    <>
      <Doughnut data={dataa} options={options} />
    </>
  );
};

export default DoughnutChart;
