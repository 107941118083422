import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const PMList = (props) => {
  const [users, setUsers] = useState();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      try {
        const response = await axiosPrivate.get("user/user-department/3", {});
        let data = response.data.all_users;
        isMounted && setUsers(data);
      } catch (err) {
        console.error(err);
      }
    };
    getData();
    return () => {
      isMounted = false;
      //controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {users?.length ? (
        <>
          {users.map((user, i) => {
            if (user?._id === props.selectedVal) {
              return (
                <option value={user?._id} key={i} selected>
                  {" "}
                  {user?.name}
                </option>
              );
            } else {
              return (
                <option value={user?._id} key={i}>
                  {" "}
                  {user?.name}
                </option>
              );
            }
          })}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default PMList;
