import React from "react";
import { useNavigate, useLocation } from "react-router";
import { BiEditAlt, BiUserPlus, BiPurchaseTag } from "react-icons/bi";

const SurveyInnerTable = () => {
  const navigate = useNavigate();
  return (
    <>
      <table className="bgc">
        <tr className="bdr">
          <td colspan="1" className="inner-border-right">
            <div className="flex flex-direction">
              <small>US Gen Pop 18+</small>
              <span>
                US-<b>Live</b>
              </span>
            </div>
            <div className="inner-icons show-icons">
              <div className="flex items-center justify-evenly">
                <a href="#">
                  <BiEditAlt
                    onClick={() => {
                      navigate("/project-detail/${1}");
                    }}
                    className="fs"
                  />
                </a>
                <a href="#">
                  <BiUserPlus className="fs" />
                </a>
                <a href="#">
                  <BiPurchaseTag className="fs" />
                </a>
              </div>
            </div>
          </td>
          <td colspan="9" className="pbzero">
            <table>
              <tbody>
                <tr>
                  <th>CO</th>
                  <th>CPI</th>
                  <th>
                    LOI <small>(Act/Est)</small>
                  </th>
                  <th>
                    IR(%) <small>(Act/Est)</small>
                  </th>
                  <th>CO(%)</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Margin</th>
                  <th>ER</th>
                </tr>
                <tr>
                  <td>3/46205</td>
                  <td>1.47</td>
                  <td>12/50</td>
                  <td>37.5%</td>
                  <td>25.0</td>
                  <td>Sept 21</td>
                  <td>Sept 30</td>
                  <td>27.21</td>
                  <td>55671</td>
                </tr>
                <tr>
                  <th>ST</th>
                  <th>RCS</th>
                  <th>RT</th>
                  <th>AB(%)</th>
                  <th>TE</th>
                  <th>QF(%)</th>
                  <th>SCL</th>
                  <th>L24S</th>
                  <th>L24C</th>
                </tr>
                <tr>
                  <td>12</td>
                  <td>12</td>
                  <td>8</td>
                  <td>15/18%</td>
                  <td>-</td>
                  <td>5/7%</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr className="bdr">
          <td colspan="1" className="inner-border-right">
            <div className="flex flex-direction">
              <small>India Gen Pop 18+</small>
              <span>
                IN-<b style={{ color: "#fdd200" }}>Paused</b>
              </span>
            </div>
            <div className="inner-icons show-icons">
              <div className="flex items-center justify-evenly">
                <a href="#">
                  <BiEditAlt
                    onClick={() => {
                      navigate("/project-detail/${1}");
                    }}
                    className="fs"
                  />
                </a>
                <a href="#">
                  <BiUserPlus className="fs" />
                </a>
                <a href="#">
                  <BiPurchaseTag className="fs" />
                </a>
              </div>
            </div>
          </td>
          <td colspan="9" className="pbzero">
            <table>
              <tbody>
                <tr>
                  <th>CO</th>
                  <th>CPI</th>
                  <th>
                    LOI <small>(Act/Est)</small>
                  </th>
                  <th>
                    IR(%) <small>(Act/Est)</small>
                  </th>
                  <th>CO(%)</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Margin</th>
                  <th>ER</th>
                </tr>
                <tr>
                  <td>3/46205</td>
                  <td>1.47</td>
                  <td>12/50</td>
                  <td>37.5%</td>
                  <td>25.0</td>
                  <td>Sept 21</td>
                  <td>Sept 30</td>
                  <td>27.21</td>
                  <td>55671</td>
                </tr>
                <tr>
                  <th>ST</th>
                  <th>RCS</th>
                  <th>RT</th>
                  <th>AB(%)</th>
                  <th>TE</th>
                  <th>QF(%)</th>
                  <th>SCL</th>
                  <th>L24S</th>
                  <th>L24C</th>
                </tr>
                <tr>
                  <td>12</td>
                  <td>12</td>
                  <td>8</td>
                  <td>15/18%</td>
                  <td>-</td>
                  <td>5/7%</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </table>
    </>
  );
};

export default SurveyInnerTable;
