import { useNavigate } from "react-router";
import Header from "../../../UI/Header/Header";
import BidsSubHeader from "../../../BidsModule/BidsSubHeader/BidsSubHeader";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import userIco from "../../../../assets/LoginIcons/users.svg";
import { useParams } from "react-router";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import "./EditBid.css";
import { useState, useEffect } from "react";
import moment from "moment";
import toast from "react-hot-toast";
import SpinnerLoader from "../../../UI/Spinner";
import { useForm } from "react-hook-form";
import OpportunityList from "./OpportunityList";
import ReplyBlock from "../../Mailbox/ReplyBlock";
import Modal from "react-modal";
import DeleteIcon from "../../../../assets/LoginIcons/delete.svg";
import CrossIcon from "../../../../assets/images/icons8-multiply-52.png";
import Select from "react-select";
import MailBoxIcon from "../../../../assets/images/icons8-mail-24.png";
import { Spinner } from "react-bootstrap";
import useAuth from "../../../../hooks/useAuth";
const EditBid = () => {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  moment.locale("en");
  const [bidNumber, setBidNumber] = useState();
  const [projectName, setProjectName] = useState();
  const [clientEmail, setClientEmail] = useState();
  const [userData, setUserData] = useState([]);
  const [clients, setClients] = useState([]);
  const [bidStatus, setbidStatus] = useState([]);
  const [createdName, setCreatedName] = useState();
  const [createdDate, setCreatedDate] = useState();
  const [lastModifyName, setLastModifyName] = useState();
  const [lastModifyDate, setLastModifyDate] = useState();
  const [userChoose, setUserChoose] = useState();
  const [userAccountId, setuserAccountId] = useState();
  const [userStatus, setUserStatus] = useState();
  const [clientContactList, setClientContactList] = useState([]);
  const [userClientContact, setUserClientContact] = useState();
  const [followToggle, setfollowToggle] = useState();
  const [loder, setLoder] = useState(false);
  const [subject, setSubject] = useState();
  const [subshow, setSubjectshow] = useState();
  const [emailCont, setEmailCont] = useState();
  const [fromHeader, SetFromHeader] = useState([]);
  const [fromHeadershow, SetFromHeadershow] = useState([]);
  const [uid, setUID] = useState();
  const [mail_id, setMail_id] = useState();
  const [cc, setCC] = useState([]);
  const [messageId, setMessageId] = useState();
  const [attachments, setattachments] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [r, setR] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [region, setRegion] = useState();
  const [clientRegion, setClientRegion] = useState();
  const [showRegionInput, setShowRegionInput] = useState(false);
  const [accountSingleId, setAccountSingleId] = useState();
  const [isEmail, setIsEmail] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [emailConatiner, setEmailConatiner] = useState([]);
  const [subjectSearch, setSubjectSearch] = useState();
  const [subjectDrop, setSubjectDRop] = useState([]);
  const [selectedMail, setSelectedMail] = useState();
  const [clickCount, setClickCount] = useState(1);
  const [emailBody, setEmailBody] = useState();
  const [againRegionid, setAgainRegionid] = useState("");
  const [showReplyBox, setReplyBox] = useState(false);
  const [loaderdrop, setLoaderDrop] = useState(false);
  const { auth } = useAuth();

  // ..........................permisssion.........
  const superUser = auth.superuser;
  const all_permission = auth.permission;

  useEffect(() => {
    let newopp = localStorage.getItem("newOpp");
    let updateOpp = localStorage.getItem("updateOpp");
    if (newopp === "1") {
      toast.success("Opportunity added successfully");
    }
    if (updateOpp === "1") {
      toast.success("Opportunity updated successfully");
    }
    localStorage.setItem("updateOpp", 0);
    localStorage.setItem("newOpp", 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("contactname", userClientContact);
    // setValue("account", userAccountId);
    setValue("BidOwner", userChoose);
    setValue("Status", userStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userClientContact, userAccountId, userChoose, userStatus]);
  useEffect(() => {
    setValue("region", region);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  const getClientsList = async () => {
    try {
      const response = await axiosPrivate.get("/client/client-list", {});
      let data = response.data.all_client;
      var clientlist = [...data];
      const clientdata = clientlist.map((item, i) =>
        Object.assign(item, {
          value: parseInt(item._id),
          label: item.name,
        })
      );
      setClients(clientdata);
    } catch (err) {}
  };

  useEffect(() => {
    getBidFromId();
    getUserLIst();
    getClientsList();
    handleStatus();
  }, []);

  const emailDetail = async (mailboxMessageId) => {
    const response = await axiosPrivate.get(
      `mailbox/message-detail/${mailboxMessageId}`,
      {}
    );

    setSubjectshow(response.data.subject);
    setEmailCont(response.data.body_html);

    let dataTo = response.data.from_header.split(",");
    SetFromHeadershow([]);
    dataTo.map((ele) => SetFromHeadershow((val) => [...val, ele]));
  };

  const getEmail = async (id, l) => {
    if (l !== 3) {
      try {
        const response = await axiosPrivate.get(
          `mailbox/initialize-reply-email/${id}`,
          {}
        );

        if (l === 2) {
          setMessageId(response.data.msg_detail.message_id);
          setUID(response.data.msg_detail.uid);
          setEmailBody(response.data.body_html);

          let dataTo = response.data.reply_to_all.to.split(",");
          SetFromHeader([]);
          dataTo.map((ele) => SetFromHeader((val) => [...val, ele]));
          if (response.data.reply_to_all.cc !== "") {
            const newString = response.data.reply_to_all.cc.replace(/^,/, "");
            let datacc = newString.split(",");
            setCC([]);
            datacc.map((ele) => setCC((val) => [...val, ele]));
          }
        } else if (l === 1) {
          setMessageId(response.data.msg_detail.message_id);
          setUID(response.data.msg_detail.uid);
          setEmailBody(response.data.body_html);

          let dataTo = response.data.reply_to.to.split(",");

          SetFromHeader([]);
          dataTo.map((ele) => SetFromHeader((val) => [...val, ele]));
          if (response.data.msg_detail.cc !== "") {
            // let datacc = response.data.msg_detail.cc.split(",");
            const newString = response.data.msg_detail.cc.replace(/^,/, "");
            let datacc = newString.split(",");
            setCC([]);
            datacc.map((ele) => setCC((val) => [...val, ele]));
          }
        }

        if (response.data.msg_detail.subject) {
          setSubject(response.data.msg_detail.subject);
        }

        setUID(response.data.msg_detail.uid);

        setEmailBody(response.data.body_html);
      } catch (err) {}
    } else {
      try {
        const response = await axiosPrivate.get(
          `mailbox/initialize-forward-email/${id}`,
          {}
        );

        setMessageId(response.data.msg_detail.message_id);
        setUID(response.data.msg_detail.uid);

        if (response.data.msg_detail.subject) {
          setSubject(response.data.msg_detail.subject);
        }

        setEmailBody(response.data.body_html);
      } catch (err) {}
    }
  };

  const handleMailClick = (e, mailid) => {
    let eleement = document.getElementById("elee" + mailid);

    document
      .querySelectorAll(".bidBtnAssign")
      .forEach((el) => el.classList.remove("colorGreen"));
    eleement.classList.add("colorGreen");
  };

  const getRoleList = async (searchText) => {
    setLoaderDrop(true);
    try {
      const response = await axiosPrivate.get(
        `/mailbox/inbox?query_filter=search%3D${searchText}&size=100&sort=-uid`,
        {}
      );

      setEmailConatiner(response.data.items);
    } catch (err) {}
    setLoaderDrop(false);
  };

  const getBidFromId = async () => {
    try {
      const response = await axiosPrivate.get(`/bid/get-bid/${id}`, {});

      if (response.data.bid.client.client_region.id == 0) {
        setAgainRegionid("");
      } else {
        setAgainRegionid(response.data.bid.client.client_region.id);
      }
      setBidNumber(response.data.bid.bid_number);
      setProjectName(response.data.bid.project_name);
      if (
        response.data.bid.mailbox_message_id !== "" &&
        response.data.bid.mailbox_message_id !== "false"
      ) {
        setIsEmail(true);
        emailDetail(response.data.bid.mailbox_message_id);
        setSelectedMail(response.data.bid.mailbox_message_id);
        setMail_id(response.data.bid.mailbox_message_id);
      }
      setUserChoose(response.data.bid.bid_owner.id);
      setValue(userChoose, response.data.bid.bid_owner.id);
      setUserStatus(response.data.bid.bid_status.id);
      setUserClientContact(response.data.bid.client.client_contact_id);
      setClientEmail(response.data.bid.client.client_contact_email);
      setCreatedName(response.data.bid.created_by);
      setCreatedDate(response.data.bid.created_at);
      setLastModifyName(response.data.bid.modified_by);
      setLastModifyDate(response.data.bid.modified_at);

      var followUp = response.data.bid.follow_up;
      if (followUp === 0) {
        setfollowToggle(false);
      } else {
        setfollowToggle(true);
      }

      get_CLientContact_AllList(response.data.bid.client.client_id);
      getRegion(response.data.bid.client.client_id);

      var data = [];
      data.push(response.data.bid.client);
      var clientlist = [...data];
      const clientdata = clientlist.map((item, i) =>
        Object.assign(item, {
          value: parseInt(item.client_id),
          label: item.client_name,
        })
      );
      setuserAccountId(clientdata);
      setAccountSingleId(response.data.bid.client.client_id);
    } catch (err) {
      if (err.response.status === 422) {
        navigate("/404");
      } else if (err.response.status === 404) {
        navigate("/404");
      } else if (err.response.status === 500) {
        navigate("/500");
      } else {
        // console.error(err.response);
      }
    }
    setLoder(true);
  };

  const getRegion = async (accountid) => {
    try {
      const response = await axiosPrivate.get(
        `client/client-region-list/${accountid}`,
        {}
      );
      let data = response.data.all_client_region;
      if (data.length > 0) {
        setClientRegion(data);
        setShowRegionInput(true);
      } else {
        setShowRegionInput(false);
      }
    } catch (err) {}
    return () => {};
  };

  const getUserLIst = async () => {
    try {
      const response = await axiosPrivate.get("user/user-department/2", {});
      setUserData(response.data.all_users);
    } catch (err) {}
  };

  const handleStatus = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get_bid_status", {});
      setbidStatus(response.data.all_status);
    } catch (err) {}
  };

  const get_CLientContact_AllList = async (id) => {
    getRegion(id);
    try {
      const response = await axiosPrivate.get(
        `/client/client-contact-list/${id}`,
        {}
      );
      let data = response.data.all_client_contact;
      setClientContactList(data);
    } catch (err) {}
    return () => {};
  };

  const handleBidUpdate = async (e) => {
    setIsSubmitting(true);
    var regionName = "";
    var regionId = 0;
    if (e.region == undefined) {
      regionId = 0;
      regionName = "";
    } else {
      const resultname = clientRegion.filter((word) => word.id == e.region);
      regionName = resultname[0].name;
      regionId = resultname[0].id;
    }

    try {
      await axiosPrivate
        .patch(
          `/bid/update-bid`,
          JSON.stringify({
            bid_id: String(id),
            project_name: e.projectname,
            bid_owner_id: e.BidOwner,
            client_id: accountSingleId,
            bid_status_id: e.Status,
            client_contact_id: parseInt(e.contactname),
            client_contact_email: clientEmail,
            client_region: {
              id: parseInt(regionId),
              name: regionName,
            },
            follow_up: followToggle,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          if (e.status === 200) {
            notifyActive();
            navigate("/bid-details");
          } else {
            notifyerror(e);
          }
        });
    } catch (err) {}
    setIsSubmitting(false);
  };

  const handleMailPost = async (bidid) => {
    try {
      await axiosPrivate
        .patch(
          `/mailbox/attach-bid-email`,
          JSON.stringify({
            mesg_id: bidid,
            bid_id: id,
          })
        )
        .then((e) => {
          if (e.status === 200) {
            getBidFromId();
          }
        });
    } catch (err) {}
  };

  const notifyerror = (e) => toast.error(`${e}`);
  const notifyActive = () => toast.success("Bid updated succesfully");
  const handleFollowUp = (e) => {
    setfollowToggle(e);
  };

  const contactNameReg = register("contactname", {
    required: "This field is Required",
  });
  const bidownerReg = register("BidOwner", {
    required: "This field is Required",
  });

  const statusReg = register("Status", {
    required: "This field is Required",
  });

  // const regionReg = register("region", {
  //   required: "This Field is required",
  // });

  const emailReg = register("email", {
    required: "This fiels is Required",
    pattern: {
      value:
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\],;:\s@\"]+)*)|(.+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@]{2,})$/i, //eslint-disable-line
      message: "Enter Valid Email Id",
    },
  });

  const customStyles = {
    content: {
      top: "0%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, 4%)",
      width: "90%",
      maxWidth: "850px",
      overflowY: "auto",
    },
  };
  const customPopupStyles = {
    content: {
      top: "0%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, 4%)",
      width: "90%",
      maxWidth: "500px",
      overflowY: "auto",
      height: "350px",
      marginTop: "100px",
    },
  };

  const handleSelect = (e) => {
    setAccountSingleId(e._id);
    get_CLientContact_AllList(e.value);
    setUserClientContact("");
    setClientEmail("Enter Client Email");
  };

  function openPopup() {
    setPopupIsOpen(true);
  }

  const DropSubject = () => {
    return (
      <>
        {subjectDrop.map((ele) => (
          <div
            className="mailboxDrop"
            id={"mailDiv" + ele.id}
            onClick={(e) => {
              handleMailClick(e, ele.id);
            }}
          >
            <h6>
              <span>
                <img
                  src={MailBoxIcon}
                  style={{
                    marginRight: "10px",
                    height: "17px",
                    marginLeft: "10px",
                  }}
                  alt=""
                />
              </span>
              {ele.subject}
            </h6>

            <p> {ele.header}</p>
            <button
              className="bidBtnAssign"
              id={"elee" + ele.id}
              onClick={(e) => {
                e.preventDefault();

                handleMailPost(ele.id);
              }}
            >
              Assign
            </button>
          </div>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (subjectSearch !== "" && subjectSearch !== undefined) {
      function test(userList, sub) {
        setSubjectDRop([]);
        emailConatiner.map((str) => {
          setSubjectDRop((val) => [
            ...val,
            { subject: str.subject, header: str.from_header, id: str._id },
          ]);
        });
      }
      const userLast = subjectSearch;

      var results = test(emailConatiner, userLast);
    }
  }, [emailConatiner]);

  return (
    <>
      <BidsSubHeader pname="biddetails" />
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachbox">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={userIco} className="headingIcons" alt="" /> Bid-{" "}
                {bidNumber}
              </h3>
            </div>
            <div className="right-pane">
              <button
                className="cbtn closeBtn"
                onClick={() => {
                  navigate("/bid-details");
                }}
              >
                Close
              </button>
            </div>
          </div>
          <hr />
          <div className="Deatail_to_client">
            <div className="tabs active-tabss">Details</div>
          </div>
          <hr style={{ marginTop: "0px" }} />
          {loder ? (
            <>
              <div className="mainContent">
                <div className="big_first_div big_first_div2">
                  <form onSubmit={handleSubmit(handleBidUpdate)}>
                    <Row>
                      <Col lg={6} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Project name*</label>
                        <input
                          type="text"
                          placeholder="Project name"
                          className="formtext"
                          {...register("projectname", {
                            value: projectName,
                            required: "This field is Required",
                          })}
                        />
                        {errors.projectname && (
                          <small className="errmsg">
                            {errors.projectname.message}
                          </small>
                        )}
                      </Col>

                      <Col lg={6} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Bid Owner*</label>
                        <select
                          className="formtext"
                          {...bidownerReg}
                          onChange={(e) => {
                            bidownerReg.onChange(e);
                            setUserChoose(e.target.value);
                          }}
                          value={userChoose}
                        >
                          {userData.map((e) => (
                            <option value={e._id}>{e.name}</option>
                          ))}
                        </select>
                        {errors.Bidowner && (
                          <small className="errmsg">
                            {errors.Bidowner.message}
                          </small>
                        )}
                      </Col>

                      <Col
                        lg={6}
                        md={4}
                        sm={6}
                        xs={12}
                        className="col formCont"
                      >
                        <label className="formlabel">Account*</label>
                        <Select
                          className="formtext"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={false}
                          isRtl={false}
                          isSearchable={true}
                          name="color"
                          options={clients}
                          onChange={handleSelect}
                          defaultValue={userAccountId}
                        />
                      </Col>

                      <Col lg={6} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Status*</label>
                        <select
                          className="formtext"
                          {...statusReg}
                          onChange={(e) => {
                            statusReg.onChange(e);
                            setUserStatus(e.target.value);
                          }}
                          value={userStatus}
                        >
                          {bidStatus.map((e) => (
                            <option value={e.id}>{e.name}</option>
                          ))}
                        </select>
                        {errors.Status && (
                          <small className="errmsg">
                            {errors.Status.message}
                          </small>
                        )}
                      </Col>

                      <Col lg={6} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Contact Name*</label>
                        <select
                          className="formtext"
                          {...contactNameReg}
                          onChange={(e) => {
                            contactNameReg.onChange(e);
                            setUserClientContact(e.target.value);
                            {
                              clientContactList.map((ccid) => {
                                if (ccid.id == e.target.value) {
                                  setClientEmail(ccid.email);
                                }
                              });
                            }
                          }}
                          value={userClientContact}
                        >
                          <option value="">Select Contact Name</option>
                          {clientContactList.map((e) => (
                            <option value={e.id}>{e.name}</option>
                          ))}
                        </select>
                        {errors.contactname && (
                          <small className="errmsg">
                            {errors.contactname.message}
                          </small>
                        )}
                      </Col>
                      <Col lg={6} md={4} sm={6} xs={12} className="col">
                        <label className="formlabel">Client Email*</label>
                        <input
                          className="formtext"
                          {...emailReg}
                          onChange={(e) => {
                            emailReg.onChange(e);
                            setClientEmail(e.target.value);
                          }}
                          value={clientEmail}
                        />
                        {errors.email && (
                          <small className="errmsg">
                            {errors.email.message}
                          </small>
                        )}
                      </Col>

                      {showRegionInput && (
                        <Col lg={6} md={6} sm={6} xs={12} className="col">
                          <label className="formlabel">Region*</label>
                          <select
                            className="formtext"
                            id="region"
                            // {...regionReg}
                            {...register("region", {
                              required: "This field is Required",
                            })}
                            // onChange={(e) => {
                            //   setAgainRegionid(e.target.value);
                            // }}
                            defaultValue={againRegionid}
                          >
                            <option value="">Select Option</option>
                            {clientRegion.map((e) => (
                              <option value={e.id}>{e.name}</option>
                            ))}
                          </select>
                          {errors.region && (
                            <small className="errmsg">
                              {errors.region.message}
                            </small>
                          )}
                        </Col>
                      )}
                    </Row>

                    <div className="follow_up_section">
                      <div className="follow_up">
                        <div>Is follow up</div>
                        <BootstrapSwitchButton
                          checked={followToggle}
                          size="xs"
                          width={60}
                          onlabel="YES"
                          offlabel="NO"
                          onstyle="outline-success"
                          offstyle="outline-danger"
                          onChange={(e) => {
                            handleFollowUp(e);
                          }}
                        />
                      </div>

                      <div className="" user_searchbox></div>

                      <div className="btns_section">
                        {superUser === true ? (
                          <>
                            <button
                              className="cbtn closeBtn"
                              onClick={() => {
                                navigate(`/add-opportunity/${id}`);
                              }}
                              style={{ marginRight: "0px" }}
                            >
                              Add Opportunity
                            </button>
                          </>
                        ) : (
                          <>
                            {all_permission.includes(
                              "bid.bid_add_opportunity"
                            ) ? (
                              <>
                                <button
                                  className="cbtn closeBtn"
                                  onClick={() => {
                                    navigate(`/add-opportunity/${id}`);
                                  }}
                                  style={{ marginRight: "0px" }}
                                >
                                  Add Opportunity
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                        {superUser === true ? (
                          <>
                            <button
                              disabled={isSubmitting}
                              className="cbtn cbtn2 saveBtn"
                            >
                              {isSubmitting && (
                                <Spinner
                                  as="span"
                                  style={{ color: "white" }}
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  onClick={() => {
                                    handleBidUpdate();
                                  }}
                                />
                              )}
                              &nbsp; Save
                            </button>
                          </>
                        ) : (
                          <>
                            {all_permission.includes("bid.bid_update") ? (
                              <>
                                <button
                                  disabled={isSubmitting}
                                  className="cbtn cbtn2 saveBtn"
                                >
                                  {isSubmitting && (
                                    <Spinner
                                      as="span"
                                      style={{ color: "white" }}
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      onClick={() => {
                                        handleBidUpdate();
                                      }}
                                    />
                                  )}
                                  &nbsp; Save
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                  {/*--opportunity div--- */}

                  {superUser === true ? (
                    <>
                      <OpportunityList bidId={id} />
                    </>
                  ) : (
                    <>
                      {all_permission.includes(
                        "bid.bid_get_opportunity_list"
                      ) ? (
                        <>
                          <OpportunityList bidId={id} />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  <div className="system_info_div">
                    <div className="system_information_div">
                      <div className="system_color">
                        System Information<br></br>
                      </div>
                      <div className="system_color">
                        Created BY: &nbsp;
                        <span>
                          {createdName}
                          &nbsp;
                          {moment(createdDate).format("D/MM/YYYY, h:mm a")}
                        </span>
                      </div>
                    </div>
                    <div className="modify_div">
                      <div className="system_color">
                        Last Modified BY: &nbsp;{" "}
                        <span>
                          {lastModifyName}
                          &nbsp;
                          {moment(lastModifyDate).format("D/MM/YYYY, h:mm a")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="big_second_div big_second_div2">
                  {isEmail ? (
                    <div className="emailContainer">
                      <input
                        className="second_div_input"
                        placeholder={fromHeadershow}
                        value={fromHeadershow}
                        readOnly={true}
                      />
                      <div className="email_subject">Email Subject</div>
                      <div className="bid_request">{subshow}</div>
                      <div className="separator">
                        <hr></hr>
                      </div>
                      <div className="reply_btns_section">
                        <div>
                          <button
                            style={{ cursor: "pointer" }}
                            className="btn_right_shift"
                            onClick={() => {
                              getEmail(selectedMail, 1);
                              setModalIsOpen(true);
                              setR(1);
                              setReplyBox(true);
                            }}
                          >
                            Reply
                          </button>
                        </div>
                        <div>
                          <button
                            style={{ cursor: "pointer" }}
                            className="btn_right_shift "
                            onClick={() => {
                              getEmail(selectedMail, 2);
                              setModalIsOpen(true);
                              setR(2);
                              setReplyBox(true);
                            }}
                          >
                            Reply All
                          </button>
                        </div>
                        <div>
                          <button
                            style={{ cursor: "pointer" }}
                            className="btn_right_shift "
                            onClick={() => {
                              getEmail(selectedMail, 3);
                              setModalIsOpen(true);
                              setR(3);
                              setReplyBox(true);
                            }}
                          >
                            Forward
                          </button>
                        </div>
                      </div>
                      <div className="email_section">
                        <div>Email Content</div>
                        <div className="email_content">
                          <br></br>
                          <div
                            dangerouslySetInnerHTML={{ __html: emailCont }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <h6>Do you want to attach an email?</h6>
                      <a className="attachmail" onClick={openPopup}>
                        Click here to assign email
                      </a>
                      <Modal isOpen={popupIsOpen} style={customPopupStyles}>
                        <img
                          className="Close"
                          src={CrossIcon}
                          alt=""
                          style={{
                            cursor: "pointer",
                            width: "20px",

                            float: "right",
                            marginBottom: "10px",
                          }}
                          onClick={(e) => {
                            setPopupIsOpen(false);
                          }}
                        />
                        <h5 style={{ fontSize: "1.25rem" }}>Assign Email</h5>
                        {/* <form> */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <input
                            style={{
                              width: "80%",
                            }}
                            type="text"
                            className="formtext"
                            onChange={(e) => {
                              setSubjectSearch(e.target.value);
                            }}
                            placeholder="Search with subject"
                          />
                          <button
                            className="cbtn cbtn2 saveBtn"
                            onClick={() => {
                              getRoleList(subjectSearch.trim());
                            }}
                          >
                            {isSubmitting && (
                              <Spinner
                                style={{ color: "white" }}
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            Submit
                          </button>
                        </div>

                        {loaderdrop === false ? (
                          <div className="suggestion">
                            <DropSubject />
                          </div>
                        ) : (
                          <>
                            <SpinnerLoader />
                          </>
                        )}

                        {/* </form> */}
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <SpinnerLoader />
            </>
          )}
        </div>
      </div>

      {showReplyBox ? (
        <>
          <Modal isOpen={modalIsOpen} style={customStyles}>
            <img
              className="Close"
              src={CrossIcon}
              alt=""
              style={{
                cursor: "pointer",
                width: "25px",
                position: "absolute",
                right: "10px",
              }}
              onClick={(e) => {
                setModalIsOpen(false);
              }}
            />

            <ReplyBlock
              fromHeader={fromHeader}
              uid={uid}
              messageId={messageId}
              subject={subject}
              cc={cc}
              body={emailBody}
              r={r}
              id={mail_id}
              attachments={attachments}
              setReplyBox={setReplyBox}
              showReplyBox={showReplyBox}
            />
            <span
              className="Close"
              onClick={(e) => {
                setModalIsOpen(false);
              }}
            >
              <img src={DeleteIcon} alt="Delete" height={20} />
            </span>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditBid;
