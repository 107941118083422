import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import CreatableSelect from "react-select/creatable";

function TargetQuestionPopup(props) {
  const [selected, setSelected] = useState([]);

  const [SingleQuestion, setSingleQuestion] = useState([
    { label: "DMA", value: ["DMA1", "DMA2", "DMA3"] },
    { label: "STATE", value: ["STATE1", "STATE4", "STATE3"] },
    {
      label: "DIVISION",
      value: [
        "DIVISION1",
        "DIVISION4",
        "DIVISION3",
        "DIVISION11",
        "DIVISION41",
        "DIVISION31",
      ],
    },
    {
      label: "HOUSEHOLD DECISION MAKER",
      value: ["HOUSEHOLD4", "HOUSEHOLD64", "HOUSEHOLD3"],
    },
    { label: "MOVIES FREQUENCY", value: ["MOVIES1", "MOVIES2", "MOVIES3"] },
    { label: "AGE AND GENDER OF CHILD", value: ["AGe1", "AGe2", "AGe3"] },
  ]);

  const [questionChild, setQuestionChild] = useState([]);

  const handleFunction = (data, lable) => {
    const clientdata = data.map((item) =>
      Object.assign({
        value: item,
        label: item,
      })
    );
    setQuestionChild(clientdata);
  };

  const handleSubmit = () => {
    props.RecevingOptions(selected);
    props.popup(false);
    setSelected([]);
  };

  return (
    <>
      <div className="">
        <Modal
          id="checkkkkk"
          className="questionpopup"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ fontSize: "16px", paddingLeft: "10px" }}
            ></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ height: "100px" }}>
              <Col lg={6} md={6} sm={6} xs={12} iv className="col-3">
                <h5>Question Decsription</h5>
              </Col>
              <Col lg={6} md={6} sm={6} xs={12} iv className="col-3 pjt_select">
                <CreatableSelect
                  className="formtext"
                  styles={{ fontSize: "14px" }}
                  isClearable
                  options={SingleQuestion}
                  onChange={(e) => handleFunction(e.value, e.label)}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ height: "50px", textAlign: "center" }}>
                I'm looking for people that work in companies that employ
                employees
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} iv className="col-3">
                <MultiSelect
                  className="multiselectoption"
                  options={questionChild}
                  value={selected}
                  onChange={setSelected}
                  labelledBy={"Select"}
                  isCreatable={true}
                  disableSearch
                />
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  backgroundColor: "black",
                  paddingTop: "2px",
                  height: "30px",
                  margin: "13px",
                  color: "white",
                  textAlign: "center",
                }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Apply Targeting
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
export default TargetQuestionPopup;
