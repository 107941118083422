import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState } from "react";
import { GrSubtractCircle } from "react-icons/gr";
import { BsFillPlayFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import { BsFillSkipForwardFill } from "react-icons/bs";
import "../QuestionModule/EmailQuestions.css";
import { MultiSelect } from "react-multi-select-component";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
const EmailQuestion = (prop) => {
  const register = prop.register;
  const count = prop.dex;
  const [inputs, setInputs] = useState([""]);
  const [selecteduser, setSelectedUser] = useState([]);

  function handleAddInput() {
    setInputs((prevInputs) => [...prevInputs, ""]);
  }
  var data = [
    { value: "Paffffffffnel", label: "Panel" },
    { value: "Fulddddddddddddcrum", label: "Fulcrum SH1" },
  ];

  function handleRemoveInput(index) {
    setInputs((prevInputs) => {
      const newInputs = [...prevInputs];
      newInputs.splice(index, 1);
      return newInputs;
    });
  }

  function handleChangeInput(index, value) {
    setInputs((prevInputs) => {
      const newInputs = [...prevInputs];
      newInputs[index] = value;
      return newInputs;
    });
  }

  return (
    <>
      <Row className="firstRowborder">
        {" "}
        <Col lg={12} md={12} sm={12} xs={12}>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <label className="formlabelamit">Question*</label>
              <input
                id="singleQuestion"
                placeholder="Enter Question"
                className="width100"
                {...register(`childInput${count}deta`, { required: true })}
              />
            </Col>
            <div className="emailquestionGap"></div>
            <Col>
              {inputs.map((input, index) => (
                <div key={input.id}>
                  <tbody>
                    <tr className="radiohidden">
                      <td>{index + 1}.</td>
                      {"Enter" === "Enter" ? (
                        <>
                          <td className="width100">
                            <input
                              type="text"
                              value={input}
                              id={count + "childinput" + index}
                              className="width100"
                              placeholder="Enter Options"
                              onChange={(event) =>
                                handleChangeInput(index, event.target.value)
                              }
                            />
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ width: "1010px" }}>
                            <input
                              className="width100"
                              required
                              {...register(`childInput${count}${index}`, {
                                required: true,
                              })}
                            />
                          </td>
                        </>
                      )}

                      <div id="prescreen" class="radio-block">
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GrSubtractCircle
                            type="button"
                            style={{ marginLeft: "-24px" }}
                            onClick={(e) => {
                              handleRemoveInput(index);
                            }}
                          />
                        </td>
                        <td className="iconalliment">
                          <div class="radio-content">
                            <input
                              name={"radio" + index + count}
                              type="radio"
                              id={"radio" + index + 11 + count}
                            />
                            <label
                              className="play"
                              for={"radio" + index + 11 + count}
                            >
                              <BsFillPlayFill />
                            </label>
                          </div>
                        </td>
                        <td className="iconalliment">
                          <div class="radio-content">
                            <input
                              name={"radio" + index + count}
                              type="radio"
                              id={"radio" + index + 33 + count}
                            />
                            <label
                              className="circle"
                              for={"radio" + index + 33 + count}
                            >
                              <BsFillArrowUpRightCircleFill size={15} />
                            </label>
                          </div>
                        </td>
                        <td className="iconalliment">
                          <div class="radio-content">
                            <input
                              name={"radio" + index + count}
                              type="radio"
                              id={"radio" + index + 22 + count}
                            />
                            <label
                              className="cross"
                              for={"radio" + index + 22 + count}
                            >
                              <ImCross size={13} />
                            </label>
                          </div>
                        </td>{" "}
                        <td
                          className="iconalliment"
                          style={{
                            margin: "10px",
                          }}
                        >
                          <div class="radio-content">
                            <input
                              name={"radio" + index + count}
                              type="radio"
                              id={"radio" + index + 44 + count}
                            />
                            <label
                              className="skip"
                              for={"radio" + index + 44 + count}
                            >
                              <BsFillSkipForwardFill size={15} />
                            </label>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </tbody>
                </div>
              ))}
            </Col>
          </Row>

          <div className="emailquestionGap"></div>
          <button
            className="addButtonOPtions"
            onClick={handleAddInput}
            type="button"
          >
            Add Answer
          </button>
        </Col>
        <div className="emailquestionGap"></div>
        <Row>
          {" "}
          <Col lg={3} md={6} sm={6} xs={12}>
            <label className="formlabel"> Mandatory</label>
            <span className="pswtchbtn">
              <BootstrapSwitchButton
                className="switch btn on btn-outline-success btn-xs"
                size="xs"
                width={60}
                onlabel="ON "
                offlabel="OFF"
                onstyle="outline-success"
                offstyle="outline-danger"
              />
            </span>
          </Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <label className="formlabel"> Randomize Answer</label>

            <span className="pswtchbtn">
              <BootstrapSwitchButton
                className="switch btn on btn-outline-success btn-xs"
                size="xs"
                width={60}
                onlabel="ON"
                offlabel="OFF"
                onstyle="outline-success"
                offstyle="outline-danger"
              />
            </span>
          </Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <label className="formlabel"> Add Predefined Answer</label>
            <div className="radiouscolorchanges">
              <select
                id="addPredefinedstyle"
                class="form-select"
                aria-label="Default select example"
                onChange={(end) => {
                  setInputs((prevInputs) => [...prevInputs, end.target.value]);
                }}
              >
                <option selected>Add Predefined Answer</option>
                <option value="None of the above">None of the above</option>
                <option value="I don't know">I don't know</option>
                <option value="Prefer not to answer">
                  Prefer not to answer
                </option>
                <option value="Others">Others</option>
              </select>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} xs={12} className="csmulti">
            {" "}
            <div className="multiselectoption">
              <label className="formlabelamit">No Show Vendor</label>
              <MultiSelect
                className="prescreenMultiselect"
                options={data}
                value={selecteduser}
                onChange={(e) => {
                  setSelectedUser(e);
                }}
                labelledBy={"Select"}
                isCreatable={true}
                disableSearch
              />
            </div>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default EmailQuestion;
