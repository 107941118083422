import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState } from "react";

import Table from "react-bootstrap/Table";
import "../Invoice.css";

const Invoicesurveylogs = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Survey Logs: SC106647 - Project 3
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered={false} hover size="sm" className="customTable">
          <thead className="thead">
            <tr>
              <td>Date/Time</td>
              <td>Activity</td>
              <td>Survey</td>
              <td
                style={{
                  padding: "0px",
                }}
              >
                User
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>11-05-2023/04:20AM</td>
              <td className="activity_row">
                File Uploaded
                <div className="invoice_log_sub">
                  <p className="invoice_table_p">Upload Ids count : 40</p>
                  <p className="invoice_table_p">Approved Ids count : 38</p>
                </div>
              </td>
              <td>US Gen Pop 18+</td>
              <td>Parth</td>
            </tr>
            <tr>
              <td>11-05-2023/04:20AM</td>
              <td>
                File Uploaded
                <div className="invoice_log_sub">
                  <p className="invoice_table_p">Upload Ids count : 40</p>
                  <p className="invoice_table_p">Approved Ids count : 38</p>
                </div>
              </td>
              <td>India Gen Pop 18+</td>
              <td>Ankit</td>
            </tr>
            <tr>
              <td>11-05-2023/04:20AM</td>
              <td>Billing Approved</td>
              <td>All</td>
              <td>Ankit</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default Invoicesurveylogs;
