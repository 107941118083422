import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "bootstrap/dist/css/bootstrap.css";
import { BiUpload, BiPurchaseTag } from "react-icons/bi";
import Table from "react-bootstrap/Table";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const Supliercostinfo = () => {
  const dataobj = [
    {
      id: 1,
      name: "US Gen Pop 18+",
      statusname: "Live",
    },
    {
      id: 2,
      name: "India Gen Pop 18+",
      statusname: "Billed",
    },
  ];
  const handleShowSect = (id) => {
    var element = document.getElementById("firstSect" + id);
    let iconShow = document.getElementById("iconSect" + id);

    if (element.matches(".open")) {
      element.classList.remove("open");
      iconShow.classList.remove("open");
    } else {
      element.classList.add("open");
      iconShow.classList.add("open");
    }
  };

  return (
    <>
      <div
        className="pswtchbtn pcolorbtn pswtchleft"
        style={{
          float: "none",
        }}
      >
        <BootstrapSwitchButton
          className="switch btn on btn-outline-success btn-xs projectswitchbtn"
          id="bbts"
          size="xs"
          width={80}
          onlabel="Live"
          offlabel="Inactive"
          onstyle="outline-success"
          offstyle="outline-danger"
        />
      </div>

      <div className="cost_main_cont">
        <div className="supplyInfocost">
          <p className="supinfoTitle">SC106647 - Project 3</p>
          {/* <div className="supplyInfoT_icon">
            <BiPurchaseTag size={25} />
            <BiUpload size={25} />
          </div> */}
        </div>

        {dataobj.map((itemobj, indexitem) => (
          <>
            <div
              className="table_box"
              id={"iconSect" + itemobj.id}
              onClick={() => {
                handleShowSect(itemobj.id);
              }}
            >
              <div className="ScreeningHead pjt_arrow_icon">
                <p className="pBlockHead">{itemobj.name}</p>
                {/* <div className="rankPain">
                  {itemobj.statusname === "Live" ? (
                    <p className="liveBtn costbtn">{itemobj.statusname}</p>
                  ) : itemobj.statusname === "Billed" ? (
                    <p
                      className="liveBtn costbtn"
                      style={{
                        background:
                          "linear-gradient(to right, #0b87b2, #0b87b2)",
                      }}
                    >
                      {itemobj.statusname}
                    </p>
                  ) : (
                    <></>
                  )}
                  <div className="supplyInfoT_icon_inside">
                    <BiPurchaseTag size={20} />
                    <div className="pjt_arrow_icon costup_icon">
                      <KeyboardArrowUpIcon className="SIconUp" />

                      <KeyboardArrowDownIcon className="SDropIcon" />
                    </div>
                  </div>
                  <div className="ScreeningBtn"></div>
                </div> */}
                <div className="pjt_arrow_icon costup_icon">
                  <KeyboardArrowUpIcon className="SIconUp" />

                  <KeyboardArrowDownIcon className="SDropIcon" />
                </div>
              </div>
              <div id={"firstSect" + itemobj.id} className="cost_table">
                <Table
                  bordered={false}
                  hover
                  size="sm"
                  className="invoice_customTable"
                >
                  <thead className="sup_thead">
                    <tr>
                      <td>Vendor</td>
                      <td>Hits</td>
                      <td>Completes</td>
                      <td>Approved Counts</td>
                      <td>Incentive</td>
                      <td>Revenue</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Dynata</td>
                      <td> 215</td>
                      <td>120 </td>

                      <td>140</td>
                      <td> $2.00</td>
                      <td>$240</td>
                    </tr>
                    <tr>
                      <td>Market Vision</td>
                      <td> 215</td>
                      <td>120 </td>

                      <td>140</td>
                      <td> $2.00</td>
                      <td>$240</td>
                    </tr>

                    <tr>
                      <td>Azure</td>
                      <td> 215</td>
                      <td>120 </td>

                      <td>140</td>
                      <td> $2.00</td>
                      <td>$240</td>
                    </tr>
                    <tr>
                      <td>Brand Research</td>
                      <td> 215</td>
                      <td>120 </td>

                      <td>140</td>
                      <td> $2.00</td>
                      <td>$240</td>
                    </tr>
                    <tr>
                      <td>GSS Insight</td>
                      <td> 215</td>
                      <td>120 </td>

                      <td>140</td>
                      <td> $2.00</td>
                      <td>$240</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>1075</td>
                      <td>700</td>
                      <td>600</td>
                      <td>-</td>
                      <td>$1200</td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default Supliercostinfo;
