import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useRef, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import "./Buildids.css";
import { useForm } from "react-hook-form";
const Buildurl = (props) => {
  const [devicetype, setDeviceTYpe] = useState([]);
  const [disabledOptions, setDisabledOptions] = useState([]);
  const [textareaValue, setTextareaValue] = useState("");
  const [variableType, setVariableType] = useState([]);
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [combinedId, setCombinedId] = useState([
    {
      name: "https://drive.google.com?RID=[%RID%]",
      value: "RID",
    },
  ]);
  const [finalId, setFinalId] = useState([
    {
      name: "https://drive.google.com?RID=[%RID%]",
      value: "RID",
    },
  ]);

  const [vttt, setVtt] = useState([
    {
      label: "Panalist Id",
      name: "Panalist Id",
      value: "PID",
    },
    {
      label: "Supplier Id",
      name: "Supplier Id",
      value: "SID",
    },
  ]);

  const [multiid, setMultiId] = useState([]);

  const devicetypearray = [
    { label: "Mobile", value: "1" },
    { label: "Tablet", value: "2" },
    { label: "Laptop", value: "3" },
    { label: "Moble", value: "11" },
    { label: "Tablt", value: "21" },
    { label: "Laptp", value: "31" },
    { label: "Mbile", value: "12" },
    { label: "Tblet", value: "22" },
    { label: "Lptop", value: "32" },
  ];
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const handleMultiSelectChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map(
      (option) => ({
        name: option.label,
        value: option.value,
        label: option.label,
        disabled: false, // Enable the option when selected
      })
    );

    setVariableType((prevVariableType) => {
      const updatedVariableType = selectedOptions.filter(
        (option) =>
          !prevVariableType.some((variable) => variable.value === option.value)
      );
      return [...prevVariableType, ...updatedVariableType];
    });
    setCheckboxOptions((prevCheckboxOptions) => {
      const updatedCheckboxOptions = selectedOptions.filter(
        (option) =>
          !prevCheckboxOptions.some(
            (checkboxOption) => checkboxOption.value === option.value
          )
      );
      return [...prevCheckboxOptions, ...updatedCheckboxOptions];
    });
    setCombinedId((prevCombinedId) => [
      ...prevCombinedId,
      ...selectedOptions.map((option) => ({
        name: `&${option.name}=[%${option.value}%]`,
        value: option.value,
      })),
    ]);

    // Disable the selected options
    setDisabledOptions((prevDisabledOptions) => [
      ...prevDisabledOptions,
      ...selectedOptions.map((option) => option.value),
    ]);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    // Enable or disable the selected option in the select element
    setDisabledOptions((prevDisabledOptions) => {
      if (!checked) {
        return prevDisabledOptions.filter((option) => option !== name);
      } else {
        return [...prevDisabledOptions];
      }
    });
    setVariableType((prevVariableType) =>
      prevVariableType.filter((variable) => variable.value !== name)
    );

    if (!checked) {
      // Remove the value from combinedId
      setCombinedId((prevCombinedId) => {
        const updatedCombinedId = prevCombinedId.filter(
          (option) => option.value !== name
        );
        return updatedCombinedId;
      });
      setCheckboxOptions((prevCheckboxOptions) =>
        prevCheckboxOptions.filter((option) => option.value !== name)
      );
    } else {
      const selectedOption = devicetypearray.find(
        (option) => option.value === name
      );
      if (selectedOption) {
        setVariableType((prevVariableType) => [
          ...prevVariableType,
          selectedOption,
        ]);
      }

      // Append the value to combinedId
      setCombinedId((prevCombinedId) => [
        ...prevCombinedId,
        {
          name: `&${selectedOption.name}=[%${selectedOption.value}%]`,
          value: selectedOption.value,
        },
      ]);
    }
  };

  const handleInputChange = (event, value) => {
    const { name, value: inputValue } = event.target;
    setCheckboxOptions((prevCheckboxOptions) => {
      return prevCheckboxOptions.map((option) => {
        if (option.value === value) {
          return {
            ...option,
            name: inputValue,
          };
        }
        return option;
      });
    });
    setCombinedId((prevCombinedId) => {
      const updatedCombinedId = prevCombinedId.map((option) => {
        if (option.value === value) {
          return {
            ...option,
            name: `&${inputValue}=[%${option.value}%]`,
          };
        }
        return option;
      });
      return updatedCombinedId;
    });
  };

  const handleVttttInputChange = (event, value) => {
    const { value: inputValue } = event.target;

    setVtt((prevVtt) => {
      const updatedVtt = prevVtt.map((item) => {
        if (item.value === value) {
          return {
            ...item,
            name: inputValue,
          };
        }
        return item;
      });
      return updatedVtt;
    });

    setCombinedId((prevCombinedId) => {
      const updatedCombinedId = prevCombinedId.map((option) => {
        if (option.value === value) {
          return {
            ...option,
            name: `&${inputValue}=[%${option.value}%]`,
          };
        }
        return option;
      });
      return updatedCombinedId;
    });
  };

  const handlesidChange = (event, index) => {
    const { checked } = event.target;
    const { name, value } = vttt[index];

    setCombinedId((prevCombinedId) => {
      let updatedCombinedId = [...prevCombinedId];

      if (checked) {
        // Append the value to the combinedId if it doesn't exist
        const isValueExists = updatedCombinedId.some(
          (param) => param.value === value
        );
        if (!isValueExists) {
          const paramToAdd = {
            name: `&${name}=[%${value}%]`,
            value,
          };
          updatedCombinedId.push(paramToAdd);
        }
      } else {
        // Remove the value from the combinedId
        updatedCombinedId = updatedCombinedId.filter(
          (param) => param.value !== value
        );
      }

      return updatedCombinedId;
    });
  };

  const formsubmission = (e) => {
    props.setClientLive(textareaValue);
    props.setClientType(textareaValue);
  };
  const handleClose = () => {
    props.setBuildUrl(false);
    setFinalId([
      {
        name: "https://drive.google.com?RID=[%RID%]",
        value: "RID",
      },
    ]);
    setMultiId([]);
  };
  useEffect(() => {
    setTextareaValue(combinedId.map((param) => param.name).join(""));
  }, [combinedId]);

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Build Your URL
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(formsubmission)}>
        <Modal.Body>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12} className="">
              <p
                style={{
                  marginLeft: "0px",
                }}
              >
                What's your survey's URL?
              </p>
              <input
                type="text"
                style={{
                  width: "100%",
                }}
                value={props.clientLIve}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={6} xs={12} className="col">
              <h6
                style={{
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                Variables
              </h6>
              <p
                style={{
                  margin: "10px 0px 20px 0px",
                }}
              >
                These are URL parameters for respondent or survey-level data
                that will be passed along when a respondent gets sent to your
                survey.
              </p>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="col"
              style={{
                marginTop: "40px",
              }}
            >
              <div className="variable_select">
                <select
                  className="variable_select__select"
                  onChange={handleMultiSelectChange}
                  value=""
                >
                  <option value="" disabled hidden>
                    Select Variable
                  </option>
                  {devicetypearray.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      disabled={disabledOptions.includes(option.value)}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="Quality_Col" lg={2} md={4} sm={4} xs={6}>
              <label className="formlabel">
                RID
                <span style={{ color: "red", fontSize: "14px" }}> *</span>
              </label>

              <div>
                <input
                  type="checkbox"
                  placeholder="RID"
                  checked={true}
                  className="box_width"
                />
                <input className="vtttinput" type="text" value="RID" />
              </div>
            </Col>

            {vttt.map((variableitem, index) => (
              <Col className="Quality_Col" lg={2} md={4} sm={4} xs={6}>
                <div key={index} className="">
                  <label className="formlabel" htmlFor={"variable1" + index}>
                    {variableitem.label}
                  </label>
                  <input
                    className=" box_width "
                    type="checkbox"
                    id={"variable1" + index}
                    name={variableitem.value}
                    onChange={(event) => {
                      handlesidChange(event, index);
                    }}
                  />
                  {combinedId.some(
                    (param) => param.value === variableitem.value
                  ) && (
                    <input
                      className="vtttinput"
                      type="text"
                      value={variableitem.name}
                      name={variableitem.value}
                      onChange={(event) => {
                        handleVttttInputChange(event, variableitem.value);
                      }}
                    />
                  )}
                </div>
              </Col>
            ))}

            {checkboxOptions.map((option) => (
              <Col className="Quality_Col" lg={2} md={4} sm={4} xs={6}>
                <div key={option.value}>
                  <label className="formlabel">{option.label}</label>
                  <input
                    type="checkbox"
                    className=""
                    value={option.value}
                    name={option.value}
                    onChange={handleCheckboxChange}
                    checked={variableType.some(
                      (variable) => variable.value === option.value
                    )}
                  />
                  {variableType.some(
                    (variable) => variable.value === option.value
                  ) && (
                    <input
                      className="vtttinput"
                      type="text"
                      value={option.name}
                      name={option.value}
                      onChange={(event) =>
                        handleInputChange(event, option.value)
                      }
                    />
                  )}
                </div>
              </Col>
            ))}
          </Row>

          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="col"
              style={{
                marginTop: "20px",
              }}
            >
              <label className="formlabel">final Test link</label>
              <textarea
                type="text"
                style={{ width: "100%" }}
                value={textareaValue}
                onChange={handleTextareaChange}
              />
            </Col>
          </Row>
        </Modal.Body>
        <button
          className="cbtn saveBtn "
          style={{
            marginBottom: "10px",
            marginRight: "15px",
          }}
          type="submit"
        >
          submit
        </button>
      </form>
    </Modal>
  );
};

export default Buildurl;
