import Supplyheader from "./Supplyheader";
import "./Supplypage.css";
import { useState, useEffect } from "react";
import Paginations from "../Paginations/Paginations";
import Table from "react-bootstrap/Table";
import pageIcon from "../../assets/BidsIcons/bids.svg";
import { MultiSelect } from "react-multi-select-component";
import { Col, Row } from "react-bootstrap";
import EditIcon from "../../assets/LoginIcons/edit.svg";
import { FiSettings, FiEye, FiUserPlus, FiFilter } from "react-icons/fi";
import AddManualSupplier from "./Popup/AddManualSupplier";
import ProjectDetails from "./Popup/Editsupplyquality";
import RequestStatus from "./Popup/RequestStatus";
import SupplierInfo from "./Popup/SupplierInfo";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
const Supply = () => {
  const axiosPrivate = useAxiosPrivate();
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);
  const [update, setUpdate] = useState(false);
  const [see, setSee] = useState(false);
  const [setting, setSetting] = useState(false);
  const [selecteduser, setSelectedUser] = useState([]);
  const [selecteduser1, setSelectedUser1] = useState([]);
  const [selecteduser2, setSelectedUser2] = useState([]);
  const [selecteduser3, setSelectedUser3] = useState([]);
  const [allsurveydata, setAllsurveydata] = useState([]);

  const pname = "Supply";
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesize, nextPage]);

  const getPageSize = (size) => {
    setPagesize(size);
  };

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };

  const statusOption = [
    {
      label: "Live",
      value: "Live",
    },
    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "Inquiry",
      value: "Inquiry",
    },
    {
      label: "paused",
      value: "Paused",
    },
  ];
  const statusOption1 = [
    {
      label: "Soni Singh",
      value: "Live",
    },
  ];
  const statusOption3 = [
    {
      label: "US Gen Pop 18+",
      value: "Live",
    },
  ];
  const statusOption2 = [
    {
      label: "SC188853US",
      value: "Live",
    },
  ];

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    get_AllSurey_api();
  }, []);

  const get_AllSurey_api = async () => {
    try {
      const response = await axiosPrivate.get(
        "survey/get-all-survey?page=1&size=50&sort=-_id"
      );

      setAllsurveydata(response.data.items);
    } catch (err) {
      console.error(err);
    }
  };

  // survey/get-all-survey?page=1&size=50&sort=-_id

  return (
    <>
      <RequestStatus show={setting} onHide={() => setSetting(false)} />
      <AddManualSupplier show={add} onHide={() => setAdd(false)} />
      <ProjectDetails show={update} onHide={() => setUpdate(false)} />
      <SupplierInfo show={see} onHide={() => setSee(false)} />
      <Supplyheader pname={pname} />
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachboxSupply">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" /> Survey
                Details
              </h3>
            </div>

            <FiFilter
              className="supplyIconColr"
              style={{
                float: "right",
                margin: "5px 0px",
                marginRight: "-5px",
                fontSize: "30px",
                padding: "2px 5px",
                color: "white",
                backgroundColor: "#1a1a1a",
                border: "1px solid black",
              }}
              onClick={() => setShow(!show)}
            />
          </div>

          <hr />
          {show ? (
            <Row
              className="supply_filterbox"
              style={{
                backgroundColor: "#f1efef",
                // margin: "15px",
                paddingBottom: "10px",
              }}
            >
              <Col lg={10} md={10} xs={11} className="supply_col">
                <Row>
                  <Col lg={3} md={3} xs={6} className="col ">
                    <label
                      for="status"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Req Status
                    </label>
                    <MultiSelect
                      className="multiselectoption"
                      style={{ width: "45px" }}
                      options={statusOption}
                      value={selecteduser}
                      onChange={(e) => {
                        setSelectedUser(e);
                      }}
                      labelledBy={"Req Status"}
                      placeholder={"Req Status"}
                      isCreatable={true}
                      disableSearch
                    />
                  </Col>
                  <Col lg={3} md={3} xs={6} className="col">
                    <label
                      for="status"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Req Owner
                    </label>
                    <MultiSelect
                      className="multiselectoption"
                      style={{ width: "35px" }}
                      options={statusOption1}
                      value={selecteduser1}
                      onChange={(e) => {
                        setSelectedUser1(e);
                      }}
                      labelledBy={"Req Owner"}
                      isCreatable={true}
                      disableSearch
                    />
                  </Col>
                  <Col lg={3} md={3} xs={6} className="col">
                    <label
                      for="status"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      SC#
                    </label>
                    <MultiSelect
                      className="multiselectoption"
                      style={{ width: "35px" }}
                      options={statusOption2}
                      value={selecteduser2}
                      onChange={(e) => {
                        setSelectedUser2(e);
                      }}
                      labelledBy={"SC#"}
                      isCreatable={true}
                      disableSearch
                    />
                  </Col>
                  <Col lg={3} md={3} xs={6} className="col">
                    <label
                      for="status"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Project Name
                    </label>
                    <MultiSelect
                      className="multiselectoption"
                      style={{ width: "35px" }}
                      options={statusOption3}
                      value={selecteduser3}
                      onChange={(e) => {
                        setSelectedUser3(e);
                      }}
                      labelledBy={"SC#"}
                      isCreatable={true}
                      disableSearch
                    />
                  </Col>
                </Row>
              </Col>

              <Col lg={2} md={2} xs={12} className="col">
                <button
                  class="cbtn saveBtn"
                  style={{
                    maxWidth: "100%",
                    marginTop: "20px",
                  }}
                >
                  Submit
                </button>
              </Col>
            </Row>
          ) : null}
          <div className="mainContent mainContent2">
            <div className="table_container ">
              <Table bordered={false} hover size="sm" className="customTable">
                <thead className="thead">
                  <tr>
                    <td>Req Owner</td>
                    <td>SC#</td>
                    <td>Project Name – LIN</td>
                    <td>Account Name</td>
                    <td>PM</td>
                    <td>Proj Status</td>
                    <td>Quota</td>
                    <td>Manual Supp</td>
                    <td>Date Opened</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {allsurveydata.map((e) => (
                    <>
                      <tr>
                        <td> Soni Singh</td>
                        <td>SC118853US</td>
                        <td>Project 3 - US Gen Pop 18+</td>
                        <td>Dynata</td>
                        <td>Parth</td>
                        <td>{e.survey_status.name}</td>
                        <td>314</td>
                        <td>-</td>
                        <td>11/20/22</td>
                        <td>
                          <FiUserPlus
                            className="SupIcon"
                            onClick={() => {
                              setAdd(true);
                            }}
                          />
                          <img
                            src={EditIcon}
                            alt="EditIcon"
                            srcset=""
                            className="SupIcon"
                            onClick={() => {
                              setUpdate(true);
                            }}
                          />
                          <FiEye
                            className="SupIcon"
                            onClick={() => {
                              setSee(true);
                            }}
                          />
                          <FiSettings
                            className="SupIcon"
                            onClick={() => {
                              setSetting(true);
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  ))}

                  {/* <tr>
                    <td> Soni Singh</td>
                    <td>SC118853US</td>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>Dynata</td>
                    <td>Parth</td>
                    <td>Live</td>
                    <td>314</td>

                    <td>-</td>
                    <td>11/20/22</td>
                    <td>
                      <FiUserPlus
                        className="SupIcon"
                        onClick={() => {
                          setAdd(true);
                        }}
                      />
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          setUpdate(true);
                        }}
                      />
                      <FiEye
                        className="SupIcon"
                        onClick={() => {
                          setSee(true);
                        }}
                      />
                      <FiSettings
                        className="SupIcon"
                        onClick={() => {
                          setSetting(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td> Soni Singh</td>
                    <td>SC118853US</td>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>Dynata</td>
                    <td>Parth</td>
                    <td>Live</td>
                    <td>314</td>

                    <td>-</td>
                    <td>11/20/22</td>
                    <td>
                      <FiUserPlus
                        className="SupIcon"
                        onClick={() => {
                          setAdd(true);
                        }}
                      />
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          setUpdate(true);
                        }}
                      />
                      <FiEye
                        className="SupIcon"
                        onClick={() => {
                          setSee(true);
                        }}
                      />
                      <FiSettings
                        className="SupIcon"
                        onClick={() => {
                          setSetting(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td> Soni Singh</td>
                    <td>SC118853US</td>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>Dynata</td>
                    <td>Parth</td>
                    <td>Live</td>
                    <td>314</td>

                    <td>-</td>
                    <td>11/20/22</td>
                    <td>
                      <FiUserPlus
                        className="SupIcon"
                        onClick={() => {
                          setAdd(true);
                        }}
                      />
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          setUpdate(true);
                        }}
                      />
                      <FiEye
                        className="SupIcon"
                        onClick={() => {
                          setSee(true);
                        }}
                      />
                      <FiSettings
                        className="SupIcon"
                        onClick={() => {
                          setSetting(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td> Soni Singh</td>
                    <td>SC118853US</td>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>Dynata</td>
                    <td>Parth</td>
                    <td>Live</td>
                    <td>314</td>

                    <td>-</td>
                    <td>11/20/22</td>
                    <td>
                      <FiUserPlus
                        className="SupIcon"
                        onClick={() => {
                          setAdd(true);
                        }}
                      />
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          setUpdate(true);
                        }}
                      />
                      <FiEye
                        className="SupIcon"
                        onClick={() => {
                          setSee(true);
                        }}
                      />
                      <FiSettings
                        className="SupIcon"
                        onClick={() => {
                          setSetting(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td> Soni Singh</td>
                    <td>SC118853US</td>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>Dynata</td>
                    <td>Parth</td>
                    <td>Live</td>
                    <td>314</td>

                    <td>-</td>
                    <td>11/20/22</td>
                    <td>
                      <FiUserPlus
                        className="SupIcon"
                        onClick={() => {
                          setAdd(true);
                        }}
                      />
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          setUpdate(true);
                        }}
                      />
                      <FiEye
                        className="SupIcon"
                        onClick={() => {
                          setSee(true);
                        }}
                      />
                      <FiSettings
                        className="SupIcon"
                        onClick={() => {
                          setSetting(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td> Soni Singh</td>
                    <td>SC118853US</td>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>Dynata</td>
                    <td>Parth</td>
                    <td>Live</td>
                    <td>314</td>

                    <td>04</td>
                    <td>11/20/22</td>
                    <td>
                      <FiUserPlus
                        className="SupIcon"
                        onClick={() => {
                          setAdd(true);
                        }}
                      />
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          setUpdate(true);
                        }}
                      />
                      <FiEye
                        className="SupIcon"
                        onClick={() => {
                          setSee(true);
                        }}
                      />
                      <FiSettings
                        className="SupIcon"
                        onClick={() => {
                          setSetting(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td> Soni Singh</td>
                    <td>SC118853US</td>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>Dynata</td>
                    <td>Parth</td>
                    <td>Live</td>
                    <td>314</td>

                    <td>04</td>
                    <td>11/20/22</td>
                    <td>
                      <FiUserPlus
                        className="SupIcon"
                        onClick={() => {
                          setAdd(true);
                        }}
                      />
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          setUpdate(true);
                        }}
                      />
                      <FiEye
                        className="SupIcon"
                        onClick={() => {
                          setSee(true);
                        }}
                      />
                      <FiSettings
                        className="SupIcon"
                        onClick={() => {
                          setSetting(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td> Soni Singh</td>
                    <td>SC118853US</td>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>Dynata</td>
                    <td>Parth</td>
                    <td>Live</td>
                    <td>314</td>

                    <td>04</td>
                    <td>11/20/22</td>
                    <td>
                      <FiUserPlus
                        className="SupIcon"
                        onClick={() => {
                          setAdd(true);
                        }}
                      />
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          setUpdate(true);
                        }}
                      />
                      <FiEye
                        className="SupIcon"
                        onClick={() => {
                          setSee(true);
                        }}
                      />
                      <FiSettings
                        className="SupIcon"
                        onClick={() => {
                          setSetting(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td> Soni Singh</td>
                    <td>SC118853US</td>
                    <td>Project 3 - US Gen Pop 18+</td>
                    <td>Dynata</td>
                    <td>Parth</td>
                    <td>Live</td>
                    <td>314</td>

                    <td>04</td>
                    <td>11/20/22</td>
                    <td>
                      <FiUserPlus
                        className="SupIcon"
                        onClick={() => {
                          setAdd(true);
                        }}
                      />
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        srcset=""
                        className="SupIcon"
                        onClick={() => {
                          setUpdate(true);
                        }}
                      />
                      <FiEye
                        className="SupIcon"
                        onClick={() => {
                          setSee(true);
                        }}
                      />
                      <FiSettings
                        className="SupIcon"
                        onClick={() => {
                          setSetting(true);
                        }}
                      />
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </div>

            <Paginations
              userlist={10}
              getPageSize={getPageSize}
              handlePageChnage={handlePageChnage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Supply;
