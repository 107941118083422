import Modal from "react-bootstrap/Modal";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

function SupplierLink(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "12px" }}
        >
          <i class="bi bi-person-plus"></i>
          Supplier Link - Project 3
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          margin: "0px 0px 0px 10px",
        }}
      >
        <Row style={{ paddingBottom: "50px" }}>
          <Col lg={2} md={7} sm={12} xs={12}>
            <label
              className="formlabelamit"
              style={{
                fontWeight: "bolder",
              }}
            >
              Fulcrum SH1
            </label>
          </Col>
          <Col lg={10} md={7} sm={12} xs={12}>
            <h6 className="quta_link_text">
              https://peanutlabs.com/return/res
            </h6>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
export default SupplierLink;
