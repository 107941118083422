import Header from "../../UI/Header/Header";
import "../Department/Update_department.css";
import { useNavigate } from "react-router";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import toast from "react-hot-toast";
import "./user.css";
import { useForm } from "react-hook-form";
import avtar1 from "../../../assets/userAvatar/avtar1.svg";
import avtar2 from "../../../assets/userAvatar/avtar2.svg";
import avtar3 from "../../../assets/userAvatar/avtar3.svg";
import userIco from "../../../assets/LoginIcons/users.svg";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Multiselect from "multiselect-react-dropdown";
const NewUser = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [department, setDepartment] = useState([]);
  const [payloadDept, setPayloadDept] = useState([]);
  const [role, setRole] = useState([]);
  const [showDeptState, setDepartState] = useState(true);
  const axiosPrivate = useAxiosPrivate();
  const [showPlaceHolder, SetShowPlaceHolder] = useState(true);

  useEffect(() => {
    setValue(
      "payloadDep",
      payloadDept.map((deptm) => deptm.name)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payloadDept]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDepartmentOptionRemove = async (department_id) => {
    setRole([]);
    department_id.map(async (item) => {
      try {
        const response = await axiosPrivate.get(
          `role/department-roles/?dept_id=${item}`,
          {}
        );

        response.data.all_roles.map((ite) => {
          setRole((val) => [...val, { id: ite.id, name: ite.name }]);
        });
      } catch (err) {}
    });
  };
  const handleDepartmentOption = async (department_id) => {
    SetShowPlaceHolder(false);
    department_id.map(async (item) => {
      try {
        const response = await axiosPrivate.get(
          `role/department-roles/?dept_id=${item}`,
          {}
        );

        response.data.all_roles.map((ite) => {
          // eslint-disable-line
          setRole((val) => [...val, { id: ite.id, name: ite.name }]);
        });
      } catch (err) {}
    });
  };

  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get("department/all-departments", {});
      setDepartment(response.data.all_departments);
    } catch (err) {}
  };

  const handleSaveButton = async (e) => {
    handleDepartmentOption(e.department);
    setIsSubmitting(true);
    try {
      await axiosPrivate
        .post(
          "user/create",
          JSON.stringify({
            first_name: e.firstName,
            middle_name: e.middleName || null,
            last_name: e.lastName,
            email: e.email,
            initial: e.initial,
            role_id: e.Role,
            department: payloadDept,
            contact_number: e.contactNumber,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysuccess("Check your mail for further verification");
            navigate("/users");
          }
        });
    } catch (err) {
      if (err.response.status === 409) {
        notifyerror("Email already exists");
      } else if (err.response.status === 422) {
        notifyerror("Select the department and role");
      }
    }
    setIsSubmitting(false);
  };

  const name = register("payloadDep", {
    required: "This field is Required",
  });

  const notifyerror = (e) => toast.error(e);
  const notifysuccess = (e) => toast.success(e);
  return (
    <>
      {/* <Header></Header> */}
      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(handleSaveButton)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img src={userIco} className="headingIcons" alt="" /> Add New
                  User
                </h3>
              </div>
              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={() => {
                    navigate("/users");
                  }}
                >
                  {" "}
                  Close
                </button>

                <button
                  disabled={isSubmitting}
                  className="cbtn saveBtn"
                  type="submit"
                >
                  {isSubmitting && (
                    <Spinner
                      style={{ color: "white" }}
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp;&nbsp; Save
                </button>
              </div>
            </div>
            <hr />
            <div className="mainContent" style={{ minHeight: "480px" }}>
              <p className="small note">* denotes required fields</p>

              <Row>
                <div className="UserleftPane">
                  <img src={avtar1} alt="Option 1" height={140} />
                  <img src={avtar2} alt="Option 2" height={140} />
                  <img src={avtar3} alt="Option 3" height={140} />
                </div>
                <div className="UserRightPane">
                  <Row>
                    <Col lg={4} md={6} xs={12} className="col">
                      <label className="formlabel">First Name*</label>
                      <input
                        type="text"
                        placeholder="First Name"
                        className="formtext"
                        {...register("firstName", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                      />
                      {errors.firstName && (
                        <small className="errmsg">
                          {errors.firstName.message}
                        </small>
                      )}
                    </Col>

                    <Col lg={4} md={6} xs={12} className="col">
                      <label className="formlabel">Middle Name</label>
                      <input
                        type="text"
                        placeholder="Middle Name"
                        className="formtext"
                        {...register("middleName", {
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                      />
                      {errors.middleName && (
                        <small className="errmsg">
                          {errors.middleName.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={4} md={6} xs={12} className="col">
                      <label className="formlabel">Last Name*</label>
                      <input
                        type="text"
                        placeholder="Last  Name"
                        className="formtext"
                        {...register("lastName", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                      />
                      {errors.lastName && (
                        <small className="errmsg">
                          {errors.lastName.message}
                        </small>
                      )}
                    </Col>
                    {/* </Row> */}

                    {/* <Row> */}
                    <Col lg={4} md={6} xs={12} className="col">
                      <label className="formlabel">Initial*</label>
                      <input
                        type="text"
                        placeholder="Initial"
                        className="formtext"
                        {...register("initial", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                      />
                      {errors.initial && (
                        <small className="errmsg">
                          {errors.initial.message}
                        </small>
                      )}
                    </Col>

                    <Col lg={4} md={6} xs={12} className="col">
                      <label className="formlabel">Department*</label>
                      <Multiselect
                        className="userDropdown updateUser"
                        {...name}
                        displayValue="name"
                        onKeyPressFn={function noRefCheck() {}}
                        onRemove={function noRefCheck(e) {
                          if (e.length === 0) {
                            setRole([]);
                            SetShowPlaceHolder(true);
                            setDepartState(true);
                          } else {
                            setDepartState(false);
                          }

                          handleDepartmentOptionRemove(
                            e.map((item) => item._id)
                          );
                          setPayloadDept([]);
                          e.map((it) => {
                            setPayloadDept((val) => [
                              ...val,
                              { id: it._id, name: it.name },
                            ]);
                          });
                        }}
                        onSearch={function noRefCheck() {
                          SetShowPlaceHolder(false);
                        }}
                        onSelect={function noRefCheck(e) {
                          setDepartState(false);
                          setPayloadDept([]);
                          e.map((it) => {
                            setPayloadDept((val) => [
                              ...val,
                              { id: it._id, name: it.name },
                            ]);
                          });
                          setRole([]);
                          handleDepartmentOption(e.map((item) => item._id));
                        }}
                        options={department}
                        placeholder={
                          showPlaceHolder === true
                            ? "Select Option(s)"
                            : payloadDept.map((item) => item.name)
                        }
                        showCheckbox
                      />
                      {showDeptState === false ? (
                        <></>
                      ) : (
                        errors.payloadDep && (
                          <small className="errmsg">
                            {errors.payloadDep.message}
                          </small>
                        )
                      )}
                    </Col>
                    <Col lg={4} md={6} xs={12} className="col">
                      <label className="formlabel">Role*</label>
                      <select
                        className="formtext"
                        {...register("Role", {
                          required: "This field is Required",
                        })}
                      >
                        <option value="">Select Option</option>
                        {role.map((e, index) => (
                          <option value={e.id}>{e.name}</option>
                        ))}
                      </select>
                      {errors.Role && (
                        <small className="errmsg">{errors.Role.message}</small>
                      )}
                    </Col>
                    <Col lg={4} md={6} xs={12} className="col">
                      <label className="formlabel">Email Address*</label>
                      <input
                        placeholder="Your Email"
                        className="formtext"
                        {...register("email", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line
                            message: "Enter Valid Email Id",
                          },
                        })}
                      />
                      {errors.email && (
                        <small className="errmsg">{errors.email.message}</small>
                      )}
                    </Col>

                    <Col lg={4} md={6} xs={12} className="col">
                      <label className="formlabel">Contact Number *</label>
                      <input
                        type="text"
                        placeholder="Contact Number"
                        className="formtext"
                        {...register("contactNumber", {
                          required: "This field is Required",
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only numbers are allowed",
                          },
                        })}
                      />
                      {errors.contactNumber && (
                        <small className="errmsg">
                          {errors.contactNumber.message}
                        </small>
                      )}
                    </Col>
                  </Row>
                </div>
              </Row>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default NewUser;
