import "./Filter.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MultiSelect } from "react-multi-select-component";
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
const Filter = () => {
  const [selected, setSelected] = useState([]);
  const [selecteduser, setSelectedUser] = useState([]);
  const [clientListStorage, setClientListStorage] = useState([]);
  const [projectStatusStorage, setProjetStatusStorage] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [apiFilterData, setApiFilterData] = useState([]);

  useEffect(() => {
    get_ClintList_api();
    get_ProjectStatus_api();
    get_Client_filter_api();
  }, []);

  const [container, setContainer] = useState({
    filter_b_C: "show",
    filter_b_I: "up",
    clientFilter_C: "show",
    clientFilter_I: "up",
  });

  const callContainer = (e) => {
    if (e === "Filter By Search") {
      setContainer((event) => ({
        ...event,
        filter_b_C: "hide",
        filter_b_I: "down",
      }));
    }
    if (e === "Client Filter") {
      setContainer((event) => ({
        ...event,
        clientFilter_C: "hide",
        clientFilter_I: "down",
      }));
    }
  };

  const handleShowSection = (id) => {
    var element = document.getElementById("FilterfirstSect" + id);
    let iconShow = document.getElementById("FiltericonSect" + id);
    if (element.matches(".open")) {
      element.classList.remove("open");
      iconShow.classList.remove("open");
    } else {
      element.classList.add("open");
      iconShow.classList.add("open");
    }
  };

  const handleParents = (id) => {
    var element = document.getElementById("filterParentContant" + id);
    let iconShow = document.getElementById("filterparenrt" + id);
    if (element.matches(".open")) {
      element.classList.remove("open");
      iconShow.classList.remove("open");
    } else {
      element.classList.add("open");
      iconShow.classList.add("open");
    }
  };

  const handleChild = (id) => {
    var element = document.getElementById("childContant" + id);
    let iconShow = document.getElementById("childIcon" + id);
    if (element.matches(".open")) {
      element.classList.remove("open");
      iconShow.classList.remove("open");
    } else {
      element.classList.add("open");
      iconShow.classList.add("open");
    }
  };

  const get_ClintList_api = async () => {
    try {
      const response = await axiosPrivate.get("client/client-list");
      response.data.all_client.map((e) => {
        setClientListStorage((val) => [
          ...val,
          { label: e.name, value: e._id },
        ]);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const get_ProjectStatus_api = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get-project-status");
      response.data.all_status.map((e) => {
        setProjetStatusStorage((val) => [
          ...val,
          { label: e.name, value: e.id },
        ]);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const get_Client_filter_api = async () => {
    try {
      const response = await axiosPrivate.get(
        "/client/filter-client?query_filter=None"
      );
      setApiFilterData(response.data.project);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="fbs">Filter by Status</div>
      <div className="csmulti">
        <div style={{ position: "relative" }}>
          <MultiSelect
            className="multiselectoption "
            style={{ width: "29px" }}
            options={projectStatusStorage}
            value={selecteduser}
            onChange={(e) => {
              setSelectedUser(e);
            }}
            labelledBy="Raj........"
            isCreatable={true}
          />
        </div>

        <div className="gap"></div>
        <div className="fbs">Filter by user</div>
        <div style={{ position: "relative" }} className="">
          <MultiSelect
            className="multiselectoption "
            style={{ width: "29px" }}
            options={clientListStorage}
            value={selected}
            onChange={(e) => {
              setSelected(e);
            }}
            labelledBy="uuuuuuuuuuuu"
            isCreatable={true}
          />
        </div>
        <div className="fbs clf" style={{ position: "relative" }}>
          <p
            className=""
            style={{
              width: "50%",
              color: "white",
              margin: "0px",
              display: "inline-block",
              height: "0px",
            }}
          >
            Client Filter
          </p>

          <div className="">
            {container.clientFilter_I === "up" ? (
              <>
                <KeyboardArrowUpIcon
                  style={{
                    position: "absolute",
                    left: "auto",
                    right: "10",
                    top: "5",
                    color: "white",
                  }}
                  onClick={() => {
                    callContainer("Client Filter");
                  }}
                />
              </>
            ) : (
              <>
                <KeyboardArrowDownIcon
                  style={{
                    position: "absolute",
                    left: "auto",
                    right: "10",
                    top: "5",
                    color: "white",
                  }}
                  onClick={() => {
                    setContainer((event) => ({
                      ...event,
                      clientFilter_C: "show",
                      clientFilter_I: "up",
                    }));
                  }}
                />
              </>
            )}
          </div>
        </div>
        <>
          <div className="optioncontainer">
            <input
              type="search"
              style={{
                width: "100%",
              }}
            />
            {apiFilterData.map((e, index) => (
              <>
                <div
                  className="filterHeading"
                  id={"FiltericonSect" + index}
                  style={{ position: "relative" }}
                >
                  <p className="FilterPtag">{e.name}</p>
                  <KeyboardArrowUpIcon
                    className="azurUpIcon"
                    onClick={() => {
                      handleShowSection(index);
                    }}
                    style={{
                      position: "absolute",
                      left: "auto",
                      right: "5",
                      top: "0",
                      color: "black",
                    }}
                  />
                  <KeyboardArrowDownIcon
                    className="azurDownIcon"
                    style={{
                      position: "absolute",
                      left: "auto",
                      right: "5",
                      top: "0",
                      color: "black",
                    }}
                    onClick={() => {
                      handleShowSection(index);
                    }}
                  />
                </div>
                <>
                  <div className="Dynatasc" id={"FilterfirstSect" + index}>
                    {e.project.map((prop) => (
                      <>
                        <div className="sc10" style={{ position: "relative" }}>
                          <div className="linegreen">.</div>
                          <div
                            className="SC106647"
                            id={"filterparenrt" + index}
                          >
                            {prop.project_name}
                            <KeyboardArrowUpIcon
                              className="scUpIcon"
                              onClick={() => {
                                handleParents(index);
                              }}
                              style={{
                                fontSize: "120%",
                                position: "absolute",
                                left: "auto",
                                right: "5",
                                top: "0",
                                color: "black",
                              }}
                            />
                            <KeyboardArrowDownIcon
                              className="scDownIcon"
                              onClick={() => {
                                handleParents(index);
                              }}
                              style={{
                                position: "absolute",
                                fontSize: "120%",
                                left: "auto",
                                right: "5",
                                top: "0",
                                color: "black",
                              }}
                            />
                          </div>
                        </div>
                        <>
                          <div
                            id={"filterParentContant" + index}
                            className="parentsContant"
                          >
                            {prop.surveychildren.map((event) => (
                              <>
                                <div
                                  className="sc101"
                                  style={{ position: "relative" }}
                                  id={"childIcon" + prop.id}
                                >
                                  <FiberManualRecordIcon
                                    style={{
                                      color: "#67c52f",
                                      height: "10px",
                                      marginTop: "5px",
                                      marginLeft: "-7px",
                                    }}
                                  />
                                  <span className="usgen">
                                    {event.project_id}
                                    <KeyboardArrowUpIcon
                                      className="childUp"
                                      onClick={() => {
                                        handleChild(prop.id);
                                      }}
                                      style={{
                                        fontSize: "120%",
                                        position: "absolute",
                                        left: "auto",
                                        right: "5",
                                        top: "0",
                                        color: "black",
                                      }}
                                    />
                                  </span>
                                </div>
                              </>
                            ))}
                          </div>
                        </>
                      </>
                    ))}
                  </div>
                </>
              </>
            ))}
          </div>
        </>
      </div>
    </>
  );
};
export default Filter;
