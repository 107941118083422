import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./StyleDatatable.css";
import Header from "../../UI/Header/Header";
import addIcon from "../../../assets/LoginIcons/add.svg";
import { useNavigate, useLocation } from "react-router";
import userIco from "../../../assets/LoginIcons/users.svg";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import EditIcon from "../../../assets/LoginIcons/edit.svg";
import DeleteIcon from "../../../assets/LoginIcons/delete.svg";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SpinnerLoader from "../../UI/Spinner";
import InsufficientPermisiion from "../User/InsufficiantPermission";
import useAuth from "../../../hooks/useAuth";

const RolesListing = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterUser, setFilterUser] = useState(null);
  const [filter_data, setFilter_data] = useState([]);
  const [referesh] = useState(0);
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const [pending, setPending] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const [permission, setPermission] = useState("No Data Found");
  const [dataLoader, setDataLoader] = useState(true);

  // ..........................permisssion.........
  const { auth } = useAuth();
  const superUser = auth.superuser;
  const all_permission = auth.permission;

  // ..........................permisssion.........

  // const notifySuccess = (msg) => {
  //   toast.success((t) => (
  //     <span style={{ color: "white" }}>
  //       {msg}
  //       <button className="closeToaster" onClick={() => toast.dismiss(t.id)}>
  //         x
  //       </button>
  //     </span>
  //   ));
  // };

  useEffect(() => {
    let role = localStorage.getItem("addrole");
    let updaterole = localStorage.getItem("updaterole");
    if (role === "1") {
      toast.success("Role added successfully");
    }
    if (updaterole === "1") {
      toast.success("Role updated successfully");
    }
    localStorage.setItem("updaterole", 0);
    localStorage.setItem("addrole", 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRoleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referesh]);

  const getRoleList = async () => {
    setPending(true);
    setDataLoader(true);
    let isMounted = true;
    try {
      const response = await axiosPrivate.get("/role/all-roles", {});
      isMounted && setFilter_data(response.data.all_roles);
    } catch (err) {
      if (
        err.response.data.return_status.message[0] == "Insufficient permissions"
      ) {
        setPermission("Insufficient permissions");
      }
      // navigate("/login", { state: { from: location }, replace: true });
    }
    setPending(false);
    setDataLoader(false);
    return () => {
      isMounted = false;
    };
  };

  const handleDelete = async (id) => {
    const payload = {
      role_id: id,
    };
    try {
      await axiosPrivate.delete(
        "/role/delete",
        { data: payload },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
    } catch (err) {}
    const del = filter_data.filter((User) => id !== User._id);
    setFilter_data(del);
    toast.success("Role deleted successfully");
  };
  const notifyerror = (e) => toast.error(`${e} error occur`);
  const handle_status = async (id, status, e) => {
    try {
      await axiosPrivate
        .patch(
          "/role/active-inactive",
          JSON.stringify({
            role_id: id.toString(),
            new_status: e,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          if (e.data.new_status === 1) {
            toast.success("Role is active");
          } else {
            toast.success("Role is inactive");
          }
        });
    } catch (err) {
      toast.error(err.response.statusText);
    }
  };

  const columns = [
    {
      id: "sno",
      name: "Sno.",
      selector: (row, i) => currentRowsPerPage * (currentPage - 1) + (i + 1),
      width: "100px",
      grow: 0,
    },
    {
      id: "Firstname",
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      id: "dept",
      name: "Department",
      selector: (row) => row.department,
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <>
          {superUser === true ? (
            <>
              {" "}
              <BootstrapSwitchButton
                className="switch btn on btn-outline-success btn-xs"
                checked={row.status === 1 ? true : false}
                onChange={(e) => {
                  handle_status(row._id, row.status, e);
                }}
                size="xs"
                width={80}
                onlabel="Active"
                offlabel="Inactive"
                onstyle="outline-success"
                offstyle="outline-danger"
              />
            </>
          ) : (
            <>
              {all_permission.includes("user.role_change_status") ? (
                <>
                  {" "}
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    checked={row.status === 1 ? true : false}
                    onChange={(e) => {
                      handle_status(row._id, row.status, e);
                    }}
                    size="xs"
                    width={80}
                    onlabel="Active"
                    offlabel="Inactive"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>

        // <BootstrapSwitchButton
        //   className="switch btn on btn-outline-success btn-xs"
        //   checked={row.status === 1 ? true : false}
        //   onChange={(e) => {
        //     handle_status(row._id, row.status, e);
        //   }}
        //   size="xs"
        //   width={80}
        //   onlabel="Active"
        //   offlabel="Inactive"
        //   onstyle="outline-success"
        //   offstyle="outline-danger"
        // />
      ),

      width: "120px",
    },
    {
      name: "Action",
      selector: (row) => (
        <td>
          {superUser === true ? (
            <>
              <span
                className="actionButton"
                onClick={() => {
                  navigate(`/update-role/${row._id}`);
                }}
              >
                <img src={EditIcon} alt="" />
              </span>
            </>
          ) : (
            <>
              {all_permission.includes("user.role_update") ? (
                <>
                  {" "}
                  <span
                    className="actionButton"
                    onClick={() => {
                      navigate(`/update-role/${row._id}`);
                    }}
                  >
                    <img src={EditIcon} alt="" />
                  </span>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {superUser === true ? (
            <>
              <span className="actionButton">
                <img
                  src={DeleteIcon}
                  alt=""
                  onClick={() => {
                    confirmAlert({
                      message: "Do you want to delete " + row.name,
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => handleDelete(row._id),
                        },
                        {
                          label: "No",
                        },
                      ],
                    });
                  }}
                />
              </span>
            </>
          ) : (
            <>
              {all_permission.includes("user.role_delete") ? (
                <>
                  {" "}
                  <span className="actionButton">
                    <img
                      src={DeleteIcon}
                      alt=""
                      onClick={() => {
                        confirmAlert({
                          message: "Do you want to delete " + row.name,
                          buttons: [
                            {
                              label: "Yes",
                              onClick: () => handleDelete(row._id),
                            },
                            {
                              label: "No",
                            },
                          ],
                        });
                      }}
                    />
                  </span>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </td>
      ),
      width: "110px",
    },
  ];

  const showSearch = (searchText) => {
    const copyOfUsers = [...filter_data];
    setSearch(searchText);
    const result = copyOfUsers.filter((points) => {
      return (
        points.name
          .toString()
          .toLowerCase()
          .match(searchText.toString().toLowerCase()) ||
        points.department
          .toString()
          .toLowerCase()
          .match(searchText.toString().toLowerCase())
      );
    });
    setFilterUser(result);
  };

  return (
    <>
      {dataLoader === true ? (
        <>
          <SpinnerLoader />
        </>
      ) : (
        <>
          {" "}
          {permission === "Insufficient permissions" ? (
            <>
              <InsufficientPermisiion />
            </>
          ) : (
            <>
              {" "}
              <div className="top_container">
                <div className="main_container ">
                  <div className="user_serachbox">
                    <div style={{ float: "left" }}>
                      <h3 className="tableHeading">
                        <img
                          src={userIco}
                          className="headingIcons"
                          alt="Roles"
                        />{" "}
                        Roles
                      </h3>
                    </div>
                    <input
                      type="search"
                      className="tableSearch"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => showSearch(e.target.value)}
                    />

                    {superUser === true ? (
                      <>
                        <button
                          className="cbtn addBtn"
                          onClick={() => {
                            navigate("/new-role");
                          }}
                        >
                          <img
                            src={addIcon}
                            style={{ marginRight: "8px" }}
                            alt="Add"
                          />{" "}
                          &nbsp;New Role
                        </button>
                      </>
                    ) : (
                      <>
                        {all_permission.includes("user.role_create") ? (
                          <>
                            <button
                              className="cbtn addBtn"
                              onClick={() => {
                                navigate("/new-role");
                              }}
                            >
                              <img
                                src={addIcon}
                                style={{ marginRight: "8px" }}
                                alt="Add"
                              />{" "}
                              &nbsp;New Role
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                  <hr />

                  <div className="mainContent dTable">
                    <DataTable
                      columns={columns}
                      data={filterUser || filter_data}
                      pagination
                      fixedHeader
                      onChangePage={(page, totalRows) => {
                        setCurrentPage(page);
                      }}
                      onChangeRowsPerPage={(
                        currentRowsPerPage,
                        currentPage
                      ) => {
                        setCurrentRowsPerPage(currentRowsPerPage);
                      }}
                      highlightOnHover
                      defaultSortFieldId="Firstname"
                      defaultSortAsc={true}
                      progressPending={pending}
                      progressComponent={<SpinnerLoader />}
                      noDataComponent="No Data Found"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RolesListing;
