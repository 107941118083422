import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const ProjectType = (props) => {
  const [status, setStatus] = useState();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    //const controller = new AbortController();
    const getData = async () => {
      try {
        const response = await axiosPrivate.get("lookup/get-project-type", {});

        let data = response.data.all_status;
        isMounted && setStatus(data);
      } catch (err) {
        console.error(err);
      }
    };
    getData();
    return () => {
      isMounted = false;
      //controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {status?.length ? (
        <> 
          {status.map((status, i) => {
            if (status?.id === props.selectedVal) {
                return <option value={status?.id}  key={i} selected> {status?.name}</option>
              }else{
                return  <option value={status?.id} key={i}> {status?.name} </option>
              }
          })}          
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ProjectType;
