import "./Supplyheader.css";
import { useNavigate } from "react-router";

const Supplyheader = (props) => {
  const navigate = useNavigate();
  const cptype = props.pname;

  return (
    <>
      <div className="Supply_to_account">
        <span
          className={cptype === "SupplyReqDetails" ? "active" : ""}
          onClick={() => {
            navigate("/supply-req-detail");
          }}
        >
          Supply Req Details
        </span>

        <span
          className={cptype === "Supply" ? "active" : ""}
          onClick={() => {
            navigate("/supply");
          }}
        >
          Survey Details
        </span>
        <span
          className={cptype === "SupplierDetails" ? "active" : ""}
          onClick={() => {
            navigate("/supplier-details");
          }}
        >
          Supplier Details
        </span>
      </div>
    </>
  );
};

export default Supplyheader;
