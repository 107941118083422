import Header from "../../UI/Header/Header";
import { useNavigate, useParams } from "react-router";
import userIco from "../../../assets/BidsIcons/client.svg";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./BidsForm.css";
import BidsSubHeader from "../../BidsModule/BidsSubHeader/BidsSubHeader";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import toast from "react-hot-toast";
import Bidstopheader from "./Bidstopheader";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Bidstopheaderselect from "../BidsTable/BidsTopHeaderSelect";
const AddClientContact = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  var localData = localStorage.getItem("clientname");
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSaveButton = async (e) => {
    setIsSubmitting(true);
    try {
      await axiosPrivate
        .post(
          "/client/add-client-contact",
          JSON.stringify({
            client_id: id,
            name: e.Name,
            email: e.Email,
            title: e.Title,
            phone: e.Phone,
            status: 1,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          if (e.statusText === "OK") {
            clientadded();
            navigate(-1);
          } else {
          }
        });
    } catch (err) {
      if (
        err.response.data.return_status.message[0] ==
        "Client contact Email already exist."
      ) {
        notifyerror1("Client contact email already exists");
      } else {
        notifyerror(err.response.statusText);
      }
    }

    setIsSubmitting(false);
  };

  const notifyerror1 = (e) => toast.error(`${e}`);
  const notifyerror = (e) => toast.error(`${e} Error Occure`);
  const clientadded = () => toast.success("Client contact added successfully");

  return (
    <>
      {/* <Header /> */}

      <BidsSubHeader pname="clientlist" />
      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(handleSaveButton)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img src={userIco} className="headingIcons" alt="" />{" "}
                  {localData}
                </h3>
              </div>
              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  {" "}
                  Close{" "}
                </button>

                <button className="cbtn saveBtn">
                  {" "}
                  {isSubmitting && (
                    <Spinner
                      as="span"
                      style={{ color: "white" }}
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp;Save
                </button>
              </div>
            </div>
            <hr className="showingHeader" />

            {/* <Bidstopheader tid="3" clientid={id} /> */}
            <Bidstopheader clientid={id} tid="3" className="showingHeader" />
            <Bidstopheaderselect clientid={id} namevalue={"client-contact"} />
            <div className="mainContent">
              <Row>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Name*</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className="formtext"
                    {...register("Name", {
                      required: "This field is Required",
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ " "]*$/,
                        message: "Only letters are allowed",
                      },
                    })}
                  />
                  {errors.Name && (
                    <small className="errmsg">{errors.Name.message}</small>
                  )}
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Email*</label>
                  <input
                    type="text"
                    placeholder="Enter Email"
                    className="formtext"
                    {...register("Email", {
                      required: "This field is Required",
                      pattern: {
                        value:
                          /^(([^<>()[\],;:\s@\"]+([^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line
                        message: "Enter Valid Email Id",
                      },
                    })}
                  />
                  {errors.Email && (
                    <small className="errmsg">{errors.Email.message}</small>
                  )}
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Title</label>
                  <input
                    type="text"
                    placeholder="Enter Title"
                    className="formtext"
                    {...register("Title", {
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/, // eslint-disable-line
                        message: "Only letters are allowed",
                      },
                    })}
                  />
                  {errors.Title && (
                    <small className="errmsg">{errors.Title.message}</small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Phone</label>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    className="formtext"
                    {...register("Phone", {
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Only numbers are allowed",
                      },
                    })}
                  />
                  {errors.Phone && (
                    <small className="errmsg">{errors.Phone.message}</small>
                  )}
                </Col>
              </Row>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddClientContact;
