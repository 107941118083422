import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import "../PopUpPage/ErrorCreated.css";
const SurveyLogs = (props) => {
  return (
    <>
      {" "}
      <Modal
        className="popupmodehere"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "16px", paddingLeft: "10px" }}
          >
            &nbsp;Survey Logs: 23020321
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr>
                <th style={{ width: "200px", fontSize: "12px" }}>Date</th>
                <th style={{ fontSize: "12px" }}>Updated By</th>
                <th style={{ width: "400px", fontSize: "12px" }}>Details</th>
                <th style={{ fontSize: "12px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "200px", fontSize: "12px" }}>
                  09 Feb 2023 00:58:53
                </td>
                <td style={{ fontSize: "12px" }}>oss:</td>
                <td style={{ width: "400px", fontSize: "12px" }}>
                  PUS,WPS,PRA,THR,FCA,SAP,RFA,DSQ,PSA,FPR,
                  FTB,FRD,FGM,FUP,FUR,URA,GRS
                </td>
                <td style={{ fontSize: "12px" }}>Tracking</td>
              </tr>
              <tr>
                <td style={{ width: "200px", fontSize: "12px" }}></td>
                <td style={{ fontSize: "12px" }}>lastupdatedate::</td>
                <td style={{ width: "400px", fontSize: "12px" }}>
                  2023-02-09 00:58:52
                </td>
                <td style={{ fontSize: "12px" }}></td>
              </tr>
              <tr>
                <td style={{ width: "200px", fontSize: "12px" }}></td>
                <td style={{ fontSize: "12px" }}>status:</td>
                <td style={{ width: "400px", fontSize: "12px" }}>Test Mode</td>
                <td style={{ fontSize: "12px" }}></td>
              </tr>
              <tr>
                <td style={{ width: "200px", fontSize: "12px" }}></td>
                <td style={{ fontSize: "12px" }}>OldStatus:</td>
                <td style={{ width: "400px", fontSize: "12px" }}>Test Mode</td>
                <td style={{ fontSize: "12px" }}></td>
              </tr>
              <tr>
                <td style={{ width: "200px", fontSize: "12px" }}></td>
                <td style={{ fontSize: "12px" }}>health</td>
                <td style={{ width: "400px", fontSize: "12px" }}>
                  ST:0|COM:0|TRM:0|QF:0|RCS:0|CON:0.00|IR:0.00
                </td>
                <td style={{ fontSize: "12px" }}></td>
              </tr>
              <tr>
                <td style={{ width: "200px", fontSize: "12px" }}></td>
                <td style={{ fontSize: "12px" }}>Additional Fee</td>
                <td style={{ width: "400px", fontSize: "12px" }}>0</td>
                <td style={{ fontSize: "12px" }}></td>
              </tr>
              <tr>
                <td style={{ width: "200px", fontSize: "12px" }}></td>
                <td style={{ fontSize: "12px" }}>remark</td>
                <td style={{ width: "400px", fontSize: "12px" }}>
                  setup in fed/API
                </td>
                <td style={{ fontSize: "12px" }}></td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SurveyLogs;
