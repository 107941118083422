import axios from "axios";
import { BASE_URL } from "../api/axios";
import useAuth from "./useAuth";
import { useNavigate } from "react-router";

const useRefreshToken = () => {
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const token = "Bearer " + localStorage.getItem("token");

  const axiosRefresh = axios.create({
    baseURL: BASE_URL,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      "access-token": token,
    },
    withCredentials: true,
  });

  const refresh = async () => {
    try {
      const response = await axiosRefresh.post("auth/refresh", {});

      setAuth((prev) => {
        return {
          ...prev,
          accessToken: response.data.access_token,
          permission: response.data.permissions.perm_list,
          superuser: response.data.permissions.is_superuser,
        };
      });

      return response.data.access_token;
    } catch (error) {
      navigate("/login");
    }
  };
  return refresh;
};
export default useRefreshToken;
