import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth = (allowedRoles) => {
  const { auth } = useAuth();

  if (auth?.permission) {
    var data = auth?.permission.find((role) =>
      allowedRoles.allowedRoles?.includes(role)
    );
  } else {
    <Navigate to="/login" replace />;
  }

  return auth.accessToken ? (
    auth.superuser === true ? (
      <Outlet />
    ) : data !== undefined ? (
      <Outlet />
    ) : (
      <Navigate to="/unauthorized" replace />
    )
  ) : (
    <Navigate to="/login" replace />
  );
};

export default RequireAuth;
