import { useState } from "react";
import Invoiceids from "./popup/Invoiceids";
import InvoicBillingattach from "./popup/InvoiceBillingattach";
import Invoicesurveylogs from "./popup/InvoiceSurveylogs";
import { BiUpload, BiPurchaseTag } from "react-icons/bi";
import { Col, Row } from "react-bootstrap";
import { CiCircleMinus } from "react-icons/ci";
import { GrAddCircle } from "react-icons/gr";
import { useForm } from "react-hook-form";
import Table from "react-bootstrap/Table";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "bootstrap/dist/css/bootstrap.css";
import "./Invoice.css";
import { MultiSelect } from "react-multi-select-component";
import EditIcon from "../../../assets/LoginIcons/edit.svg";
import Editpop from "./popup/Editpop";
import { Tooltip } from "@mui/material";

import { Button } from "@mui/material";

const Invoicing = () => {
  const [invoiceidsshow, setInvoiceidsShow] = useState(false);
  const [invoicelogs, setInvoicelogs] = useState(false);
  const [invoiceuploadids, setInvoiceuploadids] = useState(false);
  const [invoiceedit, setInvoiceEdit] = useState(false);
  const [selected, setSelected] = useState([]);
  const [boxId, setBoxId] = useState();
  // const [cpi, setCpi] = useState([]);
  // const [clcount, setClCount] = useState([]);
  // const [remarks, setRemarks] = useState([]);
  const [activeFormId, setActiveFormId] = useState(null);
  const [invoiceclac, setInvoiceCalc] = useState([
    {
      Respondent_count: 40,
      Appr_Reject: 42,
      CPI: `$ ${1.5}`,
      Revenue: `$ ${63}`,
      Remarks: "",
    },
    {
      Respondent_count: 26,
      Appr_Reject: 22,
      CPI: `$ ${2.0}`,
      Revenue: `$ ${44}`,
      Remarks: "",
    },
    {
      Respondent_count: 20,
      Appr_Reject: 30,
      CPI: `$ ${1.55}`,
      Revenue: `$ ${44.2}`,
      Remarks: "",
    },
  ]);
  const [totalinvoicecalc, setTotalINvoicecalc] = useState([
    {
      Respondent_count: 86,
      Appr_Reject: 0,
      CPI: "-",
      Revenue: `$ ${132}`,
      Remarks: "",
    },
  ]);

  const [boxStatus, setBoxStatus] = useState([
    {
      id: 1,
      status: false,
    },
    {
      id: 2,
      status: false,
    },
    {
      id: 3,
      status: false,
    },
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const options = [
    { label: "US Gen Pop 18+", value: "US Gen Pop 18+" },
    { label: "India Gen Pop 18+", value: "India Gen Pop 18+" },
  ];

  const dataobj = [
    {
      id: 1,
      name: "US Gen Pop 18+",
      statusname: "Live",
      tableaction: "Click to Bill",
    },
    {
      id: 2,
      name: "India Gen Pop 18+",
      statusname: "Billed",
      tableaction: "Click to Revised Bill",
    },
    {
      id: 3,
      name: "Summary",
      tableaction: "Click to Bill",
    },
  ];

  const handlesubfunc = (e) => {
    setInvoiceCalc((val) => [
      ...val,
      {
        Respondent_count: `${e.CLCount}`,
        Appr_Reject: 0,
        CPI: `${e.CPI}`,
        Revenue: `${parseInt(e.CLCount) + parseInt(e.CPI)}`,
        Remarks: e.Remarks,
      },
    ]);

    setTotalINvoicecalc((val) => [
      {
        Respondent_count: parseInt(e.CLCount) + 86,
        Appr_Reject: 0,
        CPI: "-",
        Revenue: `$  ${parseInt(e.CLCount) + parseInt(e.CPI) + 132}`,
      },
    ]);
  };

  const showAddbox = (boxid) => {
    let iconShow = document.getElementById("boxSect" + boxid);

    if (iconShow.matches(".open")) {
      iconShow.classList.remove("open");
    } else {
      iconShow.classList.add("open");
    }
  };

  const deleteAddbox = (boxid) => {
    let iconShow = document.getElementById("boxSect" + boxid);

    if (iconShow.matches(".open")) {
      iconShow.classList.remove("open");
    } else {
      iconShow.classList.add("open");
    }
  };

  const NewaddedBox = (props) => {
    return (
      <>
        {boxId === props.idb ? (
          <>
            <form onSubmit={handleSubmit(handlesubfunc)}>
              <div className="">
                <Row className="Quality_sf">
                  <Col
                    lg={11}
                    style={{
                      paddingBottom: "10px",
                    }}
                  >
                    <Row>
                      <Col className="Quality_Col" lg={3} md={4} sm={4} xs={12}>
                        <label class="formlabel">CL Count</label>

                        <input
                          {...register(`CLCount`, {
                            required: "This field is Required",
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only numbers are allowed",
                            },
                          })}
                          type="text"
                          className=""
                          style={{
                            width: "100%",
                          }}
                        />
                        {errors.CLCount && (
                          <small className="invoice_errmsg">
                            {errors.CLCount.message}
                          </small>
                        )}
                      </Col>
                      <Col className="Quality_Col" lg={3} md={4} sm={4} xs={12}>
                        <label class="formlabel">CPI</label>

                        <input
                          {...register(`CPI`, {
                            required: "This field is Required",
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only numbers are allowed",
                            },
                          })}
                          type="text"
                          className=""
                          style={{
                            width: "100%",
                          }}
                        />
                        {errors.CPI && (
                          <small className="invoice_errmsg">
                            {errors.CPI.message}
                          </small>
                        )}
                      </Col>
                      <Col className="Quality_Col" lg={4} md={4} sm={4} xs={12}>
                        <label class="formlabel">Remarks</label>

                        <input
                          {...register(`Remarks`, {
                            required: "This field is Required",
                          })}
                          type="text"
                          className=""
                          style={{
                            width: "100%",
                          }}
                        />
                        {errors.Remarks && (
                          <small className="invoice_errmsg">
                            {errors.Remarks.message}
                          </small>
                        )}
                      </Col>
                      <Col lg={2} md={4} sm={4} xs={12}>
                        <label class="formlabel"></label>
                        <div className="Quality_subbtn flex flex-direction remove-mt">
                          <CiCircleMinus
                            className="invoice_two_icon"
                            size={25}
                            onClick={() => {
                              deleteAddbox(props.idb);
                            }}
                          />
                          <button className="cbtn saveBtn">Submit</button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </form>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const Invoicecalc = (props) => {
    return (
      <>
        <Table
          bordered={false}
          hover
          size="sm"
          className="invoice_customTable"
          style={{
            backgroundColor: "#f5f3f3",
          }}
        >
          <thead className="">
            <tr>
              <td>Respondent Count</td>
              <td
                style={{
                  width: "auto",
                  maxWidth: "100px",
                }}
              >
                Approved/Rejected Count
              </td>
              <td>CPI</td>
              <td
                style={{
                  width: "auto",
                  maxWidth: "50px",
                }}
              >
                Revenue
              </td>
              {props.nameof === "Summary" ? <></> : <td>Remarks</td>}

              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {invoiceclac.map((invoiceItem) => (
              <tr>
                <td>{invoiceItem.Respondent_count}</td>
                <td> {invoiceItem.Appr_Reject}</td>
                <td>{invoiceItem.CPI}</td>

                <td>{invoiceItem.Revenue}</td>

                <td className="inovice_btn">
                  {invoiceItem.Remarks !== "" ? (
                    <>
                      <Tooltip title={invoiceItem.Remarks} placement="top">
                        <Button variant="contained">
                          {invoiceItem.Remarks.substr(0, 5)}
                        </Button>
                      </Tooltip>
                    </>
                  ) : (
                    <></>
                  )}
                </td>

                <td>
                  {invoiceItem.Remarks !== "" ? (
                    <>
                      <span className="actionButton">
                        <img
                          src={EditIcon}
                          alt=""
                          onClick={() => {
                            setInvoiceEdit(true);
                          }}
                        />
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {props.nameof === "Summary" ? <td>Total Revenue</td> : <></>}

              <td></td>
            </tr>
            {totalinvoicecalc.map((totalinvoiceItem) => (
              <tr>
                <td>{totalinvoiceItem.Respondent_count}</td>
                <td>{totalinvoiceItem.Appr_Reject}</td>
                <td>{totalinvoiceItem.CPI}</td>
                <td>{totalinvoiceItem.Revenue}</td>
                <td>{totalinvoiceItem.Remarks}</td>
                <td></td>
                <td></td>
              </tr>
            ))}
          </tfoot>
        </Table>
      </>
    );
  };

  const handleShowSect = (id) => {
    var element = document.getElementById("firstSect" + id);
    let iconShow = document.getElementById("iconSect" + id);

    if (element.matches(".open")) {
      element.classList.remove("open");
      iconShow.classList.remove("open");
    } else {
      element.classList.add("open");
      iconShow.classList.add("open");
    }
  };
  return (
    <>
      <InvoicBillingattach
        show={invoiceidsshow}
        onHide={() => setInvoiceidsShow(false)}
      />

      <Invoicesurveylogs
        show={invoicelogs}
        onHide={() => setInvoicelogs(false)}
      />
      <Invoiceids
        show={invoiceuploadids}
        onHide={() => setInvoiceuploadids(false)}
      />
      <Editpop show={invoiceedit} onHide={() => setInvoiceEdit(false)} />
      <div
        className="pswtchbtn pcolorbtn pswtchleft"
        style={{
          float: "none",
        }}
      >
        <BootstrapSwitchButton
          className="switch btn on btn-outline-success btn-xs projectswitchbtn"
          id="bbts"
          size="xs"
          width={80}
          onlabel="Live"
          offlabel="Inactive"
          onstyle="outline-success"
          offstyle="outline-danger"
        />
      </div>

      <div className="cost_main_cont">
        <div className="supplyInfocost">
          <p className="supinfoTitle">SC106647 - Project 3</p>
          <div className="supplyInfoT_icon">
            <BiPurchaseTag
              className="invoice_two_icon"
              size={25}
              onClick={() => {
                setInvoicelogs(true);
              }}
            />
            <BiUpload
              className="invoice_two_icon"
              size={25}
              onClick={() => {
                setInvoiceuploadids(true);
              }}
            />
          </div>
        </div>

        {dataobj.map((itemobj, indexitem) => (
          <>
            <div className="table_box">
              <div
                className="InvoiceHead pjt_arrow_icon"
                id={"iconSect" + itemobj.id}
                onClick={() => {
                  handleShowSect(itemobj.id);
                }}
              >
                <p className="pBlockHead">{itemobj.name}</p>
                {itemobj.statusname === "Live" ? (
                  <p className="liveBtn  invoice_lbtn">{itemobj.statusname}</p>
                ) : itemobj.statusname === "Billed" ? (
                  <p
                    className="liveBtn costbtn"
                    style={{
                      background: "linear-gradient(to right, #0b87b2, #0b87b2)",
                    }}
                  >
                    {itemobj.statusname}
                  </p>
                ) : (
                  <></>
                )}
                {/* 
                <div
                  className=""
                  onClick={() => {
                    handleShowSect(itemobj.id);
                  }}
                > */}
                <div className="supplyInfoT_icon_inside">
                  <GrAddCircle
                    className="invoice_two_icon"
                    onClick={(e) => {
                      setBoxId(itemobj.id);
                      showAddbox(itemobj.id);
                      e.stopPropagation();
                    }}
                  />
                  <BiUpload
                    className="invoice_two_icon"
                    size={20}
                    onClick={(e) => {
                      setInvoiceuploadids(true);
                      e.stopPropagation();
                    }}
                  />
                  <div className="pjt_arrow_icon costup_icon">
                    <KeyboardArrowUpIcon
                      className="SIconUp"
                      onClick={() => {
                        handleShowSect(itemobj.id);
                      }}
                    />

                    <KeyboardArrowDownIcon
                      className="SDropIcon"
                      onClick={() => {
                        handleShowSect(itemobj.id);
                      }}
                    />
                  </div>
                  {/* </div> */}
                </div>
              </div>

              <div
                id={"firstSect" + itemobj.id}
                className={
                  itemobj.name === "Summary"
                    ? "cost_table table_box_sumary"
                    : "cost_table table_box"
                }
              >
                <Table
                  bordered={false}
                  hover
                  size="sm"
                  className="invoicing invoice_customTable"
                >
                  <thead className="sup_thead">
                    {itemobj.name === "Summary" ? (
                      <>
                        <tr>
                          <td>Est. Revenue</td>
                          <td>Revised Est. Revenue</td>
                          <td>Work in Progress</td>
                          <td>Billed Revenue</td>
                          <td>Project Minimum Fee</td>
                          <td>List of Surveys</td>
                          <td>Action</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr>
                          <td>Work in Progress</td>
                          <td>Billed Revenue</td>
                          <td>Project Minimum Fee</td>
                          <td>Status</td>
                          <td>Action</td>
                        </tr>
                      </>
                    )}
                  </thead>
                  <tbody>
                    {itemobj.name === "Summary" ? (
                      <>
                        <tr>
                          <td>$450</td>
                          <td>$560</td>
                          <td>$625</td>
                          <td>$520</td>
                          <td>$420</td>
                          <td>
                            <div className="csmulti invoice_multi">
                              <MultiSelect
                                className="multiselectoption "
                                style={{ width: "29px" }}
                                options={options}
                                value={selected}
                                onChange={(e) => {
                                  setSelected(e);
                                }}
                                labelledBy="uuuuuuuuuuuu"
                                isCreatable={true}
                              />
                            </div>
                          </td>
                          <td className="reduced-height">
                            {itemobj.tableaction === "Click to Bill" ? (
                              <p
                                className="invoice_table_btn"
                                style={{
                                  background:
                                    "linear-gradient(to right, #e28b3c, #f2a251)",
                                  padding: "2px",
                                }}
                                onClick={() => {
                                  setInvoiceidsShow(true);
                                }}
                              >
                                {itemobj.tableaction}
                              </p>
                            ) : itemobj.tableaction ===
                              "Click to Revised Bill" ? (
                              <p
                                className="invoice_table_btn costbtn clk_rvs_btn"
                                style={{
                                  background:
                                    "linear-gradient(to right, #e28b3c, #f2a251)",
                                  padding: "2px",
                                }}
                                onClick={() => {
                                  setInvoiceidsShow(true);
                                }}
                              >
                                {itemobj.tableaction}
                              </p>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr>
                          <td>$625</td>
                          <td> $0</td>
                          <td>$450 </td>

                          <td>Approved</td>
                          <td className="reduced-height">
                            {itemobj.tableaction === "Click to Bill" ? (
                              <p
                                className="invoice_table_btn"
                                style={{
                                  background:
                                    "linear-gradient(to right, #e28b3c, #f2a251)",

                                  padding: "2px",
                                }}
                                onClick={() => {
                                  setInvoiceidsShow(true);
                                }}
                              >
                                {itemobj.tableaction}
                              </p>
                            ) : itemobj.tableaction ===
                              "Click to Revised Bill" ? (
                              <p
                                className="invoice_table_btn costbtn clk_rvs_btn"
                                style={{
                                  background:
                                    "linear-gradient(to right, #e28b3c, #f2a251)",

                                  padding: "2px",
                                }}
                                onClick={() => {
                                  setInvoiceidsShow(true);
                                }}
                              >
                                {itemobj.tableaction}
                              </p>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
                {itemobj.name === "Summary" ? (
                  <>
                    <div
                      style={{
                        backgroundColor: "#f5f3f3",
                      }}
                    >
                      <Invoicecalc idb={itemobj.id} nameof={"Summary"} />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        backgroundColor: "#f5f3f3",
                        padding: "5px 5px 5px 5px",
                      }}
                    >
                      <Invoicecalc idb={itemobj.id} nameof={"not"} />
                    </div>
                  </>
                )}

                <div
                  id={"boxSect" + itemobj.id}
                  className="boxselect"
                  style={{
                    backgroundColor: "#f5f3f3",
                  }}
                >
                  <NewaddedBox idb={itemobj.id} />
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default Invoicing;
