import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState } from "react";

const Editpop = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handlesubfunc = (e) => {
    setInvoiceCalc((val) => [
      ...val,
      {
        Respondent_count: `${e.CLCount}`,
        Appr_Reject: 0,
        CPI: `${e.CPI}`,
        Revenue: `${parseInt(e.CLCount) + parseInt(e.CPI)}`,
        Remarks: e.Remarks,
      },
    ]);

    setTotalINvoicecalc((val) => [
      {
        Respondent_count: parseInt(e.CLCount) + 86,
        Appr_Reject: 0,
        CPI: "-",
        Revenue: `$  ${parseInt(e.CLCount) + parseInt(e.CPI) + 132}`,
      },
    ]);
  };

  const [invoiceclac, setInvoiceCalc] = useState([
    {
      Respondent_count: 40,
      Appr_Reject: 42,
      CPI: `$ ${1.5}`,
      Revenue: `$ ${63}`,
      Remarks: "",
    },
    {
      Respondent_count: 26,
      Appr_Reject: 22,
      CPI: `$ ${2.0}`,
      Revenue: `$ ${44}`,
      Remarks: "",
    },
    {
      Respondent_count: 20,
      Appr_Reject: 30,
      CPI: `$ ${1.55}`,
      Revenue: `$ ${44.2}`,
      Remarks: "",
    },
  ]);
  const [totalinvoicecalc, setTotalINvoicecalc] = useState([
    {
      Respondent_count: 86,
      Appr_Reject: 0,
      CPI: "-",
      Revenue: `$ ${132}`,
      Remarks: "",
    },
  ]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Edit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handlesubfunc)}>
          <div className="">
            <Row className="Quality_sf">
              <Col className="Quality_Col" lg={2} md={4} sm={4} xs={12}>
                <label class="formlabel">CL Count</label>

                <input
                  {...register(`CLCount`)}
                  type="text"
                  className=""
                  style={{
                    width: "100%",
                  }}
                />
                {errors.CLCount && (
                  <small className="errmsg">{errors.CLCount.message}</small>
                )}
              </Col>
              <Col className="Quality_Col" lg={2} md={4} sm={4} xs={12}>
                <label class="formlabel">CPI</label>

                <input
                  {...register(`CPI`)}
                  type="text"
                  className=""
                  style={{
                    width: "100%",
                  }}
                />
                {errors.CPI && (
                  <small className="errmsg">{errors.CPI.message}</small>
                )}
              </Col>
              <Col className="Quality_Col" lg={6} md={4} sm={4} xs={12}>
                <label class="formlabel">Remarks</label>

                <input
                  {...register(`Remarks`)}
                  type="text"
                  className=""
                  style={{
                    width: "100%",
                  }}
                />
                {errors.Remarks && (
                  <small className="errmsg">{errors.Remarks.message}</small>
                )}
              </Col>
              <Col lg={2} md={4} sm={4} xs={12}>
                <div
                  className="Quality_subbtn flex flex-direction"
                  style={{
                    marginTop: "25px",
                    marginRight: "-20px",
                  }}
                >
                  <button className="cbtn saveBtn">Submit</button>
                </div>
              </Col>
            </Row>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Editpop;
