import { useNavigate } from "react-router";
const ResetSubHeader = (props) => {
  const navigate = useNavigate();
  const cptype = props.pname;

  return (
    <>
      <div className="Mail_to_account">
        <span
          className={cptype === "resetpassword" ? "active" : ""}
          onClick={() => {
            navigate("/reset-password");
          }}>
          Change Password
        </span>
        <span
          className={cptype === "changesignature" ? "active" : ""}
          onClick={() => {
            navigate("/change-signature");
          }}>
          Change Signature
        </span>
      </div>
    </>
  );
};

export default ResetSubHeader;
