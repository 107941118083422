import { useEffect, useState } from "react";
import "./ProjectDetail.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { MultiSelect } from "react-multi-select-component";
import icons8 from "../../../assets/images/icons8-uchiha-eyes-24.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Select from "react-select";

const ProjectDetailMulipalForm = (props) => {
  var index = props.index;
  var genPopModel = props.genPopModel;
  const axiosPrivate = useAxiosPrivate();
  const [countryselect, setCountrySelect] = useState();
  const [languageselect, setLanguageSelect] = useState();
  const [typeselect, setTypeSelect] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const country = [
    { value: "India", label: "India" },
    { value: "Canada", label: "Canada" },
    { value: "Australia", label: "Australia" },
  ];

  const language = [
    { value: "English", label: "English" },
    { value: "Spanish", label: "Spanish" },
    { value: "French", label: "French" },
  ];

  const [container, setContainer] = useState({
    usGen_C: "show",
    usGen_I: "up",
    indian_gen_C: "show",
    indian_gen_I: "up",
  });

  useEffect(() => {
    setValue("Country", countryselect);
    setValue("Languagename", languageselect);
    setValue("Type", typeselect);
  }, [countryselect, languageselect, typeselect]);

  const RecallContainer = (e) => {
    if (e === "Project Setting") {
      setContainer((event) => ({
        ...event,
        project_C: "hide",
        project_I: "down",
      }));
    }
    if (e === "US Gen Pop 18+") {
      setContainer((event) => ({
        ...event,
        usGen_C: "hide",
        usGen_I: "down",
      }));
    }
    if (e === "Target Details") {
      setContainer((event) => ({
        ...event,
        target_C: "hide",
        target_I: "down",
      }));
    }
    if (e === "Custom Targeting") {
      setContainer((event) => ({
        ...event,
        custom_C: "hide",
        custom_I: "down",
      }));
    }
    if (e === "India Gen Pop 18+") {
      setContainer((event) => ({
        ...event,
        indian_gen_C: "hide",
        indian_gen_I: "down",
      }));
    }

    if (e === "Pre Screening") {
      setContainer((event) => ({ ...event, pre_C: "hide", pre_I: "down" }));
    }
  };

  // .................................................................
  //           *****************Api************************
  // ...............................................................

  const [projectTypeStorage, setProjectTypeStorage] = useState([{}]);
  const [countryListStorage, setCountryListStorage] = useState([{}]);
  const [surveyMargin, setSurveyMargin] = useState([{}]);

  useEffect(() => {
    get_ProjectType_api();
    get_Country_api();
    get_SurveyMargin_api();
  }, []);

  const get_ProjectType_api = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get-project-type");
      response.data.all_status.map((e) => {
        setProjectTypeStorage((val) => [
          ...val,
          { label: e.name, value: e.id },
        ]);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const get_SurveyMargin_api = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get-survey-margin");

      response.data.all_status.map((e) => {
        setSurveyMargin((val) => [...val, { label: e.name, value: e.id }]);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const get_Country_api = async () => {
    try {
      const response = await axiosPrivate.get("lookup/country-list");
      response.data.all_countries.map((e) => {
        setCountryListStorage((val) => [
          ...val,
          { label: e.country_name, value: e._id },
        ]);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="first firstone">
          <div className="ScreeningHead pjt_arrow_icon">
            {container.usGen_I === "up" ? (
              <>
                <KeyboardArrowUpIcon
                  onClick={() => {
                    RecallContainer("US Gen Pop 18+");
                  }}
                />
              </>
            ) : (
              <>
                <KeyboardArrowDownIcon
                  onClick={() => {
                    setContainer((e) => ({
                      ...e,
                      usGen_C: "show",
                      usGen_I: "up",
                    }));
                  }}
                />
              </>
            )}
            <p className="pBlockHead">US Gen Pop 18+</p>
            <div className="rankPain">
              <button className="PSaveBtn">Save</button>
              <p className="liveBtn">Live</p>
              <div className="ScreeningBtn">
                <img src={icons8} alt="" />
                &nbsp;
                <span
                  onClick={() => {
                    props.props.setShowSubHeader(2);
                    props.props.setShowScreening(true);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Screening
                </span>
              </div>
            </div>
          </div>

          {container.usGen_C === "show" ? (
            <>
              <div
                style={{
                  backgroundColor: "white",
                }}
                className="csmulti"
              >
                <Row className="prj_rowtwo">
                  <Col lg={3} md={6} xs={12} className="col">
                    <label className="formlabel">Country*</label>
                    <Select
                      placeholder="Select..."
                      className="pjt_select formtext"
                      classNamePrefix="select"
                      {...register("Country", {
                        required: "This field is Required",
                      })}
                      options={countryListStorage}
                      onChange={(e) => {
                        setCountrySelect(e.value);
                      }}
                      noOptionsMessage={() => "No Data Found"}
                    />
                    {countryselect ? (
                      <></>
                    ) : (
                      <>
                        {errors.Country && (
                          <small className="errmsg perrmsg">
                            {errors.Country.message}
                          </small>
                        )}
                      </>
                    )}
                  </Col>
                  <Col lg={3} md={6} xs={12} className="col">
                    <label className="formlabel">Language*</label>
                    <Select
                      placeholder="Select..."
                      className="pjt_select formtext"
                      classNamePrefix="select"
                      {...register("Languagename", {
                        required: "This field is Required",
                      })}
                      options={language}
                      onChange={(e) => {
                        setLanguageSelect(e.value);
                      }}
                      noOptionsMessage={() => "No Data Found"}
                    />
                    {languageselect ? (
                      <></>
                    ) : (
                      <>
                        {errors.Languagename && (
                          <small className="errmsg perrmsg">
                            {errors.Languagename.message}
                          </small>
                        )}
                      </>
                    )}
                  </Col>
                  <Col lg={3} md={6} xs={12} className="col">
                    <label className="formlabel">Type*</label>
                    <Select
                      placeholder="Select..."
                      className="pjt_select formtext"
                      classNamePrefix="select"
                      {...register("Type", {
                        required: "This field is Required",
                      })}
                      options={projectTypeStorage}
                      onChange={(e) => {
                        setTypeSelect(e.value);
                      }}
                      noOptionsMessage={() => "No Data Found"}
                    />
                    {typeselect ? (
                      <></>
                    ) : (
                      <>
                        {errors.Type && (
                          <small className="errmsg perrmsg">
                            {errors.Type.message}
                          </small>
                        )}
                      </>
                    )}
                  </Col>
                  <Col lg={3} md={6} xs={12} className="col">
                    <label className="formlabel">IR*</label>
                    <input
                      {...register("IRS", {
                        required: "This field is Required",
                      })}
                      type="text"
                      placeholder={genPopModel[index].name}
                      className="CsformText"
                    />
                    {errors.IRS && (
                      <small className="errmsg perrmsg">
                        {errors.IRS.message}
                      </small>
                    )}
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: "0px",
                  }}
                  className="prj_rowtwo"
                >
                  <Col lg={3} md={6} xs={12} className="col">
                    <label className="formlabel">LOI*</label>
                    <input
                      {...register("Loi", {
                        required: "This field is Required",
                      })}
                      type="text"
                      placeholder={genPopModel[index].email}
                      className="CsformText"
                    />
                    {errors.Loi && (
                      <small className="errmsg perrmsg">
                        {errors.Loi.message}
                      </small>
                    )}
                  </Col>
                  <Col lg={3} md={6} xs={12} className="col">
                    <label className="formlabel">CPI*</label>
                    <input
                      {...register("Cpi", {
                        required: "This field is Required",
                      })}
                      type="text"
                      placeholder="CPI"
                      className="CsformText"
                    />
                    {errors.Cpi && (
                      <small className="errmsg perrmsg">
                        {errors.Cpi.message}
                      </small>
                    )}
                  </Col>
                  <Col lg={3} md={6} xs={12} className="col">
                    <label className="formlabel">Quota*</label>
                    <input
                      {...register("Quota", {
                        required: "This field is Required",
                      })}
                      type="text"
                      placeholder="Quota"
                      className="CsformText"
                    />
                    {errors.Quota && (
                      <small className="errmsg perrmsg">
                        {errors.Quota.message}
                      </small>
                    )}
                  </Col>
                  <Col lg={3} md={6} xs={12} className="col">
                    <label className="formlabel">Margin Threshold</label>
                    <Select
                      placeholder="Select..."
                      className="pjt_select formtext"
                      classNamePrefix="select"
                      options={surveyMargin}
                      noOptionsMessage={() => "No Data Found"}
                    />
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </form>
    </>
  );
};

export default ProjectDetailMulipalForm;
