import React, { useState } from "react";
import "./Login.css";
import samplicitytagline from "../../assets/SamplicityBlackLogo.svg";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import "react-confirm-alert/src/react-confirm-alert.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";

const UpdatePassword = () => {
  const { token } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } = useForm();

  const handleSubmitButton = async (e) => {
    if (e.NewName !== e.ConfirmPassword) {
      notifyerrorpassword();
    } else {
      setIsSubmitting(true);
      try {
        await axiosPrivate
          .post(
            "user/reset-password",
            JSON.stringify({
              token: token,
              password: e.ConfirmPassword,
            }),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((e) => {
            if (e.statusText === "OK") {
              localStorage.setItem("reset", "yes");
              navigate("/login");
            } else {
            }
          });
      } catch (err) {
        notifyerror(err.response.statusText);
      }
      setIsSubmitting(false);
    }
  };

  const notifyerror = (e) => toast.error(`${e} Error Occure`);
  const notifyerrorpassword = () =>
    toast.error("New Password & Confirm Password Not Match");

  return (
    <>
      <div className="login_container">
        <div>
          <img className="login_torfacimg" alt="" />
        </div>
        <div>
          <img
            className="login_samplicity_logo"
            src={samplicitytagline}
            alt="samplicity"
          />
        </div>
        <div className="login_form">
          <>
            <form onSubmit={handleSubmit(handleSubmitButton)}>
              <input
                type="password"
                placeholder="New Password"
                className="newPassword input-text"
                {...register("NewName", {
                  required: "This field is Required",
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,10}$/,
                    message:
                      "Minimum 6 and maximum 10 characters, atleast one uppercase,one lowercase,one number and one special character",
                  },
                })}
              />{" "}
              {errors.NewName && (
                <small className="errmsg">{errors.NewName.message}</small>
              )}
              <input
                type="password"
                placeholder="Confirm Password"
                className="newPassword input-text"
                {...register("ConfirmPassword", {
                  required: "This field is Required",
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,10}$/,
                    message:
                      "Minimum 6 and maximum 10 characters, atleast one uppercase,one lowercase,one number and one special character",
                  },
                })}
              />{" "}
              {errors.ConfirmPassword && (
                <small className="errmsg">
                  {errors.ConfirmPassword.message}
                </small>
              )}
              <p className="errorMessage"></p>
              <button id="loginbutton" disabled={isSubmitting}>
                {isSubmitting && (
                  <Spinner
                    as="span"
                    style={{ color: "white" }}
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                &nbsp;SUBMIT
              </button>
            </form>
            <p
              className="login_forget_text"
              onClick={() => {
                navigate("/login");
              }}
            >
              Back to Login
            </p>
          </>
        </div>
      </div>
    </>
  );
};
export default UpdatePassword;
