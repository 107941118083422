import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

const OppStatus = (props) => {
  const [bidStatus, setBidStatus] = useState();
  const axiosPrivate = useAxiosPrivate();

  let selectedValue = "";
  if(props.sel){
    selectedValue= props.sel;
  }

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      try {
        const response = await axiosPrivate.get("lookup/get_bid_status", {
        });
        let data = response.data.all_status;
        isMounted && setBidStatus(data)

      } catch (err) {
        console.error(err);
      }
    }
    getData();
    return () => {
      isMounted = false;
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (    
    <>
        {bidStatus?.length
        ? (
          <>
            {bidStatus.map((status, i) =>
               (status.id!="1") ? (
                <option value={status?.id}  key={i}> {status?.name}</option>
               )
               : ""              
            )}
            </> 
        ) :
        <option>No account to dispaly</option>        
      }
      </>  
     
  )
}

export default OppStatus