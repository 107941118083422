import surveyTrackingIcon from "../../../assets/CSicons/icons/survey tracking.svg";
import Table from "react-bootstrap/Table";
import { TableCell, TableRow, TableBody } from "@mui/material";
// import Modal from "react-modal";
import Modal from "react-bootstrap/Modal";
import "./Qualityproject.css";
import { useForm } from "react-hook-form";
import { useState } from "react";
import linkIcon from "../../../assets/CSicons/icons/link.svg";
const Qualityproject = () => {
  const [savecomnt, setSavecomnt] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpencomment, setModalIsOpencomment] = useState(false);
  const [modelsaved, setModelSaved] = useState(false);
  const [anchorElUse, setAnchorElUse] = useState(null);
  const [comnt, setComnt] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const handleSaveButton = (e) => {
    setComnt(savecomnt);
    setModalIsOpen(false);
    setShow(false);
  };

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
    setModalIsOpencomment(false);
    setModelSaved(false);
  };

  const handleOpenUserMenu = (event, bid_id) => {
    setAnchorElUse(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUse(null);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "400px",
    },
  };

  return (
    <>
      <hr className="csHR" />
      <div className="Sfirstbox">
        <Table className="Quotatable" responsive>
          <thead className="thead">
            <TableRow className="projlabel ">
              <TableCell className="qualitytablecell">Date</TableCell>
              <TableCell className="qualitytablecell">Type Of</TableCell>
              <TableCell className="qualitytablecell">Subject Line</TableCell>
              <TableCell className="qualitytablecell">Receiver (TO)</TableCell>
              <TableCell className="qualitytablecell">Parameters</TableCell>
              <TableCell className="qualitytablecell"> EW</TableCell>
              <TableCell className="qualitytablecell">Dispute</TableCell>
              <TableCell className="qualitytablecell">Action</TableCell>
            </TableRow>
          </thead>
          <tbody>
            <tr
              className="QArrowHeead projlabel"
              id={"iconSect" + 1}
              onClick={() => {}}
            >
              <td
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                }}
              >
                09 Feb 2023
              </td>
              <td
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                }}
              >
                QA, Mark Error
              </td>
              <td
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                }}
                title="mail has been deliverd to sunny@torfac, verify the following document"
              >
                verify mail...
              </td>
              <td
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                }}
                className="projlabel"
              >
                sunny@torfac.com
              </td>

              <td
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                  fontFamily: "'Ubuntu', sans-serif",
                }}
              >
                pre-screen
              </td>
              <td
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                  fontFamily: "'Ubuntu', sans-serif",
                }}
              >
                20
              </td>

              <td>
                {comnt !== undefined && comnt !== "" && comnt !== null ? (
                  <>
                    {comnt.substr(0, 5)}
                    <span
                      className="read_more"
                      onClick={() => {
                        setModalIsOpencomment(true);
                      }}
                    >
                      ...Read More
                    </span>

                    <Modal
                      id="checkkkkk"
                      className="questionpopup"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={modalIsOpencomment}
                      onHide={() => {
                        setModalIsOpenToFalse(false);
                      }}
                    >
                      <Modal.Header closeButton>
                        <hr />
                        <Modal.Title
                          id="contained-modal-title-vcenter"
                          style={{ fontSize: "16px", paddingLeft: "10px" }}
                        >
                          Dispute Comment
                        </Modal.Title>
                      </Modal.Header>
                      {comnt !== "undefined" ||
                      comnt !== "" ||
                      comnt !== null ? (
                        <>
                          <p
                            style={{
                              padding: "10px 1px 1px 1px",
                            }}
                          >
                            {comnt}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </Modal>
                  </>
                ) : (
                  <></>
                )}
              </td>
              <td>
                <img
                  src={surveyTrackingIcon}
                  className="SupplyIcon"
                  alt="surveyTrackingIcon"
                  srcset=""
                  onClick={handleShow}
                />
                <img
                  src={linkIcon}
                  className="SupplyIcon"
                  alt="surveyTrackingIcon"
                  srcset=""
                  onClick={() => {
                    setModelSaved(true);
                  }}
                />
              </td>
              <Modal
                id="checkkkkk"
                className="questionpopup"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modelsaved}
                onHide={() => {
                  setModelSaved(false);
                }}
              >
                <Modal.Header closeButton>
                  <hr />
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{ fontSize: "16px" }}
                  >
                    Dispute Comment
                  </Modal.Title>
                </Modal.Header>

                <p className="qa_comnt">
                  {" "}
                  where the real content should be. If you have business such as
                  a blog and you do not have content alread
                </p>
              </Modal>
            </tr>
          </tbody>
        </Table>

        <Modal
          id="checkkkkk"
          className="questionpopup"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <hr />
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ fontSize: "16px" }}
            >
              Dispute
            </Modal.Title>
          </Modal.Header>

          <form
            onSubmit={handleSubmit(handleSaveButton)}
            style={{
              padding: "0px 15px 10px 15px",
            }}
          >
            <label
              htmlFor=""
              className="formlabel"
              style={{
                color: "black",
                fontSize: "13px",
              }}
            >
              Reason
            </label>
            <textarea
              className="quality_prj_textarea"
              onChange={(e) => {
                setSavecomnt(e.target.value);
              }}
            />

            <button
              className="cbtn saveBtn"
              style={{
                marginRight: "0px",

                float: "left",
              }}
              type="submit"
            >
              {" "}
              Submit
            </button>
          </form>
        </Modal>
      </div>
    </>
  );
};
export default Qualityproject;
