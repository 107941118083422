import Header from "../../UI/Header/Header";
import { useNavigate, useLocation, useParams } from "react-router";
import userIco from "../../../assets/BidsIcons/client.svg";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import "./BidsForm.css";
import BidsSubHeader from "../../BidsModule/BidsSubHeader/BidsSubHeader";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import Bidstopheader from "./Bidstopheader";
import Bidstopheaderselect from "../BidsTable/BidsTopHeaderSelect";
const AddFinanceDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [stateSelect, setStateSelect] = useState([]);
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [country, setCountry] = useState([]);
  const [selectState, setSelectState] = useState(true);

  var localData = localStorage.getItem("clientname");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    countryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countryList = async () => {
    try {
      const response = await axiosPrivate.get(`lookup/country-list`, {});
      let array = [];
      Object.values(response.data.all_countries).forEach(function (key) {
        array.push(key);
      });
      setCountry(array);
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const notifysuccess = (e) => toast.success(e, {});
  const notifyError = (e) => toast.error(e);

  const newFinanceDetail = async (e) => {
    setIsSubmitting(true);
    try {
      await axiosPrivate
        .post(
          "client/add-client-finance",

          JSON.stringify({
            client_id: id,
            company_name: e.CompanyName,
            billing_phone_number: e.Phone,
            client_code: e.Client,
            billing_email: e.Email,
            billing_contact: e.BillingContac,
            payment_term: e.PaymentTerms,
            billing_address: {
              street: e.Street,
              state_id: e.State,
              po_box: e.POBox,
              zip: e.Zip,
              city: e.City,
              country_id: e.Country,
              finance_doc: "string",
            },
            status: 1,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysuccess("Finance details added successfully");
            navigate(`/finance/${id}`);
          }
        });
    } catch (err) {
      if (err.response.status === 404) {
        notifyError(err.response.data.return_status.message);
      } else if (err.response.status === 422) {
      }
    }

    setIsSubmitting(false);
  };

  const statefun = (id) => {
    setStateSelect([]);
    var dropDown = document.getElementById("ddlFruits");
    dropDown.selectedIndex = 0;
    country.map((e) => {
      if (e._id.toString() === id) {
        e.all_states.map((states) => {
          setStateSelect((val) => [
            ...val,
            { id: states.id, name: states.state_name },
          ]);
        });
      }
    });
  };

  return (
    <>
      {/* <Header /> */}
      <BidsSubHeader pname="finance" />
      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(newFinanceDetail)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img src={userIco} className="headingIcons" alt="" />
                  {localData}
                </h3>
              </div>
              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/finance/${id}`);
                  }}
                >
                  {" "}
                  Close
                </button>

                <button className="cbtn saveBtn">
                  {isSubmitting && (
                    <Spinner
                      style={{ color: "white" }}
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp;&nbsp; Save
                </button>
              </div>
            </div>
            <hr className="showingHeader" />
            {/* <Bidstopheader tid="2" clientid={id} /> */}
            <Bidstopheader clientid={id} tid="2" className="showingHeader" />
            <Bidstopheaderselect clientid={id} namevalue={"finance"} />

            <div className="mainContent">
              <Row>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Company Name*</label>
                  <input
                    type="text"
                    placeholder="Enter Company Name"
                    className="formtext"
                    {...register("CompanyName", {
                      required: "This field is Required",
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                        message: "Only letters are allowed",
                      },
                    })}
                  />
                  {errors.CompanyName && (
                    <small className="errmsg">
                      {errors.CompanyName.message}
                    </small>
                  )}
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Billing Phone Number*</label>
                  <input
                    type="text"
                    placeholder="Enter Billing Phone Numbe"
                    className="formtext"
                    {...register("Phone", {
                      required: "This field is Required",
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Only numbers are allowed",
                      },
                    })}
                  />
                  {errors.Phone && (
                    <small className="errmsg">{errors.Phone.message}</small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Client Code*</label>
                  <input
                    type="text"
                    placeholder="Enter Client Code"
                    className="formtext"
                    {...register("Client", {
                      required: "This field is Required",
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                        message: "Only letters are allowed",
                      },
                    })}
                  />
                  {errors.Client && (
                    <small className="errmsg">{errors.Client.message}</small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Billing Email*</label>
                  <input
                    type="text"
                    placeholder="Enter Billing Email"
                    className="formtext"
                    {...register("Email", {
                      required: "This field is Required",
                      pattern: {
                        value:
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\],;:\s@\"]+)*)|(.+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@]{2,})$/i,
                        message: "Enter Valid Email Id",
                      },
                    })}
                  />
                  {errors.Email && (
                    <small className="errmsg">{errors.Email.message}</small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Billing Contact*</label>
                  <input
                    type="text"
                    placeholder="Enter Billing Contact"
                    className="formtext"
                    {...register("BillingContac", {
                      required: "This field is Required",
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Only numbers are allowed",
                      },
                    })}
                  />
                  {errors.BillingContac && (
                    <small className="errmsg">
                      {errors.BillingContac.message}
                    </small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Payment Terms*</label>
                  <input
                    type="text"
                    placeholder="Enter Payment Terms"
                    className="formtext"
                    {...register("PaymentTerms", {
                      required: "This field is Required",
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890]*$/,
                        message: "letters and numbers are allowed",
                      },
                    })}
                    // jhakcj
                  />
                  {errors.PaymentTerms && (
                    <small className="errmsg">
                      {errors.PaymentTerms.message}
                    </small>
                  )}
                </Col>
              </Row>

              <h5 style={{ marginTop: "1rem" }} className="headinginternal">
                Billing Address*
              </h5>

              <Row>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Street*</label>
                  <input
                    type="text"
                    placeholder="Enter Street"
                    className="formtext"
                    {...register("Street", {
                      required: "This field is Required",
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                        message: "Only letters are allowed",
                      },
                    })}
                  />
                  {errors.Street && (
                    <small className="errmsg">{errors.Street.message}</small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Country*</label>
                  <select
                    className="formtext"
                    onClick={(e) => {
                      statefun(e.target.value);
                      // setSelectState(false);
                    }}
                    {...register("Country", {
                      required: "This field is Required",
                    })}
                  >
                    <option value="">Options</option>
                    {country.map((e) => (
                      <option value={e._id}>{e.country_name}</option>
                    ))}
                  </select>
                  {errors.Country && (
                    <small className="errmsg">{errors.Country.message}</small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">State</label>
                  <select
                    id="ddlFruits"
                    className="formtext"
                    {...register("State", {
                      required: "This field is Required",
                    })}
                  >
                    <option value="0">Options</option>
                    {stateSelect.map((e) => (
                      <option value={e.id}>{e.name}</option>
                    ))}
                  </select>

                  {errors.State && (
                    <small className="errmsg">{errors.State.message}</small>
                  )}

                  {/* {selectState === false ? (
                      <></>
                    ) : (
                      <>
                        {stateSelect.map((e) => (
                          <option value={e.id}>{e.state_name}</option>
                        ))}
                      </>
                    )} */}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">City*</label>
                  <input
                    type="text"
                    placeholder="Enter City"
                    className="formtext"
                    {...register("City", {
                      required: "This field is Required",
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                        message: "Only letters are allowed",
                      },
                    })}
                  />
                  {errors.City && (
                    <small className="errmsg">{errors.City.message}</small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">PO Box*</label>
                  <input
                    type="text"
                    placeholder="Enter PO Box"
                    className="formtext"
                    {...register("POBox", {
                      required: "This field is Required",
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890]*$/,
                        message: "Only letters and numbers are allowed",
                      },
                    })}
                  />
                  {errors.POBox && (
                    <small className="errmsg">{errors.POBox.message}</small>
                  )}
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Zip*</label>
                  <input
                    type="text"
                    placeholder="Enter Zip"
                    className="formtext"
                    {...register("Zip", {
                      required: "This field is Required",
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Only numbers are allowed",
                      },
                    })}
                  />
                  {errors.Zip && (
                    <small className="errmsg">{errors.Zip.message}</small>
                  )}
                </Col>
              </Row>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddFinanceDetails;
