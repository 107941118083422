import React, { useState, useEffect } from "react";
import "./Permissions.css";
// import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Header from "../UI/Header/Header";
import "bootstrap/dist/css/bootstrap.css";

import Bids from "../PermissionsModule/BidsPage";
import Admin from "./Admin";
import Quality from "../PermissionsModule/Quality";
import Finance from "./Finance";
import Operation from "./Operations";
import Supply from "./Supply";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useParams } from "react-router";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Permissions(props) {
  const [value, setValue] = React.useState(0);
  const [menu, setMenu] = useState();
  const [all_data, setAll_data] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findData = async () => {
    try {
      const response = await axiosPrivate.get("/lookup/get-module-list");
      setAll_data(response.data.all_status);
    } catch (err) {
      console.error(err);
      // navigate("/login", { state: { from: location }, replace: true });
    }
    return () => {};
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            {all_data.map((e, pid) => (
              <Tab label={e.name} {...a11yProps(pid)} />
            ))}
          </Tabs>
        </Box>
        {/* 
        <TabPanel value={value} index={0}>
          <BidsPage id={id} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Operations id={id} />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Supply id={id} />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <Quality id={id} />
        </TabPanel>

        <TabPanel value={value} index={4}>
          <Finance id={id} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Admin id={id} />
        </TabPanel>
      */}

        {all_data.map((e, pid) => (
          <>
            {e.name === "Bids" && (
              <TabPanel value={value} index={pid}>
                <Bids id={id} />
              </TabPanel>
            )}

            {e.name === "Operation" && (
              <TabPanel value={value} index={pid}>
                <Operation id={id} />
              </TabPanel>
            )}
            {e.name === "Supply" && (
              <TabPanel value={value} index={pid}>
                {" "}
                <Supply id={id} />
              </TabPanel>
            )}
            {e.name === "Admin" && (
              <TabPanel value={value} index={pid}>
                {" "}
                <Admin id={id} />
              </TabPanel>
            )}
            {e.name === "Quality" && (
              <TabPanel value={value} index={pid}>
                {" "}
                <Quality id={id} />
              </TabPanel>
            )}
            {e.name === "Finance" && (
              <TabPanel value={value} index={pid}>
                {" "}
                <Finance id={id} />
              </TabPanel>
            )}
          </>
        ))}
      </Box>
    </>
  );
}
