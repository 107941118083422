import React from "react";
import { components } from "react-select";

const CustomValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? "" : "s";
    values = `${values.length} item${plural} selected`;
  }

  return (
    <components.ValueContainer {...props}>
      <span
        style={{
          color: "#999",

          fontSize: "14px",
        }}
      >
        {values}
        {input}
      </span>
    </components.ValueContainer>
  );
};
export default CustomValueContainer;
