import React from "react";
import Col from "react-bootstrap/Col";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    title: {
      display: true,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};

const labels = [
  "Shubham Bhatnagar",
  "Ayush Bajpai",
  "Jash Pandit",
  "Raman Deep",
  "Ayushi Rawat",
  "Aman Pandey",
  "Vivek Satyakam",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      barThickness: 6,
      data: [65, 59, 80, 81, 56, 55, 40],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      barThickness: 6,
      data: [165, 39, 50, 120, 46, 50, 80],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const BarChart = () => {
  return (
    <Col lg={12} md={6} sm={6} xs={12}>
      <Bar options={options} data={data} />
    </Col>
  );
};
export default BarChart;
