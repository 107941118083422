import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import moment from "moment";
const RevenueShare = (props) => {
  const [revenueShare, setRevenueShare] = useState([]);
  let newCurrentDate = moment().subtract(7, "days").format("YYYY-MM-DD");
  let newEndDate = moment().format("YYYY-MM-DD");
  const axiosPrivate = useAxiosPrivate();

  let startD = useRef();
  let endD = useRef();
  useEffect(() => {
    if (props.revenueState === undefined) {
      startD.current = newCurrentDate;
      endD.current = newEndDate;
    } else {
      startD.current = props.revenueState.CurrentDate;
      endD.current = props.revenueState.EndDate;
    }
    if (props.data) {
      props.setFlag(false);
      let user = "";
      props.data.map((e) => {
        if (props.data[props.data.length - 1]._id !== e._id) {
          user = user + `user${e._id}&`;
        } else {
          user = user + `user${e._id}`;
        }
      });

      getData(startD, endD, user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.revenueState]);

  useEffect(() => {
    if (props.revenueState === undefined) {
      startD.current = newCurrentDate;
      endD.current = newEndDate;
    } else {
      startD.current = props.revenueState.CurrentDate;
      endD.current = props.revenueState.EndDate;
    }
    getDataDetail(startD, endD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.revenueState]);

  const getData = async (startD, endD, user) => {
    props.setIsSubmitting(true);
    try {
      const response = await axiosPrivate.get(
        `bid-dashboard/revenue-share?start_time=${startD.current}&end_time=${endD.current}&${user}`,
        {}
      );

      setRevenueShare(response.data.revenue_share);
    } catch (err) {}
    props.setIsSubmitting(false);
  };

  const getDataDetail = async (startD, endD) => {
    try {
      const response = await axiosPrivate.get(
        `bid-dashboard/revenue-share?start_time=${startD.current}&end_time=${endD.current}`,
        {}
      );

      setRevenueShare(response.data.revenue_share);
    } catch (err) {}
  };

  return (
    <div>
      <table className="dbTable">
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Bid Amount</th>
            <th className="text-center">Booked Amount</th>
            <th>Conversion</th>
          </tr>
        </thead>
        <tbody>
          {revenueShare.map((e) => (
            <tr>
              <td>{e.client_name}</td>
              <td>{e.bid_amount}</td>
              <td className="text-center">{e.booked_amount}</td>
              <td>{e.conversion}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RevenueShare;
