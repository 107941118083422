import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router";
import SettingHeader from "./SettingHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useState, useEffect } from "react";
import ButtonSpinner from "../../UI/ButtonSpinner";
import userIco from "../../../assets/LoginIcons/users.svg";
import { useForm } from "react-hook-form";
import "./ExclusionSetting.css";
import { useParams } from "react-router";
import toast from "react-hot-toast";
import axios from "axios";
const ExclusionSetting = () => {
  const navigate = useNavigate();
  const pname = "Exclusion Setting";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [irStatus, setIrStatus] = useState(false);
  const [loiStatus, setLoiStatus] = useState(false);
  const [conversionStatus, setConversionStatus] = useState(false);
  const [cpiStatus, setCpiStatus] = useState(false);
  const [loder, setLoder] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [formData, setFormData] = useState({
    formStatus: "",
    Irstatus: "",
    min_ir: "",
    repeated_ir_hits: "",
    Loistatus: "",
    max_loi: "",
    repeated_loi_hits: "",
    Cpistatus: "",
    min_cpi: "",

    Conversionstatus: "",
    repeated_conversion_hits: "",
    conversion: "",
  });
  const notifyerror = (e) => toast.error(`${e} Error Occure`);
  const notifyerror1 = () => toast.error("Role already exists");
  useEffect(() => {
    get_ProjectType_api();
  }, []);

  const get_ProjectType_api = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(`supplyinfo/get-supplier/${id}`);
      // setData(response.data.supplier);
      if (
        response.data.supplier.exclusion_settings === null ||
        response.data.supplier.exclusion_settings === undefined
      ) {
        setShowForm(false);
      } else {
        setFormData((prevData) => ({
          ...prevData,

          min_ir: response.data.supplier.exclusion_settings.settings.ir.min_ir,
          repeated_ir_hits:
            response.data.supplier.exclusion_settings.settings.ir
              .repeated_ir_hits,

          max_loi:
            response.data.supplier.exclusion_settings.settings.loi.max_loi,
          repeated_loi_hits:
            response.data.supplier.exclusion_settings.settings.loi
              .repeated_loi_hits,

          min_cpi:
            response.data.supplier.exclusion_settings.settings.cpi.min_cpi,

          repeated_conversion_hits:
            response.data.supplier.exclusion_settings.settings.conversion
              .repeated_conversion_hits,
          conversion:
            response.data.supplier.exclusion_settings.settings.conversion
              .conversion,
        }));
        if (response.data.supplier.exclusion_settings.status === 1) {
          setShowForm(true);
        } else {
          setShowForm(false);
        }
        if (
          response.data.supplier.exclusion_settings.settings.ir.status === 1
        ) {
          setIrStatus(true);
        } else {
          setIrStatus(false);
        }
        if (
          response.data.supplier.exclusion_settings.settings.loi.status === 1
        ) {
          setLoiStatus(true);
        } else {
          setLoiStatus(false);
        }
        if (
          response.data.supplier.exclusion_settings.settings.cpi.status === 1
        ) {
          setCpiStatus(true);
        } else {
          setCpiStatus(false);
        }
        if (
          response.data.supplier.exclusion_settings.settings.conversion
            .status === 1
        ) {
          setConversionStatus(true);
        } else {
          setConversionStatus(false);
        }
      }

      setLoder(true);
      // setCountrySelect(response.data.supplier.address.country.id);
      // setprescreening(response.data.supplier.screening.has_screening);
      // setQualiﬁcation(response.data.supplier.screening.has_qualification);
      // setScreen(response.data.supplier.screening.screen_captcha);
      // setHerring(response.data.supplier.screening.has_job_type);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchDataWithToken = async () => {
    setLoder(false);

    const token = "YOUR_API_TOKEN"; // Replace 'YOUR_API_TOKEN' with your actual API token
    const url = `http://52.13.201.242:8003/api/v1/supplyinfo/get-supplier/${id}`; // Replace 'YOUR_API_URL' with the URL of the API you want to fetch

    try {
      const response = await axios.get(url, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "access-token":
            "Bearer  eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIzOTExNzYsIm5iZiI6MTY5MjM5MTE3NiwianRpIjoiOGU2NjBkNjMtNDk5Yi00ZDZiLWE4YjItZTM2NGVlMjI0ZWNhIiwiZXhwIjoxNjkyODIzMTc2LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.G6QS_iwod1fRt9etZGDye1tbhb5dxF0uHy-n5ZBDhrU",
        },
      });
      setFormData((prevData) => ({
        ...prevData,
        formStatus: response.data.supplier.exclusion_settings.status,
        Irstatus: response.data.supplier.exclusion_settings.settings.ir.status,
        min_ir: response.data.supplier.exclusion_settings.settings.ir.min_ir,
        repeated_ir_hits:
          response.data.supplier.exclusion_settings.settings.ir
            .repeated_ir_hits,

        max_loi: response.data.supplier.exclusion_settings.settings.loi.max_loi,
        repeated_loi_hits:
          response.data.supplier.exclusion_settings.settings.loi
            .repeated_loi_hits,
        Loistatus:
          response.data.supplier.exclusion_settings.settings.loi.status,
        Cpistatus:
          response.data.supplier.exclusion_settings.settings.cpi.status,
        min_cpi: response.data.supplier.exclusion_settings.settings.cpi.min_cpi,

        repeated_conversion_hits:
          response.data.supplier.exclusion_settings.settings.conversion
            .repeated_conversion_hits,
        conversion:
          response.data.supplier.exclusion_settings.settings.conversion
            .conversion,
        Conversionstatus:
          response.data.supplier.exclusion_settings.settings.conversion.status,
      }));
      // setIrStatus(response.data.supplier.exclusion_settings.settings.ir.status);
      // setConversionStatus(
      //   response.data.supplier.exclusion_settings.settings.conversion.status
      // );
      // setCpiStatus(
      //   response.data.supplier.exclusion_settings.settings.cpi.status
      // );
      // setLoiStatus(
      //   response.data.supplier.exclusion_settings.settings.loi.status
      // );
      setShowForm(response.data.supplier.exclusion_settings.status);
      setLoder(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmitForm = async (e) => {
    const response = await fetch(
      `http://52.13.201.242:8003/api/v1/supplyinfo/supplier-exclusion-settings/${id}`,
      {
        method: "POST",
        body: JSON.stringify({
          status: 1,
          settings: {
            min_hits: 0,
            ir: {
              status: irStatus,
              min_ir: parseInt(e.MinIr),
              repeated_ir_hits: parseInt(e.ReapeatedIrHits),
            },
            loi: {
              status: loiStatus,
              max_loi: parseInt(e.MaxLoi),
              repeated_loi_hits: parseInt(e.ReapeatedLoiHits),
            },
            cpi: {
              status: cpiStatus,
              min_cpi: parseInt(e.MinCpi),
            },
            conversion: {
              status: conversionStatus,
              repeated_conversion_hits: parseInt(e.RepeatedConversionHits),
              conversion: parseInt(e.Conversion),
            },
          },
        }),
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "access-token":
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIzOTExNzYsIm5iZiI6MTY5MjM5MTE3NiwianRpIjoiOGU2NjBkNjMtNDk5Yi00ZDZiLWE4YjItZTM2NGVlMjI0ZWNhIiwiZXhwIjoxNjkyODIzMTc2LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.G6QS_iwod1fRt9etZGDye1tbhb5dxF0uHy-n5ZBDhrU",
        },
      }
    );
    const result = await response.json();
    e.preventDefault();

    setIsSubmitting(false);
  };

  const postData = async (e) => {
    setIsSubmitting(true);

    try {
      await axiosPrivate.post(
        `/supplyinfo/supplier-exclusion-settings/${id}`,
        JSON.stringify({
          status: 1,
          settings: {
            min_hits: 0,
            ir: {
              status: irStatus,
              min_ir: parseInt(e.MinIr),
              repeated_ir_hits: parseInt(e.ReapeatedIrHits),
            },
            loi: {
              status: loiStatus,
              max_loi: parseInt(e.MaxLoi),
              repeated_loi_hits: parseInt(e.ReapeatedLoiHits),
            },
            cpi: {
              status: cpiStatus,
              min_cpi: parseInt(e.MinCpi),
            },
            conversion: {
              status: conversionStatus,
              repeated_conversion_hits: parseInt(e.RepeatedConversionHits),
              conversion: parseInt(e.Conversion),
            },
          },
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (err) {}
    setIsSubmitting(false);
  };

  return (
    <>
      <SettingHeader pname={pname} id={id} />
      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(postData)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img
                    src={userIco}
                    className="headingIcons"
                    alt="Add Department"
                  />
                  Exclusion Setting
                </h3>
              </div>
              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={(e) => {
                    navigate("/supplier-details");

                    e.preventDefault();
                  }}
                >
                  Close
                </button>

                <button disabled={isSubmitting} className="cbtn saveBtn">
                  {isSubmitting && <ButtonSpinner />} &nbsp; Save
                </button>
              </div>
            </div>

            <hr />
            {loder ? (
              <>
                <div
                  id="dividerow"
                  style={{ marginTop: "10px" }}
                  className="mainContent"
                >
                  <Row>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Status*</label>
                      <BootstrapSwitchButton
                        className="switch btn on btn-outline-success btn-xs"
                        onChange={(e) => {
                          setShowForm(e);
                        }}
                        size="xs"
                        width={80}
                        onlabel="Active"
                        offlabel="Inactive"
                        onstyle="outline-success"
                        offstyle="outline-danger"
                        checked={showForm}
                      />
                    </Col>
                  </Row>

                  {showForm ? (
                    <>
                      <div className="settingHeading">
                        <p className="pBlockHead">Ir</p>
                      </div>
                      <div style={{ border: "1px solid whiteSmoke" }}>
                        <div className="rowContant">
                          <Row>
                            <Col lg={4} md={3} xs={12} className="col">
                              <label className="formlabel">Min Ir*</label>
                              <input
                                {...register("MinIr", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Min Ir"
                                className="CsformText"
                                defaultValue={formData.min_ir}
                              />
                              {errors.MinIr && (
                                <small className="errmsg perrmsg">
                                  {errors.MinIr.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={3} xs={12} className="col">
                              <label className="formlabel">
                                Reapeated Ir Hits*
                              </label>
                              <input
                                {...register("ReapeatedIrHits", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Reapeated Ir Hits*"
                                className="CsformText"
                                defaultValue={formData.repeated_ir_hits}
                              />
                              {errors.ReapeatedIrHits && (
                                <small className="errmsg perrmsg">
                                  {errors.ReapeatedIrHits.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={3} xs={12} className="col">
                              <label className="formlabel">Status*</label>
                              <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                onChange={(e) => {
                                  setIrStatus(e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                                checked={irStatus}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="settingHeading">
                        <p className="pBlockHead">Loi</p>
                      </div>
                      <div style={{ border: "1px solid whiteSmoke" }}>
                        <div className="rowContant">
                          <Row>
                            <Col lg={4} md={3} xs={12} className="col">
                              <label className="formlabel">Max Loi*</label>
                              <input
                                {...register("MaxLoi", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Max Loi"
                                className="CsformText"
                                defaultValue={formData.max_loi}
                              />
                              {errors.MaxLoi && (
                                <small className="errmsg perrmsg">
                                  {errors.MaxLoi.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={3} xs={12} className="col">
                              <label className="formlabel">
                                Reapeated Loi Hits*
                              </label>
                              <input
                                {...register("ReapeatedLoiHits", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Reapeated Loi Hits*"
                                className="CsformText"
                                defaultValue={formData.repeated_loi_hits}
                              />
                              {errors.ReapeatedIrHits && (
                                <small className="errmsg perrmsg">
                                  {errors.ReapeatedIrHits.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={3} xs={12} className="col">
                              <label className="formlabel">Status*</label>
                              <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                onChange={(e) => {
                                  setLoiStatus(e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                                checked={loiStatus}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="settingHeading">
                        <p className="pBlockHead">Conversion</p>
                      </div>
                      <div style={{ border: "1px solid whiteSmoke" }}>
                        <div className="rowContant">
                          <Row>
                            <Col lg={4} md={3} xs={12} className="col">
                              <label className="formlabel">
                                Repeated Conversion Hits*
                              </label>
                              <input
                                {...register("RepeatedConversionHits", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder=" Repeated Conversion Hits*"
                                className="CsformText"
                                defaultValue={formData.repeated_conversion_hits}
                              />
                              {errors.RepeatedConversionHits && (
                                <small className="errmsg perrmsg">
                                  {errors.RepeatedConversionHits.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={3} xs={12} className="col">
                              <label className="formlabel">Conversion*</label>
                              <input
                                {...register("Conversion", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Conversion"
                                className="CsformText"
                                defaultValue={formData.conversion}
                              />
                              {errors.Conversion && (
                                <small className="errmsg perrmsg">
                                  {errors.Conversion.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={3} xs={12} className="col">
                              <label className="formlabel">Status*</label>
                              <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                onChange={(e) => {
                                  setConversionStatus(e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                                checked={conversionStatus}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="settingHeading">
                        <p className="pBlockHead">Cpi</p>
                      </div>
                      <div style={{ border: "1px solid whiteSmoke" }}>
                        <div className="rowContant">
                          <Row>
                            <Col lg={4} md={3} xs={12} className="col">
                              <label className="formlabel">Min Cpi*</label>
                              <input
                                {...register("MinCpi", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Min Cpi"
                                className="CsformText"
                                defaultValue={formData.min_cpi}
                              />
                              {errors.MinCpi && (
                                <small className="errmsg perrmsg">
                                  {errors.MinCpi.message}
                                </small>
                              )}
                            </Col>

                            <Col lg={4} md={3} xs={12} className="col">
                              <label className="formlabel">Status*</label>
                              <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                onChange={(e) => {
                                  setCpiStatus(e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                                checked={cpiStatus}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ExclusionSetting;
