import React, { useState, useEffect } from "react";
import Header from "../../UI/Header/Header";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import pageIcon from "../../../assets/BidsIcons/addClient.svg";
import Bidstopheader from "./Bidstopheader";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BidsSubHeader from "../../BidsModule/BidsSubHeader/BidsSubHeader";
import ButtonSpinner from "../../UI/ButtonSpinner";
import { useForm } from "react-hook-form";
import Bidstopheaderselect from "../BidsTable/BidsTopHeaderSelect";
const AddClient = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [accountOwner, setAccountOwner] = useState([]);
  const [clientType, setClientType] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState(false);
  const [tierPricingLevel, setTierPricingLevel] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateSelect, setStateSelect] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const notifyerr = () => toast.error("Client already exists");
  const newclient = () => toast.success("Client added successfully");
  const addClient = async (e) => {
    setIsSubmitting(true);

    var state = e.State;

    if (state === "") {
      state = null;
    }

    try {
      await axiosPrivate
        .post(
          "/client/add-client",
          JSON.stringify({
            name: e.Name,
            user_reference_id: e.AccountOwner,
            client_type_id: e.ClientType,
            project_minimum_fee: e.ProjectMinimumFee,
            excluded_vendor_id: [],
            secret_key: e.SecretKey,
            phone: e.Phone,
            billing_address: e.BillingAddress,
            country_id: e.Country,
            state_id: state,
            city: e.city,
            zipcode: e.Zipcode,
            tier_price_id: e.TierPricing,
            status: 1,
            is_api: e.IsAPI,
            is_approved: 1,
            quota_check_type: 0,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          if (e.status === 200) {
            newclient();
            navigate("/clients");
          }
        });
    } catch (err) {
      if (err.response.status === 409) {
        notifyerr();
        setIsSubmitting(false);
      }
    }

    setIsSubmitting(false);
  };

  const tierPricing = async () => {
    try {
      const response = await axiosPrivate.get(
        "lookup/get-tier-pricing-level",
        {}
      );
      setTierPricingLevel(response.data.all_status);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    tierPricing();
    getCountriesList();
    clientTypes();
    getAccountOwners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clientTypes = async () => {
    let isMounted = true;
    try {
      const response = await axiosPrivate.get("lookup/client-type-list", {});
      isMounted && setClientType(response.data.all_client_type);
    } catch (err) {
      console.error(err);
    }
  };
  const getAccountOwners = async (e) => {
    let isMounted = true;
    try {
      const response = await axiosPrivate.get("user/user-department/2", {});
      isMounted && setAccountOwner(response.data.all_users);
    } catch (err) {
      console.error(err);
    }
  };

  const stateFun = (id) => {
    const Id = parseInt(id);
    setStateSelect([]);
    var dropDown = document.getElementById("ddlFruits");
    dropDown.selectedIndex = 0;
    country.map((e) => {
      if (e._id == Id) {
        let array = [];
        Object.values(e.all_states).forEach(function (key) {
          array.push(key);
        });
        setStateSelect(array);
      }
    });
  };

  const getCountriesList = async () => {
    let isMounted = true;
    try {
      const response = await axiosPrivate.get("lookup/country-list", {});
      let array = [];
      Object.values(response.data.all_countries).forEach(function (key) {
        array.push(key);
      });
      isMounted && setCountry(array);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {/* <Header /> */}
      <BidsSubHeader pname="clientlist" />
      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(addClient)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img src={pageIcon} className="headingIcons" alt="" /> Add
                  Client
                </h3>
              </div>
              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={() => {
                    navigate("/clients");
                  }}>
                  {" "}
                  Close
                </button>

                <button className="cbtn saveBtn">
                  {isSubmitting && <ButtonSpinner />} &nbsp; Save
                </button>
              </div>
            </div>
            <hr className="showingHeader" />
            {/* <Bidstopheader tid="4" /> */}
            <Bidstopheader tid="4" className="showingHeader" />
            <Bidstopheaderselect />
            <div className="mainContent">
              <Row>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Name*</label>
                  <input
                    type="text"
                    placeholder="Name"
                    className="formtext"
                    {...register("Name", {
                      required: "This field is Required",
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 ]*$/,
                        message: "Only letters and Numbers are allowed",
                      },
                    })}
                  />
                  {errors.Name && (
                    <small className="errmsg">{errors.Name.message}</small>
                  )}
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Billing Address</label>
                  <input
                    type="text"
                    placeholder="Billing Address"
                    className="formtext"
                    {...register("BillingAddress", {
                      pattern: {
                        value:
                          /^(([^<>()[\],;:\s@\"]+([^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line
                        message: "Enter Valid Email Id",
                      },
                    })}
                  />
                  {errors.BillingAddress && (
                    <small className="errmsg">
                      {errors.BillingAddress.message}
                    </small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Account Owner*</label>
                  <select
                    className="formtext"
                    {...register("AccountOwner", {
                      required: "This field is Required",
                    })}>
                    <option value="">Select Option</option>
                    {accountOwner.map((owner) => (
                      <option value={owner._id} key={owner.name}>
                        {owner.name}
                      </option>
                    ))}
                  </select>
                  {errors.AccountOwner && (
                    <small className="errmsg">
                      {errors.AccountOwner.message}
                    </small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Client Type*</label>
                  <select
                    className="formtext"
                    {...register("ClientType", {
                      required: "This field is Required",
                    })}>
                    <option value="">Select Option</option>
                    {clientType.map((type) => (
                      <option value={type._id} key={type.name}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                  {errors.ClientType && (
                    <small className="errmsg">
                      {errors.ClientType.message}
                    </small>
                  )}
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Project Minimum Fee*</label>
                  <input
                    type="text"
                    placeholder="Project Minimum Fee"
                    className="formtext"
                    {...register("ProjectMinimumFee", {
                      required: "This field is Required",
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Only numbers are allowed",
                      },
                    })}
                  />
                  {errors.ProjectMinimumFee && (
                    <small className="errmsg">
                      {errors.ProjectMinimumFee.message}
                    </small>
                  )}
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Phone</label>
                  <input
                    type="text"
                    placeholder="Phone"
                    className="formtext"
                    {...register("Phone", {
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Only numbers are allowed",
                      },
                    })}
                  />
                  {errors.Phone && (
                    <small className="errmsg">{errors.Phone.message}</small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Country*</label>
                  <select
                    className="formtext"
                    onClick={(e) => stateFun(e.target.value)}
                    {...register("Country", {
                      required: "This field is Required",
                    })}>
                    <option value="">Select Option</option>
                    {country.map((countriesList) => (
                      <option
                        key={countriesList.country_name}
                        value={countriesList._id}>
                        {countriesList.country_name}
                      </option>
                    ))}
                  </select>
                  {errors.Country && (
                    <small className="errmsg">{errors.Country.message}</small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">State</label>
                  <select
                    id="ddlFruits"
                    className="formtext"
                    {...register("State")}>
                    <option value=""> Option</option>

                    {stateSelect.map((e) => (
                      <option value={e.id}>{e.state_name}</option>
                    ))}
                  </select>
                  {errors.State && (
                    <small className="errmsg">{errors.State.message}</small>
                  )}
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">City</label>
                  <input
                    type="text"
                    placeholder="city"
                    className="formtext"
                    {...register("city", {
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                        message: "Only letters are allowed",
                      },
                    })}
                  />
                  {errors.city && (
                    <small className="errmsg">{errors.city.message}</small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Is API*</label>
                  <select
                    className="formtext"
                    {...register("IsAPI", {
                      required: "This field is Required",
                    })}>
                    <option value="">Select Option</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  {errors.IsAPI && (
                    <small className="errmsg">{errors.IsAPI.message}</small>
                  )}
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Excluded Vendors</label>
                  <select className="formtext">
                    <option value="">Select Option</option>
                  </select>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Secret Key</label>
                  <input
                    type="text"
                    placeholder="Secret Key"
                    className="formtext"
                    {...register("SecretKey", {
                      pattern: {
                        value:
                          /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@!#$%^&*]*$/,
                        message: "Only letters are allowed",
                      },
                    })}
                  />
                  {errors.SecretKey && (
                    <small className="errmsg">{errors.SecretKey.message}</small>
                  )}
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col-3 col">
                  <label className="formlabel">Tier Pricing Level*</label>
                  <select
                    className="formtext"
                    {...register("TierPricing", {
                      required: "This field is Required",
                    })}>
                    <option value="">Select Option</option>
                    {tierPricingLevel.map((tier) => (
                      <option value={tier.id} key={tier.id}>
                        {tier.name}
                      </option>
                    ))}
                  </select>
                  {errors.TierPricing && (
                    <small className="errmsg">
                      {errors.TierPricing.message}
                    </small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col-3 col">
                  <label className="formlabel">Zipcode</label>
                  <input
                    type="text"
                    placeholder="zipcode"
                    className="formtext"
                    {...register("Zipcode", {
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Only numbers are allowed",
                      },
                    })}
                  />
                  {errors.Zipcode && (
                    <small className="errmsg">{errors.Zipcode.message}</small>
                  )}
                </Col>
                <br />
              </Row>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddClient;
