import "./CreateBid.css";
import BidsSubHeader from "../BidsSubHeader/BidsSubHeader";
import { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { confirmAlert } from "react-confirm-alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router";
import userIco from "../../../assets/LoginIcons/users.svg";
import DeleteIcon from "../../../assets/LoginIcons/delete.svg";
import toast from "react-hot-toast";
import ButtonSpinner from "../../UI/ButtonSpinner";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useParams } from "react-router";
import SpinnerLoader from "../../UI/Spinner";
import PMList from "../BidDetails/CommonServices/PMList";
import ProjectType from "../BidDetails/CommonServices/ProjectType";
import OppStatus from "../BidDetails/CommonServices/OppStatus";
import Modal from "react-modal";
import { ModalBody, ModalHeader } from "react-bootstrap";
import BidMarginPercent from "../BidDetails/CommonServices/BidMarginPercent";
import { CalcBidAmt } from "./CalcBidAmt";

const AddOpportunity = () => {
  const navigate = useNavigate();
  var today = new Date();
  var nextweek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 7
  );
  var nextmonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );

  const [editorData, setEditorData] = useState("");
  const [receivedDate, setReceivedDate] = useState(today);
  const [closeDate, setCloseDate] = useState(nextmonth);
  const [projectStartDate, setProjectStartDate] = useState(new Date());
  const [projectEndDate, setProjectEndDate] = useState(nextweek);
  const [bookedAmt, setBookedAmt] = useState(0);
  const [bidAmt, setBidAmt] = useState("");
  const [bidPercentage, setBidPercentage] = useState("100");
  const [dollarAmount, setDollarAmount] = useState(0);
  const [bidData, setBidData] = useState([]);
  const [geography, setGeography] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [emailCont, setEmailCont] = useState();
  const [subject, setSubject] = useState();
  const [serviceType, setServiceType] = useState([]);
  const showOpportunity = ["2", "3", ""];
  const { id } = useParams();
  const notifyerr = (msg) => toast.error(msg);
  const axiosPrivate = useAxiosPrivate();

  const {
    register,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      opportunities: [
        {
          lineItem: "",
          service: "",
          geography: "",
          ir: "",
          loi: "",
          n: "",
          cpi: "",
        },
      ],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "opportunities",
  });
  const updateCloseDate = (rdate) => {
    let date = new Date(rdate);
    date.setDate(date.getDate() + 30);
    setCloseDate(date);
    setValue("close_date", date);
  };

  const oppStatus = watch("opp_status");
  const pReason = watch("pass_reason");
  const projectManager = watch("pm");
  const pType = watch("project_type");

  useEffect(() => {
    if (id) {
      bidDetail();
    } else {
      setIsLoading(false);
    }
    getCountryList();
    getServiceType();
  }, []);
  const getServiceType = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get-service-type", {});
      setServiceType(response.data.all_status);
    } catch (err) {
      // console.error(err);
    }
  };

  const getCountryList = async () => {
    try {
      const response = await axiosPrivate.get("/lookup/country-list", {});
      let data = response.data.all_countries;

      var countryList = [...data];
      const updateddata = countryList.map((item, i) =>
        Object.assign(item, {
          value: item._id,
          label: item.country_name,
        })
      );
      let countryOptions = countryList.map((obj) => {
        delete obj.country_name;
        delete obj.country_code;
        delete obj.status;
        delete obj._id;
        delete obj.all_states;
        return obj;
      });
      setGeography(countryOptions);
    } catch (err) {
      // console.error(err);
    }
  };
  //const default_country = 25;

  const emailDetail = async (mid) => {
    const response = await axiosPrivate.get(
      `mailbox/message-detail/${mid}`,
      {}
    );
    setSubject(response.data.subject);
    setEmailCont(response.data.body_html);
  };

  const showMailContent = (e) => {
    e.preventDefault();
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const bidDetail = async () => {
    try {
      const response = await axiosPrivate.get(`/bid/get-bid/${id}`, {});
      setBidData(response.data.bid);
      //.log(response.data, "BidData");
      let msgBoxId = response.data.bid.mailbox_message_id;
      if (response.data.bid.mailbox_message_id) {
        emailDetail(response.data.bid.mailbox_message_id);
      }
      setIsLoading(false);
    } catch (err) {}
    return () => {};
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    var opp = [...data.opportunities];
    const updateddata = opp.map((oppitem, i) =>
      Object.assign(oppitem, {
        status: 1,
        is_project_created: 0,
        name: oppitem.lineItem,
        country: { id: parseInt(oppitem.geography.value) },
        category: { id: oppitem.service, name: "string" },
      })
    );
    let lineItems = opp.map((obj) => {
      delete obj.lineItem;
      delete obj.geography;
      delete obj.service;
      return obj;
    });
    let passReason = "",
      oppLabel = "",
      pTypeLabel = "";
    let passReasonLabel = "";
    let passComment = "";
    let pmLabel = "";

    if (data.opp_status) {
      var sel = document.getElementById("oppStatus");
      oppLabel = sel.options[sel.selectedIndex].text;
    }
    if (data.project_type) {
      var sel = document.getElementById("projectType");
      pTypeLabel = sel.options[sel.selectedIndex].text;
    }

    if (parseInt(data.opp_status) === 6) {
      passReason = data.pass_reason;
      var sel = document.getElementById("pass_reason");
      passReasonLabel = sel.options[sel.selectedIndex].text;
      if (data.pass_comment) {
        passComment = data.pass_comment;
      }
    }
    if (data.pm && parseInt(data.opp_status) === 3) {
      var sel = document.getElementById("pm");
      pmLabel = sel.options[sel.selectedIndex].text;
    }

    const payloadData = {
      project_name: data.project_name,
      ...(receivedDate && {
        received_date: moment(receivedDate).toISOString(),
      }),
      ...(closeDate && { closed_date: moment(closeDate).toISOString() }),
      status: {
        id: parseInt(data.opp_status),
        name: oppLabel,
      },
      ...(passReason &&
        parseInt(data.opp_status) === 6 && {
          pass_reason: {
            id: parseInt(passReason),
            name: passReasonLabel,
          },
          pass_comment: passComment,
        }),
      project_type: {
        id: parseInt(data.project_type),
        name: pTypeLabel,
      },
      ...(data.no_of_waves &&
        parseInt(data.project_type) === 5 && {
          no_waves: parseInt(data.no_of_waves),
        }),
      ...(data.frequency &&
        parseInt(data.project_type) === 5 && {
          frequency_id: parseInt(data.frequency),
        }),
      ...(projectStartDate &&
        parseInt(data.opp_status) === 3 && {
          project_start_date: moment(projectStartDate).toISOString(),
        }),
      ...(projectEndDate &&
        parseInt(data.opp_status) === 3 && {
          project_end_date: moment(projectEndDate).toISOString(),
        }),
      ...(data.pm &&
        parseInt(data.opp_status) === 3 && {
          opp_assign_user: {
            id: data.pm ? parseInt(data.pm) : 0,
            name: pmLabel,
          },
        }),
      ...(data.pm_notes &&
        parseInt(data.opp_status) === 3 && { notes_user: data.pm_notes }),
      client_bid_details: editorData,
      bid_amount: data.bid_amount ? parseInt(data.bid_amount) : 0,
      margin_bid_amount: {
        id: parseInt(data.bid_percent),
        name: data.bid_percent,
      },
      ...(data.booked_amount &&
        parseInt(data.opp_status) === 3 && {
          booked_amount: parseInt(data.booked_amount),
        }),
      is_project_created: 0,
      ...((data.opp_status === "2" || data.opp_status === "3") && {
        line_item: lineItems,
      }),
      ...((data.opp_status === "4" ||
        data.opp_status === "5" ||
        data.opp_status === "6") && { line_item: [] }),
      bid_id: id,
    };
    try {
      await axiosPrivate
        .post("/bid/add-opportunity", payloadData, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((e) => {
          if (e.status === 200) {
            localStorage.setItem("newOpp", "1");
            // notifyerr("Opportunity Created Successfully");
            navigate(`/update-bid/${id}`);
          }
        });
    } catch (err) {
      if (err.response.status === 409) {
        notifyerr(err.response);
        //  notifyerr("Opportunity Created Successfully");
      }
    }
    setIsSubmitting(false);
  };

  /************Calculate Final Amount from booked Amount***************/
  const calculateAmount = () => {
    const percentage = getValues("bid_percent");
    const amt = getValues("booked_amount");
    var finalamt = parseFloat(amt) * (parseFloat(percentage) / 100);
    finalamt = Number.isNaN(finalamt) ? 0 : finalamt;
    setDollarAmount(finalamt);
    setValue("amount_dollar", finalamt);
  };

  const changeBookedAmount = (e, amt) => {
    setBookedAmt(amt);
  };

  useEffect(() => {
    calculateAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookedAmt]);

  useEffect(() => {
    setBookedAmt(bidAmt);
    setValue("booked_amount", bidAmt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidAmt]);
  /************Calculate Final Amount from booked Amount***************/

  const bidAmtReg = register("bid_amount", {
    required: "This field is Required",
    pattern: {
      value: /^[0-9]+.?[0-9]*/,
      message: "Only numbers are allowed",
    },
  });

  const oppStatusReg = register("opp_status", {
    required: "This field is Required",
  });
  const pTypeReg = register("project_type", {
    required: "This field is Required",
  });

  const updateBidAmount = (e, opp) => {
    e.preventDefault();
    if (opp === "4" || opp === "5" || opp === "6") {
      setValue("bid_amount", 0);
    }
    if (opp === "3") {
      setValue("booked_amount", bidAmt);
    }
  };

  const addLineItem = (e) => {
    e.preventDefault();
    let index = parseInt(fields.length) - 1;
    let lineitemname = getValues("opportunities[" + index + "].lineItem");
    let cat = getValues("opportunities[" + index + "].service");
    let geog = getValues("opportunities[" + index + "].geography");
    let irval = getValues("opportunities[" + index + "].ir");
    let loival = getValues("opportunities[" + index + "].loi");
    let nval = getValues("opportunities[" + index + "].n");
    let cpival = getValues("opportunities[" + index + "].cpi");
    append({
      lineItem: lineitemname,
      service: cat,
      geography: geog,
      ir: irval,
      loi: loival,
      n: nval,
      cpi: cpival,
    });
  };
  return (
    <>
      {/* <Header /> */}
      <BidsSubHeader pname="biddetails" />
      <div className="top_container">
        <div className="main_container ">
          {isLoading ? (
            <SpinnerLoader />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="user_serachbox">
                <div style={{ float: "left" }}>
                  <h3 className="tableHeading">
                    <img src={userIco} className="headingIcons" alt="" />
                    Add Opportunity
                  </h3>
                </div>
                <div className="right-pane">
                  <button
                    className="cbtn closeBtn"
                    onClick={() => {
                      navigate(`/update-bid/${id}`);
                    }}
                  >
                    {" "}
                    Close
                  </button>

                  <button disabled={isSubmitting} className="cbtn saveBtn">
                    {isSubmitting && <ButtonSpinner />} &nbsp; Save
                  </button>
                </div>
              </div>
              <hr />
              <div className="Deatail_to_client">
                <div className="tabs active-tabss">Details</div>
              </div>
              <hr style={{ marginTop: "0px" }} />

              <div className="mainContent">
                <Row>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Project name*</label>
                    <input
                      type="text"
                      placeholder="Project name"
                      className="formtext"
                      {...register("project_name", {
                        required: "This field is Required",
                      })}
                      defaultValue={bidData.project_name}
                    />
                    {errors.project_name && (
                      <small className="errmsg">
                        {errors.project_name.message}
                      </small>
                    )}
                  </Col>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Project Bid Number*</label>
                    <input
                      type="text"
                      placeholder="Project Bid Number"
                      className="formtext"
                      {...register("bnumbar")}
                      defaultValue={bidData.bid_number}
                      readOnly
                    />
                  </Col>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Received Date</label>
                    <DatePicker
                      className="formtext"
                      {...register("received_date")}
                      selected={receivedDate ? new Date(receivedDate) : null}
                      onChange={(date) => {
                        setReceivedDate(date);
                        updateCloseDate(date);
                      }}
                      autoComplete="Off"
                    />
                  </Col>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Close Date</label>
                    <DatePicker
                      className="formtext"
                      {...register("close_date")}
                      selected={closeDate ? new Date(closeDate) : null}
                      onChange={(date) => setCloseDate(date)}
                      autoComplete="Off"
                    />
                  </Col>
                  {/* </Row> */}

                  {/* <Row> */}
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Project Type*</label>
                    <select
                      id="projectType"
                      className="formtext"
                      {...pTypeReg}
                      onChange={(e) => {
                        pTypeReg.onChange(e);
                      }}
                    >
                      <option value="">Select Option</option>
                      <ProjectType />
                    </select>
                    {errors.project_type && (
                      <small className="errmsg">
                        {errors.project_type.message}
                      </small>
                    )}
                  </Col>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">No of Waves</label>
                    <select className="formtext" {...register("no_of_waves")}>
                      <option value="">Select Option</option>
                      {pType === "5" ? (
                        <>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </select>
                    {errors.no_of_waves && (
                      <small className="errmsg">
                        {errors.no_of_waves.message}
                      </small>
                    )}
                  </Col>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Frequency</label>

                    <select className="formtext" {...register("frequency")}>
                      <option value="">Select Option</option>
                      {pType === "5" ? (
                        <>
                          <option value="1">Weekly</option>
                          <option value="2">Monthly</option>
                          <option value="3">Quaterly</option>{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </select>
                  </Col>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Opportunity Status*</label>
                    <select
                      id="oppStatus"
                      className="formtext"
                      {...oppStatusReg}
                      onChange={(e) => {
                        oppStatusReg.onChange(e);
                        updateBidAmount(e, e.target.value);
                      }}
                    >
                      <option value="">Select Option</option>
                      <OppStatus />
                    </select>
                    {errors.opp_status && (
                      <small className="errmsg">
                        {errors.opp_status.message}
                      </small>
                    )}
                  </Col>
                </Row>
                {parseInt(oppStatus) === 6 && (
                  <Row>
                    <Col lg={3} xs={12} className="col">
                      <label className="formlabel">Pass Reason*</label>
                      <select
                        id="pass_reason"
                        className="formtext"
                        {...register("pass_reason", {
                          required: "This field is Required",
                        })}
                        onChange={(e) => {
                          oppStatusReg.onChange(e);
                        }}
                      >
                        <option value="">Select Option</option>
                        <option value="1">No Feasibility</option>
                        <option value="2">CPI Offered Low</option>
                        <option value="3">Difficult Target</option>
                        <option value="4">Others</option>
                      </select>
                      {errors.pass_reason && (
                        <small className="errmsg">
                          {errors.pass_reason.message}
                        </small>
                      )}
                    </Col>

                    {pReason === "4" && (
                      <Col lg={9} xs={12} className="col">
                        <div className="form-group">
                          <label className="formlabel"></label>
                          <input
                            type="text"
                            placeholder="If Others,  define"
                            className="formtext"
                            {...register("pass_comment", {
                              required: "This field is Required",
                            })}
                          />
                          {errors.pass_comment && (
                            <small className="errmsg">
                              {errors.pass_comment.message}
                            </small>
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>
                )}

                <Row style={{ marginTop: "10px" }}>
                  <div className="opportunity_main formCont">
                    {showOpportunity.indexOf(oppStatus) > -1 && (
                      <>
                        <Accordion preExpanded={[0]}>
                          {fields.map((item, index) => (
                            <AccordionItem
                              id={index}
                              key={item.id}
                              uuid={index}
                            >
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  <div className="line_item">
                                    <label className="formlabel">
                                      Line Item #{index + 1}*
                                    </label>
                                    <input
                                      type="text"
                                      {...register(
                                        `opportunities[${index}].lineItem`,
                                        {
                                          required: "This field is required",
                                        }
                                      )}
                                      defaultValue={item.lineItem}
                                      placeholder=""
                                      className="formtext"
                                    />
                                    {errors.opportunities &&
                                      errors.opportunities[`${index}`] && (
                                        <small className="errmsg">
                                          &nbsp; &nbsp; PLease fill all
                                          mandatory fields in line item.
                                        </small>
                                      )}
                                  </div>
                                  <img
                                    src={DeleteIcon}
                                    alt="Delete Row"
                                    className="deleteBtn"
                                    onClick={() => {
                                      confirmAlert({
                                        message:
                                          "Are you sure, you want to delete this?",
                                        buttons: [
                                          {
                                            label: "Yes",
                                            onClick: () => {
                                              remove(index);
                                            },
                                          },
                                          { label: "No" },
                                        ],
                                      });
                                    }}
                                  />
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                <div
                                  className="extra_client_input_header"
                                  key="1"
                                >
                                  <Row>
                                    <Col
                                      lg={3}
                                      md={3}
                                      sm={6}
                                      xs={12}
                                      className="col"
                                    >
                                      <label className="formlabel">
                                        Service Type*
                                      </label>
                                      <select
                                        className="formtext"
                                        {...register(
                                          `opportunities[${index}].service`,
                                          {
                                            required: "This field is required",
                                          }
                                        )}
                                      >
                                        <option value="">Select Option</option>
                                        {serviceType.map((status, i) => (
                                          <option value={status?.id} key={i}>
                                            {status?.name}
                                          </option>
                                        ))}
                                      </select>
                                    </Col>
                                    <Col
                                      lg={3}
                                      md={3}
                                      sm={6}
                                      xs={12}
                                      className="col"
                                    >
                                      <label className="formlabel">
                                        Geography*
                                      </label>
                                      <Controller
                                        name={`opportunities[${index}].geography`}
                                        {...register(
                                          `opportunities[${index}].geography`,
                                          {
                                            required: "This field is required",
                                          }
                                        )}
                                        control={control}
                                        rules={{ required: "Area is required" }}
                                        render={({ field }) => (
                                          <Select
                                            //isClearable
                                            value={field.value}
                                            onChange={field.onChange}
                                            ref={field.ref}
                                            placeholder="Select Your Area"
                                            options={geography}
                                          />
                                        )}
                                      />
                                    </Col>
                                    <Col
                                      lg={6}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className="col"
                                    >
                                      <Row>
                                        <Col
                                          lg={3}
                                          md={3}
                                          sm={3}
                                          xs={6}
                                          className="col"
                                        >
                                          {" "}
                                          <label className="formlabel">
                                            IR%*
                                          </label>
                                          <input
                                            type="number"
                                            className="formtext"
                                            {...register(
                                              `opportunities[${index}].ir`,
                                              {
                                                required:
                                                  "This field is required",
                                              }
                                            )}
                                            defaultValue={item.ir}
                                          />
                                        </Col>
                                        <Col
                                          lg={3}
                                          md={3}
                                          sm={3}
                                          xs={6}
                                          className="col"
                                        >
                                          <label className="formlabel">
                                            LOI(Min)*
                                          </label>
                                          <input
                                            type="number"
                                            className="formtext"
                                            {...register(
                                              `opportunities[${index}].loi`,
                                              {
                                                required:
                                                  "This field is required",
                                              }
                                            )}
                                            defaultValue={item.loi}
                                          />
                                        </Col>

                                        <Col
                                          lg={3}
                                          md={3}
                                          sm={3}
                                          xs={6}
                                          className="col"
                                        >
                                          <label className="formlabel">
                                            N*
                                          </label>
                                          <input
                                            type="number"
                                            className="formtext"
                                            {...register(
                                              `opportunities[${index}].n`,
                                              {
                                                required:
                                                  "This field is required",
                                              }
                                            )}
                                            defaultValue={item.n}
                                          />
                                        </Col>

                                        <Col
                                          lg={3}
                                          md={3}
                                          sm={3}
                                          xs={6}
                                          className="col"
                                        >
                                          <label className="formlabel">
                                            CPI*
                                          </label>
                                          <input
                                            type="text"
                                            className="formtext"
                                            {...register(
                                              `opportunities[${index}].cpi`,
                                              {
                                                required:
                                                  "This field is required",
                                                pattern: {
                                                  value:
                                                    /^[+-]?([0-9]*[.])?[0-9]+/,
                                                  message:
                                                    "Enter numeric value",
                                                },
                                              }
                                            )}
                                            defaultValue={item.cpi}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </AccordionItemPanel>
                            </AccordionItem>
                          ))}
                        </Accordion>
                        <CalcBidAmt
                          control={control}
                          setValue={setValue}
                          setBookedAmt={setBookedAmt}
                          setBidAmt={setBidAmt}
                          style={{ visibility: "hidden" }}
                        />
                      </>
                    )}

                    {showOpportunity.indexOf(oppStatus) > -1 && (
                      <button
                        className="cbtn mset addBtn"
                        onClick={(e) => {
                          addLineItem(e);
                        }}
                      >
                        + Add Line Item
                      </button>
                    )}
                    {bidData.mailbox_message_id ? (
                      <button
                        className="cbtn closeBtn"
                        onClick={(e) => {
                          showMailContent(e);
                        }}
                      >
                        View Mail
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                <Row>
                  <Col lg={3} md={3} sm={6} xs={12} className="col">
                    <label className="formlabel">Bid Amount*</label>
                    <input
                      type="text"
                      className="formtext"
                      {...bidAmtReg}
                      readOnly={oppStatus === "3"}
                      onChange={(e) => {
                        bidAmtReg.onChange(e);
                        setBidAmt(e.target.value);
                      }}
                      defaultValue={bidAmt}
                    />
                    {errors.bid_amount && (
                      <small className="errmsg">
                        {errors.bid_amount.message}
                      </small>
                    )}
                  </Col>
                  {oppStatus === "3" && (
                    <>
                      <Col lg={3} md={3} sm={6} xs={12} className="col">
                        <div className="form-group">
                          <label className="formlabel">Booked Amount*</label>
                          <Controller
                            name={"booked_amount"}
                            {...register("booked_amount")}
                            control={control}
                            rules={{
                              required: "This field is required",
                              pattern: {
                                value: /^[0-9]*$/,
                                message: "Only numbers are allowed",
                              },
                            }}
                            value={bookedAmt}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="formtext"
                                value={field.value}
                                // onChange={field.onChange}
                                ref={field.ref}
                                placeholder="Booked Amount"
                                onChange={(e) => {
                                  field.onChange(e);
                                  changeBookedAmount(e, e.target.value);
                                }}
                              />
                            )}
                          />
                          {errors.booked_amount && (
                            <small className="errmsg">
                              {errors.booked_amount.message}
                            </small>
                          )}
                        </div>
                      </Col>
                      <Col lg={3} md={3} sm={6} xs={12} className="col">
                        <label className="formlabel">% of Booked Amount*</label>
                        <Controller
                          name={"bid_percent"}
                          {...register("bid_percent")}
                          control={control}
                          rules={{
                            required: "This field is required",
                          }}
                          value={bidPercentage}
                          render={({ field }) => (
                            <select
                              className="formtext"
                              value={field.value}
                              ref={field.ref}
                              onChange={(e) => {
                                field.onChange(e);
                                setBidPercentage(e.target.value);
                                calculateAmount();
                              }}
                            >
                              <BidMarginPercent />
                            </select>
                          )}
                        />
                        {errors.bid_percent && (
                          <small className="errmsg">
                            {errors.bid_percent.message}
                          </small>
                        )}
                      </Col>
                      <Col lg={3} md={3} sm={6} xs={12}>
                        <label className="formlabel">Final Amount($)</label>
                        <input
                          type="text"
                          placeholder=""
                          className="formtext"
                          readOnly
                          {...register("amount_dollar")}
                          value={dollarAmount}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                {oppStatus === "3" && (
                  <Row id="bidWon">
                    <Col lg={2} md={4} xs={12} className="col">
                      <label className="formlabel">Project Start Date</label>
                      <DatePicker
                        className="formtext"
                        {...register("project_start_date")}
                        autoComplete="off"
                        selected={projectStartDate}
                        onChange={(date) => setProjectStartDate(date)}
                      />
                      {errors.project_start_date && (
                        <small className="errmsg">
                          {errors.project_start_date.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={2} md={4} xs={12} className="col">
                      <label className="formlabel">Project End Date</label>
                      <DatePicker
                        className="formtext"
                        {...register("project_end_date")}
                        autoComplete="off"
                        selected={projectEndDate}
                        onChange={(date) => setProjectEndDate(date)}
                      />
                      {errors.project_end_date && (
                        <small className="errmsg">
                          {errors.project_end_date.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={2} md={4} xs={12} className="col">
                      <label className="formlabel">PM*</label>
                      <select
                        id="pm"
                        className="formtext"
                        {...register("pm", {
                          required: "This field is Required",
                        })}
                      >
                        <option value="">Select Option</option>
                        <option value="0">Others</option>
                        <PMList />
                      </select>
                      {errors.pm && (
                        <small className="errmsg">{errors.pm.message}</small>
                      )}
                    </Col>

                    <Col lg={6} md={12} xs={12}>
                      <label className="formlabel">Notes for PM</label>
                      <input
                        type="text"
                        placeholder="If Others,  define"
                        className="formtext"
                        {...register("pm_notes")}
                      />
                      {errors.pm_notes && (
                        <small className="errmsg">
                          {errors.pm_notes.message}
                        </small>
                      )}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <label className="formlabel">Client Bid Details</label>
                    <CKEditor
                      editor={Editor}
                      data={editorData}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setEditorData(data);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </form>
          )}
        </div>
      </div>
      <Modal isOpen={modalIsOpen}>
        <button onClick={setModalIsOpenToFalse} className="closeModal">
          x
        </button>
        <ModalHeader>
          <h5>{subject}</h5>
          <hr />
        </ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: emailCont }} />
        </ModalBody>
      </Modal>
    </>
  );
};
export default AddOpportunity;
