import { useState, useEffect, useRef } from "react";
import "./QuotaTarget.css";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
// import Select from "react-select";
import Select from "../Multiselect";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import Multiselect from "../../Multiselect/Multiselect";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { CloseButton } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
const QuotaTarget = () => {
  const [activeOption, setActiveOption] = useState([0]);
  const [currentOption, setCurrentOption] = useState();
  const [currentElement, setCurrentElement] = useState();
  const [switchDisabled, setSwitchDisabled] = useState(false);

  const [definition, setDefinition] = useState({
    0: ["+Add Definition", "", "", ""],
  });
  const [indrow, setIndRow] = useState();
  const minRef = useRef(null);
  const maxRef = useRef(null);
  const [denum, setDefNUm] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    setError,
    trigger,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      targetdetails: [
        {
          gender: "",
          age: "",
          ethnicity: "",
          hispanic: "",
          household: "",
          definition: "",
          upload: "",
        },
      ],
    },
  });
  const {
    register: register2,
    setValue,
    handleSubmit: handleSubmit2,
  } = useForm();
  const { fields, append } = useFieldArray({
    control,
    name: "targetdetails",
  });
  const formatOptionLabel = ({ label }) => <div>{label}</div>;
  const hideModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    setIsDisabled(true);
  }, []);
  const uploadZip = (e) => {
    e.preventDefault();
    var element = e.target;
    var parent = element.closest(".fileUpload");
    parent.querySelector(".fname").innerHTML = +element.files[0].name;
  };

  const addDefinition = (e, d, i) => {
    setIndRow({ id: d, name: i });

    e.preventDefault();

    setCurrentElement(e.target);
    setIsOpen(true);
  };

  const [label, setlabel] = useState({
    0: [],
  });
  const [selTargetOption, setSelTargetOption] = useState();
  const [SingleQuestion, setSingleQuestion] = useState([
    { label: "DMA", value: ["DMA1", "DMA2", "DMA3"] },
    { label: "STATE", value: ["STATE1", "STATE4", "STATE3"] },
    {
      label: "DIVISION",
      value: [
        "DIVISION1",
        "DIVISION4",
        "DIVISION3",
        "DIVISION11",
        "DIVISION41",
        "DIVISION31",
      ],
    },
    {
      label: "HOUSEHOLD DECISION MAKER",
      value: ["HOUSEHOLD4", "HOUSEHOLD64", "HOUSEHOLD3"],
    },
    { label: "MOVIES FREQUENCY", value: ["MOVIES1", "MOVIES2", "MOVIES3"] },
    { label: "AGE AND GENDER OF CHILD", value: ["AGe1", "AGe2", "AGe3"] },
  ]);

  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const ethnicity = [
    { label: "White", value: "1" },
    { label: "Black or African American", value: "2" },
    { label: "Asian", value: "3" },
    { label: "Native Hawaiian or Other Pacific Islander", value: "4" },
    { label: "American Indian or Alaska Native", value: "5" },
    { label: "Other Race", value: "6" },
  ];

  const hispanic = [
    { label: "Mexican", value: "1" },
    { label: "Puerto Ricans", value: "2" },
    { label: "Cubans", value: "3" },
    { label: "Colombians", value: "4" },
    { label: "Dominicans", value: "5" },
    { label: "Other", value: "6" },
  ];

  const hincome = [
    { label: "Less than $15000", value: "1" },
    { label: "$15,000 - $24,999", value: "2" },
    { label: "$24,999 - $49,999", value: "3" },
    { label: "$50,000 - $74,999", value: "4" },
    { label: "$75,000 - $99,999", value: "5" },
    { label: "Greater than $1,00,000", value: "6" },
  ];

  const [questionChild, setQuestionChild] = useState([]);
  useEffect(() => {
    setValue("definationName", label);
  }, [label]);

  function handleSelect(data) {
    setSelTargetOption(data);
  }

  // ************** function that doing form validation for particular index *******************
  const onSubmit = async (formData, formIndex) => {
    const isEmptyForm = Object.values(formData).every((value) => !value);
    const { min, max } = formData.age;
    if (min !== "" && max !== "" && parseInt(min) >= parseInt(max)) {
      setError(`targetdetails[${formIndex}].age`, {
        type: "manual",
        message: "Minimum value must be less than maximum value",
      });
      return;
    }

    if (isEmptyForm) {
      try {
        const errors = await trigger(`targetdetails[${formIndex}]`);
        if (Object.keys(errors).length === 0) {
          await handleSubmit((data) => "")();
        } else {
          setError(`targetdetails[${formIndex}].gender`, {
            type: "manual",
            message: "This field is required",
          });
          setError(`targetdetails[${formIndex}].age`, {
            type: "manual",
            message: "This field is required",
            validate: {
              minMaxCheck: (value) => {
                const { min, max } = value || {
                  min: "",
                  max: "",
                };
                if (min === "" || max === "") {
                  return true;
                }
                return parseInt(min) < parseInt(max);
              },
            },
          });
          setError(`targetdetails[${formIndex}].ethnicity`, {
            type: "manual",
            message: "This field is required",
          });
          setError(`targetdetails[${formIndex}].household`, {
            type: "manual",
            message: "This field is required",
          });
        }
      } catch (error) {}
    } else {
      const result = await trigger(`targetdetails[${formIndex}]`);
      if (Object.keys(result).length === 0 && result === true) {
        handleFormPost(formData, formIndex);
      } else {
      }
    }
  };
  // ************** function that doing form validation for particular index *******************

  // *************** function that giving the form data *************
  const handleFormPost = (fm, fi) => {
    setSwitchDisabled((prevState) => {
      const switchState = { ...prevState };
      switchState[fi] = true;
      return switchState;
    });
  };

  // *************** function that giving the form data *************

  // *************** function that handling clone and new functionality ******************
  const ClonelineItem = (e, indexnum, type) => {
    let numk = indexnum + 1;
    if (type === "clone") {
      setlabel((prevState) => {
        const label = { ...prevState };
        label[numk] = Array.isArray(prevState[indexnum])
          ? [...prevState[indexnum]]
          : prevState[indexnum];
        return label;
      });

      setDefinition((prevState) => {
        const objLength = Object.keys(definition).length;
        const newDefinition = { ...prevState };
        newDefinition[objLength] = [...prevState[indexnum]];
        return newDefinition;
      });

      setSwitchDisabled((prevState) => {
        const switchState = { ...prevState };
        switchState[numk] = false;
        return switchState;
      });

      const dataclone = activeOption.filter((eid, index) => index === indexnum);
      setActiveOption((prev) => [...prev, dataclone[0]]);
    } else if (type === "new") {
      setSwitchDisabled((prevState) => {
        const switchState = { ...prevState };
        switchState[numk] = false;
        return switchState;
      });

      setDefinition((prevState) => {
        const objLength = Object.keys(definition).length;
        const newDefinition = { ...prevState };
        newDefinition[objLength] = ["+Add Definition", "", "", ""];
        return newDefinition;
      });

      setlabel((prevState) => {
        const label = { ...prevState };
        label[numk] = [];
        return label;
      });

      setActiveOption((prevData) => {
        const objLength = Object.keys(definition).length;
        const newData = [...prevData];

        newData.splice(objLength + 1, 0, 0);
        return newData;
      });
    }

    e.preventDefault();
    if (type === "new") {
      append({
        gender: "",
        age: "",
        ethnicity: "",
        hispanic: "",
        household: "",
        definition: "",
        answer: "",
        upload: "",
        status: "",
      });
    } else if (type === "clone") {
      let index = parseInt(indexnum);
      let gender = getValues("targetdetails[" + index + "].gender");
      let age = getValues("targetdetails[" + index + "].age");
      let ethnicity = getValues("targetdetails[" + index + "].ethnicity");
      let hispanic = getValues("targetdetails[" + index + "].hispanic");
      let household = getValues("targetdetails[" + index + "].household");
      let definition = getValues("definition[" + index + "].definationname");
      let answer = getValues("definition[" + index + "].answer");
      let upload = getValues("targetdetails[" + index + "].upload");
      append(
        {
          gender: gender,
          age: age,
          ethnicity: ethnicity,
          hispanic: hispanic,
          household: household,
          definition: definition,
          answer: answer,
          upload: upload,
          status: "",
        },
        {
          shouldUnregister: true,
        }
      );
    }
    setDefNUm(numk);
  };
  // *************** function that handling clone and new functionalit ******************

  // ************ function that handling the single select in add Definition *****************************
  const handleFunction = (data, label) => {
    setSelTargetOption([]);
    const clientdata = data.map((item) =>
      Object.assign({
        value: item,
        label: item,
      })
    );
    setlabel((prevState) => {
      const updatedLabel = { ...prevState };
      updatedLabel[indrow.id] = updatedLabel[indrow.id] || [];
      updatedLabel[indrow.id][indrow.name] = label;
      return updatedLabel;
    });
    setQuestionChild(clientdata);
    setIsDisabled(false);
  };
  // ************ function that handling the single select in add Definition *****************************

  // **************** function that handling add Definition On submit Form ********************
  const onSubmitTarget = (data) => {
    let activeopt = parseInt(indrow.name) + 1;
    setActiveOption((prevData) => {
      const newData = [...prevData];
      newData[indrow.id] = activeopt;
      return newData;
    });
    setCurrentOption("TARGET " + activeopt);
    currentElement.value = currentOption;
    if (Array.isArray(data.definationName[indrow.id])) {
      data.definationName[indrow.id].map((e, index) => {
        if (index === indrow.name) {
          setDefinition((prevState) => {
            const definition = { ...prevState };
            if (definition[indrow.id].length - 1 === index) {
              definition[indrow.id].push("");
            }
            definition[indrow.id][indrow.name] = e;
            return definition;
          });
        }
      });
    }
    hideModal();
  };
  // **************** function that handling add Definition On submit Form ********************

  // ******************** function that handling delete of Add Definition ******************
  const deleteAdddefinition = (index, indexitem) => {
    setDefinition((prevDefinition) => {
      const newDefinition = { ...prevDefinition };
      const itemArray = [...newDefinition[indexitem]];
      itemArray[index] = "";
      for (let i = index; i < itemArray.length - 1; i++) {
        itemArray[i] = itemArray[i + 1];
      }
      itemArray[itemArray.length - 1] = "";
      newDefinition[indexitem] = itemArray;
      return newDefinition;
    });
  };
  // ******************** function that handling delete of Add Definition ******************

  // ********************** function that handles the hide and show of target Detail *******************
  const handleShowSect = (id) => {
    var element = document.getElementById("TarfirstSect" + id);
    let iconShow = document.getElementById("TariconSect" + id);

    if (element.matches(".open")) {
      element.classList.remove("open");
      iconShow.classList.remove("open");
    } else {
      element.classList.add("open");
      iconShow.classList.add("open");
    }
  };
  // ********************** function that handles the hide and show of target Detail *******************

  const ValueContainer = ({ children, ...props }) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
      const plural = values.length === 1 ? "" : "s";
      values = `${values.length} item${plural} selected`;
    }

    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    );
  };

  return (
    <Row>
      <>
        <div className="quotaMain  ">
          <Accordion style={{ marginTop: "10px" }} preExpanded={[0]}>
            {fields.map((itemdetail, indexitem) => (
              <AccordionItem key={indexitem} id={indexitem} uuid={indexitem}>
                <form>
                  <AccordionItemHeading
                    onClick={() => {
                      handleShowSect(indexitem);
                    }}
                  >
                    <AccordionItemButton>
                      <div className="line_item" style={{ width: "100%" }}>
                        <Row
                          onClick={() => {
                            setIndRow({ id: denum, name: "" });
                          }}
                        >
                          <Col lg={5} md={5} sm={12} xs={12} className="col">
                            <div className="itemWrap">
                              <label className="formlabel">
                                Target Details
                              </label>
                              <input
                                type="text"
                                placeholder="US Gen pop 18"
                                className="formtext"
                                defaultValue={itemdetail.Target}
                              />
                            </div>
                          </Col>
                          <Col
                            lg={7}
                            md={7}
                            sm={12}
                            xs={12}
                            className="col "
                            style={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <div
                              className="pswtchbtn targrt_swit_btn"
                              style={{
                                marginRight: "10px",
                                marginTop: "0px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <button
                                className="new "
                                type="button"
                                onClick={(e) =>
                                  ClonelineItem(e, indexitem, "new")
                                }
                              >
                                New
                              </button>
                              <button
                                className="tClose "
                                type="button"
                                onClick={(e) => {
                                  ClonelineItem(e, indexitem, "clone");
                                }}
                              >
                                {" "}
                                Clone
                              </button>
                              &nbsp; &nbsp;
                              <button
                                key={indexitem}
                                className="PSaveBtnA"
                                type="button"
                                onClick={(e) => {
                                  onSubmit(
                                    getValues(`targetdetails[${indexitem}]`),
                                    indexitem
                                  );
                                  e.stopPropagation();
                                }}
                              >
                                Save
                              </button>
                              {switchDisabled[indexitem] === true &&
                              switchDisabled[indexitem] !== "undefined" ? (
                                <>
                                  <BootstrapSwitchButton
                                    className="switch btn on btn-outline-success btn-xs"
                                    size="xs"
                                    width={60}
                                    onlabel="ON"
                                    offlabel="OFF"
                                    onstyle="outline-success"
                                    offstyle="outline-danger"
                                    checked={true}
                                  />
                                </>
                              ) : (
                                <>
                                  <BootstrapSwitchButton
                                    className="switch btn on btn-outline-success btn-xs"
                                    size="xs"
                                    width={60}
                                    onlabel="ON"
                                    offlabel="OFF"
                                    onstyle="outline-success"
                                    offstyle="outline-danger"
                                    checked={false}
                                    disabled={switchDisabled[indexitem]} // Use the switchDisabled state for the disabled prop
                                    onChange={(e) => e.preventDefault()}
                                  />
                                </>
                              )}
                            </div>
                            <div
                              id={"TariconSect" + indexitem}
                              className="target_arrow"
                            >
                              <KeyboardArrowUpIcon className="T_IconUp" />

                              <KeyboardArrowDownIcon className="T_DropIcon" />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div
                      className="extra_client_input_header_quota_target Target_panel"
                      key={indexitem}
                      id={"TarfirstSect" + indexitem}
                    >
                      <Row className="row_manual">
                        <Col
                          lg={3}
                          md={3}
                          sm={6}
                          xs={12}
                          className="col target_select"
                        >
                          <label className="formlabel">Gender*</label>
                          <Controller
                            name={`targetdetails[${indexitem}].gender`}
                            control={control}
                            rules={{ required: "This field is required" }}
                            render={({ field }) => (
                              <div className="">
                                <Multiselect
                                  hideSelectedOptions={false}
                                  closeMenuOnSelect={false}
                                  value={field.value}
                                  onChange={field.onChange}
                                  options={genderOptions}
                                  isMulti={true}
                                  isClearable={true}
                                  isSearchable={false}
                                  allowSelectAll={true}
                                  onMenuOpen={true}
                                />

                                {errors?.targetdetails?.[indexitem]?.gender && (
                                  <small className="errmsg">
                                    {
                                      errors.targetdetails[indexitem].gender
                                        .message
                                    }
                                  </small>
                                )}
                              </div>
                            )}
                          />
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={12} className="col ">
                          <label className="formlabel">Age*</label>
                          <Controller
                            name={`targetdetails[${indexitem}].age`}
                            control={control}
                            rules={{
                              required: "This field is Required",
                              validate: {
                                minMaxCheck: (value) => {
                                  const { min, max } = value || {
                                    min: "",
                                    max: "",
                                  };
                                  if (min === "" || max === "") {
                                    return true; // will be handled by the 'required' rule
                                  }
                                  return parseInt(min) < parseInt(max);
                                },
                              },
                            }}
                            render={({ field }) => {
                              const { min, max } = field.value || {
                                min: "",
                                max: "",
                              };
                              return (
                                <div>
                                  <InputGroup
                                    className="mb-3"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Form.Control
                                      id={`min${indexitem}`}
                                      defaultValue={min}
                                      ref={minRef}
                                      aria-label="Min"
                                      onChange={(e) =>
                                        field.onChange({
                                          ...field.value,
                                          min: e.target.value,
                                        })
                                      }
                                    />
                                    <InputGroup.Text>To</InputGroup.Text>
                                    <Form.Control
                                      id={`max${indexitem}`}
                                      defaultValue={max}
                                      ref={maxRef}
                                      aria-label="Max"
                                      onChange={(e) =>
                                        field.onChange({
                                          ...field.value,
                                          max: e.target.value,
                                        })
                                      }
                                    />
                                  </InputGroup>
                                  {errors?.targetdetails?.[indexitem]?.age && (
                                    <small className="errmsg">
                                      {
                                        errors.targetdetails[indexitem].age
                                          .message
                                      }
                                    </small>
                                  )}
                                  {errors?.targetdetails?.[indexitem]?.age
                                    ?.type === "minMaxCheck" && (
                                    <small className="errmsg">
                                      Minimum value must be less than maximum
                                      value
                                    </small>
                                  )}
                                </div>
                              );
                            }}
                          />
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={12} className="col">
                          <label className="formlabel">Ethnicity*</label>
                          <Controller
                            name={`targetdetails[${indexitem}].ethnicity`}
                            control={control}
                            rules={{ required: "This field is required" }}
                            render={({ field }) => (
                              <div className="target_select">
                                <Multiselect
                                  hideSelectedOptions={false}
                                  closeMenuOnSelect={false}
                                  value={field.value}
                                  onChange={field.onChange}
                                  options={ethnicity}
                                  isMulti
                                  isClearable
                                  components={{ ValueContainer }}
                                  allowSelectAll={true}
                                  isSearchable={false}
                                />
                                {errors?.targetdetails?.[indexitem]
                                  ?.ethnicity && (
                                  <small className="errmsg">
                                    {
                                      errors.targetdetails[indexitem].ethnicity
                                        .message
                                    }
                                  </small>
                                )}
                              </div>
                            )}
                          />
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={12} className="col">
                          <label className="formlabel">Hispanic Origin*</label>
                          <Controller
                            name={`targetdetails[${indexitem}].hispanic`}
                            control={control}
                            rules={{ required: "This field is required" }}
                            render={({ field }) => (
                              <div>
                                <Multiselect
                                  components={{ ValueContainer }}
                                  hideSelectedOptions={false}
                                  closeMenuOnSelect={false}
                                  value={field.value}
                                  onChange={field.onChange}
                                  options={hispanic}
                                  isMulti
                                  isClearable
                                  isSearchable={false}
                                  allowSelectAll={true}
                                />
                                {errors?.targetdetails?.[indexitem]
                                  ?.hispanic && (
                                  <small className="errmsg">
                                    {
                                      errors.targetdetails[indexitem].hispanic
                                        .message
                                    }
                                  </small>
                                )}
                              </div>
                            )}
                          />
                        </Col>

                        <Col lg={3} md={3} sm={6} xs={12} className="col">
                          <label className="formlabel">Household Income*</label>
                          <Controller
                            name={`targetdetails[${indexitem}].household`}
                            control={control}
                            rules={{ required: "This field is required" }}
                            render={({ field }) => (
                              <div>
                                <Multiselect
                                  hideSelectedOptions={false}
                                  closeMenuOnSelect={false}
                                  value={field.value}
                                  onChange={field.onChange}
                                  options={hincome}
                                  isMulti
                                  isClearable
                                  allowSelectAll={true}
                                />
                                {errors?.targetdetails?.[indexitem]
                                  ?.household && (
                                  <small className="errmsg">
                                    {
                                      errors.targetdetails[indexitem].household
                                        .message
                                    }
                                  </small>
                                )}
                              </div>
                            )}
                          />
                        </Col>
                        <Col
                          lg={9}
                          md={9}
                          sm={6}
                          xs={12}
                          className="col fileUpload"
                        >
                          <label class="custom-file-upload">
                            <input
                              type="file"
                              onChange={(e) => {
                                uploadZip(e);
                              }}
                              accept=".xlsx,.xls,.doc, .docx,.txt,.pdf"
                            />
                            Upload Zipcode
                          </label>{" "}
                          <span class="fname">No file Choosen</span>
                        </Col>
                      </Row>
                      <div
                        style={{
                          borderTop: "1px solid #eee ",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      ></div>
                      <Row>
                        {definition[indexitem] &&
                          definition[indexitem].map((item, index) => (
                            <Col
                              lg={3}
                              md={3}
                              sm={6}
                              xs={12}
                              className="col"
                              key={index}
                            >
                              <div className="input-container">
                                <input
                                  {...register(
                                    `targetdetails.${indexitem}.definition`
                                  )}
                                  className={`addDefinition ${
                                    index > activeOption[0] ? "disabled" : ""
                                  }`}
                                  type="text"
                                  placeholder={
                                    item ? item : "+ Add Definition +"
                                  }
                                  id={`ad_${denum}_ + ${index}`}
                                  onClick={(e) => {
                                    addDefinition(e, indexitem, index);
                                  }}
                                  autoComplete="Off"
                                  readOnly
                                  disabled={index > activeOption[indexitem]}
                                />
                                {item && item !== "+Add Definition" && (
                                  <AiFillDelete
                                    className="delete-icon"
                                    onClick={() => {
                                      deleteAdddefinition(index, indexitem);
                                    }}
                                  />
                                )}
                              </div>
                            </Col>
                          ))}
                      </Row>
                    </div>
                  </AccordionItemPanel>
                </form>
              </AccordionItem>
            ))}
          </Accordion>
        </div>

        <Modal show={isOpen} className="targetMain">
          <ModalHeader>
            <ModalTitle
              style={{
                fontSize: "14px",
              }}
            >
              Question Description
            </ModalTitle>
            <CloseButton
              onClick={(e) => {
                hideModal();
              }}
            />
          </ModalHeader>
          <>
            <form onSubmit={handleSubmit2(onSubmitTarget)}>
              <ModalBody>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <CreatableSelect
                      className="formtext"
                      id="targetmain"
                      styles={{ fontSize: "14px" }}
                      isClearable
                      options={SingleQuestion}
                      {...register2("definationName")}
                      onChange={(e) => handleFunction(e.value, e.label)}
                    />
                  </Col>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      fontSize: "13px",
                      height: "40px",
                      marginTop: "25px",
                      textAlign: "center",
                    }}
                  >
                    I'm looking for people{" "}
                    <span className="qCategoryText"></span>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Multiselect
                      {...register2("answerName")}
                      closeMenuOnSelect={false}
                      components={{ ValueContainer }}
                      hideSelectedOptions={false}
                      value={selTargetOption}
                      onChange={handleSelect}
                      options={questionChild}
                      isMulti
                      isClearable
                      isSearchable
                      allowSelectAll={true}
                      isDisabled={isDisabled}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <button className="targetSubmit" type="submit">
                  Apply Targetting
                </button>
              </ModalFooter>
            </form>
          </>
        </Modal>
      </>
    </Row>
  );
};

export default QuotaTarget;
