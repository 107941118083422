import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "react-circular-progressbar/dist/styles.css";

const PercentageSpinner = (props) => {
  const percentage = props.percent;
  const role = props.type;
  var pathColour = "grey";

  switch (role) {
    case "Bidding":
      pathColour = "yellow";
      break;
    case "New":
      pathColour = "purple";
      break;
    case "Pass":
      pathColour = "orange";
      break;
    case "Bid Won":
      pathColour = "green";
      break;
    case "Cancel":
      pathColour = "red";
      break;
    case "Lost":
      pathColour = "turquoise";
      break;
    default:
      pathColour = "grey";
      break;
  }

  const options = {
    textColor: "#555",
    pathColor: pathColour,
    trailColor: "#ddd",
    textSize: "15px",
  };
  return (
    <div style={{ width: 50, height: 50 }}>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles(options)}
      />
    </div>
  );
};

export default PercentageSpinner;
