import AttachmentIcon from "../../../assets/BidsIcons/link.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DotMenu from "../../../assets/images/dot-menu.png";

import ReplyBlock from "./ReplyBlock";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import moment from "moment";

import JsonIcon from "../../../assets/images/image icon.svg";
import PdfIcon from "../../../assets/images/pdf.png";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "../../../assets/LoginIcons/delete.svg";
import SpinnerLoader from "../../UI/Spinner";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { createRef, useEffect, useState } from "react";
import { BASE_URL } from "../../../api/axios";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const MailItem = (props) => {
  const [emilThreadList, setEmilThreadList] = useState();
  const [fileExte, setFileExte] = useState([]);
  const [Filetext, setFiletext] = useState([]);
  const [attachments, setattachments] = useState([]);
  const [fromHeader, SetFromHeader] = useState([]);
  const [bcc, setBcc] = useState();
  const [cc, setCC] = useState([]);
  const [messageId, setMessageId] = useState();
  const [subject, setSubject] = useState();
  const [uid, setUID] = useState();
  const [r, setR] = useState();
  const [id, setId] = useState();
  const [body, setbody] = useState();
  const [replyFocus, setReplyFocus] = useState(false);

  const [mainReplyTab, setMailnReplyTab] = useState(false);
  const [replySimple, setReplySimple] = useState(false);
  const [allccshow, setAllccshow] = useState([]);
  const [allbcc, setAllbcc] = useState([]);

  const [isHeaderOpen, setIsHeaderOpen] = useState(false);

  const createMail = createRef();

  let documentArray = ["pdf", "xlsx", "txt", "json", "jsx"];
  let imageArray = ["png", "jpeg", "gif", "JPG", "jpg", "GIF", "PNG"];
  const { auth } = useAuth();

  // ..........................permisssion.........
  const superUser = auth.superuser;
  const all_permission = auth.permission;
  const axiosPrivate = useAxiosPrivate();

  moment.locale("en");

  const handleClick = (eid) => {
    var element = document.getElementById("item" + eid);
    if (element.matches(".open")) {
      element.classList.remove("open");
      handleEmailThread(eid);
    } else {
      element.classList.add("open");
      handleEmailThread(eid);
    }
  };

  const emailItem = props.data;

  useEffect(() => {
    if (emailItem.cc != null) {
      var commasplit = emailItem.cc.split(",");
      var data = [];

      for (let i = 0; i < commasplit.length; i++) {
        var splitbyspace = commasplit[i].split(" ");
        if (splitbyspace[0] == "") {
          data.push(splitbyspace[1]);
        } else {
          data.push(splitbyspace[0]);
        }
      }
      var storeattherate = [];
      for (let i = 0; i < data.length; i++) {
        var sliceforattherate = data[i].split("@");
        storeattherate.push(sliceforattherate[0]);
      }
      var finalcc = [];
      for (let i = 0; i < storeattherate.length; i++) {
        let result = storeattherate[i].replace("\r\n\t", "");
        finalcc.push(result);
      }
      setAllccshow(finalcc);
    }

    if (emailItem.bcc != null) {
      var commabcc = [];
      var commarbcc = emailItem.bcc.split(",");
      commabcc.push(commarbcc);

      var ratestore = [];
      for (let i = 0; i < commarbcc.length; i++) {
        var ratesplit = commarbcc[i].split("@");
        ratestore.push(ratesplit[0]);
      }

      var finalbcc = [];
      for (let i = 0; i < ratestore.length; i++) {
        let result = ratestore[i].replace("\r\n\t", "");
        finalbcc.push(result);
      }

      setAllbcc(finalbcc);
    }
  }, []);

  useEffect(() => {
    handleEmailThread(emailItem.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmailThread = async (id) => {
    try {
      const response = await axiosPrivate.get(
        `/mailbox/message-detail/${id}`,
        {}
      );

      var objList = response.data.body_html;

      setEmilThreadList(objList);

      if (response.data.attachments !== null) {
        setattachments(response.data.attachments);
      }
    } catch (err) {}
  };

  function openReplyBlock(eid, l) {
    var element = document.getElementById("reply" + eid);
    element.classList.add("show");
    props.setReplyTab(false);

    const getEmail = async (eid) => {
      if (l !== 3) {
        try {
          const response = await axiosPrivate.get(
            `mailbox/initialize-reply-email/${eid}`,
            {}
          );
          setMessageId(response.data.msg_detail.message_id);
          setUID(response.data.msg_detail.uid);
          setbody(response.data.body_html);
          if (l === 2) {
            let dataTo = response.data.reply_to_all.to.split(",");
            SetFromHeader([]);

            dataTo.map((ele) => SetFromHeader((val) => [...val, ele]));

            if (response.data.reply_to_all.cc !== "") {
              // let datacc = response.data.reply_to_all.cc.split(",");
              const newString = response.data.reply_to_all.cc.replace(/^,/, "");
              let datacc = newString.split(",");
              setCC([]);
              datacc.map((ele) => setCC((val) => [...val, ele]));
            }
          } else {
            let dataTo = response.data.reply_to.to.split(",");

            SetFromHeader([]);

            dataTo.map((ele) => SetFromHeader((val) => [...val, ele]));

            if (response.data.msg_detail.cc !== "") {
              // let datacc = response.data.msg_detail.cc.split(",");
              const newString = response.data.msg_detail.cc.replace(/^,/, "");
              let datacc = newString.split(",");
              setCC([]);
              datacc.map((ele) => setCC((val) => [...val, ele]));
            }
          }

          if (response.data.msg_detail.subject) {
            setSubject(response.data.msg_detail.subject);
          }
        } catch (err) {}
      } else {
        try {
          const response = await axiosPrivate.get(
            `mailbox/initialize-forward-email/${eid}`,
            {}
          );
          setUID(response.data.msg_detail.uid);
          setId(response.data.msg_detail._id);
          setbody(response.data.body_html);

          if (response.data.msg_detail.subject) {
            setSubject(response.data.msg_detail.subject);
          }
          setMessageId(response.data.msg_detail.message_id);
        } catch (err) {}
      }
    };
    getEmail(eid);
  }
  const hideBlock = (eid) => {
    var element = document.getElementById(eid);
    element.classList.remove("show");
  };

  useEffect(() => {
    let extension;

    extension = attachments.map((em) =>
      em
        .split(".")
        .filter(
          (m) =>
            documentArray.includes(m.toLowerCase()) ||
            imageArray.includes(m.toLowerCase())
        )
    );
    setFiletext(extension);
    if (extension) {
      for (let i = 0; i < extension.length; i++) {
        if (imageArray.includes(extension[i].toString())) {
          setFileExte((oldArray) => [...oldArray, JsonIcon]);
        } else {
          setFileExte((oldArray) => [...oldArray, PdfIcon]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  useEffect(() => {
    if (mainReplyTab === true) {
      props.setReplyTab(true);
      setMailnReplyTab(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainReplyTab]);

  const getTrimmedContent = (content, position) => {
    if (content) {
      let [first, ...rest] = content.split(
        '<div dir="ltr" class="gmail_attr">'
      );

      if (position === 0) {
        return first;
      } else if (position === 1) {
        return rest;
      } else if (position === 3) {
        return rest;
      } else {
        return content;
      }
    }
  };

  const expandContent = (e) => {
    e.preventDefault();
    var element = e.target;
    var parent = element.closest(".mail-content-body");
    var target = parent.querySelector(".trimmedContent");
    target.classList.toggle("show");
  };
  useEffect(() => {
    const closeHeaderPopup = (e) => {
      setIsHeaderOpen(false);
    };
    document.body.addEventListener("click", closeHeaderPopup);
    return () => document.body.removeEventListener("click", closeHeaderPopup);
  }, []);

  const toggleHeaderPopup = (e) => {
    e.stopPropagation();
    setIsHeaderOpen((prev) => !prev);
  };

  return (
    <div className={"mail-item open"} id={"item" + emailItem.id}>
      <>
        <div className="mail-content-header">
          <div className="headerTop">
            <div
              className="mail-header-left"
              onClick={(e) => {
                handleClick(emailItem.id);
              }}
            >
              <>
                <span className="mailInitial">
                  {emailItem.from_header.split("<")[0].split(" ")[0][0]}
                </span>
                <h4>{emailItem.from_header}</h4>
                <p className="toemail">{emailItem.to_header}</p>
                {allccshow.map((e) => (
                  // <p>{e}</p>
                  <p className="toemail">,{e}</p>
                ))}
                {allbcc.map((end) => (
                  <p className="toemail">,{end}</p>
                ))}
                <div className="headerPopupContainer">
                  <button
                    className="togglePopupBtn"
                    id="tpb"
                    onClick={(e) => {
                      toggleHeaderPopup(e);
                    }}
                  >
                    <ArrowDropDownIcon />
                  </button>
                  <div
                    className={"headerPopup " + (isHeaderOpen ? "open" : "")}
                    id="headerPopup"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <table cellpadding="0" className="headerDetailsTable">
                      <tbody>
                        <tr>
                          <td tabindex="0" class="gG">
                            from:
                          </td>
                          <td>
                            <span> {emailItem.from_header}</span>
                          </td>
                        </tr>
                        <tr>
                          <td tabindex="0" class="gG">
                            to:
                          </td>
                          <td>
                            <span> {emailItem.to_header}</span>
                          </td>
                        </tr>
                        {emailItem.cc !== null ? (
                          <>
                            <tr>
                              <td tabindex="0" class="gG">
                                cc:
                              </td>
                              <td>
                                <span> {emailItem.cc}</span>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}

                        {emailItem.from_header.split("<")[0].split(" ")[0] ===
                          "Purchase" && emailItem.bcc !== null ? (
                          <>
                            <tr>
                              <td tabindex="0" class="gG">
                                bcc:
                              </td>
                              <td>
                                <span> {emailItem.bcc}</span>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td tabindex="0" class="gG">
                            date:
                          </td>
                          <td>
                            <span>{emailItem.email_date}</span>
                          </td>
                        </tr>
                        <tr>
                          <td tabindex="0" class="gG">
                            subject:
                          </td>
                          <td>
                            <span>{emailItem.subject}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            </div>
            <div className="mail-header-right">
              <>
                {emailItem.attachments !== null && (
                  <span className="attachment">
                    <img src={AttachmentIcon} alt="attachment" />
                  </span>
                )}
                <span className="mail-date lg">
                  {moment(emailItem.email_date).format("Do MMM YYYY, h:mm a")}
                </span>
                <span className="mail-date sm">
                  {moment(emailItem.email_date).format("DD MMM")}
                </span>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={DotMenu} height="18" alt="menu" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {superUser === true ? (
                      <>
                        <Dropdown.Item
                          onClick={(e) => {
                            openReplyBlock(emailItem.id, 1);
                            setR(1);
                            setReplyFocus(true);
                            props.setReplyBox(false);
                            setReplySimple(true);
                          }}
                        >
                          Reply
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => {
                            openReplyBlock(emailItem.id, 2);
                            setR(2);
                            setReplyFocus(true);
                            props.setReplyBox(false);
                            setReplySimple(true);
                          }}
                        >
                          Reply All
                        </Dropdown.Item>
                      </>
                    ) : (
                      <>
                        {all_permission.includes(
                          "mailbox.mailbox_reply_email"
                        ) ? (
                          <>
                            <Dropdown.Item
                              onClick={(e) => {
                                openReplyBlock(emailItem.id, 1);
                                setR(1);
                                setReplyFocus(true);
                                props.setReplyBox(false);
                                setReplySimple(true);
                              }}
                            >
                              Reply
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                openReplyBlock(emailItem.id, 2);
                                setR(2);
                                setReplyFocus(true);
                                props.setReplyBox(false);
                                setReplySimple(true);
                              }}
                            >
                              Reply All
                            </Dropdown.Item>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    {superUser === true ? (
                      <>
                        <Dropdown.Item
                          onClick={(e) => {
                            openReplyBlock(emailItem.id, 3);
                            setR(3);
                            setReplyFocus(true);
                            props.setReplyBox(false);
                            setReplySimple(true);
                          }}
                        >
                          Forward
                        </Dropdown.Item>
                      </>
                    ) : (
                      <>
                        {all_permission.includes(
                          "mailbox.mailbox_forward_email"
                        ) ? (
                          <>
                            <Dropdown.Item
                              onClick={(e) => {
                                openReplyBlock(emailItem.id, 3);
                                setR(3);
                                setReplyFocus(true);
                                props.setReplyBox(false);
                                setReplySimple(true);
                              }}
                            >
                              Forward
                            </Dropdown.Item>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            </div>
          </div>
          <div className="headerBottom">
            <p className="content-summary">
              {emailItem.body_text.replace(/<[^>]+>/g, "")}
            </p>
          </div>
        </div>
        {emilThreadList ? (
          <>
            <div className="mail-content-body">
              <div
                ref={createMail}
                dangerouslySetInnerHTML={{
                  __html: getTrimmedContent(emilThreadList, 0),
                }}
              />
              {getTrimmedContent(emilThreadList, 3) &&
                getTrimmedContent(emilThreadList, 3).length > 0 && (
                  <div>
                    <span
                      className="expand-mail-button"
                      onClick={(e) => {
                        expandContent(e);
                      }}
                      title="Show trimmed content"
                    >
                      ...
                    </span>
                  </div>
                )}

              <div
                className="trimmedContent show"
                ref={createMail}
                dangerouslySetInnerHTML={{
                  __html: getTrimmedContent(emilThreadList, 1),
                }}
              />
              <div className="mail-attachment">
                {superUser === true ? (
                  <>
                    {emailItem.attachments !== null &&
                      emailItem.attachments.map((attachment, i) => (
                        <a
                          href={
                            BASE_URL +
                            `/mailbox/download-attachment/${emailItem.mailbox_id}/${emailItem.uid}/${attachment}`
                          }
                        >
                          <div className="attachmentbox">
                            <Tooltip
                              className="tooltipattach"
                              placement="bottom"
                              title={<>{attachment}</>}
                              arrow
                            >
                              <img
                                src={fileExte[i]}
                                height={60}
                                alt="attachment"
                                style={{
                                  border: "1px solid lightgrey",
                                }}
                                onClick={() => {
                                  <></>;
                                }}
                              />
                            </Tooltip>
                          </div>
                        </a>
                      ))}
                  </>
                ) : (
                  <>
                    {all_permission.includes(
                      "mailbox.mailbox_download_attachment"
                    ) ? (
                      <>
                        {emailItem.attachments !== null &&
                          emailItem.attachments.map((attachment, i) => (
                            <a
                              href={
                                BASE_URL +
                                `/mailbox/download-attachment/${emailItem.mailbox_id}/${emailItem.uid}/${attachment}`
                              }
                            >
                              <div className="attachmentbox">
                                <Tooltip
                                  className="tooltipattach"
                                  placement="bottom"
                                  title={<>{attachment}</>}
                                  arrow
                                >
                                  <img
                                    src={fileExte[i]}
                                    height={70}
                                    alt="attachment"
                                    style={{
                                      border: "1px solid lightgrey",
                                    }}
                                    onClick={() => {
                                      <></>;
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </a>
                          ))}
                      </>
                    ) : (
                      <>
                        {emailItem.attachments !== null &&
                          emailItem.attachments.map((attachment, i) => (
                            <div className="attachmentbox">
                              <Tooltip
                                className="tooltipattach"
                                placement="bottom"
                                title={<>{attachment}</>}
                                arrow
                              >
                                <img
                                  src={fileExte[i]}
                                  height={60}
                                  alt="attachment"
                                  style={{
                                    border: "1px solid lightgrey",
                                  }}
                                />
                              </Tooltip>
                            </div>
                          ))}
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="replyBlockContainer" id={"reply" + emailItem.id}>
                {replySimple ? (
                  <>
                    <ReplyBlock
                      fromHeader={fromHeader}
                      uid={uid}
                      messageId={messageId}
                      subject={subject}
                      cc={cc}
                      bcc={bcc}
                      r={r}
                      id={id}
                      attachments={attachments}
                      body={body}
                      setReplyFocus={setReplyFocus}
                      replyFocus={replyFocus}
                      setMailnReplyTab={setMailnReplyTab}
                      setReplySimple={setReplySimple}
                      replySimple={replySimple}
                    />
                    <span
                      className="Close"
                      onClick={(e) => {
                        hideBlock("reply" + emailItem.id);
                        props.setReplyTab(true);
                        setReplyFocus(false);
                      }}
                    >
                      <img src={DeleteIcon} alt="Delete" height={20} />
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <SpinnerLoader />
          </>
        )}
      </>
    </div>
  );
};

export default MailItem;
