import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./StyleDatatable.css";
import Header from "../../UI/Header/Header";
import addIcon from "../../../assets/LoginIcons/add.svg";
import userIco from "../../../assets/LoginIcons/users.svg";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import EditIcon from "../../../assets/LoginIcons/edit.svg";
import DeleteIcon from "../../../assets/LoginIcons/delete.svg";
import SpinnerLoader from "../../UI/Spinner";
import InsufficientPermisiion from "../User/InsufficiantPermission";
import { useNavigate } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import toast from "react-hot-toast";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
const DepartmentListing = () => {
  const navigate = useNavigate();
  const [pending, setPending] = useState(true);
  const [filterUser, setFilterUser] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const [search, setSearch] = useState("");
  const [getDepartments, setGetDepartments] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const [permission, setPermission] = useState("");
  const [dataLoader, setDataLoader] = useState(true);
  // ..........................permisssion.........
  const { auth } = useAuth();

  const superUser = auth.superuser;
  const all_permission = auth.permission;

  // all_permission.includes("user.role_update")
  // ..........................permisssion.........

  useEffect(() => {
    var a = localStorage.getItem("newdept");
    var b = localStorage.getItem("updatedepartment");

    if (a === "1") {
      toast.success("Department added successfully");
      localStorage.setItem("newdept", "0");
    }

    if (b === "1") {
      toast.success("Department updated successfully");
      localStorage.setItem("updatedepartment", "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;

    const getDepartments = async () => {
      setDataLoader(true);
      try {
        const response = await axiosPrivate.get(
          "/department/all-departments",
          {}
        );
        setPending(false);
        isMounted && setGetDepartments(response.data.all_departments);
      } catch (err) {
        if (
          err.response.data.return_status.message[0] ==
          "Insufficient permissions"
        ) {
          setPermission("Insufficient permissions");
        }
      }
      setDataLoader(false);
    };

    getDepartments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleDelete = async (id) => {
    const payload = {
      dept_id: id,
    };

    try {
      await axiosPrivate.delete(
        "/department/delete",
        { data: payload },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
    } catch (err) {}
    const del = getDepartments.filter((User) => id !== User._id);
    setGetDepartments(del);
    toast.success("Department deleted successfully");
  };

  const showSearch = (searchText) => {
    const copyOfUsers = [...getDepartments];

    setSearch(searchText);
    const result = copyOfUsers.filter((points) => {
      return (
        points.name
          .toString()
          .toLowerCase()
          .match(searchText.toString().toLowerCase()) ||
        points.description
          .toString()
          .toLowerCase()
          .match(searchText.toString().toLowerCase()) ||
        points.head_of_dept
          .toString()
          .toLowerCase()
          .match(searchText.toString().toLowerCase())
      );
    });
    setFilterUser(result);
  };
  const notifyerror = (e) => toast.error(`${e} error occur`);

  const email_api = async (id, status, e) => {
    try {
      await axiosPrivate
        .patch(
          "/department/active-inactive",
          JSON.stringify({
            dept_id: id.toString(),
            new_status: e,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          if (e.data.new_status === 1) {
            toast.success("Department is active");
          } else {
            toast.success("Department is inActive");
          }
        });
    } catch (err) {
      notifyerror(err.response.statusText);
    }
  };
  const columns = [
    {
      id: "sno",
      name: "Sno.",
      selector: (row, i) => currentRowsPerPage * (currentPage - 1) + (i + 1),
      width: "90px",
      grow: 0,
    },
    {
      id: "DepartmentName",
      name: "Department Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },

    {
      name: "Head of Department",
      selector: (row) => row.head_of_dept,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          {superUser === true ? (
            <>
              {" "}
              <BootstrapSwitchButton
                className="switch btn on btn-outline-success btn-xs"
                checked={row.status === 1 ? true : false}
                onChange={(e) => {
                  email_api(row._id, row.status, e);
                }}
                size="xs"
                width={80}
                onlabel="Active"
                offlabel="Inactive"
                onstyle="outline-success"
                offstyle="outline-danger"
              />
            </>
          ) : (
            <>
              {all_permission.includes("user.department_change_status") ? (
                <>
                  {" "}
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    checked={row.status === 1 ? true : false}
                    onChange={(e) => {
                      email_api(row._id, row.status, e);
                    }}
                    size="xs"
                    width={80}
                    onlabel="Active"
                    offlabel="Inactive"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ),
      width: "120px",
    },
    {
      name: "Action",
      selector: (row) => (
        <td>
          {superUser === true ? (
            <>
              <span className="actionButton">
                <img
                  src={EditIcon}
                  onClick={() => {
                    navigate(`/update-department/${row._id}`);
                  }}
                  alt=""
                />
              </span>
            </>
          ) : (
            <>
              {all_permission.includes("user.department_update") ? (
                <>
                  {" "}
                  <span className="actionButton">
                    <img
                      src={EditIcon}
                      onClick={() => {
                        navigate(`/update-department/${row._id}`);
                      }}
                      alt=""
                    />
                  </span>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <span className="actionButton">
            {superUser === true ? (
              <>
                {" "}
                <img
                  src={DeleteIcon}
                  onClick={() => {
                    confirmAlert({
                      message: "Do you want to delete " + row.name,
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => handleDelete(row._id),
                        },
                        {
                          label: "No",
                        },
                      ],
                    });
                  }}
                  alt=""
                />
              </>
            ) : (
              <>
                {all_permission.includes("user.department_delete") ? (
                  <>
                    {" "}
                    <img
                      src={DeleteIcon}
                      onClick={() => {
                        confirmAlert({
                          message: "Do you want to delete " + row.name,
                          buttons: [
                            {
                              label: "Yes",
                              onClick: () => handleDelete(row._id),
                            },
                            {
                              label: "No",
                            },
                          ],
                        });
                      }}
                      alt=""
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </span>
        </td>
      ),
      width: "120px",
    },
  ];

  return (
    <>
      {dataLoader === true ? (
        <>
          {" "}
          <SpinnerLoader />
        </>
      ) : (
        <>
          {permission === "Insufficient permissions" ? (
            <InsufficientPermisiion />
          ) : (
            <>
              {" "}
              <div className="top_container">
                <div className="main_container ">
                  <div className="user_serachbox">
                    <div style={{ float: "left" }}>
                      <h3 className="tableHeading">
                        <img src={userIco} className="headingIcons" alt="" />{" "}
                        Department
                      </h3>
                    </div>
                    <input
                      type="search"
                      className="tableSearch"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => showSearch(e.target.value)}
                    />
                    {superUser === true ? (
                      <>
                        {" "}
                        <button
                          className="cbtn addBtn"
                          onClick={() => {
                            navigate("/new-department");
                          }}
                        >
                          <img src={addIcon} alt="" /> &nbsp; New Department
                        </button>
                      </>
                    ) : (
                      <>
                        {all_permission.includes("user.department_create") ? (
                          <>
                            {" "}
                            <button
                              className="cbtn addBtn"
                              onClick={() => {
                                navigate("/new-department");
                              }}
                            >
                              <img src={addIcon} alt="" /> &nbsp; New Department
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                  <hr />

                  <div className="mainContent dTable">
                    <DataTable
                      columns={columns}
                      // data={getDepartments}
                      data={filterUser || getDepartments}
                      pagination
                      onChangePage={(page, totalRows) => {
                        setCurrentPage(page);
                      }}
                      onChangeRowsPerPage={(
                        currentRowsPerPage,
                        currentPage
                      ) => {
                        setCurrentRowsPerPage(currentRowsPerPage);
                      }}
                      fixedHeader
                      highlightOnHover
                      defaultSortFieldId="DepartmentName"
                      defaultSortAsc={true}
                      progressPending={pending}
                      progressComponent={<SpinnerLoader />}
                      noDataComponent="No Data Found"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DepartmentListing;
