import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Nav, Navbar, Container } from "react-bootstrap";
import brandlogo from "../../../assets/SamplicityWhiteLogo.svg";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationBell from "./Notificationbell/Notificationbell";
import cam from "../../../assets/images/camera.png";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Toast from "../Toaster/Toaster";

const Header = (props) => {
  const { setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  let navigate = useNavigate();
  const [showadmin, setShowAdmin] = useState(null);
  const [showsubmenu, setSubmenu] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElUserIcon, setAnchorElUserIcon] = useState(null);
  const [userId, setUserId] = useState();
  const [photo, setPhoto] = useState();
  const [username, setUsername] = useState();
  const [uemail, setEmail] = useState();
  const [menu, setMenu] = useState([]);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    // setShowAdmin(null);
  };
  const handleCloseUserIcon = () => {
    setAnchorElUserIcon(null);
  };

  const handleMainMenu = (e) => {
    var element = document.getElementById("MainMenu");
    if (element.matches(".open")) {
      element.classList.remove("open");
    } else {
      element.classList.add("open");
    }
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenUserIcon = (event) => {
    setAnchorElUserIcon(event.currentTarget);
  };

  const handleOpenUserTable = (e) => {
    setShowAdmin(true);
  };
  const submenuconstent = (e) => {
    setSubmenu(e);
  };

  useEffect(() => {
    fetchUser();
    fetchmenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchUser = async () => {
    try {
      const response = await axiosPrivate.get("/user", {});
      if (response.status === 200) {
        setUserId(response.data.user.id);
        setPhoto(response.data.user.photo);
        setUsername(response.data.user.first_name);
        setEmail(response.data.user.email);
      }
    } catch (err) {}
  };

  const fetchmenu = async () => {
    try {
      const response = await axiosPrivate.get("/permission/get-menu", {});

      if (response.status === 200) {
        setMenu(response.data.menu);
      }
    } catch (err) {}
  };

  const logout = async () => {
    setAuth({});
    localStorage.removeItem("persist");
    localStorage.removeItem("token");
    navigate("/");
  };

  const changeProfile = async (e) => {
    const formData = new FormData();
    formData.append("photo", e.target.files[0]);
    formData.append("user_id", userId);

    try {
      const response = await axiosPrivate.post("/user/upload-photo", formData, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      if (response?.data?.file_url) {
        setPhoto(response?.data?.file_url);
      }
    } catch (err) {}
  };

  const resetpassword = () => {
    handleCloseUserIcon();
    navigate("/reset-password");
  };

  return (
    <>
      <Toast />

      <div className="header">
        <Navbar className="color-nav fixed" inverse={"true"} collapseOnSelect>
          <div>
            <img
              className="header_samplicity_logo"
              src={brandlogo}
              alt="Samplicity"
            />
          </div>
          <Container className="ml-2">
            <Nav className="" id="MainMenu">
              {menu.map((e) => {
                if (!e.childmenu.length) {
                  return (
                    <Nav.Link
                      className="navitem"
                      onClick={() => {
                        localStorage.setItem("idd", e._id);
                        navigate("/dashboard");
                        navigate(e.menu_url);
                      }}
                    >
                      {e.name}{" "}
                    </Nav.Link>
                  );
                } else {
                  return (
                    <Nav.Link
                      className="navitem"
                      onClick={(k) => {
                        handleOpenUserMenu(k);
                        handleOpenUserTable();
                        submenuconstent(e.childmenu);
                      }}
                    >
                      {e.name}
                    </Nav.Link>
                  );
                }
              })}
            </Nav>

            <div className="notification">
              <NotificationBell />
              <IconButton onClick={handleOpenUserIcon} sx={{ ml: 3, p: 0 }}>
                <Avatar alt="Remy Sharp" src={photo} />
              </IconButton>

              <MenuIcon
                className="hamberger"
                onClick={(e) => {
                  handleMainMenu(e);
                }}
              />
            </div>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {showadmin &&
                showsubmenu.map((e) => {
                  return (
                    <MenuItem key={e.name} onClick={handleCloseUserMenu}>
                      <Typography
                        textAlign="center"
                        onClick={() => {
                          navigate(e.menu_url);
                        }}
                      >
                        {e.name}
                      </Typography>
                    </MenuItem>
                  );
                })}
            </Menu>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUserIcon}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUserIcon)}
              onClose={handleCloseUserIcon}
            >
              <MenuItem onClick={handleCloseUserIcon}>
                <div className="profileDropdown">
                  <div className="leftPane">
                    <div className="profile">
                      <form method="HTTP_METHOD" enctype="multipart/form-data">
                        <label htmlFor="photo" class="uploadPic">
                          <Avatar alt="profile" src={photo} />
                          <img src={cam} className="uploadHover" alt="Upload" />
                        </label>
                        <input
                          id="photo"
                          name="photo"
                          type="file"
                          onChange={changeProfile}
                          accept="image/*"
                        />
                      </form>
                    </div>
                  </div>
                  <div className="rightPane">
                    <h5>{username}</h5>
                    <p>{uemail}</p>
                  </div>
                  <div className="ddFooter">
                    <button
                      className="btn btn-sm btn-changepwd"
                      onClick={resetpassword}
                    >
                      Manage Profile
                    </button>
                    <button className="btn btn-sm btn-logout" onClick={logout}>
                      Log Out
                    </button>
                  </div>
                </div>
              </MenuItem>
            </Menu>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
