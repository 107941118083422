import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
import "./QuestionPopup.css";
import Table from "react-bootstrap/Table";
import { GrFormAdd } from "react-icons/gr";
import { FaRegDotCircle } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import DeleteIcon from "../../../assets/LoginIcons/delete.svg";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";

import EmailQuestion from "./QuestionModule/EmailQuestion";
import toast from "react-hot-toast";
function QuestionPopup(props) {
  const [showQuestion, setShowQuestions] = useState([]);
  const [questionShow, setQuestionShow] = useState();

  const [show, setShow] = useState(false);
  const [deleteModel, setDeleteModel] = useState();
  const [selecteduser, setSelectedUser] = useState([]);
  const { register, handleSubmit } = useForm();

  var statusOption = [
    { value: "Paffffffffnel", label: "Panel" },
    { value: "Fulddddddddddddcrum", label: "Fulcrum SH1" },
  ];

  const handleClose = () => {
    setShow(false);
    var data = document.getElementById("checkkkkk");
    data.classList.remove("blur");
  };

  const handleCloseWithYes = () => {
    setShow(false);

    var data = document.getElementById("checkkkkk");
    data.classList.remove("blur");
    var deletedata = document.getElementById(
      `${deleteModel + "deleteQuestion"}`
    );
    deletedata.style.display = "none";
    var a = showQuestion.filter((word, index) => index !== deleteModel);

    setShowQuestions(a);
  };

  const handleBlur = () => {
    var data = document.getElementById("checkkkkk");
    data.classList.add("blur");
  };

  useEffect(() => {
    setQuestionShow(showQuestion.length - 1);

    if (showQuestion.length > 0) {
      for (let i = 0; i <= showQuestion.length - 1; i++) {
        if (i === showQuestion.length - 1) {
          var data = document.getElementById(`QuestionCountShow${i}`);
          data.style.color = "blue";
        } else {
          var data1 = document.getElementById(`QuestionCountShow${i}`);
          data1.style.color = "black";
        }
      }
    }
  }, [showQuestion]);

  const submitForm = () => {};

  const paretsFormData = () => {
    notifysucces();
  };

  const ShowQuestionNumber = (e) => {
    setQuestionShow(e);
    for (let i = 0; i <= showQuestion.length - 1; i++) {
      if (i === e) {
        var data = document.getElementById(`QuestionCountShow${i}`);
        data.style.color = "blue";
      } else {
        var data1 = document.getElementById(`QuestionCountShow${i}`);
        data1.style.color = "black";
      }
    }
  };

  const handleShowQuestion = (e) => {
    setShowQuestions((end) => [...end, e.target.value]);
    setSelectedUser([]);
  };

  const notifyerror = () => toast.error("Fill the form");
  const notifysucces = () => toast.success("Form  successfully submited");

  const onErrors = () => {
    notifyerror();
  };
  const handledeleteQuestion = (e) => {
    setDeleteModel(e);
    handleShow();
  };

  const handleShow = (e) => {
    setShow(true);
    handleBlur();
  };

  return (
    <>
      <div>
        <Modal
          id="checkkkkk"
          className="questionpopup"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="modelRTitle"
            >
              Prescreening Questions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ textAlign: "right" }}>
              <Col lg={4}></Col>
              <Col className="givepadding" lg={2} md={3} sm={6} xs={12}>
                <div className="switchButtonAllInOne">
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    onlabel="One By One "
                    offlabel="All In One"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </div>
              </Col>

              <Col className="givepadding" lg={2} md={3} sm={6} xs={12}>
                <select
                  className="headingSelect"
                  id="QuestionSelect"
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    handleShowQuestion(e);
                  }}
                >
                  <option selected>
                    <GrFormAdd />
                    Add Questions
                  </option>
                  <option value="Single Select">Single Select</option>
                  <option value="Multi Select">Multi Select</option>
                  <option value="Open End">Open End</option>
                  <option value="Message Box">Message Box</option>
                  <option value="Email">Email</option>
                  <option value="Phone">Phone</option>
                  <option value="Name">Name</option>
                  <option value="Address">Address</option>
                </select>
              </Col>

              <Col className="givepadding" lg={2} md={3} sm={6} xs={12}>
                <button className="addTemplateButton">Add Templates</button>
              </Col>
              <Col lg={2} md={3} sm={6} xs={12}>
                <button className="addTemplateButton">Logs</button>
              </Col>
            </Row>

            <Row>
              <Col lg={2} md={12} sm={12} xs={12}>
                <Row>
                  <DragDropContext>
                    <Table>
                      <Droppable droppableId="tbody">
                        {(provided) => (
                          <tbody
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="texta respques"
                          >
                            {showQuestion.map((e, index) => (
                              <Draggable
                                key={index + 100}
                                draggableId={index.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <td
                                      className="questionShow"
                                      onClick={() => {
                                        ShowQuestionNumber(index);
                                      }}
                                    >
                                      <span
                                        className="questionCOuntcss"
                                        id={`QuestionCountShow${index}`}
                                      >
                                        Question.{index + 1}
                                      </span>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                          </tbody>
                        )}
                      </Droppable>
                    </Table>
                  </DragDropContext>
                </Row>
              </Col>

              <Col lg={10} md={12} sm={12} xs={12}>
                <form onSubmit={handleSubmit(paretsFormData, onErrors)}>
                  {showQuestion.map((e, index) => (
                    <>
                      {e === undefined ? (
                        <></>
                      ) : (
                        <>
                          {index === questionShow ? (
                            <>
                              <Row>
                                <Col>
                                  <Row className="questionCountCss">
                                    <Col lg={11} md={11} sm={11} xs={11}>
                                      Questions {index + 1} &nbsp;(
                                      <FaRegDotCircle
                                        size={12}
                                        className="dotCircle"
                                      />
                                      <span className="showQuestion">{e}</span>)
                                    </Col>
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                      <span className="actionButton">
                                        <img
                                          src={DeleteIcon}
                                          alt=""
                                          onClick={() => {
                                            handledeleteQuestion(index);
                                          }}
                                        />
                                      </span>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col lg={12} md={12} sm={12} xs={12}>
                                  <div id="showQuestion">
                                    {e === "Single Select" ||
                                    e === "Multi Select" ? (
                                      <>
                                        <EmailQuestion
                                          register={register}
                                          dex={index}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {e === "Message Box" ? (
                                          <div id={index + "deleteQuestion"}>
                                            <Row className="messageBoxRow">
                                              <Col
                                                className="messageTop"
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                              >
                                                <Row>
                                                  <label className="formlabelamit">
                                                    Message*
                                                  </label>
                                                  <textarea
                                                    textarea
                                                    id="w3review"
                                                    rows="4"
                                                    cols="50"
                                                    className="messageBoxTextArea"
                                                    placeholder="Enter Message"
                                                    type="text"
                                                    {...register(
                                                      `MessageBox${index}`,
                                                      {
                                                        required: true,
                                                      }
                                                    )}
                                                  ></textarea>
                                                </Row>
                                              </Col>
                                              <div className="boxgap"></div>
                                              <Row className="csmulti">
                                                <Col
                                                  lg={4}
                                                  md={6}
                                                  sm={6}
                                                  xs={6}
                                                >
                                                  <label className="formlabelamit">
                                                    No Show Vendor
                                                  </label>
                                                  <MultiSelect
                                                    className="multiselectoption  "
                                                    options={statusOption}
                                                    value={selecteduser}
                                                    onChange={(e) => {
                                                      setSelectedUser(e);
                                                    }}
                                                    labelledBy={"Select"}
                                                    isCreatable={true}
                                                    disableSearch
                                                  />
                                                </Col>
                                                <Col
                                                  lg={4}
                                                  md={6}
                                                  sm={6}
                                                  xs={6}
                                                  className="centerstyle"
                                                >
                                                  <label className="formlabelamit">
                                                    Mandatory
                                                  </label>
                                                  <span className="pswtchbtn spacebutton">
                                                    <BootstrapSwitchButton
                                                      width={65}
                                                      className="switch btn on btn-outline-success btn-xs"
                                                      size="xs"
                                                      onlabel="ON"
                                                      offlabel="OFF"
                                                      onstyle="outline-success"
                                                      offstyle="outline-danger"
                                                    />
                                                  </span>
                                                </Col>
                                              </Row>
                                            </Row>
                                          </div>
                                        ) : (
                                          <div id={index + "deleteQuestion"}>
                                            <Row className="openendRow">
                                              <Col
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                              >
                                                <label className="formlabelamit">
                                                  Question*
                                                </label>
                                                <input
                                                  id="questionPhone"
                                                  className="opendQuestionInput questionInputheight"
                                                  placeholder="Enter Message"
                                                  type="text"
                                                  {...register(
                                                    `OpenendtQuestion${index}`,
                                                    {
                                                      required: true,
                                                    }
                                                  )}
                                                />
                                              </Col>
                                              <div className="boxgap"></div>
                                              <Row className="csmulti">
                                                <Col
                                                  lg={4}
                                                  md={6}
                                                  sm={6}
                                                  xs={6}
                                                >
                                                  <label className="formlabelamit">
                                                    No Show Vendor
                                                  </label>
                                                  <MultiSelect
                                                    className="multiselectoption  "
                                                    options={statusOption}
                                                    value={selecteduser}
                                                    onChange={(e) => {
                                                      setSelectedUser(e);
                                                    }}
                                                    labelledBy={"Select"}
                                                    isCreatable={true}
                                                    disableSearch
                                                  />
                                                </Col>
                                                <Col
                                                  lg={4}
                                                  md={6}
                                                  sm={6}
                                                  xs={6}
                                                  className="centerstyle"
                                                >
                                                  <label className="formlabelamit">
                                                    Mandatory
                                                  </label>
                                                  <span className="pswtchbtn spacebutton">
                                                    <BootstrapSwitchButton
                                                      width={65}
                                                      className="switch btn on btn-outline-success btn-xs"
                                                      size="xs"
                                                      onlabel="ON"
                                                      offlabel="OFF"
                                                      onstyle="outline-success"
                                                      offstyle="outline-danger"
                                                    />
                                                  </span>
                                                </Col>
                                              </Row>
                                            </Row>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <>
                              <Row style={{ display: "none" }}>
                                <Col>
                                  <Row className="questionCountCss">
                                    <Col lg={11} md={11} sm={11} xs={11}>
                                      Questions {index + 1} &nbsp;(
                                      <FaRegDotCircle
                                        size={12}
                                        className="dotCircle"
                                      />
                                      <span className="showQuestion">{e}</span>)
                                    </Col>
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                      <span className="actionButton">
                                        <img
                                          src={DeleteIcon}
                                          alt=""
                                          onClick={() => {}}
                                        />
                                      </span>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col lg={12} md={12} sm={12} xs={12}>
                                  <div id="showQuestion">
                                    {e === "Single Select" ||
                                    e === "Multi Select" ? (
                                      <>
                                        <EmailQuestion
                                          register={register}
                                          dex={index}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {e === "Message Box" ? (
                                          <div id={index + "deleteQuestion"}>
                                            <Row className="messageBoxRow">
                                              <Col
                                                className="messageTop"
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                              >
                                                <Row>
                                                  <label className="formlabelamit">
                                                    Message*
                                                  </label>
                                                  <textarea
                                                    textarea
                                                    id="w3review"
                                                    rows="4"
                                                    cols="50"
                                                    className="messageBoxTextArea"
                                                    placeholder="Enter Message"
                                                    type="text"
                                                    {...register(
                                                      `MessageBox${index}`,
                                                      {
                                                        required: true,
                                                      }
                                                    )}
                                                  ></textarea>
                                                </Row>
                                              </Col>
                                              <div className="boxgap"></div>
                                              <Row className="csmulti">
                                                <Col
                                                  lg={4}
                                                  md={6}
                                                  sm={6}
                                                  xs={6}
                                                >
                                                  <label className="formlabelamit">
                                                    No Show Vendor
                                                  </label>
                                                  <MultiSelect
                                                    className="multiselectoption  "
                                                    options={statusOption}
                                                    value={selecteduser}
                                                    onChange={(e) => {
                                                      setSelectedUser(e);
                                                    }}
                                                    labelledBy={"Select"}
                                                    isCreatable={true}
                                                    disableSearch
                                                  />
                                                </Col>
                                                <Col
                                                  lg={4}
                                                  md={6}
                                                  sm={6}
                                                  xs={6}
                                                >
                                                  <label className="formlabelamit">
                                                    Mandatory
                                                  </label>
                                                  <span className="pswtchbtn spacebutton">
                                                    <BootstrapSwitchButton
                                                      width={65}
                                                      className="switch btn on btn-outline-success btn-xs"
                                                      size="xs"
                                                      onlabel="ON"
                                                      offlabel="OFF"
                                                      onstyle="outline-success"
                                                      offstyle="outline-danger"
                                                    />
                                                  </span>
                                                </Col>
                                              </Row>
                                            </Row>
                                          </div>
                                        ) : (
                                          <div id={index + "deleteQuestion"}>
                                            <Row className="openendRow">
                                              <Col
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                              >
                                                <label className="formlabelamit">
                                                  Question*
                                                </label>
                                                <input
                                                  id="questionPhone"
                                                  className="opendQuestionInput"
                                                  placeholder="Enter Message"
                                                  type="text"
                                                  {...register(
                                                    `OpenendtQuestion${index}`,
                                                    {
                                                      required: true,
                                                    }
                                                  )}
                                                />
                                              </Col>
                                              <div className="boxgap"></div>
                                              <Row className="csmulti">
                                                <Col
                                                  lg={4}
                                                  md={6}
                                                  sm={6}
                                                  xs={6}
                                                >
                                                  <label className="formlabelamit">
                                                    No Show Vendor
                                                  </label>
                                                  <MultiSelect
                                                    className="multiselectoption  "
                                                    options={statusOption}
                                                    value={selecteduser}
                                                    onChange={(e) => {
                                                      setSelectedUser(e);
                                                    }}
                                                    labelledBy={"Select"}
                                                    isCreatable={true}
                                                    disableSearch
                                                  />
                                                </Col>
                                                <Col
                                                  lg={4}
                                                  md={6}
                                                  sm={6}
                                                  xs={6}
                                                >
                                                  <label className="formlabelamit">
                                                    Mandatory
                                                  </label>
                                                  <span className="pswtchbtn spacebutton">
                                                    <BootstrapSwitchButton
                                                      width={65}
                                                      className="switch btn on btn-outline-success btn-xs"
                                                      size="xs"
                                                      onlabel="ON"
                                                      offlabel="OFF"
                                                      onstyle="outline-success"
                                                      offstyle="outline-danger"
                                                    />
                                                  </span>
                                                </Col>
                                              </Row>
                                            </Row>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ))}

                  {showQuestion.length > 0 ? (
                    <>
                      <div className="buttonPosition">
                        <button
                          type="submit"
                          onClick={submitForm}
                          className="submitButtonQuestion butttonRadious"
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </form>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body className="deleteMOdule">
            Do you want to delete ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="buttonbackground"
              variant="secondary"
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              variant="primary"
              className="buttonbackground"
              onClick={handleCloseWithYes}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
export default QuestionPopup;
