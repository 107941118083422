import Header from "../../UI/Header/Header";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import Select from "react-select";
import addIcon from "../../../assets/LoginIcons/add.svg";
import toast from "react-hot-toast";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import Spinner from "react-bootstrap/Spinner";
import JoditEditor from "jodit-react";
import { CheckCircle, Delete, ErrorRounded } from "@mui/icons-material";
import "bootstrap/dist/css/bootstrap.css";
import Row from "react-bootstrap/Row";
import "./Mailbox.css";
import composeIco from "../../../assets/BidsIcons/composeEmail.svg";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
const Compose = () => {
  const editor = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [editorData, setEditorData] = useState();
  const [randomNum, setRandomNumber] = useState();
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);

  const progressInfosRef = useRef(null);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const notifysuccess = (e) => toast.success(e);
  const [test, setTest] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editorState, setEditorState] = useState(true);
  const [emailSignature, setEmailSignature] = useState();
  const [userList, setUserList] = useState([]);
  const [selectUser, setSelectUser] = useState();
  const [selectCC, setSelectCC] = useState();

  const [multipleEmailCC, setMultipleEmailCC] = useState([]);
  const [multipleEmailBCC, setMultipleEmailBCC] = useState([]);
  const [selectBCC, setSelectBCC] = useState();
  const [emails, setEmails] = useState([]);
  const [showToState, setToState] = useState(true);
  const [userDrop, setUserDropState] = useState([]);
  const [ccDrop, setCCDRop] = useState([]);
  const [bccDrop, setBCCDRop] = useState([]);
  const [focused, setFocused] = useState(false);
  // const ToRef = useRef(selectUser);

  let config = {
    saveSelectionOnBlur: true,
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "font",
      "fontsize",
      "brush",
      "|",
      "ul",
      "ol",
      "table",
      "|",
      "center",
      "left",
      "right",
      "justify",
      "|",
      "link",
      "image",
      "file",
    ],
    uploader: { insertImageAsBase64URI: true, insertFileAsBase64URI: true },
    removeButtons: [],
    toolbarAdaptive: false,
  };

  useEffect(() => {
    getEmailSignature();
    allUserMail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setEditorData(emailSignature);
  }, [emailSignature]);
  useEffect(() => {
    setValue("To", emails);
    setValue("CC", multipleEmailCC);
    setValue("BCC", multipleEmailBCC);
  }, [emails, multipleEmailCC, multipleEmailBCC]);

  const fileupload = async (file, onUploadProgress) => {
    let formData = new FormData();

    formData.append("file", file);

    formData.append("uid", `${randomNum}`);

    setTest((value) => [...value, file]);

    await axiosPrivate.post("/mailbox/add-attachment", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  };
  // mailbox/get-email-signature
  const getEmailSignature = async () => {
    const response = await axiosPrivate.get(`mailbox/get-email-signature`, {});
    setEmailSignature(response.data.email_signature);
  };

  const allUserMail = async () => {
    const response = await axiosPrivate.get(
      `mailbox/search-mailbox-contacts/{search-text}?search_text=a`,
      {}
    );
    setUserList(
      response.data.contact_list.map((emailitem) => ({
        id: emailitem._id,
        email_id: emailitem.contact_id,
      }))
    );
  };

  const getFiles = () => {
    return axiosPrivate.get("/mailbox/message-detail/632dcc5c452a6ec546e2f0f9");
  };

  useEffect(() => {
    let x = Math.floor(Math.random() * 100000000000 + 1);
    setRandomNumber(x);
  }, []);

  const upload = async (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    return fileupload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      setProgressInfos((vale) => ({ ...vale, val: _progressInfos }));
    })
      .then(() => {
        setMessage((prevMessage) => [...prevMessage, "success"]);
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        setProgressInfos({ val: _progressInfos });
        setMessage((prevMessage) => [...prevMessage, "error"]);
      });
  };

  const uploadFiles = async (event) => {
    const files = Array.from(event.target.files);
    let merged = files.concat(test);
    merged = merged.filter((item, index) => {
      return merged.indexOf(item) === index;
    });

    let _progressInfos = merged.map((file) => ({
      percentage: 0,
      fileName: file.name,
    }));
    progressInfosRef.current = {
      val: _progressInfos,
    };

    const uploadPromises = merged.map((file, i) => upload(i, file));

    Promise.all(uploadPromises)
      .then(() => getFiles())
      .then((files) => {});

    setMessage([]);
  };

  const deleteHandler = async (i, e) => {
    const del = progressInfos.val.filter((User, index) => i !== index);
    const el = progressInfos.val.filter((User, index) => i === index);

    setProgressInfos({ val: del });
    const bar = test.filter((User, index) => i !== index);
    setTest(bar);

    try {
      await axiosPrivate.get(
        `mailbox/delete-attachment/${randomNum}/${el[0].fileName}`,
        {}
      );
    } catch (err) {}
  };

  const handleSaveButton = async (e) => {
    setIsSubmitting(true);
    try {
      await axiosPrivate
        .post(
          "mailbox/compose-email",
          JSON.stringify({
            subject: e.Subject,
            to: e.To.toString(),
            cc: e.CC.toString(),
            bcc: e.BCC.toString(),
            uid: `${randomNum}`,
            body: e.bodyT,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysuccess("Mail sent successfully");
            navigate("/mailbox");
          }
        });
    } catch (err) {}
    setIsSubmitting(false);
  };
  useEffect(() => {
    if (editorData !== undefined && editorData !== "") {
      setEditorState(false);
    } else {
      setEditorState(true);
    }
  }, [editorData]);
  const BodyT = register("bodyT", {
    required: "This field is Required",
  });

  const handleCcBcc = (e, cid) => {
    e.preventDefault();

    e.target.classList.add("hide");
    var element = document.getElementById(cid);
    element.classList.add("show");
  };

  const hideToDRop = (e) => {
    var element = e.target;
    var parent = element.closest(".drop");

    parent.classList.add("hide");
  };

  // ======= DropTO And UseEffect =========
  const Drop = () => {
    return (
      <>
        {userDrop.map((ele) => (
          <h6
            onClick={(e) => {
              setToState(false);
              setEmails((val) => [...val, e.target.innerText]);
              //let element = document.querySelector(".react-multi-email");
              let multiEmail = e.target.closest(".drop").previousElementSibling;
              let element = multiEmail.querySelector("input");
              if (element !== null) {
                element.value = "";
              }
              // setNoValue(true);
              hideToDRop(e);
            }}
          >
            {ele}
          </h6>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (selectUser !== "" && selectUser !== undefined) {
      function test(userList, sub) {
        setUserDropState([]);
        userList.map((str) => {
          if (
            str.email_id
              .toLowerCase()
              .startsWith(sub.slice(0, Math.max(str.email_id.length - 1, 1)))
          ) {
            // setUserDropState(str);
            setUserDropState((val) => [...val, str.email_id]);
          } else {
          }
        });
      }
      const userLast = selectUser;

      var results = test(userList, userLast);
    }

    var arr = ["Alfred", "Alf", "alf", "al", "altered", "half", "", "bob"];
  }, [selectUser, userList]);

  // =============DRopCC and UseEffect ===============

  const DropCC = (namev) => {
    return (
      <>
        {ccDrop.map((ele) => (
          <h6
            onClick={(e) => {
              setMultipleEmailCC((val) => [...val, e.target.innerText]);
              hideToDRop(e);
            }}
          >
            {ele}
          </h6>
        ))}
      </>
    );
  };
  useEffect(() => {
    if (selectCC !== "" && selectCC !== undefined) {
      function test(userList, sub) {
        setCCDRop([]);
        userList.map((str) => {
          if (
            str.email_id
              .toLowerCase()
              .startsWith(sub.slice(0, Math.max(str.email_id.length - 1, 1)))
          ) {
            setCCDRop((val) => [...val, str.email_id]);
          } else {
          }
        });
      }
      const userLast = selectCC;

      var results = test(userList, userLast);
    }

    var arr = ["Alfred", "Alf", "alf", "al", "altered", "half", "", "bob"];
  }, [selectCC, userList]);

  // ========= DropBcc and UseEFFect  ==========
  const DropBCC = (namev) => {
    return (
      <>
        {bccDrop.map((ele) => (
          <h6
            onClick={(e) => {
              setMultipleEmailBCC((val) => [...val, e.target.innerText]);
              hideToDRop(e);
              // setValue("To", e.target.innerText);
              // setSelectUser();
            }}
          >
            {ele}
          </h6>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (selectBCC !== "" && selectBCC !== undefined) {
      function test(userList, sub) {
        setBCCDRop([]);
        userList.map((str) => {
          if (
            str.email_id
              .toLowerCase()
              .startsWith(sub.slice(0, Math.max(str.email_id.length - 1, 1)))
          ) {
            setBCCDRop((val) => [...val, str.email_id]);
          }
        });
      }
      const userLast = selectBCC;

      var results = test(userList, userLast);
    }
  }, [selectBCC, userList]);

  // ======= to Hide and show  DropDown===========

  const showDrop = (e) => {
    e.preventDefault();

    let element = e.target;
    let wrapper = element.closest(".formgroupCompose");
    let toshow = wrapper && wrapper.querySelector(".drop.hide");
    toshow.classList.remove("hide");
  };

  const hideDrop = (e) => {
    e.preventDefault();
    let element = e.target;
    let parent = element.closest(".drop");
    parent.classList.add("hide");
  };

  // ======= validation========

  const To = register("To", {
    required: "This field is Required",
  });

  return (
    <>
      <div className="top_container">
        <div className="main_container ">
          <form name="myForm" onSubmit={handleSubmit(handleSaveButton)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img src={composeIco} className="headingIcons" alt="" />{" "}
                  Compose Email
                </h3>
              </div>
              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={() => {
                    navigate("/mailbox");
                  }}
                >
                  {" "}
                  Close
                </button>

                <button className="cbtn saveBtn">
                  {isSubmitting && (
                    <Spinner
                      style={{ color: "white" }}
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp; Send
                </button>
              </div>
            </div>
            <hr />

            <div className="mainContent">
              <Row>
                <div className="formgroupCompose">
                  <label>To</label>
                  <ReactMultiEmail
                    id="reactText"
                    name="Tofeild"
                    style={{
                      borderBottom: "1px solid #eee",
                      paddingLeft: "60px",
                    }}
                    {...To}
                    emails={emails}
                    onChange={(e) => {
                      setToState(false);
                      setEmails(e);
                    }}
                    autoFocus={true}
                    // autoFocus={true}
                    onKeyUp={(e) => {
                      if (e.key !== "Enter") {
                        setSelectUser(e.target.value);
                        showDrop(e);
                        // setNoValue(false);
                        Drop();
                      }
                    }}
                    onFocus={() => setFocused(false)}
                    onBlur={() => setFocused(false)}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                  <div className="drop dropTo">
                    <Drop />
                  </div>
                  <span
                    className="rbtn cc"
                    onClick={(e) => {
                      handleCcBcc(e, "cc");
                    }}
                  >
                    Cc{" "}
                  </span>
                  <span
                    className="rbtn bcc"
                    onClick={(e) => {
                      handleCcBcc(e, "bcc");
                    }}
                  >
                    Bcc{" "}
                  </span>
                  {showToState === true ? (
                    errors.To && (
                      <small className="errmsg">{errors.To.message}</small>
                    )
                  ) : (
                    <></>
                  )}

                  {/* {errors.To && (
                    <small className="errmsg">{errors.To.message}</small>
                  )} */}
                </div>
              </Row>
              <Row className="ccblock" id="cc">
                <div className="formgroupCompose">
                  <label className="">CC</label>
                  <ReactMultiEmail
                    style={{
                      borderBottom: "1px solid #eee",
                      paddingLeft: "60px",
                    }}
                    // {...To}
                    emails={multipleEmailCC}
                    onChange={(e) => {
                      setMultipleEmailCC(e);
                      // setEmailForward(e.target.value);
                    }}
                    // autoFocus={true}
                    onKeyUp={(e) => {
                      if (e.key !== "Enter") {
                        setSelectCC(e.target.value);
                        showDrop(e);
                        DropCC();
                      }
                    }}
                    onFocus={() => setFocused(false)}
                    onBlur={() => setFocused(false)}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                  <div className="drop dropTo">
                    <DropCC />
                  </div>
                </div>
              </Row>
              <Row className="bccblock" id="bcc">
                <div className="formgroupCompose">
                  <label className="">BCC</label>

                  <ReactMultiEmail
                    style={{
                      borderBottom: "1px solid #eee",
                      paddingLeft: "60px",
                    }}
                    // {...To}
                    emails={multipleEmailBCC}
                    onChange={(e) => {
                      setMultipleEmailBCC(e);
                      // setEmailForward(e.target.value);
                    }}
                    autoFocus={true}
                    onKeyUp={(e) => {
                      if (e.key !== "Enter") {
                        setSelectBCC(e.target.value);
                        showDrop(e);
                        DropBCC();
                      }
                    }}
                    onFocus={() => setFocused(false)}
                    onBlur={() => setFocused(false)}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                  <div className="drop dropTo">
                    <DropBCC />
                  </div>
                </div>
              </Row>
              <Row>
                <div className="formgroupCompose">
                  <label className="">Subject</label>
                  <input
                    {...register("Subject", {})}
                    type="text"
                    placeholder=""
                    className="etext"
                    autoComplete="off"
                  />
                </div>
              </Row>

              <div className="mailContentBlock" id="editorBlock">
                <JoditEditor
                  {...BodyT}
                  ref={editor}
                  config={config}
                  value={editorData}
                  onChange={(newContent) => {
                    setValue("bodyT", newContent);
                  }}
                  autoFocus={true}
                />
                {editorState ? (
                  errors.bodyT && (
                    <small className="errmsg">{errors.bodyT.message}</small>
                  )
                ) : (
                  <></>
                )}

                <div className="attachmentContainer">
                  {progressInfos &&
                    progressInfos.val.length > 0 &&
                    progressInfos.val.map((progressInfo, index) => (
                      <div key={index} className="attachmentItem">
                        <div className="attachmentContent">
                          <span className="fileInfo">
                            {progressInfo.fileName}
                          </span>
                          {message[index] === "success" ? (
                            <CheckCircle className="success" />
                          ) : (
                            <ErrorRounded className="error" />
                          )}

                          <div className="progress">
                            <div
                              className="progress-bar progress-bar-default"
                              role="progressbar"
                              aria-valuenow={progressInfo.percentage}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: progressInfo.percentage + "%" }}
                            >
                              {progressInfo.percentage}%
                            </div>
                          </div>
                          <Delete
                            className="deleteAttachment"
                            title="Remove Attachment"
                            onClick={(e) => deleteHandler(index, e)}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <hr />

              <Row>
                <label className="cbtn addBtn" style={{ cursor: "pointer" }}>
                  <img src={addIcon} className="headingIcons" alt="" />{" "}
                  Attachment
                  <input
                    type="file"
                    multiple
                    onChange={uploadFiles}
                    className="attachInput"
                  />
                </label>

                <br />
              </Row>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Compose;
