import Header from "../../../UI/Header/Header";

import { useNavigate } from "react-router";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import userIco from "../../../../assets/LoginIcons/users.svg";
import { useParams } from "react-router";
import { useEffect, useState, useRef } from "react";

import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import SpinnerLoader from "../../../UI/Spinner";

import JoditEditor from "jodit-react";

const UpdateMailboxConfig = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const editor = useRef(null);
  const axiosPrivate = useAxiosPrivate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loder, setLoder] = useState(false);
  const [editorData, setEditorData] = useState();

  const [bcc, setBcc] = useState();
  const [cc, setCC] = useState();
  const [emailid, setEmailid] = useState();
  const [fromEmail, setFromemail] = useState();
  const [lable, setLable] = useState();
  const [name, setName] = useState();

  useEffect(() => {
    getUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getUser = async () => {
    let isMounted = true;
    try {
      const response = await axiosPrivate.get(`mailbox/get-detail/${id}`, {});
      setBcc(response.data.mailbox.bcc_email);
      setCC(response.data.mailbox.cc_email);
      setEmailid(response.data.mailbox.email_id);
      setFromemail(response.data.mailbox.from_email);
      setLable(response.data.mailbox.label);
      setName(response.data.mailbox.name);
      setEditorData(response.data.mailbox.signature_text);

      isMounted && setLoder(true);
    } catch (err) {
      console.error(err);
    }
    return () => {
      isMounted = false;
    };
  };

  const handleSaveButton = async (e) => {
    try {
      setIsSubmitting(true);
      await axiosPrivate
        .put(
          "/mailbox/update",
          JSON.stringify({
            name: e.Name,
            email_id: e.Email,
            label: e.Lable,
            from_email: e.From_Email,
            cc_email: e.CC,
            bcc_email: e.BCC,
            signature_text: editorData,
            mailbox_id: id,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          if (e.statusText === "OK") {
            localStorage.setItem("UpdateMailboxConfig", "1");
            navigate("/mailboxconfig");
          } else {
          }
        });
    } catch (err) {}
    setIsSubmitting(false);
  };

  return (
    <>
      <div className="users"></div>
      {/* <Header /> */}
      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(handleSaveButton)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img src={userIco} className="headingIcons" alt="Update" />{" "}
                  Update {name}
                </h3>
              </div>
              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={() => {
                    navigate("/mailboxconfig");
                  }}
                >
                  {" "}
                  Close
                </button>

                <button
                  className="cbtn saveBtn"
                  onClick={() => {
                    handleSaveButton();
                  }}
                >
                  {isSubmitting && (
                    <Spinner
                      as="span"
                      style={{ color: "white" }}
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp; Save
                </button>
              </div>
            </div>

            <hr />

            <div className="mainContent">
              <p className="small note">* denotes required fields</p>

              {loder ? (
                <>
                  {" "}
                  <Row>
                    <Col lg={4} md={4} xs={12} className="col">
                      <label className="formlabel">Name*</label>
                      <input
                        type="text"
                        placeholder="Name"
                        className="formtext"
                        {...register("Name", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                        defaultValue={name}
                      />
                      {errors.Name && (
                        <small className="errmsg">{errors.Name.message}</small>
                      )}
                    </Col>

                    <Col lg={4} md={4} xs={12} className="col">
                      <label className="formlabel">Email Id*</label>
                      <input
                        type="text"
                        placeholder="Email Id"
                        className="formtext"
                        {...register("Email", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\],;:\s@\"]+)*)|(.+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@]{2,})$/i, // eslint-disable-line
                            message: "Enter Valid Email Id",
                          },
                        })}
                        defaultValue={emailid}
                      />
                      {errors.Email && (
                        <small className="errmsg">{errors.Email.message}</small>
                      )}
                    </Col>
                    <Col lg={4} md={4} xs={12} className="col">
                      <label className="formlabel">Lable*</label>
                      <input
                        type="text"
                        placeholder="Lable"
                        className="formtext"
                        {...register("Lable", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                        defaultValue={lable}
                      />
                      {errors.Lable && (
                        <small className="errmsg">{errors.Lable.message}</small>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={4} xs={12} className="col">
                      <label className="formlabel">From Email*</label>
                      <input
                        type="text"
                        placeholder="From Email"
                        className="formtext"
                        {...register("From_Email", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\],;:\s@\"]+)*)|(.+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@]{2,})$/i, // eslint-disable-line
                            message: "Enter Valid Email Id",
                          },
                        })}
                        defaultValue={fromEmail}
                      />
                      {errors.From_Email && (
                        <small className="errmsg">
                          {errors.From_Email.message}
                        </small>
                      )}
                    </Col>

                    <Col lg={4} md={4} xs={12} className="col">
                      <label className="formlabel">CC Email*</label>
                      <input
                        type="text"
                        placeholder="CC Email"
                        className="formtext"
                        {...register("CC", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\],;:\s@\"]+)*)|(.+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@]{2,})$/i, // eslint-disable-line
                            message: "Enter Valid Email Id",
                          },
                        })}
                        defaultValue={cc}
                      />
                      {errors.CC && (
                        <small className="errmsg">{errors.CC.message}</small>
                      )}
                    </Col>

                    <Col lg={4} md={4} xs={12} className="col">
                      <label className="formlabel">BCC Email*</label>
                      <input
                        type="text"
                        placeholder="BCC Email"
                        className="formtext"
                        {...register("BCC", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\],;:\s@\"]+)*)|(.+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@]{2,})$/i, // eslint-disable-line
                            message: "Enter Valid Email Id",
                          },
                        })}
                        defaultValue={bcc}
                      />
                      {errors.BCC && (
                        <small className="errmsg">{errors.BCC.message}</small>
                      )}
                    </Col>
                    <Col lg={4} md={4} xs={12} className="col">
                      <label className="formlabel">Maximum page size</label>
                      <select
                        style={{
                          width: "100px",
                        }}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </Col>
                  </Row>
                  <label className="formlabel">Signature</label>
                  <JoditEditor
                    ref={editor}
                    value={editorData}
                    onChange={(newContent) => {
                      setEditorData(newContent);
                    }}
                  />
                </>
              ) : (
                <SpinnerLoader />
              )}
            </div>
          </form>
          <Col> </Col>
        </div>
      </div>
    </>
  );
};
export default UpdateMailboxConfig;
