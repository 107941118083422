import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router";
import SettingHeader from "./SettingHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useState, useEffect } from "react";
import ButtonSpinner from "../../UI/ButtonSpinner";
import userIco from "../../../assets/LoginIcons/users.svg";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import axios from "axios";
import "./ExclusionSetting.css";
const Hashing = () => {
  const navigate = useNavigate();
  const pname = "Hashing";
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showFormone, setShowFormone] = useState(false);
  const [showFormtwo, setShowFormtwo] = useState(false);
  const [inHashstatus, setInhashstatus] = useState(false);
  const [outHashstatus, setOuthashstatus] = useState(false);

  const [loder, setLoder] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [formData, setFormData] = useState({
    Instatus: "",
    InHash: "",
    Invar_f: "",
    InHasf: "",
    InSecretkey: "",
    Outstatus: "",
    OutHash: "",
    OutHasf: "",
    OutSecretkey: "",
  });
  useEffect(() => {
    get_ProjectType_api();
  }, []);
  const get_ProjectType_api = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(`supplyinfo/get-supplier/${id}`);
      if (response.data.supplier.hashing !== null) {
        setFormData((prevData) => ({
          ...prevData,

          InHash:
            response.data.supplier.hashing.hash_variable.in_hash_var.hash_var,
          Invar_f:
            response.data.supplier.hashing.hash_variable.in_hash_var.var_format,

          InHasf:
            response.data.supplier.hashing.hash_variable.in_hash_var.hash_fn,

          InSecretkey:
            response.data.supplier.hashing.hash_variable.in_hash_var.secret_key,

          OutHash:
            response.data.supplier.hashing.hash_variable.out_hash_var.hash_var,
          OutHasf:
            response.data.supplier.hashing.hash_variable.out_hash_var.hash_fn,
          OutSecretkey:
            response.data.supplier.hashing.hash_variable.out_hash_var
              .secret_key,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,

          InHash: "",
          Invar_f: "",
          InHasf: "",
          InSecretkey: "",

          OutHash: "",
          OutHasf: "",
          OutSecretkey: "",
        }));
      }
      // setData(response.data.supplier);

      if (response.data.supplier.hashing !== null) {
        setShowFormone(true);
      } else {
        setShowFormone(false);
      }
      if (response.data.supplier.hashing !== null) {
        setShowFormtwo(true);
      } else {
        setShowFormtwo(false);
      }

      // setShowFormone(
      //   response.data.supplier.hashing.hash_variable.in_hash_var.status
      // );
      // setShowFormtwo(
      //   response.data.supplier.hashing.hash_variable.out_hash_var.status
      // );
      setLoder(true);
      // setCountrySelect(response.data.supplier.address.country.id);
      // setprescreening(response.data.supplier.screening.has_screening);
      // setQualiﬁcation(response.data.supplier.screening.has_qualification);
      // setScreen(response.data.supplier.screening.screen_captcha);
      // setHerring(response.data.supplier.screening.has_job_type);
    } catch (err) {
      console.error(err);
    }
  };

  // const fetchDataWithToken = async () => {
  //   setLoder(false);

  //   const token = "YOUR_API_TOKEN"; // Replace 'YOUR_API_TOKEN' with your actual API token
  //   const url = `http://52.13.201.242:8003/api/v1/supplyinfo/get-supplier/${id}`; // Replace 'YOUR_API_URL' with the URL of the API you want to fetch

  //   try {
  //     const response = await axios.get(url, {
  //       headers: {
  //         accept: "application/json",
  //         "Content-Type": "application/json",
  //         "access-token":
  //           "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIyOTExMzgsIm5iZiI6MTY5MjI5MTEzOCwianRpIjoiZDgxOWRiYjgtYjBkYi00MjAwLTk3YjMtY2M4M2FhYzUzYTkzIiwiZXhwIjoxNjkyNzIzMTM4LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.ec0MG1JkCkJokFGtZ-1sMNrehSE1qCIAhywLxXRC1sg",
  //       },
  //     });
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       Instatus:
  //         response.data.supplier.hashing.hash_variable.in_hash_var.status,
  //       InHash:
  //         response.data.supplier.hashing.hash_variable.in_hash_var.hash_var,
  //       Invar_f:
  //         response.data.supplier.hashing.hash_variable.in_hash_var.var_format,

  //       InHasf:
  //         response.data.supplier.hashing.hash_variable.in_hash_var.hash_fn,

  //       InSecretkey:
  //         response.data.supplier.hashing.hash_variable.in_hash_var.secret_key,
  //       Outstatus:
  //         response.data.supplier.hashing.hash_variable.out_hash_var.status,
  //       OutHash:
  //         response.data.supplier.hashing.hash_variable.out_hash_var.hash_var,
  //       OutHasf:
  //         response.data.supplier.hashing.hash_variable.out_hash_var.hash_fn,
  //       OutSecretkey:
  //         response.data.supplier.hashing.hash_variable.out_hash_var.secret_key,
  //     }));
  //     setShowFormone(
  //       response.data.supplier.hashing.hash_variable.in_hash_var.status
  //     );
  //     setShowFormtwo(
  //       response.data.supplier.hashing.hash_variable.out_hash_var.status
  //     );
  //     setLoder(true);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // const handleSubmitForm = async (e) => {
  //   var postData = {
  //     complete: e.Complete,
  //     terms: e.Terms,
  //     overquotas: e.OverQuotas,
  //     security_terms: e.SecurityTerms,
  //   };
  //   const response = await fetch(
  //     `http://52.13.201.242:8003/api/v1/supplyinfo/supplier-hashing/${id}`,
  //     {
  //       method: "POST",
  //       body: JSON.stringify({
  //         hash_variable: {
  //           in_hash_var: {
  //             status: showFormone,
  //             hash_var: e.Hash,
  //             var_format: e.Variablef,
  //             secret_key: e.secret_key,
  //             hash_fn: e.hash_fn,
  //           },
  //           out_hash_var: {
  //             status: showFormtwo,
  //             hash_var: e.Hashkeyob,
  //             hash_fn: e.var_format,
  //             secret_key: e.Secretkeyob,
  //           },
  //         },
  //       }),
  //       headers: {
  //         accept: "application/json",
  //         "Content-Type": "application/json",
  //         "access-token":
  //           "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZWNob3BzQHRvcmZhYy5jb20iLCJpYXQiOjE2OTIyOTExMzgsIm5iZiI6MTY5MjI5MTEzOCwianRpIjoiZDgxOWRiYjgtYjBkYi00MjAwLTk3YjMtY2M4M2FhYzUzYTkzIiwiZXhwIjoxNjkyNzIzMTM4LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.ec0MG1JkCkJokFGtZ-1sMNrehSE1qCIAhywLxXRC1sg",
  //       },
  //     }
  //   );
  //   const result = await response.json();
  //   setIsSubmitting(false);
  // };

  const postData = async (e) => {
    setIsSubmitting(true);

    try {
      await axiosPrivate.post(
        `/supplyinfo/supplier-hashing/${id}`,
        JSON.stringify({
          hash_variable: {
            in_hash_var: {
              status: showFormone,
              hash_var: e.Hash,
              var_format: e.Variablef,
              secret_key: e.secret_key,
              hash_fn: e.hash_fn,
            },
            out_hash_var: {
              status: showFormtwo,
              hash_var: e.Hashkeyob,
              hash_fn: e.var_format,
              secret_key: e.Secretkeyob,
            },
          },
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (err) {}
    setIsSubmitting(false);
  };
  return (
    <>
      <>
        <SettingHeader pname={pname} />
        <div className="top_container">
          <div className="main_container ">
            <form onSubmit={handleSubmit(postData)}>
              {/* <form onSubmit={handleSubmit(createDepartment)}> */}
              <div className="user_serachbox">
                <div style={{ float: "left" }}>
                  <h3 className="tableHeading">
                    <img
                      src={userIco}
                      className="headingIcons"
                      alt="Add Department"
                    />
                    Hashing
                  </h3>
                </div>

                <div className="right-pane">
                  <button
                    className="cbtn closeBtn"
                    onClick={() => {
                      navigate("/supplier-details");
                    }}
                  >
                    Close
                  </button>

                  <button disabled={isSubmitting} className="cbtn saveBtn">
                    {isSubmitting && <ButtonSpinner />} &nbsp; Save
                  </button>
                </div>
              </div>
              <hr />
              {loder ? (
                <>
                  <div
                    id="dividerow"
                    style={{ marginTop: "10px" }}
                    className="mainContent"
                  >
                    <>
                      <div className="settingHeading">
                        <p className="pBlockHead">Hash variable</p>
                      </div>
                      <Row
                        style={{
                          marginTop: "15px",
                        }}
                      >
                        <Col lg={4} md={4} xs={12}>
                          {" "}
                          <label
                            className="formlabel"
                            stsecret_keyyle={{
                              marginBottom: "10px",
                            }}
                          >
                            Status inbound*
                          </label>
                          <BootstrapSwitchButton
                            className="switch btn on btn-outline-success btn-xs"
                            onChange={(e) => {
                              setShowFormone(e);
                            }}
                            size="xs"
                            width={80}
                            onlabel="Active"
                            offlabel="Inactive"
                            onstyle="outline-success"
                            offstyle="outline-danger"
                            checked={showFormone}
                          />
                        </Col>
                      </Row>
                      {showFormone === true ? (
                        <>
                          <Row>
                            <Col lg={4} md={4} xs={12}>
                              <textarea
                                {...register("Hash", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Hash Variable Inbound"
                                className="CsformText"
                                style={{
                                  // width: "445px",
                                  marginTop: "20px",
                                  height: "30px",
                                }}
                                defaultValue={formData.InHash}
                              />
                              {errors.Hash && (
                                <small
                                  className="errmsg perrmsg"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.Hash.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                              <textarea
                                {...register("hash_fn", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Hash function Inbound"
                                className="CsformText"
                                style={{
                                  // width: "445px",
                                  marginTop: "20px",
                                  height: "30px",
                                }}
                                defaultValue={formData.InHasf}
                              />
                              {errors.hash_fn && (
                                <small
                                  className="errmsg perrmsg"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.hash_fn.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                              <textarea
                                {...register("Variablef", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Variable format Inbound"
                                className="CsformText"
                                style={{
                                  // width: "445px",
                                  marginTop: "20px",
                                  height: "30px",
                                }}
                                defaultValue={formData.Invar_f}
                              />
                              {errors.Variablef && (
                                <small
                                  className="errmsg perrmsg"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.Variablef.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                              <textarea
                                {...register("secret_key", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Secret key Inbound"
                                className="CsformText"
                                style={{
                                  // width: "445px",
                                  marginTop: "20px",
                                  height: "30px",
                                }}
                                defaultValue={formData.InSecretkey}
                              />
                              {errors.secret_key && (
                                <small
                                  className="errmsg perrmsg"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.secret_key.message}
                                </small>
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* <div className="settingHeading">
                        <p className="pBlockHead">Sha 1</p>
                      </div>
                      <div style={{ border: "1px solid whiteSmoke" }}>
                        <div className="rowContant">
                          <Row>
                            <Col lg={6} md={6} xs={12}>
                              {" "}
                              <label
                                className="formlabel"
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                Status inbound*
                              </label>
                              <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                onChange={(e) => {
                                  setShowFormone(e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                              />
                              {showFormone === true ? (
                                <>
                                  <textarea
                                    {...register("Secretkeyinbound1", {
                                      required: "This field is Required",
                                    })}
                                    type="text"
                                    placeholder="Secret key outbound"
                                    className="CsformText"
                                    style={{
                                      // width: "445px",
                                      marginTop: "20px",
                                      height: "30px",
                                    }}
                                  />
                                  {errors.Secretkeyinbound1 && (
                                    <small
                                      className="errmsg perrmsg"
                                      style={{
                                        display: "block",
                                      }}
                                    >
                                      {errors.Secretkeyinbound1.message}
                                    </small>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                              <label
                                className="formlabel"
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                Status outbound*
                              </label>
                              <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                onChange={(e) => {
                                  setShowFormtwo(e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                              />
                              {showFormtwo === true ? (
                                <>
                                  <textarea
                                    {...register("Secretkeyoutbound1", {
                                      required: "This field is Required",
                                    })}
                                    type="text"
                                    placeholder="Secret key outbound"
                                    className="CsformText"
                                    style={{
                                      // width: "445px",
                                      marginTop: "20px",
                                      height: "30px",
                                    }}
                                  />
                                  {errors.Secretkeyoutbound1 && (
                                    <small
                                      className="errmsg perrmsg"
                                      style={{
                                        display: "block",
                                      }}
                                    >
                                      {errors.Secretkeyoutbound1.message}
                                    </small>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="settingHeading">
                        <p className="pBlockHead">Sha 2</p>
                      </div>
                      <div style={{ border: "1px solid whiteSmoke" }}>
                        <div className="rowContant">
                          <Row>
                            <Col lg={6} md={6} xs={12}>
                              <label
                                className="formlabel"
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                Status inbound*
                              </label>
                              <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                // checked={row.status === 1 ? true : false}
                                onChange={(e) => {
                                  // handle_status(row._id, row.status, e);
                                  setShowFormthree(e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                                style={{
                                  marginTop: "5px",
                                }}
                              />
                              {showFormthree ? (
                                <>
                                  <textarea
                                    {...register("Secretkeyinbound2", {
                                      required: "This field is Required",
                                    })}
                                    type="text"
                                    placeholder="Secret key outbound"
                                    className="CsformText"
                                    style={{
                                      // width: "445px",
                                      marginTop: "20px",
                                      height: "30px",
                                    }}
                                  />
                                  {errors.Secretkeyinbound2 && (
                                    <small
                                      className="errmsg perrmsg"
                                      style={{
                                        display: "block",
                                      }}
                                    >
                                      {errors.Secretkeyinbound2.message}
                                    </small>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                              <label
                                className="formlabel"
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                Status outbound*
                              </label>
                              <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                // checked={row.status === 1 ? true : false}
                                onChange={(e) => {
                                  setShowFormsv(e);
                                  // handle_status(row._id, row.status, e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                                style={{
                                  marginTop: "5px",
                                }}
                              />
                              {showFormsv === true ? (
                                <>
                                  <textarea
                                    {...register("Secretkeyoutbound2", {
                                      required: "This field is Required",
                                    })}
                                    type="text"
                                    placeholder="Secret key outbound"
                                    className="CsformText"
                                    style={{
                                      // width: "445px",
                                      marginTop: "20px",
                                      height: "30px",
                                    }}
                                  />
                                  {errors.Secretkeyoutbound2 && (
                                    <small
                                      className="errmsg perrmsg"
                                      style={{
                                        display: "block",
                                      }}
                                    >
                                      {errors.Secretkeyoutbound2.message}
                                    </small>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="settingHeading">
                        <p className="pBlockHead">Sha 3</p>
                      </div>
                      <div style={{ border: "1px solid whiteSmoke" }}>
                        <div className="rowContant">
                          <Row>
                            <Col lg={6} md={6} xs={12}>
                              <label
                                className="formlabel"
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                Status inbound*
                              </label>
                              <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                // checked={row.status === 1 ? true : false}
                                onChange={(e) => {
                                  setShowFormfr(e);
                                  // handle_status(row._id, row.status, e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                              />
                              {showFormfr === true ? (
                                <>
                                  <textarea
                                    {...register("Secretkeyinbound3", {
                                      required: "This field is Required",
                                    })}
                                    type="text"
                                    placeholder="Secret key outbound"
                                    className="CsformText"
                                    style={{
                                      // width: "445px",
                                      marginTop: "20px",
                                      height: "30px",
                                    }}
                                  />
                                  {errors.Secretkeyinbound3 && (
                                    <small
                                      className="errmsg perrmsg"
                                      style={{
                                        display: "block",
                                      }}
                                    >
                                      {errors.Secretkeyinbound3.message}
                                    </small>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                              <label
                                className="formlabel"
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                Status outbound*
                              </label>
                              <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                onChange={(e) => {
                                  setShowFormfi(e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                                style={{
                                  marginTop: "5px",
                                }}
                              />
                              {showFormfi === true ? (
                                <>
                                  <textarea
                                    {...register("Secretkeyoutbound3", {
                                      required: "This field is Required",
                                    })}
                                    type="text"
                                    placeholder="Secret key outbound"
                                    className="CsformText"
                                    style={{
                                      // width: "445px",
                                      marginTop: "20px",
                                      height: "30px",
                                    }}
                                  />
                                  {errors.Secretkeyoutbound3 && (
                                    <small
                                      className="errmsg perrmsg"
                                      style={{
                                        display: "block",
                                      }}
                                    >
                                      {errors.Secretkeyoutbound3.message}
                                    </small>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="settingHeading">
                        <p className="pBlockHead">Sha 4</p>
                      </div>
                      <div style={{ border: "1px solid whiteSmoke" }}>
                        <div className="rowContant">
                          <Row>
                            <Col lg={6} md={6} xs={12}>
                              <label
                                className="formlabel"
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                Status inbound*
                              </label>
                              <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                onChange={(e) => {
                                  setShowFormsix(e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                              />
                              {showFormsix === true ? (
                                <>
                                  <textarea
                                    {...register("Secretkeyinbound4", {
                                      required: "This field is Required",
                                    })}
                                    type="text"
                                    placeholder="Secret key outbound"
                                    className="CsformText"
                                    style={{
                                      // width: "445px",
                                      marginTop: "20px",
                                      height: "30px",
                                    }}
                                  />
                                  {errors.Secretkeyinbound4 && (
                                    <small
                                      className="errmsg perrmsg"
                                      style={{
                                        display: "block",
                                      }}
                                    >
                                      {errors.Secretkeyinbound4.message}
                                    </small>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div> */}
                      <Row
                        style={{
                          marginTop: "15px",
                        }}
                      >
                        <Col lg={4} md={4} xs={12}>
                          {" "}
                          <label
                            className="formlabel"
                            style={{
                              marginBottom: "10px",
                            }}
                          >
                            Status outbound*
                          </label>
                          <BootstrapSwitchButton
                            className="switch btn on btn-outline-success btn-xs"
                            onChange={(e) => {
                              setShowFormtwo(e);
                            }}
                            size="xs"
                            width={80}
                            onlabel="Active"
                            offlabel="Inactive"
                            onstyle="outline-success"
                            offstyle="outline-danger"
                            checked={showFormtwo}
                          />
                        </Col>
                      </Row>
                      {showFormtwo === true ? (
                        <>
                          <Row>
                            <Col lg={4} md={4} xs={12}>
                              <textarea
                                {...register("Hashkeyob", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Hash variable outbound"
                                className="CsformText"
                                style={{
                                  // width: "445px",
                                  marginTop: "20px",
                                  height: "30px",
                                }}
                                defaultValue={formData.OutHash}
                              />
                              {errors.Hashkeyob && (
                                <small
                                  className="errmsg perrmsg"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.Hashkeyob.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                              <textarea
                                {...register("Secretkeyob", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Secret key outbound"
                                className="CsformText"
                                style={{
                                  // width: "445px",
                                  marginTop: "20px",
                                  height: "30px",
                                }}
                                defaultValue={formData.OutSecretkey}
                              />
                              {errors.Secretkeyob && (
                                <small
                                  className="errmsg perrmsg"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.Secretkeyob.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                              <textarea
                                {...register("var_format", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Variable format outbound"
                                className="CsformText"
                                style={{
                                  // width: "445px",
                                  marginTop: "20px",
                                  height: "30px",
                                }}
                                defaultValue={formData.OutHasf}
                              />
                              {errors.var_format && (
                                <small
                                  className="errmsg perrmsg"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.var_format.message}
                                </small>
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                </>
              ) : (
                <></>
              )}
            </form>
          </div>
        </div>
      </>
    </>
  );
};

export default Hashing;
