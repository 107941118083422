import Header from "../../UI/Header/Header";
import "../Tables/StyleDatatable.css";
import { useNavigate, useParams } from "react-router";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./user.css";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import avtar1 from "../../../assets/userAvatar/avtar1.svg";
import avtar2 from "../../../assets/userAvatar/avtar2.svg";
import avtar3 from "../../../assets/userAvatar/avtar3.svg";
import { useForm } from "react-hook-form";
import userIco from "../../../assets/LoginIcons/users.svg";
import Spinner from "react-bootstrap/Spinner";
import SpinnerLoader from "../../UI/Spinner";
import Multiselect from "multiselect-react-dropdown";

const UpdateUser = () => {
  let { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [fname, setfName] = useState();
  const [Mname, setMName] = useState();
  const [lname, setlName] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initial, setInitial] = useState();
  const [contactNum, setcontactNum] = useState();
  const [Email, setEmail] = useState();
  const [selectrole, setSelectRole] = useState();
  const [payloadDept, setPayloadDept] = useState([]);
  const [role, setRole] = useState([]);
  const [departments, setDepartment] = useState([]);
  const [prevDept, setPrevDept] = useState();
  const navigate = useNavigate();
  const [loder, setLoder] = useState(false);
  const [showPlaceHolder, SetShowPlaceHolder] = useState(true);
  const [showDeptState, setDepartState] = useState(true);
  const [roleState, setRoleState] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    getSingleUser();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectrole) {
      setRoleState(false);
    }

    SetShowPlaceHolder(false);
    setValue("payloadDep", payloadDept);
    setValue("Role", selectrole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectrole, payloadDept, role]);

  let deptnameselected;
  const getSingleUser = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(`/user/${id}`, {});

      const comingRole = response.data.user.department;
      comingRole.map(async (role) => {
        setRole([]);
        try {
          const response = await axiosPrivate.get(
            `role/department-roles/?dept_id=${role.id}`,
            {}
          );
          response.data.all_roles.map((ite) => {
            setRole((val) => [...val, { id: ite.id, name: ite.name }]);
          });
        } catch (err) {}
      });

      setPrevDept(response.data.user.department);
      setfName(response.data.user.first_name);
      setMName(response.data.user.middle_name);
      setlName(response.data.user.last_name);
      setInitial(response.data.user.initial);
      setEmail(response.data.user.email);
      setcontactNum(response.data.user.contact_number);
      setSelectRole(response.data.user.role_id);
      deptnameselected = response.data.user.department.map((item) => item.id);

      fetchData();

      setLoder(true);
    } catch (err) {
      if (err.response.status === 404) {
        navigate("/404");
      }
    }
  };
  const fetchData = async () => {
    let i = 0;
    try {
      const response = await axiosPrivate.get(
        "/department/all-departments",
        {}
      );
      setPayloadDept([]);
      while (i < deptnameselected.length) {
        response.data.all_departments.map((depat) => {
          if (depat._id === deptnameselected[i]) {
            setPayloadDept((val) => [
              ...val,
              { id: depat._id, name: depat.name },
            ]);

            setValue("payloadDep", payloadDept);
          }
        });
        i++;
      }

      setDepartment(
        response.data.all_departments.map((department) => ({
          id: department._id,
          name: department.name,
        }))
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleDepartmentOptionRemove = async (department_id) => {
    setRole([]);
    department_id.map(async (item) => {
      try {
        const response = await axiosPrivate.get(
          `role/department-roles/?dept_id=${item.id}`,
          {}
        );

        response.data.all_roles.map((ite) => {
          setRole((val) => [...val, { id: ite.id, name: ite.name }]);
        });
      } catch (err) {
        console.error(err);
      }
    });
  };
  const handleDepartmentOption = async (department_id) => {
    SetShowPlaceHolder(false);

    department_id.map(async (item) => {
      try {
        const response = await axiosPrivate.get(
          `role/department-roles/?dept_id=${item.id}`,
          {}
        );

        response.data.all_roles.map((ite) => {
          setRole((val) => [...val, { id: ite.id, name: ite.name }]);
        });
      } catch (err) {
        console.error(err);
      }
    });
  };

  const handlepostreq = async (e) => {
    setIsSubmitting(true);
    if (e === undefined) {
      setIsSubmitting(false);
    }
    try {
      await axiosPrivate
        .patch(
          `/user/update-user/${id}`,
          JSON.stringify({
            first_name: e.Firstname,
            middle_name: Mname || null,
            last_name: e.LastName,
            email: e.EmailAddress,
            initial: e.initial,

            role_id: e.Role,
            contact_number: e.contactnumber,
            department: e.payloadDep,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          if (e.status === 200) {
            localStorage.setItem("userUpdated", 1);
            navigate("/users");
          }
        });
    } catch (err) {
      if (err.response.status === 422) {
        if (err.response.data.return_status.message) {
        }
      }
    }
  };

  const roleReg = register("Role", {
    required: "This field is Required",
  });

  const departmentReg = register("payloadDep", {
    required: "This field is Required",
  });

  return (
    <>
      {/* <Header></Header> */}
      <div className="top_container">
        <div className="main_container">
          <form onSubmit={handleSubmit(handlepostreq)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img src={userIco} className="headingIcons" alt="" /> Update{" "}
                  {fname}
                </h3>
              </div>
              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={() => {
                    navigate("/users");
                  }}>
                  {" "}
                  Close
                </button>

                <button className="cbtn saveBtn">
                  {isSubmitting && (
                    <Spinner
                      style={{ color: "white" }}
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp;&nbsp; Save
                </button>
              </div>
            </div>
            <hr />
            {loder ? (
              <>
                <div className="mainContent" style={{ minHeight: "400px" }}>
                  <p className="small note">* denotes required fields</p>

                  <Row>
                    <div className="UserleftPane">
                      <img src={avtar1} alt="Option 1" height={140} />
                      <img src={avtar2} alt="Option 2" height={140} />
                      <img src={avtar3} alt="Option 3" height={140} />
                    </div>

                    <div className="UserRightPane">
                      <Row>
                        <Col lg={4} md={6} xs={12} className="col">
                          <label className="formlabel">First Name*</label>

                          <input
                            type="text"
                            placeholder="First Name"
                            className="formtext"
                            {...register("Firstname", {
                              required: "This field is Required",
                              pattern: {
                                value:
                                  /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                                message: "Only letters are allowed",
                              },
                            })}
                            defaultValue={fname}
                          />
                          {errors.Firstname && (
                            <small className="errmsg">
                              {errors.Firstname.message}
                            </small>
                          )}
                        </Col>

                        <Col lg={4} md={6} xs={12} className="col">
                          <label className="formlabel">Middle Name</label>
                          <input
                            {...register("Middlename", {
                              pattern: {
                                value:
                                  /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                                message: "Only letters are allowed",
                              },
                            })}
                            type="text"
                            placeholder="Middle Name"
                            className="formtext"
                            defaultValue={Mname}
                            onChange={(e) => {
                              setMName(e.target.value);
                            }}
                          />
                          {errors.Middlename && (
                            <small className="errmsg">
                              {errors.Middlename.message}
                            </small>
                          )}
                        </Col>
                        <Col lg={4} md={6} xs={12} className="col">
                          <label className="formlabel">Last Name*</label>
                          <input
                            type="text"
                            className="formtext"
                            placeholder="Enter Descriptions"
                            {...register("LastName", {
                              required: "This field is Required",
                              pattern: {
                                value:
                                  /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                                message: "Only letters are allowed",
                              },
                            })}
                            defaultValue={lname}
                          />
                          {errors.LastName && (
                            <small className="errmsg">
                              {errors.LastName.message}
                            </small>
                          )}
                        </Col>

                        <Col lg={4} md={6} xs={12} className="col">
                          <label className="formlabel">Initial*</label>
                          <input
                            type="text"
                            className="formtext"
                            {...register("initial", {
                              required: "This field is Required",
                              pattern: {
                                value:
                                  /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                                message: "Only letters are allowed",
                              },
                            })}
                            defaultValue={initial}
                          />
                          {errors.initial && (
                            <small className="errmsg">
                              {errors.initial.message}
                            </small>
                          )}
                        </Col>

                        <Col lg={4} md={6} xs={12} className="col">
                          <label className="formlabel ">Department*</label>

                          <Multiselect
                            className="userDropdown updateUser"
                            {...departmentReg}
                            displayValue="name"
                            onKeyPressFn={function noRefCheck() {}}
                            onRemove={function noRefCheck(e) {
                              if (e.length === 0) {
                                setRoleState(true);
                                SetShowPlaceHolder(true);
                                setSelectRole();
                                setDepartState(true);
                              } else {
                                setDepartState(false);
                                setSelectRole();
                                setRoleState(true);
                              }
                              handleDepartmentOptionRemove(e);
                              setPayloadDept([]);
                              e.map((it) => {
                                setPayloadDept((val) => [
                                  ...val,
                                  { id: it.id, name: it.name },
                                ]);
                              });
                            }}
                            onSearch={function noRefCheck() {
                              SetShowPlaceHolder(false);
                            }}
                            onSelect={function noRefCheck(e) {
                              setSelectRole();
                              setDepartState(false);
                              setRoleState(true);
                              setPayloadDept([]);
                              e.map((it) => {
                                setPayloadDept((val) => [
                                  ...val,
                                  { id: it.id, name: it.name },
                                ]);
                              });
                              setRole([]);

                              handleDepartmentOption(e);
                            }}
                            options={departments}
                            value={prevDept}
                            placeholder={
                              showPlaceHolder === true
                                ? "Option"
                                : payloadDept.map((item) => item.name)
                            }
                            selectedValues={payloadDept}
                            showCheckbox
                          />
                          {showDeptState === false ? (
                            <></>
                          ) : (
                            errors.payloadDep && (
                              <small className="errmsg">
                                {errors.payloadDep.message}
                              </small>
                            )
                          )}
                        </Col>
                        <Col lg={4} md={6} xs={12} className="col">
                          <label className="formlabel">Roles*</label>

                          <select
                            className="formtext"
                            {...roleReg}
                            onChange={(e) => {
                              setSelectRole(e.target.value);
                              setValue("Role", e.target.value);
                              setRoleState(false);
                            }}
                            value={selectrole}>
                            <option value="">Option</option>
                            {role.map((e, index) => (
                              <>
                                <option value={e.id}>{e.name}</option>
                              </>
                            ))}
                          </select>
                          {roleState === true ? (
                            errors.Role && (
                              <small className="errmsg">
                                {errors.Role.message}
                              </small>
                            )
                          ) : (
                            <></>
                          )}
                        </Col>

                        <Col lg={4} md={6} xs={12} className="col">
                          <label className="formlabel">Email Address*</label>
                          <input
                            className="formtext"
                            {...register("EmailAddress", {
                              required: "This field is Required",
                              pattern: {
                                value:
                                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\],;:\s@\"]+)*)|(.+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@]{2,})$/i, //eslint-disable-line
                                message: "Enter Valid Email Id",
                              },
                            })}
                            defaultValue={Email}
                          />
                          {errors.EmailAddress && (
                            <small className="errmsg">
                              {errors.EmailAddress.message}
                            </small>
                          )}
                        </Col>
                        <Col lg={4} md={6} xs={12} className="col">
                          <label className="formlabel">Contact Number *</label>
                          <input
                            type="text"
                            className="formtext"
                            {...register("contactnumber", {
                              required: "This field is Required",
                              pattern: {
                                value: /^[01223456789 ]*$/,
                                message: "Only numbers are allowed",
                              },
                            })}
                            defaultValue={contactNum}
                          />
                          {errors.contactnumber && (
                            <small className="errmsg">
                              {errors.contactnumber.message}
                            </small>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Row>
                </div>
              </>
            ) : (
              <SpinnerLoader />
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateUser;
